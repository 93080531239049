import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ActivatedRoute, Router } from '@angular/router';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { SocketServiceService } from '../services/socket-service.service';
import { ReportService } from '../services/report.service';
import { environment } from '../../environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import * as moment from 'moment';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-pl-user-bet',
  templateUrl: './pl-user-bet.component.html',
  styleUrls: ['./pl-user-bet.component.scss']
})
export class PlUserBetComponent implements AfterViewInit, OnDestroy, OnInit {
  match_id: any;
  match_name: any;
  moment: any = moment;
  @ViewChild('popoverRef') private _popoverRef: PopoverDirective;
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  time: Date;
  date: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  dateTime = new Date();
  statementStartDate = new Date()
  statementEndDate = new Date()
  pageSize: number = 50;
  statementsTotalItems: any;
  statementsPageIndex: number;
  timeStatement: Date;
  dateStatement: Date;
  endDateStatement: Date;
  endTimeStatement: Date;
  endTime: any;
  market_id: any;
  typeId: any;
  data: any;
  betList: any;
  public Base_Url = environment['adminServerUrl'];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public token = localStorage.getItem('userAccessToken');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });

  constructor(private http: HttpClient, private route: ActivatedRoute, private sport: SportService, private reportService: ReportService,
    private cookie: CookieService, private toastr: ToastrService, private socketService: SocketServiceService, private router: Router) {
    this.route.params.subscribe(params => {
      this.match_id = params.matchId;
      this.market_id = params.marketId;
      this.typeId = params.type;
    })
  }

  ngOnInit(): void {
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      searching: false,
      paging: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        if (this.typeId == 1) {
          dataTablesParameters = {
            match_id: this.match_id,
            search: {
              market_id: this.market_id,
            }
          }
        } else {
          dataTablesParameters = {
            match_id: this.match_id,
            search: {
              fancy_id: this.market_id,
            }
          }
        }
        that.http.post<DataTablesResponse>(this.Base_Url + 'bet/plBets', dataTablesParameters, { headers: this.reqHeader })
          .subscribe(resp => {
            if (resp["status"]) {
              this.betList = resp.data;
              for (let i = 0; i < this.betList.length; i++) {
                this.betList[i].updateDateTime = moment(this.betList[i].createdAt).utcOffset("+5:30").format('DD-MM-YYYY HH:mm:ss');
                if (this.betList[i].is_fancy == 0) {
                  this.betList[i].updateSelection = this.betList[i].selection_name;
                  this.betList[i].updateOdds = this.betList[i].odds;
                  this.betList[i].updatePl = this.betList[i].p_l + '/' + this.betList[i].liability
                  if (this.betList[i].is_back == 1) {
                    this.betList[i].updateType = 'Back'
                  } else {
                    this.betList[i].updateType = 'Lay'
                  }
                } else {
                  this.betList[i].updateSelection = this.betList[i].odds;
                  this.betList[i].updateOdds = this.betList[i].odds + '/' + this.betList[i].size;
                  this.betList[i].updatePl = this.betList[i].chips + '/' + this.betList[i].liability
                  if (this.betList[i].is_back == 1) {
                    this.betList[i].updateType = 'Yes'
                  } else {
                    this.betList[i].updateType = 'No'
                  }
                }
              }
              callback({
                recordsTotal: this.betList.length,
                recordsFiltered: this.betList.length,
                data: this.betList
              });

            } else {
              this.toastr.error(resp["msg"]);
              this.betList = []
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: this.betList
              });
            }


          });
      },
      columns: [
        {
          title: 'Sport Name',
          data: 'sport_name',
        },
        {
          title: 'Event Name',
          data: 'match_name',
        },
        {
          title: 'Market Name',
          data: 'market_name',
        },
        {
          title: 'Selection',
          data: 'updateSelection',
        },
        {
          title: 'Bet Type',
          data: 'updateType',
        },
        {
          title: 'User Price',
          data: 'updateOdds',
        },
        {
          title: 'Amount',
          data: 'stack',
        },
        {
          title: 'Profit/Loss',
          data: 'liability',
          "render": function (data, type, row) {
            var content = ''
            if (row.is_fancy == 0) {
              if (row.p_l != null && row.p_l < 0) {
                content = content + '<span class="text-center text-danger">' + (row.p_l).toFixed(2) + '</span>/ <span class="text-center text-danger">' + row.liability + ' </span>';
              } else if (row.p_l != null && row.p_l >= 0) {
                content = content + '<span class="text-center text-success">' + (row.p_l).toFixed(2) + '</span> / <span class="text-center text-danger">' + row.liability + ' </span>';
              } else {
                content = '';
              }
            } else {
              if (row.chips != null && row.chips < 0) {
                content = content + '<span class="text-center text-danger">' + (row.chips).toFixed(2) + '</span>/ <span class="text-center text-danger">' + row.liability + ' </span>';
              } else if (row.chips != null && row.chips >= 0) {
                content = content + '<span class="text-center text-success">' + (row.chips).toFixed(2) + '</span> / <span class="text-center text-danger">' + row.liability + ' </span>';
              } else {
                content = '';
              }
            }

            return content;
          }
        },
        {
          title: 'Placed Time',
          data: 'updateDateTime',
        },
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $(row).addClass('text-center');
        return row;
      }
    };
  }
  userBets() {
    if (this.typeId == 1) {
      this.data = {
        match_id: this.match_id,
        search: {
          market_id: this.market_id,
        }
      }
    } else {
      this.data = {
        match_id: this.match_id,
        search: {
          fancy_id: this.market_id,
        }
      }
    }
    this.rerender();
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  statement() {
    this.router.navigate(['statement'])
  }
  profitLoss() {
    this.router.navigate(['profit-loss'])
  }
  info() {
    this.router.navigate(['info'])
  }
  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  rollComission() {
    this.router.navigate(['rollComission'])
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
