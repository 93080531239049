<div class="app-body">
  <app-header></app-header>
  <div class="full-wrap">
    <div class="col-left d-none-mobile">
      <!-- <app-sidebar></app-sidebar> -->
      <div class="sub_path">
        <div class="path">
          <a  class="path-back">...</a>
          <ul>
            <li class="path-last"><a>My Account</a></li>
          </ul>
        </div>
        <ul class="menu-list" data-widget="tree">
          <li>
            <a (click)="info()" class="select">
              <span>My Profile</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="rollComission()">
              <span>Rolling Commision</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="statement()">
              <span>Account Statement</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/betHistory','openBets']">
              <span>Bets History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="profitLoss()">
              <span>Profit & Loss</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="history()">
              <span>Password History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="activity()">
              <span>Activity Log</span><span class="pull-right-container"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div id="centerColumn" class="col-center report d-none-mobile">

      <h2>Account Details</h2>
      <div class="event-left">
        <div class="profile-wrap">
          <h3>About You</h3>
          <dl>
            <dt>First Name</dt>
            <dd>{{userDetails?.user_name}}</dd>

            <dt>Last Name</dt>
            <dd>--</dd>

            <dt>Birthday</dt>
            <dd>--</dd>

            <dt>E-mail</dt>
            <dd>--</dd>

            <dt>Password</dt>
            <dd>********************************
              <a class="favor-set" (click)="openModalChangePwd(changePwd);">Edit</a>
            </dd>
          </dl>
        </div>
        <div class="profile-wrap">
          <h3>Address</h3>
          <dl>
            <dt>Address</dt>
            <dd>--</dd>

            <dt>Town/City</dt>
            <dd>--</dd>

            <dt>Country</dt>
            <dd>--</dd>

            <dt>Country/State</dt>
            <dd>--</dd>

            <dt>Postcode</dt>
            <dd>--</dd>

            <dt>Timezone</dt>
            <dd>IST</dd>
          </dl>
        </div>
      </div>
      <div class="event-right">
        <div class="profile-wrap">
          <h3>Contact Details</h3>
          <dl>
            <dt>Primary number</dt>
            <dd>--</dd>
          </dl>
        </div>

        <div class="profile-wrap">
          <h3>Setting</h3>
          <dl>
            <dt>Currency</dt>
            <dd>PTH</dd>

            <dt>Odds Format</dt>
            <dd>--</dd>
          </dl>
        </div>

        <div class="profile-wrap">
          <h3>Commission</h3>
          <dl>
            <dt>Comm charged</dt>
            <dd>0</dd>
          </dl>
        </div>
      </div>
    </div>

    <div class="d-lg-none">
      <div class="mian-wrap">
        <div class="path-wrap">
          <p class="account-id">
            <span>{{userDetails?.user_name}}</span>
            <span class="time-zone">GMT+5:30</span>
          </p>
        </div>
        <ul class="menu-list">
          <li>
            <a (click)="info()">
              <span>My Profile</span>
            </a>
          </li>
          <li>
            <a (click)="rollComission()">
              <span>Rolling Commision</span>
            </a>
          </li>
          <li>
            <a (click)="statement()">
              <span>Account Statement</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/deposit']">
              <span>Deposit</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/withdraw']">
              <span>Withdraw</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/betHistory','openBets']">
              <span>Bets History</span>
            </a>
          </li>
          <li>
            <a (click)="profitLoss()">
              <span>Profit & Loss</span>
            </a>
          </li>
          <li>
            <a (click)="history()">
              <span>Password History</span>
            </a>
          </li>
          <li>
            <a (click)="activity()">
              <span>Activity Log</span>
            </a>
          </li>
        </ul>
        <a id="logout" class="logout" (click)="logoutUser()">LOGOUT</a>
      </div>
    </div>
  </div>
  <app-footer-main></app-footer-main>
</div>

<ng-template #changePwd>
  <a class="close_pop" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">close_pop</a>
  <h3>Change Password</h3>
  <dl class="form_list" [formGroup]='changePasswordForm' (ngSubmit)='onSubmitChangePassword()'>
    <dt>Old Password</dt>
    <dd>
      <input type="password" placeholder="Enter" formControlName='old_password'>
      <span class="must">＊</span>
    </dd>
    <dt>New Password </dt>
    <dd>
      <input type="password" placeholder="Enter" formControlName='new_password'>
      <span class="must">＊</span>
    </dd>
    <dt>New Password Confirm</dt>
    <dd>
      <input type="password" placeholder="Enter" formControlName='confirm_password'>
      <span class="must">＊</span>
    </dd>
    <dd><a id="changePasswordBtn" (click)="onSubmitChangePassword()" class="btn-send">Change</a></dd>
  </dl>

</ng-template>