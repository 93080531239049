import { Component, OnInit, TemplateRef, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { OpenApiService } from '../services/open-api.service';
import { Match } from '../model/match';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service'
import { ToastrService } from 'ngx-toastr';
import { param } from 'jquery';
import { SafeResourceUrl } from '@angular/platform-browser';
import { SportService } from '../services/sport.service';
import { UsersService } from '../services/users.service';
@Component({
  selector: 'app-open-match-detail',
  templateUrl: './open-match-detail.component.html',
  styleUrls: ['./open-match-detail.component.scss']
})
export class OpenMatchDetailComponent implements OnInit {
  Form: FormGroup;
  loginButtonDisable = false
  submitted = false;
  transactionPassword: string;
  showResponseMessage;
  public showPassword: boolean;
  private clickTimeout = null;
  a: any;
  modalRef: BsModalRef;
  matchData: any;
  matchDetail: any;
  activeBetTab: string = 'all';
  betslipinfo: boolean;
  betPrice: any;
  arrayObj: any = [];
  public IsToggle = false;
  private oddBetSlipValArray: any = [];
  public oneClickSelectedBet;
  betslipStatus: boolean = false;
  selection: any;
  market: any;
  betSize: any;
  fancyIdValidation: any;
  stakeIds: any;
  stackval: any;
  isActive: boolean;
  total_liability: any;
  config_max_odd_limit: any;
  setRef: any;
  fancy: any = [];
  showFancyList: boolean;
  userMatchStack: any;
  matchDetailData: any;
  match_id: any;
  loading: boolean;
  popFancyId: any;
  popShow: boolean;
  logo: string;
  selectedBackBet: any
  selectedLayBet: any
  selectedLayBetDetails: any;
  selectedBackBetDetails: any;
  betType: any;




  activeScoreTab: string = 'scoreBoard';
  activeScoreDiv: string = 'scoreBoardDiv';
  fancytimer: any;
  matchDetailLength: number;

  userDetails: any;

  user_id: any;
  betData: any;
  matchedBets: any;
  fancyBets: any;
  liveTv: string;
  graphicTv: string;
  liveUrl: SafeResourceUrl;
  graphicTvUrl: SafeResourceUrl;
  matchName: any;
  matchDate: any;
  tv: boolean = false;
  graph: boolean = false;
  scoreData: any;
  scoreBoard: any;
  scoreLength: any;
  score: boolean;
  perball: any;
  callingFancyType = 1
  matchLength: any;
  inplayStatus: boolean;
  teamPositionData: any;
  selectedAllBetFilter: any;
  selectedPopAllBetFilter: any;
  selectedFancyBetFilter: any;
  selectedMatchBetFilter: any
  allBetData: any
  graphicScore: boolean;
  allPopBetData: any;
  selectedMarket: any;
  matchedBetsLength: any;
  fancyBetsLength: any;
  allBetDataLength: any;
  disablePlaceBet: boolean = false;
  disableFancyPlaceBet: boolean = false;
  fancyLoading: boolean;
  fancyPosData: any;
  displayfancyPositionBox: Array<boolean> = []
  selectedFancyMarket: any;
  applyUserValidation: boolean;
  fancyLiability: any;
  twenty: boolean;
  param: any;
  marketIds: any = [];
  marketRunnerData: any;
  matchDetailFirst: any;
  runnerObjectData: any = [];
  objectData: any;
  marketObjectData: any;
  sampleObjectData: any;
  sportsSettingValues: any;
  showSetting: boolean;
  sessionSetting: any;
  fancySetting: boolean;
  check_event_limit: any;
  fancyLength: any;
  betDataPopup: boolean;
  message: string;
  fancyDataArray = [];
  fancyArray: any;
  fancyRunnerData: any;
  primaryFancy: any;
  ring: boolean = false;
  ringOn: boolean = false;
  walletBalance: any;
  currentExpo: number;
  currentBalance: number;
  detailCalling = 1;
  fancyId: any = [];
  fancyDta: any;
  createFancyType: number = 1;
  fancyTimer: any;
  pageSize: number;
  dataSource: any;
  openBetsLength: any = 0;
  MatchList: any;
  searchMatch: any;
  layData: any = [];
  backData: any = [];
  cuurency: string;
  showBet: any = 0;
  tvId: any = 0;
  exposuerLimit: any;
  marketLiability: any;
  fancyExpoLiability: any;
  marketCurrentData: any;
  marketPL: number;
  marketLiabilityCondition: boolean;
  currentFancyLiability: any;
  fancySource: any;
  totalMaxLoss: number;
  volumeLimit: boolean = false;
  minVolLimit: boolean = false;
  sportsList: any;
  b: any;
  homeDataCount: any;
  userData: {};
  firstData: any;
  firstDataLength: any;
  runnerDataLength: any;
  runnerData: any;
  updateRunnerData: any;
  tPassword: string;
  constructor(private ref: ChangeDetectorRef, private route: ActivatedRoute, private open: OpenApiService, public matchModel: Match, private toastr: ToastrService,
    private router: Router, private modalService: BsModalService, private fb: FormBuilder, private loginService: LoginService, private sportService: SportService,
    private usersService: UsersService,) {

    this.matchData = JSON.parse(localStorage.getItem('matchData'));
    this.route.params.subscribe(params => {
      if (params.match_id == undefined) {
        this.match_id = this.matchData.match_id;
        this.getMatchDetail()
      } else {
        this.match_id = params.match_id
        this.getMatchDetail()
      }
    })
  }

  ngOnInit(): void {
    this.createRandomTransactionPassword()
    this.logo = localStorage.getItem('logo')
    this.createFrom();
    this.userMatchStack = [
      1000,
      5000,
      10000, 25000, 50000,
      100000, 200000,
      500000
    ]
  }
  getMatchDetail() {
    this.loading = true;
    let data = {
      "match_id": this.match_id
    }
    this.open.matchDetails(data).subscribe((res) => {
      this.loading = false;
      this.matchDetailData = res.data;
      for (let i = 0; i < this.matchDetailData.length; i++) {
        if (this.matchDetailData[i].hasOwnProperty('matched')) {
          if (this.matchDetailData[i].matched > 999 && this.matchDetailData[i].matched < 1000000) {
            this.matchDetailData[i].matched = (this.matchDetailData[i].matched / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
          } else if (this.matchDetailData[i].matched > 1000000) {
            this.matchDetailData[i].matched = (this.matchDetailData[i].matched / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
          } else if (this.matchDetailData[i].matched < 900) {
            this.matchDetailData[i].matched = this.matchDetailData[i].matched; // if value < 1000, nothing to do
          }
        } else {
          this.matchDetailData[i].matched = 0;
        }
        for (let o = 0; o < this.matchDetailData[i].runners.length; o++) {
          if ((this.matchDetailData[i].runners[o].ex.availableToBack).length != 0) {
            for (let m = 0; m < (this.matchDetailData[i].runners[o].ex.availableToBack).length; m++) {
              if (this.matchDetailData[i].runners[o].ex.availableToBack[m] != null) {
                if (this.matchDetailData[i].runners[o].ex.availableToBack[m].size > 999 && this.matchDetailData[i].runners[o].ex.availableToBack[m].size < 1000000) {
                  this.matchDetailData[i].runners[o].ex.availableToBack[m].size = (this.matchDetailData[i].runners[o].ex.availableToBack[m].size / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                } else if (this.matchDetailData[i].runners[o].ex.availableToBack[m].size > 1000000) {
                  this.matchDetailData[i].runners[o].ex.availableToBack[m].size = (this.matchDetailData[i].runners[o].ex.availableToBack[m].size / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                } else if (this.matchDetailData[i].runners[o].ex.availableToBack[m].size < 900) {
                  this.matchDetailData[i].runners[o].ex.availableToBack[m].size = this.matchDetailData[i].runners[o].ex.availableToBack[m].size; // if value < 1000, nothing to do
                }
              }
            }
          }
          if ((this.matchDetailData[i].runners[o].ex.availableToLay).length != 0) {
            for (let n = 0; n < (this.matchDetailData[i].runners[o].ex.availableToLay).length; n++) {
              if (this.matchDetailData[i].runners[o].ex.availableToLay[n] != null) {
                if (this.matchDetailData[i].runners[o].ex.availableToLay[n].size > 999 && this.matchDetailData[i].runners[o].ex.availableToLay[n].size < 1000000) {
                  this.matchDetailData[i].runners[o].ex.availableToLay[n].size = (this.matchDetailData[i].runners[o].ex.availableToLay[n].size / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
                } else if (this.matchDetailData[i].runners[o].ex.availableToLay[n].size > 1000000) {
                  this.matchDetailData[i].runners[o].ex.availableToLay[n].size = (this.matchDetailData[i].runners[o].ex.availableToLay[n].size / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
                } else if (this.matchDetailData[i].runners[o].ex.availableToLay[n].size < 900) {
                  this.matchDetailData[i].runners[o].ex.availableToLay[n].size = this.matchDetailData[i].runners[o].ex.availableToLay[n].size; // if value < 1000, nothing to do
                }
              }
            }
          }
        }
      }

      this.matchDetail = this.matchDetailData;
      this.ref.detectChanges();
      let matchOddds
      this.matchDetail.forEach((matDta, index) => {
        if (matDta.market_name == 'Match Odds') {
          matchOddds = this.matchDetail.splice(index, 1)
        }
      });

      this.matchDetail.unshift(matchOddds[0])
      console.log("matchDetail", this.matchDetail)
    }, (err) => {
      // this.scoreApi();
    })

  }

  clearAll = function () {
    this.betslipinfo = false;
    this.betslipStatus = false;
    this.IsShowBetInfo = false;
    this.total_liability = 0;
    this.isActive = false;
    for (let i = 0; i < this.matchModel.stakeIds.length; i++) {
      this.matchModel.stake2['field_' + this.matchModel.stakeIds[i].UId] = 0;
    }
  };

  getBetSlipDataForOdds() {

    this.matchModel.tempArray = [];
    let oddBetSlipVal = JSON.parse(localStorage.getItem('betList'));
    this.oddBetSlipValArray.push(oddBetSlipVal);
    this.matchModel.tempArray.push(oddBetSlipVal);
    if (this.IsToggle) {
      this.matchModel.tempArray[0].stake = this.oneClickSelectedBet;
      this.matchModel.tempArray[0].p_l = ((this.matchModel.tempArray[0].odds * this.matchModel.tempArray[0].stake) - this.matchModel.tempArray[0].stake);
    }
  }
  hideClose() {
    this.betslipStatus = false;
  }
  activeBetslip() {
    this.betslipStatus = true;
  }
  trackHero(index, runner) {
    // return hero ? hero.id : undefined;
  }

  setbtn(indx) {
    this.setRef = indx;
  }
  addClassWhenOddChange(newSize, oldSize, newPrice, oldPrice, i, type) {
    if ((newSize != "--" && oldSize != "--") || (newPrice != "--" && oldPrice != "--")) {
      if ((newSize != oldSize || newPrice != oldPrice)) {
        return 'backColorChange' + i;
      }

    }
  }

  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe: [false]
    })
    if (JSON.parse(localStorage.getItem('RememberMe'))) {
      this.Form.patchValue({
        user_name: localStorage.getItem('user_name'),
        password: localStorage.getItem('password'),
        isRememberMe: localStorage.getItem('RememberMe')
      })
    }
  }
  get f() { return this.Form.controls; }

  openModalLoginPopup(loginPopup: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginPopup,
      Object.assign({}, { class: 'loginModal modal-dialog-centered modal-fullscreen-lg-down', ignoreBackdropClick: true })
    );
  }
  openModalCondition(Terms: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      Terms,
      Object.assign({}, { class: 'modal-dialog modal-dialog-scrollable' })
    );
  }
  popOver(data) {
    this.popFancyId = data.fancy_id;
    this.popShow = true;
    setTimeout(() => (this.closePopOver()), 5000)
  }
  closePopOver() {
    this.popShow = false;
  }
  status: boolean = false;
  betLimit() {
    this.status = true;
  }
  betLimitClose() {
    this.status = false;
  }
  infoStatus: boolean = false;
  infoClick() {
    this.infoStatus = true;
  }
  infoClose() {
    this.infoStatus = false;
  }
  fancyInfoStatus: boolean = false;
  fancyInfoClick() {
    this.fancyInfoStatus = true;
  }
  fancyInfoClose() {
    this.fancyInfoStatus = false;
  }

  onClickOfBet(matchData, betType, clickedBetDetails) {
    this.betType = betType
    if (betType === "Back") {
      this.selectedBackBet = matchData
      this.selectedBackBetDetails = clickedBetDetails

    } else if (betType === "Lay") {
      this.selectedLayBet = matchData
      this.selectedLayBetDetails = clickedBetDetails
    }
  }

  cancelAllBets() {
    this.betType = null;
    this.selectedBackBet = null;
    this.selectedBackBetDetails = null;
    this.selectedLayBet = null;
    this.selectedLayBetDetails = null;
  }

  // Calculation on Bet

  getOddsValue(price, size, isback, name, id, marketid, index, marketitem) {
    this.matchData = marketitem;
    this.marketCurrentData = marketitem;
    this.ring = false;
    this.betslipinfo = true;
    this.betslipStatus = true;
    this.betPrice = price;
    this.selection = id;
    this.market = marketid;
    this.arrayObj = {
      selection_id: id,
      market_id: marketid,
      size: size,
      odds: price,
      stake: 0,
      is_back: isback,
      is_fancy: 0,
      MarketName: marketitem.market_name,
      placeName: name,
      isManual: 0,
      is_session_fancy: 'N',
      inplay: marketitem.inplay,
    };
    // localStorage.setItem('type',type);
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }

  calculateP_lOnStack(stake, stkbtn, isback, back) {

    this.stakeIds = this.matchModel.stakeIds;
    this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
    back.stake = parseFloat(stkbtn) + parseFloat(this.stackval);
    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = ((back.odds * back.stake) - back.stake);
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;
      }
      this.matchModel.calculateProfitLoss(back);
      if (back.odds <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
        if (back.odds > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = ((pval * back.stake) - back.stake);
          } else {
            back.p_l = ((back.odds * back.stake) - back.stake);
          }
          this.matchModel.ProfitLoss = back.p_l;


        }
      }
    } else {
      let msg = '';
      if (back.is_session_fancy == 'Y') {
        msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
      } else {
        msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }

  //login popup related code 
  openModalLoginDesktop(loginDesktop: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginDesktop,
      Object.assign({}, { class: 'loginModalDesktop loginModal modal-dialog-centered modal-fullscreen-lg-down', ignoreBackdropClick: true })
    );
  }

  async onSubmitLogin(from?: string) {
    this.loginButtonDisable = true
    if (this.clickTimeout) {
      this.setClickTimeout(() => { });
    } else {
      this.setClickTimeout(() => {
        if (from == 'login') {
          this.headerSingleLoginClick()
        } else {
          this.handleSingleLoginClick();
        }
      })
    }
  }
  public handleSingleLoginClick() {
    this.loading = true;
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if (this.tPassword == this.transactionPassword) {
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          console.log("504", result);
          this.modalRef.hide();
          this.loading = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
          this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.loading = false;
          this.showResponseMessage = result.msg;
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loading = false;
        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loading = false;
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }
  }
  public headerSingleLoginClick() {
    this.loading = true;
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if (this.tPassword == this.transactionPassword) {
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.loading = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
          this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.loading = false;
          this.showResponseMessage = result.msg;
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loading = false;
        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loading = false;
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }
  }
  public setClickTimeout(callback) {
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async setCokkies(result) {
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    var acctoken = localStorage.getItem('userAccessToken');
    this.router.navigate(['dashboard'])
  }

  createRandomTransactionPassword() {
    //initialize a variable having alpha-numeric characters
    var chars = "0123456789";

    //specify the length for the new string to be generated
    var string_length = 4;
    var randomstring = '';

    //put a loop to select a character randomly in each iteration
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    this.transactionPassword = randomstring;
  }

}
