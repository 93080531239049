export const themes = {
  oceanBlueThemProps: {
    '--background': '#F4F8FC',
    '--text-color': '#0F0F0F',
    '--header-background': '#FFFFFF',
    '--blue-text': '#003060'
  },
  darkThemProps: {
    '--background': '#282828',
    '--text-color': 'whitesmoke',
    '--header-background': '#3d3d3d',
    '--blue-text': '#fff'
  },
};
