import { Component, OnInit, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrls: ['./withdraw.component.scss']
})
export class WithdrawComponent implements OnInit {
  userDetails: any;
  detailList: any;
  amount: any;
  showPopUp: boolean;
  walletBalance: any;
  updatedBalance: number;
  showPopUpError: boolean;
  loading: boolean = false;
  index: any = 0;

  constructor(private router: Router, private sportService: SportService, private toastr: ToastrService, private cdRef: ChangeDetectorRef, private usersService: UsersService, private sport: SportService) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.getUserBalance();
    this.list();
  }

  info() {
    this.router.navigate(['info'])
  }

  rollComission() {
    this.router.navigate(['rollComission'])
  }

  statement() {
    this.router.navigate(['statement'])
  }

  profitLoss() {
    this.router.navigate(['profit-loss'])
  }

  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  
  withdrawdetails() {
    this.router.navigate(['withdraw-details'])
  }

  list() {
    this.loading = true;
    let data = {
      user_id: this.userDetails._id,
      type: 'WITHDRAW_REQUEST'
    }
    this.sport.statementDetail(data).subscribe((res) => {
      if (res.status == true) {
        this.detailList = res.data.getData;
      } else {
        this.toastr.error(res.msg)
      }
      this.loading = false;
    })
  }

  withdrawData() {
    this.loading = true;
    this.index = 1;
    this.cdRef.detectChanges()
    if (this.amount > 0) {
      let data = {
        user_id: this.userDetails._id,
        parent_id: this.userDetails.parent_id,
        amount: parseInt(this.amount),
        crdr: 2,
        remark: 'wallet'
      }
      this.sport.paymentWithdraw(data).subscribe((res) => {
        if (res.status == true) {
          this.amount = 0;
          this.toastr.success(res.msg);
          this.list()
        } else {
          this.toastr.error(res.msg)
        }
        this.index = 0;
        this.cdRef.detectChanges()
        this.loading = false;
      })
    } else {
      this.toastr.error('Please Enter Amount ...')
    }
  }

  validate() {
    if (this.updatedBalance >= (this.amount)) {
      this.showPopUpError = false;
      if (this.amount > 0) {
        this.showPopUp = true;
      } else {
        this.showPopUp = false;
      }
      this.cdRef.detectChanges();
    } else {
      this.showPopUpError = true;
      this.showPopUp = false;
      this.cdRef.detectChanges();
    }
  }
  
  getUserBalance() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    let data = {};
    this.sportService.getUserBalance(data).subscribe(response => {
      if (response.status == true) {
        this.walletBalance = response.data;
        this.updatedBalance = (this.walletBalance.balance) - 100;
        this.cdRef.detectChanges();
      } else {
        this.toastr.error(response.msg, '', {
          timeOut: 10000,
        });
        this.cdRef.detectChanges();
      }
    })
  }

}
