import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHeaderResponse, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class OpenApiService {
  public Base_Url = environment['adminServerUrl'];
  constructor(private http: HttpClient,) { }

  getAllSportsList(request) {
    return this.http.post<any>(this.Base_Url + 'sport/sports', request);
  }
  getSeriesList(request) {
    return this.http.post<any>(this.Base_Url + 'series/series', request);
  }
  getMatchList(request) {
    return this.http.post<any>(this.Base_Url + 'match/matches', request);
  }
  getSearchMatchList(request) {
    return this.http.post<any>(this.Base_Url + 'match/matchesList', request);
  }
  getHomeMatchesList(request) {
    return this.http.post<any>(this.Base_Url + 'match/homeMatchesOpen', request);
  }
  matchDetails(request) {
    return this.http.post<any>(this.Base_Url + 'match/matchDetailsOpen', request);
  }
  showFancyList(request) {
    return this.http.post<any>(this.Base_Url + 'fancy/fancies', request);
  }
  footerContent() {
    return this.http.get<any>(this.Base_Url + 'content/footer-items');
  }
  getBannerImage() {
    return this.http.get<any>(this.Base_Url + 'content/sliders');
  }
  popUpContent(param) {
    return this.http.post<any>(this.Base_Url + 'content/get',param);
  }
}
