import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { MatchDetailComponent } from './match-detail/match-detail.component';
import { HomeComponent } from './home/home.component';
import { InplayComponent } from './inplay/inplay.component';
import { StatementComponent } from './statement/statement.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import { DataTablesModule } from 'angular-datatables';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
// import { DatetimePopupModule } from 'ngx-bootstrap-datetime-popup';
import { Match } from '../app/model/match';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import {ErrorInterceptor} from './helper/error.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgHttpLoaderModule } from 'ng-http-loader';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS,OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {MatMenuModule} from '@angular/material/menu';
import { OrderByPipe } from './order-by.pipe';
import { ThemeModule } from './theme/theme.module';
import {MatIconModule} from '@angular/material/icon';
import { SearchPipe } from './pipes/search.pipe';
import {ReportService} from './services/report.service';
import {MatPaginatorModule} from '@angular/material/paginator';
import { NgSelectModule } from '@ng-select/ng-select';
import { CasinoComponent } from './casino/casino.component';
import { FooterComponent } from './footer/footer.component';
import { SupernovaComponent } from './supernova/supernova.component';
import { EventplComponent } from './eventpl/eventpl.component';
import { PlMatchWiseComponent } from './pl-match-wise/pl-match-wise.component';
import { PlMarketwiseComponent } from './pl-marketwise/pl-marketwise.component';
import { PlUserBetComponent } from './pl-user-bet/pl-user-bet.component';
import { InfoComponent } from './info/info.component';
import { HistoryComponent } from './history/history.component';
import { ActivitylogComponent } from './activitylog/activitylog.component';
import { RollingComissionComponent } from './rolling-comission/rolling-comission.component';
import { OpenSidebarComponent } from './open-sidebar/open-sidebar.component';
import { OpenHeaderComponent } from './open-header/open-header.component';
import { OpenDashboardComponent } from './open-dashboard/open-dashboard.component';
import { OpenMatchDetailComponent } from './open-match-detail/open-match-detail.component';
import { OpenInplayComponent } from './open-inplay/open-inplay.component';
import { OpenMultiMarketComponent } from './open-multi-market/open-multi-market.component';
import { MultiMarketComponent } from './multi-market/multi-market.component';
import { FooterMainComponent } from './footer-main/footer-main.component';
import { OpenFooterComponent } from './open-footer/open-footer.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { BetHistoryComponent } from './bet-history/bet-history.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PlUserBetSnComponent } from './pl-user-bet-sn/pl-user-bet-sn.component';
import { OpenHomeComponent } from './open-home/open-home.component';
import { OpenNewsComponent } from './open-news/open-news.component';
import { QtechComponent } from './qtech/qtech.component';
import { RegisterComponent } from './register/register.component';
import { DepositComponent } from './deposit/deposit.component';
import { WithdrawComponent } from './withdraw/withdraw.component';
import { WithdrawDetailComponent } from './withdraw-detail/withdraw-detail.component';
// import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';
export const MY_CUSTOM_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric',second:'numeric'},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
  };
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    LoginComponent,
    MatchDetailComponent,
    HomeComponent,
    InplayComponent,
    StatementComponent,
    ProfitLossComponent,
    OrderByPipe,
    SearchPipe,
    CasinoComponent,
    FooterComponent,
    SupernovaComponent,
    EventplComponent,
    PlMatchWiseComponent,
    PlMarketwiseComponent,
    PlUserBetComponent,
    InfoComponent,
    HistoryComponent,
    ActivitylogComponent,
    RollingComissionComponent,
    OpenSidebarComponent,
    OpenHeaderComponent,
    OpenDashboardComponent,
    OpenMatchDetailComponent,
    OpenInplayComponent,
    OpenMultiMarketComponent,
    MultiMarketComponent,
    FooterMainComponent,
    OpenFooterComponent,
    MyAccountComponent,
    RightSidebarComponent,
    BetHistoryComponent,
    PlUserBetSnComponent,
    OpenHomeComponent,
    OpenNewsComponent,
    QtechComponent,
    RegisterComponent,
    DepositComponent,
    WithdrawComponent,
    WithdrawDetailComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,NgbModule,
    DataTablesModule,
    DatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    // DatetimePopupModule,
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgBootstrapFormValidationModule.forRoot(),
    NgBootstrapFormValidationModule,
    NgHttpLoaderModule.forRoot(),
    MatTableModule,
    MatExpansionModule,
    MatMenuModule,
    OwlDateTimeModule, 
  OwlNativeDateTimeModule,
  ThemeModule,
  MatIconModule,
  MatPaginatorModule,
  NgSelectModule,
  
  // OwlMomentDateTimeModule
  ],
  
  providers: [CookieService,Match,ReportService,
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
  ],
  bootstrap: [AppComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
