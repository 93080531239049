import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service'
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  modalRef: BsModalRef;
  Form: FormGroup;
  loginButtonDisable = false
  submitted = false;
  transactionPassword: string;
  showResponseMessage;
  public showPassword: boolean;
  private clickTimeout = null;
  a: any;
  logo: string;
  tPassword: any;
  constructor(private router: Router, private modalService: BsModalService,
    private fb: FormBuilder,
    private loginService: LoginService, private cookie: CookieService,
    private toastr: ToastrService) { }


  ngOnInit(): void {
    this.logo = localStorage.getItem('logo')
    this.createFrom();
    this.createRandomTransactionPassword();
    this.clearCookieAndLocalStorage();
  }
  openModalLoginCnfm(loginCnfm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginCnfm,
      Object.assign({}, { class: 'loginCnfm-modal modal-lg', ignoreBackdropClick: true })
    );
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe: [false]
    })
    if (JSON.parse(localStorage.getItem('RememberMe'))) {
      this.Form.patchValue({
        user_name: localStorage.getItem('user_name'),
        password: localStorage.getItem('password'),
        isRememberMe: localStorage.getItem('RememberMe')
      })
    }
  }




  get f() { return this.Form.controls; }

  async onSubmitLogin() {
    this.loginButtonDisable = true
    if (this.clickTimeout) {
      this.setClickTimeout(() => { });
    } else {
      this.setClickTimeout(() =>
        this.handleSingleLoginClick());
    }
  }
  public handleSingleLoginClick() {
    //The actual action that should be performed on click      
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if (this.tPassword == this.transactionPassword) {
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.setCokkies(result);
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
          this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.redirectToDashboard();
        } else {
          this.showResponseMessage = result.msg;
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }

  }

  async redirectToDashboard() {
    // var acctoken = await this.cookie.get('accessToken');
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }

  async setCokkies(result) {
    localStorage.setItem('userAccessToken', result.token.accessToken);
    this.cookie.set('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    var acctoken = localStorage.getItem('userAccessToken');
    this.router.navigate(['dashboard'])
  }

  createRandomTransactionPassword() {
    //initialize a variable having alpha-numeric characters
    var chars = "0123456789";

    //specify the length for the new string to be generated
    var string_length = 4;
    var randomstring = '';

    //put a loop to select a character randomly in each iteration
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    this.transactionPassword = randomstring;

  }

  public setClickTimeout(callback) {
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }

  clearCookieAndLocalStorage() {
    this.cookie.delete('userId');
    this.cookie.delete('is_socket');
    this.loginService.clearLocalStorage()
  }
  download() {
    var a = window.location.origin
    var b = window.location.hostname
    var c = a + '/assets/apk/' + b + '.apk'
    window.open(c, "_blank")
  }
}
