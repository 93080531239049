<!-- sky design desktop -->
<div class="app-body d-none-mobile">
  <!-- Loading Wrap -->
  <div id="loading" class="loading-wrap d-none-mobile" *ngIf="loading == true">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>
  <app-header></app-header>
  <div class="full-wrap">
    <div class="col-left">
      <app-sidebar></app-sidebar>
    </div>

    <div id="centerColumn" class="col-center markets">
      <!-- Message -->
      <div id="message" class="message-wrap success">
        <a class="btn-close">Close</a>
        <p></p>
      </div>

      <div class="over-wrap">
        <div id="liveMatchGameHead" class="game-head">
          <div class="match-tracker-wrap">
            <div *ngIf="graph  == true">
              <iframe [src]="graphicTvUrl" frameborder="0" class="w-100 d-block"></iframe>
            </div>
            <ul id="liveMatchTrackerBtn" class="match-btn">
              <li><a id="liveMultiMarketPin" class="btn-pin" title="Add to Multi Markets"></a></li>
              <li><a class="btn-refresh"></a></li>
            </ul>
          </div>
          
          <div class="game-streaming-wrap" *ngIf="tv == true && !!liveUrl" [ngClass]="tv? 'show-tv' : ''" >
            <iframe [src]="liveUrl" frameborder="0" class="w-100 d-block" style="height: 200px;"></iframe>
          </div>
        </div>
        <div *ngFor="let matchOddsData of matchDetail;let mDTIndex=index;">
          <div class="game-head">
            <div class="match-odds">
              {{matchOddsData?.market_name}}
              <img src="assets/sky_img/transparent.gif">
            </div>
            <ul class="game-info">
              <li id="gameInfoDate" class="green"><img class="icon-irun" src="assets/sky_img/transparent.gif"
                  *ngIf="matchOddsData.inplay"> In-Play</li>
            </ul>
            <span *ngIf="matchOddsData?.market_name == 'Match Odds'">
              <a id="lmtOpenStreaming" class="open-tv-wrap"  (click)="tvOpen(tv)">Live</a>
            </span>
            <dl class="game-matched">
              <dt>Matched</dt>
              <dd id="liveGameMatched">{{matchOddsData?.matched}}</dd>
            </dl>
            <dl id="minMaxBox" class="fancy-info">
              <dt id="minMaxDt">Min/Max</dt>
              <!-- <dt id="maxDt">Max</dt> -->
              <dd id="minMaxInfo">{{matchOddsData?.min_stake}}
                - {{matchOddsData?.max_stake}}</dd>
            </dl>
          </div>
          <!-- match-odd -->
          <div class="bets-wrap asiahadicap">
            <span class="refer_only">{{matchDetail?.length}} selections</span>

            <table class="bets">
              <tbody>
                <tr class="bet-all">
                  <td></td>
                  <td id="backPercent" class="refer-bet d-none-mobile" colspan="2">100.6%</td>
                  <td><a id="backAll" class="back-all"><img src="assets/sky_img/transparent.gif"><span>Back all</span></a>
                  </td>
                  <td><a id="layAll" class="lay-all"><img src="assets/sky_img/transparent.gif"><span>Lay all</span></a>
                  </td>
                  <td id="layPercent" class="refer-book d-none-mobile" colspan="2">99.9%</td>
                </tr>

                <tr *ngFor="let runner of matchOddsData.runners; let i = index;trackBy: trackHero">
                  <th>
                    <p><a><img class="icon-predict" src="assets/sky_img/transparent.gif"></a>{{runner?.selection_name}}
                    </p>
                    <span id="withoutBet"
                      [ngClass]="{'lose':(runner.win_loss+ matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))<0,'win':(runner.win_loss+ matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))>0}">
                        {{ (runner.win_loss+this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id)) != 0 ? ((runner.win_loss+this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))|
                          number : '0.0-2') : ''}}</span>
                  </th>
                  <td class="back-3 d-none-mobile" *ngIf="runner?.ex?.availableToBack?.length == 3">
                    <a
                      (click)="getOddsValue((runner.ex.availableToBack[2].price),runner.ex.availableToBack[2].size,1,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData)">
                      <p>{{(runner?.ex?.availableToBack[2]
                        !=undefined?runner?.ex?.availableToBack[2]?.price
                        :'')}}</p>
                      <span>{{(runner?.ex?.availableToBack[2]
                        !=undefined?runner?.ex?.availableToBack[2]?.size
                        :'')}}</span>
                    </a>
                  </td>
                  <td class="back-3 d-none-mobile" *ngIf="runner?.ex?.availableToBack?.length != 3">
                    <a>
                      <p></p>
                      <span></span>
                    </a>
                  </td>
                  <td class="back-2 d-none-mobile" *ngIf="runner?.ex?.availableToBack?.length != 1">
                    <a
                      (click)="getOddsValue((runner.ex.availableToBack[1].price),runner.ex.availableToBack[1].size,1,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData)">
                      <p>{{(runner?.ex?.availableToBack[1]
                        !=undefined?runner?.ex?.availableToBack[1]?.price
                        :'')}}</p>
                      <span>{{(runner?.ex?.availableToBack[1]
                        !=undefined?runner?.ex?.availableToBack[1]?.size
                        :'')}}</span>
                    </a>
                  </td>
                  <td class="back-2 d-none-mobile" *ngIf="runner?.ex?.availableToBack?.length == 1">
                    <a>
                      <p></p>
                      <span></span>
                    </a>
                  </td>
                  <td class="back-1" *ngIf="runner?.ex?.availableToBack?.length > 0">
                    <a
                      (click)="getOddsValue((runner.ex.availableToBack[0].price),runner.ex.availableToBack[0].size,1,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData)">
                      <p>{{(runner?.ex?.availableToBack[0]
                        !=undefined?runner?.ex?.availableToBack[0]?.price
                        :'')}}</p>
                      <span>{{(runner?.ex?.availableToBack[0]
                        !=undefined?runner?.ex?.availableToBack[0]?.size
                        :'')}}</span>
                    </a>
                  </td>
                  <td class="back-1"
                    *ngIf="runner?.ex?.availableToBack?.length == undefined ||runner?.ex?.availableToBack?.length == 0">
                    <a>
                      <p></p>
                      <span></span>
                    </a>
                  </td>
                  <td class="lay-1" *ngIf="runner?.ex?.availableToLay?.length > 0">
                    <a
                      (click)="getOddsValue((runner.ex.availableToLay[0].price),runner.ex.availableToBack[0].size,0,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData)">
                      <p>{{(runner?.ex?.availableToLay[0]
                        !=undefined?runner?.ex?.availableToLay[0]?.price
                        :'')}}</p>
                      <span>{{(runner?.ex?.availableToLay[0]
                        !=undefined?runner?.ex?.availableToLay[0]?.size
                        :'')}}</span>
                    </a>
                  </td>
                  <td class="lay-1"
                    *ngIf="runner?.ex?.availableToLay?.length == undefined || runner?.ex?.availableToLay?.length == 0">
                    <a>
                      <p></p>
                      <span></span>
                    </a>
                  </td>
                  <td class="lay-2 d-none-mobile" *ngIf="runner?.ex?.availableToLay?.length != 1">
                    <a
                      (click)="getOddsValue((runner.ex.availableToLay[1].price),runner.ex.availableToBack[0].size,runner.ex.availableToLay[1].size,0,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData)">
                      <p>{{(runner?.ex?.availableToLay[1]
                        !=undefined?runner?.ex?.availableToLay[1]?.price
                        :'')}}</p>
                      <span>{{(runner?.ex?.availableToLay[1]
                        !=undefined?runner?.ex?.availableToLay[1]?.size
                        :'')}}</span>
                    </a>
                  </td>
                  <td class="lay-2 d-none-mobile" *ngIf="runner?.ex?.availableToLay?.length == 1 ">
                    <a>
                      <p></p>
                      <span></span>
                    </a>
                  </td>
                  <td class="lay-3 d-none-mobile" *ngIf="runner?.ex?.availableToLay?.length == 3">
                    <a
                      (click)="getOddsValue((runner.ex.availableToLay[2].price),runner.ex.availableToBack[0].size,runner.ex.availableToLay[2].size,0,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData)">
                      <p>{{(runner?.ex?.availableToLay[2]
                        !=undefined?runner?.ex?.availableToLay[2]?.price
                        :'')}}</p>
                      <span>{{(runner?.ex?.availableToLay[2]
                        !=undefined?runner?.ex?.availableToLay[2]?.size
                        :'')}}</span>
                    </a>
                  </td>
                  <td class="lay-3 d-none-mobile" *ngIf="runner?.ex?.availableToLay?.length != 3">
                    <a>
                      <p></p>
                      <span></span>
                    </a>
                  </td>
                </tr>

              </tbody>
            </table>
            
            <div class="news-section" *ngIf="matchOddsData?.news != undefined">
              <span>News: </span><marquee>{{matchOddsData?.news}}</marquee>
            </div>
          </div>
        </div>

        <!-- fancy -->
        <div class="bets-wrap fancy_bet" *ngIf="matchData.sport_id == 4">
          <div id="fancyBetHead" class="fancy-head">
            <h4 class="in-play">
              <span id="headerName">Fancy Bet</span>
              <a class="btn-fancybet_rules"></a>
            </h4>
          </div>

          <table class="bets">
            <colgroup>
              <col span="1" width="280">
              <col span="1" width="70">
              <col span="1" width="70">
              <col span="1" width="70">
              <col span="1" width="70">
              <col span="1" width="70">
              <col span="1" width="70">
            </colgroup>
            <tbody>
              <tr class="colgroup-adjust">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr id="fancyBetSpecialBet" class="special_bet">
                <td colspan="7">
                  <h3><a id="multiMarketPin" class="add-pin" title="Add to Multi Markets">Add Pin</a> Fancy Bet</h3>
                </td>
              </tr>
              <tr class="bet-all">
                <td></td>
                <td class="refer-bet" colspan="2"></td>
                <td>No</td>
                <td>Yes</td>
                <td class="refer-book" colspan="2"></td>
              </tr>
              <tr *ngFor="let fancyData of fancy;let i = index;">
                <th colspan="3">
                  <dl class="fancy-th-layout">
                    <dt>
                      <p id="marketName">{{fancyData?.name}}</p>
                      <span
                        [ngClass]="{'to-lose':fancyData?.liability<0,'win':fancyData?.liability>0}">
                        {{fancyData?.liability != undeined ? fancyData?.liability :
                        ''}}</span>
                    </dt>
                    <dd class="dd-tips">
                      <ul class="fancy-tips">
                      </ul>
                      <a id="fancyBetBookBtn" class="btn-book" (click)="fancyPositionData(fancyData,fancyPosition);"
                        *ngIf="fancyData?.liability != undeined">Book</a>
                    </dd>
                  </dl>
                </th>
                <td colspan="2" class="multi_select">
                  <ul>
                    <li class="lay-1" id="lay_1"
                      (click)="setSessionValue(fancyData,0,fancyData.session_value_no,fancyData.session_size_no,fancyData?.liability)">
                      <a>{{fancyData?.session_value_no == 'Ball' ||
                        fancyData?.session_value_no == '-' ||
                        fancyData?.session_value_no == '0' ? '0' :
                        fancyData?.session_value_no}}
                        <span>{{fancyData?.session_size_no == 'SUSPENDED'
                          ||
                          fancyData?.session_size_no == 'Running' ||
                          fancyData?.session_size_no == '0'? '0' :
                          fancyData?.session_size_no}} </span>
                      </a>
                    </li>

                    <li class="back-1" id="back_1"
                      (click)="setSessionValue(fancyData,1,fancyData.session_value_yes,fancyData.session_size_yes,fancyData?.liability)">
                      <a>{{fancyData?.session_value_yes == 'Ball' ||
                        fancyData?.session_value_yes == '-' ||
                        fancyData?.session_value_yes == '0' ? '0' :
                        fancyData?.session_value_yes}}
                        <span>{{fancyData?.session_size_yes == 'SUSPENDED'
                          ||
                          fancyData?.session_size_yes == 'Running'
                          ||fancyData?.session_size_yes == '0' ? '0' :
                          fancyData?.session_size_yes}} </span></a>
                    </li>
                    <div class="spnd-fncy" *ngIf="fancyData.display_message != ''">
                      <span class="stats-text">{{fancyData?.display_message}}</span>
                    </div>
                    <div class="spnd-fncy" *ngIf="fancyData.is_lock == true">
                      <span class="stats-text">LOCK</span>
                    </div>
                  </ul>
                </td>
                <td class="td-fancy_merge" colspan="2">
                  <dl *ngIf="fancyData?.min_stake || fancyData?.max_stake">
                    <dt>Min/Max</dt>
                    <dd id="minMax">{{fancyData?.min_stake}} / {{fancyData?.max_stake}}</dd>
                  </dl>
                  <dl class="fancy-info" *ngIf="fancyData.news != undefined">
                    <dt id="rebateName">{{fancyData.news}}</dt>
                    <dd id="rebate"></dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-right accordion" id="betslipRisgtDiv">
      <div class="slip-wrap accordion-item">
        <h3 id="betSlipHeading" data-bs-toggle="collapse" data-bs-target="#betSlipCollapse" aria-expanded="true"
          aria-controls="betSlipCollapse">
          <a class="to-expand">Bet Slip</a>
        </h3>
        <ul id="loadingMsg" class="loading" style="display: none;">
          <li><img src="assets/sky_img/loading40.gif"></li>
          <li id="countDownTime">Place Bets
          </li>
        </ul>
        <div id="betSlipCollapse" class="accordion-collapse collapse show" data-bs-parent="#betslipRisgtDiv">
          <ul id="loadingMsg" class="loading" style="display: none">
            <li><img src="assets/sky_img/loading40.gif"></li>
            <li id="countDownTime">Place Bets</li>
          </ul>
          <p id="noBetSlipInfo" *ngIf="!betslipStatus">Click on the odds to add selections to the betslip.
          </p>
          <div id="betSlipBoard" class="bet_slip" *ngIf="betslipStatus">
            <ng-container *ngFor="let back of matchModel.tempArray;let index=index">
              <div id="betList" class="slip-list" style="max-height:calc(100% - 86px);">
                <div>
                  <ul id="backSlipHeader" class="slip-head" name="slipHeader" *ngIf="arrayObj?.is_back == 1">
                    <li class="col-bet">Back (Bet For)</li>
                    <li id="oddsHeader" class="col-odd">Odds</li>
                    <li class="col-stake">Stake</li>
                    <li class="col-profit">Profit</li>
                  </ul>
                  <ul id="laySlipHeader" class="slip-head" name="slipHeader" *ngIf="arrayObj?.is_back == 0">
                    <li class="col-bet">Lay (Bet Against)</li>
                    <li id="oddsHeader" class="col-odd">Odds</li>
                    <li class="col-stake">Stake</li>
                    <li class="col-profit">Liability</li>
                  </ul>
                  <div>
                    <h4><img [ngClass]="arrayObj?.inplay ? 'icon-in_play' : 'icon-no_play'"
                        src="/assets/sky_img/transparent.gif">{{matchData.match_name}}</h4>
                    <dl [ngClass]="arrayObj?.is_back == 1 ? 'slip-back ' : 'slip-lay'">
                      <dt>
                        <a id="delete" class="delete">delete</a>
                        <span class="short-amount">{{(back?.is_fancy == 0)? (back?.MarketName) : (back?.MatchName)}} </span>
                        <!-- <span id="marketName"> Match Odds</span> -->
                      </dt>
                      <dd class="col-odd">
                        <input type="text" class="form-control" *ngIf="back?.isManual=='0'"
                          ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" min="0" step="0.01" [(ngModel)]="back.odds"
                          id="ShowBetPrice" formcontrolname="odds"
                          class="calProfitLoss odds-input form-control CommanBtn ng-untouched ng-pristine ng-valid"
                          [ngModelOptions]="{standalone: true}" name="quant[2]" (keyup)="choc(back?.odds)"
                          value="{{back?.odds}}" readonly>
                        <ul class="odd-add">
                          <li><a id="oddsUp" class="up">up</a></li>
                          <li><a id="oddsDown" class="down">down</a></li>
                        </ul>
                        <ul id="oddsTipsPoint"></ul>
                      </dd>
                      <dd class="col-stake">
                        <input type="number" class="form-control" min="0" id="stackVal" (focus)="setbtn(index)"
                          (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);" (click)="updateFancyTimer();updateMarketTimer()"
                          pattern="[0-9]*" step="1" id="stakeValue" formcontrolname="stake" placeholder="0"
                          class="calProfitLoss stake-input f0orm-control CommanBtn ng-untouched ng-pristine ng-valid"
                          [(ngModel)]="back.stake" [ngModelOptions]="{standalone: true}">
                        <ul id="listPoint"></ul>
                      </dd>
                      <dd id="profitLiability" class="col-profit" *ngIf="arrayObj?.is_back == 1">{{
                        back.is_back == 0 ?
                        back.stake
                        :((back.odds*back.stake)-back.stake)=='NaN'?0:
                        ((back.odds*back.stake)-back.stake)|
                        number :
                        '0.0-2'}}</dd>
                      <dd id="profitLiability" class="col-profit" *ngIf="arrayObj?.is_back == 0">{{back.is_back
                        == 0 ?
                        ((back.odds*back.stake)-back.stake)=='NaN'?0:
                        (((back.odds*back.stake)-back.stake)|
                        number : '0.0-2') :
                        back.stake}}</dd>
                      <dd id="stakePopupList" class="col-stake_list" style="display: block;">
                        <ul>
                          <li *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                            (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">
                            <a class="btns">{{stake.value}}</a>
                          </li>
                        </ul>
                        <p id="totalStakeP" style="display: none;">Total Stake: <strong id="totalStake"></strong></p>
                      </dd>
                      <dd id="asianHandicapForecast" class="bet-forecast" style="display: none;">
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_1"></span>
                          <span id="winLoss_1"></span>
                        </div>
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_2"></span>
                          <span id="winLoss_2"></span>
                        </div>
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_3"></span>
                          <span id="winLoss_3"></span>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div id="betSlipFullBtn" class="full_btn">
                <dl class="sum" *ngIf="arrayObj?.is_back == 1">
                  <dt>Liability
                  </dt>
                  <dd>
                    <span id="total"><a [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}">{{back.is_back
                        == 0 ?
                        ((back.odds*back.stake)-back.stake)=='NaN'?0:
                        (((back.odds*back.stake)-back.stake)|
                        number : '0.0-2') :
                        back.stake}}</a></span>
                  </dd>
                </dl>
                <dl class="sum" *ngIf="arrayObj?.is_back == 0">
                  <dt>Profit
                  </dt>
                  <dd>
                    <span id="total"><a [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}">{{
                        back.is_back == 0 ?
                        back.stake
                        :((back.odds*back.stake)-back.stake)=='NaN'?0:
                        ((back.odds*back.stake)-back.stake)|
                        number :
                        '0.0-2'}}</a></span>
                  </dd>
                </dl>

                <ul class="btn-wrap">
                  <li><a id="cancelAll" (click)="this.matchModel.tempArray=[];clearAll();betslipStatus = false;updateMarketTimer();matchRunner();updateFancyTimer();fancyRunner()"
                      class="btns" style="cursor:pointer;" tabindex="0">Cancel All
                    </a></li>
                  <li *ngIf="arrayObj?.is_fancy == 0" (click)="saveBet(this.matchModel.tempArray,inplayStatus)"><a
                      id="placeBets" class="btn-send" [ngClass]="{'disable':back.stake <= 0}" style="cursor:pointer;"
                      tabindex="0">Place Bets
                    </a></li>
                  <li *ngIf="arrayObj?.is_fancy == 1" (click)="openFancyBets(this.matchModel.tempArray);"><a
                      id="placeBets" class="btn-send" [ngClass]="{'disable':back.stake <= 0}" style="cursor:pointer;"
                      tabindex="0" [disabled]="loading || back.stake <= 0 || back.odd <= 0" [ngClass]="arrayObj.is_back == 1 ? 'back-button' : 'lay-button'"  (click)="saveFancyBet(fancyData,this.matchModel.tempArray);">Place Bets
                  </a></li>
                </ul>

                <ul class="slip-option">
                  <li><input id="comfirmBets" type="checkbox" style="margin-right:5px;"><label for="comfirmBets">Please
                      confirm
                      your bets.
                    </label>
                  </li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <app-right-sidebar></app-right-sidebar>
    </div>
  </div>
</div>

<!-- sky design mobile -->
<div class="app-body d-lg-none">
  <div class="loading-overlay d-none-desktop" id="loading" *ngIf="loading == true">
    <div class="loading-wrap">
      <div class="loading">
        <div></div>
        <div></div>
      </div>
      <p>Loading...
      </p>
    </div>
  </div>
  <app-header></app-header>
  <div class="mian-wrap">
      <div class="game-wrap">
        <div class="game-streaming-wrap" [ngClass]="showTvStatus? 'show-tv' : ''" *ngIf="!!liveUrl">
          <a (click)="hideTvDiv()"><i class="fa fa-times"></i></a>
          <iframe [src]="liveUrl" frameborder="0" class="w-100 d-block" style="height: 200px;"></iframe>
        </div>
        <div class="match-tracker-wrap">
          <div *ngIf="graph == true">
            <iframe [src]="graphicTvUrl" frameborder="0" class="w-100 d-block"></iframe>
          </div>
          <ul class="match-btn">
            <li><a id="liveMultiMarketPin" class="btn-pin" title="Add to Multi Markets">Pin</a></li>
            <li><a class="btn-refresh">Refresh</a></li>
          </ul>
        </div>
      </div>
      <div>
        <div class="market-type">
          <ul id="naviMarketList" id="myTab" role="tablist">
            <li (click)="getMatchDetail(0)"><a id="all-tab" data-bs-toggle="tab" data-bs-target="#all" role="tab" aria-selected="true">All<span class="icon-arrow_down"></span></a></li>
            <li class="select" id="matchOdd-tab" data-bs-toggle="tab" data-bs-target="#matchOdd" role="tab" aria-selected="false" (click)="getMatchDetail(0)"><a>Match Odds</a></li>
          </ul>
        </div>

        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
            <ng-container *ngFor="let matchOddsData of matchDetail; let mDTIndex=index;">
              <div class="game-head">
                <div class="match-odds"> {{matchOddsData?.market_name}} <img src="assets/sky_img/transparent.gif"></div>
                
                <span *ngIf="matchOddsData?.market_name == 'Match Odds'" class="float-right"><a class="open-tv-wrap"  (click)="showTvDiv();"></a></span>
              </div>
              <div class="bets-wrap asiahadicap disabled">
                <div id="minMaxBox" class="limit_info-popup" [ngClass]="[ status ? 'bteLimitBlock' : 'bteLimitNone']">
                  <a class="close" (click)="betLimitClose();">Close</a>
                  <dl>
                    <dt id="minMaxDt">Min / Max</dt>
                    <dt>{{matchOddsData?.min_stake}}
                      / {{matchOddsData?.max_stake}}</dt>
                  </dl>
                </div>
                <a id="minMaxButton" class="bet-limit" (click)="betLimit();"></a>
                <dl id="betsHead" class="bets-selections-head">
                  <dt>
                    <a class="a-depth">Markets Depth</a>
                    <p>
                      <span>Matched</span>
                      <strong>PTE {{matchOddsData?.totalMatched}}</strong>
                    </p>
                  </dt>
                  <dd class="mode-land"></dd>
                  <dd class="mode-land"></dd>

                  <dd>Back</dd>
                  <dd>Lay</dd>

                </dl>
                <div *ngFor="let runner of matchOddsData.runners; let i = index;">
                  <dl class="bets-selections" style="border-bottom: 1px solid #7e97a7;">
                    <dt>
                      <h4 class="runnerName">{{runner?.selection_name}}</h4>
                      <ul id="winLoss">
                        <li class="lose"
                          [ngClass]="{'lose':(runner.win_loss+ this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))<0,'win':(runner.win_loss+ this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))>0}">
                          {{ (runner.win_loss+this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id)) != 0 ? ((runner.win_loss+this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))|
                          number : '0.0-2') : ''}}</li>
                      </ul>
                    </dt>
                    <dd id="suspend" class="suspend" style="display: none;">
                      <p>Suspend</p>
                    </dd>
                    <dd id="back_1" style="cursor:pointer" *ngIf="runner?.ex?.availableToBack?.length > 0"
                      (click)="getOddsValue((runner.ex.availableToBack[0].price),runner.ex.availableToBack[0].size,1,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData)">
                      <div class="value back-1" style="width:100%" data-bs-toggle="collapse" data-bs-target="#betSlip1"
                        aria-expanded="false">
                        <a>{{(runner?.ex?.availableToBack[0]
                          !=undefined?runner?.ex?.availableToBack[0]?.price
                          :'--')}} <span>{{(runner?.ex?.availableToBack[0]
                            !=undefined?runner?.ex?.availableToBack[0]?.size
                            :'--')}}</span></a>
                      </div>

                    </dd>
                    <dd id="back_1" style="cursor:pointer"
                      *ngIf="runner?.ex?.availableToBack?.length == undefined ||runner?.ex?.availableToBack?.length == 0">
                      <div class="value back-1" style="width:100%" data-bs-toggle="collapse" data-bs-target="#betSlip1"
                        aria-expanded="false">
                        <a>-- <span>--</span></a>
                      </div>
                    </dd>
                    <dd id="lay_1" style="cursor:pointer" *ngIf="runner?.ex?.availableToLay?.length > 0"
                      (click)="getOddsValue((runner.ex.availableToLay[0].price),runner.ex.availableToLay[0].size,0,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData)">
                      <div class="value lay-1" style="width:100%">
                        <a>{{(runner?.ex?.availableToLay[0]
                          !=undefined?runner?.ex?.availableToLay[0]?.price
                          :'--')}} <span>{{(runner?.ex?.availableToLay[0]
                            !=undefined?runner?.ex?.availableToLay[0]?.size
                            :'--')}}</span></a>
                      </div>
                      <div id="lay_1" class="spnd-fncy large"
                        *ngIf="runner?.status == 'SUSPENDED' && (matchOddsData.status != 'SUSPENDED')"><span
                          class="stats-text">Suspended</span></div>
                    </dd>
                    <dd id="lay_2" class="count" style="cursor:pointer"
                      *ngIf="runner?.ex?.availableToLay?.length == undefined || runner?.ex?.availableToLay?.length == 0">
                      <div class="value lay-1" style="width:100%">
                        <a>--<span>--</span></a>
                      </div>
                    </dd>
                  </dl>
                  <dl class="bet_slip-wrap" id="betSlip1"
                    *ngIf="matchOddsData.market_id == market && runner.selectionId == selection "
                    [ngClass]="arrayObj.is_back == 1 ? 'back' : 'lay'">
                    <ng-container *ngFor="let back of this.matchModel.tempArray;let index=index">
                      <ul class="btn-list">
                        <li id="inputOdds" class="input-num">
                          <a id="oddsDown" class="icon-minus disable"
                            style="cursor:pointer;height: 12.93333vw;width:13vw;pointer-events: none;"
                            (click)="setOddsDec(index)"></a>
                          <input type="number" name="" id="" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" min="0" step="0.01"
                            [(ngModel)]="back.odds" id="ShowBetPrice" formcontrolname="odds"
                            [ngModelOptions]="{standalone: true}" name="quant[2]" (keyup)="choc(back?.odds)"
                            value="{{back?.odds}}" style="width: 20vw;" disabled>
                          <a id="oddsUp" class="icon-plus disable"
                            style="cursor:pointer;height: 12.93333vw;width:13vw;pointer-events: none;"
                            (click)="setOddsInc(index)"></a>
                        </li>
                        <li id="inputStake" class="input-num">
                          <a id="stakeDown" class="icon-minus" style="cursor:pointer;height: 12.93333vw;width:13vw;"
                            (click)="back.stake = back.stake - 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"></a>
                          <input type="number" class="oddinput" type="number" class="form-control" min="0" id="stackVal"
                            (focus)="setbtn(index)" (click)="updateMarketTimer()"
                            (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);" pattern="[0-9]*"
                            step="1" id="stakeValue" formcontrolname="stake" placeholder="0" class="oddinput"
                            [(ngModel)]="(back.stake < 0) ? 0 :back.stake" [ngModelOptions]="{standalone: true}"
                            style="width: 20vw;">
                          <a id="stakeUp" class="icon-plus" style="cursor:pointer;height: 12.93333vw;width:13vw;"
                            (click)="back.stake = back.stake + 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"></a>
                        </li>
                      </ul>
                      <ul class="coin-list">
                        <li *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                          (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);"><a
                            class="btn">{{stake.value}}</a></li>
                      </ul>
                      <ul class="btn-list">
                        <li><a id="cancel" class="btns" (click)="this.matchModel.tempArray=[];clearAll();updateMarketTimer();matchRunner()">Cancel</a></li>
                        <li><a id="placeBet" class="btn-send" [ngClass]="{'disable':back.stake <= 0}"
                            (click)="saveBet(this.matchModel.tempArray,inplayStatus)">Place Bet</a></li>
                      </ul>
                    </ng-container>

                  </dl>
                </div>
                <div class="news-section" *ngIf="matchOddsData?.news != undefined">
                  <span>News: </span><marquee>{{matchOddsData?.news}}</marquee>
                </div>
              </div>
            </ng-container>

            <!-- fancy bet -->
            <div *ngIf="matchData.sport_id == 4">
              <div id="fancyBetHead" class="fancy_bet-head">
                <h4 class="in-play">
                  <a id="multiMarketPin" class="pin-off">Pin to Muilti Markets</a>
                  <span>
                    <pre>in-play</pre>Fancy Bet
                  </span>
                  <a class="btn-head_rules" (click)="openModalCondition(Terms)">Rules</a>
                </h4>
              </div>

              <div id="fancyBetField" class="bets-wrap fancy-bet">
                <dl class="bets-selections-head">
                  <dt></dt>
                  <dd class="mode-land"></dd>
                  <dd class="mode-land"></dd>
                  <dd>No</dd>
                  <dd>Yes</dd>
                  <dd class="mode-land"></dd>
                  <dd class="mode-land"></dd>
                </dl>
              </div>

              <div id="fancyBetTag" *ngFor="let fancyData of fancy;let i = index;">
                <div class="bets-wrap fancy-bet">
                  <h5>
                    <span id="marketName">{{fancyData?.name}}</span>
                    <a id="open-fancy_info" class="btn-fancy_info" (click)="fancyInfoClick(fancyData)">fancybet info</a>
                    <div id="fancy_popup2" class="fancy_info-popup" *ngIf="fancyInfoId == fancyData.fancy_id"
                      [ngClass]="[ fancyInfoStatus ? 'fancyInfoBlock' : 'fancyInfoNone']">
                      <dl>
                        <dt>Min / Max</dt>
                        <dd id="minMax"> {{fancyData?.min_stake}} / {{fancyData?.max_stake}}</dd>
                      </dl>
                      <a id="close-fancy_info" class="close" (click)="fancyInfoClose();">Close</a>
                    </div>
                  </h5>
                  <dl class="bets-selections">
                    <dt class="line_market-selection">
                      <dl class="tips">
                        <dt>
                          <span class="win"
                            [ngClass]="{'to-lose':fancyData?.liability<0,'win':fancyData?.liability>0}">{{fancyData?.liability
                            != undeined ? fancyData?.liability : ''}}</span>
                        </dt>
                      </dl>
                      <a id="fancyBetBookBtn" class="btn-book" (click)="fancyPositionData(fancyData,fancyPosition);"
                        *ngIf="fancyData?.liability != undeined ">Book</a>
                    </dt>
                    <!-- Suspend for Fancy Bet -->
                    <dd class="count lay-1" style="cursor:pointer">
                      <div class="value lay-1"
                        (click)="setSessionValue(fancyData,0,fancyData.session_value_no,fancyData.session_size_no,fancyData?.liability)">
                        <a class="lay-1">{{fancyData?.session_value_no == 'Ball' ||
                          fancyData?.session_value_no == '-' ||
                          fancyData?.session_value_no == '0' ? '0' :
                          fancyData?.session_value_no}}
                          <span>{{fancyData?.session_size_no == 'SUSPENDED'
                            ||
                            fancyData?.session_size_no == 'Running' ||
                            fancyData?.session_size_no == '0'? '0' :
                            fancyData?.session_size_no}} </span>
                        </a>
                      </div>
                    </dd>
                    <dd class="count position-relative back-1 " style="cursor:pointer">
                      <div class="value back-1"
                        (click)="setSessionValue(fancyData,1,fancyData.session_value_yes,fancyData.session_size_yes,fancyData?.liability)">
                        <a class="back-1">{{fancyData?.session_value_yes == 'Ball' ||
                          fancyData?.session_value_yes == '-' ||
                          fancyData?.session_value_yes == '0' ? '0' :
                          fancyData?.session_value_yes}}
                          <span>{{fancyData?.session_size_yes == 'SUSPENDED'
                            ||
                            fancyData?.session_size_yes == 'Running'
                            ||fancyData?.session_size_yes == '0' ? '0' :
                            fancyData?.session_size_yes}} </span></a>
                      </div>

                      <div class="spnd-fncy" *ngIf="fancyData.display_message != ''">
                        <span class="stats-text">{{fancyData?.display_message}}</span>
                      </div>
                      <div class="spnd-fncy" *ngIf="fancyData.is_lock == true">
                        <span class="stats-text">LOCK</span>
                      </div>
                    </dd>
                    <dd class="mode-land"></dd>
                    <dd class="mode-land"></dd>
                  </dl>
                </div>
                <dl class="bet_slip-wrap" id="betSlip1" *ngIf="fancyData.fancy_id == selection"
                  [ngClass]="arrayObj.is_back == 1 ? 'back' : 'lay'">
                  <ng-container *ngFor="let back of this.matchModel.tempArray;let index=index">
                    <ul class="btn-list">
                      <li id="inputOdds" class="input-num">
                        <a id="oddsDown" class="icon-minus disable"
                          style="cursor:pointer;height: 12.93333vw;width:13vw;pointer-events: none;"
                          (click)="setOddsDec(index)"></a>
                        <input type="number" name="" id="" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" min="0" step="0.01"
                          [(ngModel)]="back.odds" id="ShowBetPrice" formcontrolname="odds"
                          [ngModelOptions]="{standalone: true}" name="quant[2]" (keyup)="choc(back?.odds)"
                          value="{{back?.odds}}" style="width: 20vw;" disabled>
                        <a id="oddsUp" class="icon-plus disable"
                          style="cursor:pointer;height: 12.93333vw;width:13vw;pointer-events: none;"
                          (click)="setOddsInc(index)"></a>
                      </li>
                      <li id="inputStake" class="input-num">
                        <a id="stakeDown" class="icon-minus" style="cursor:pointer;height: 12.93333vw;width:13vw;"
                          (click)="back.stake = back.stake - 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"></a>
                        <input type="number" class="oddinput" type="number" class="form-control" min="0" id="stackVal"
                          (focus)="setbtn(index)" (click)="updateFancyTimer()"
                          (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);" pattern="[0-9]*"
                          step="1" id="stakeValue" formcontrolname="stake" placeholder="0" class="oddinput"
                          [(ngModel)]="(back.stake < 0) ? 0 :back.stake" [ngModelOptions]="{standalone: true}"
                          style="width: 20vw;">
                        <a id="stakeUp" class="icon-plus" style="cursor:pointer;height: 12.93333vw;width:13vw;"
                          (click)="back.stake = back.stake + 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"></a>
                      </li>
                    </ul>
                    <ul class="coin-list">
                      <li *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                        (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);"><a
                          class="btn">{{stake.value}}</a></li>
                    </ul>
                    <ul class="btn-list">
                      <li><a id="cancel" class="btns" (click)="this.matchModel.tempArray=[];clearAll();updateFancyTimer();fancyRunner()">Cancel</a></li>
                      <li><button id="placeBet" class="btn-send" [ngClass]="{'disable':back.stake <= 0}"
                        (click)="saveFancyBet(fancyData,this.matchModel.tempArray);" [disabled]="loading || back.stake <= 0">Place Bet</button></li>
                    </ul>
                  </ng-container>
                </dl>

              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="matchOdd" role="tabpanel" aria-labelledby="matchOdd-tab">
            <ng-container *ngFor="let matchOddsData of matchDetail; let mDTIndex=index;">
              <div class="game-head" *ngIf="matchOddsData?.market_name == 'Match Odds'">
                <div class="match-odds"> {{matchOddsData?.market_name}} <img src="assets/sky_img/transparent.gif"></div>
                
                <span *ngIf="matchOddsData?.market_name == 'Match Odds'" class="float-right"><a class="open-tv-wrap" *ngIf="matchOddsData?.has_tv_url" (click)="showTvDiv();"></a></span>
              </div>
              <div class="bets-wrap asiahadicap disabled" *ngIf="matchOddsData?.market_name == 'Match Odds'">
                <div id="minMaxBox" class="limit_info-popup" [ngClass]="[ status ? 'bteLimitBlock' : 'bteLimitNone']">
                  <a class="close" (click)="betLimitClose();">Close</a>
                  <dl>
                    <dt id="minMaxDt">Min / Max</dt>
                    <dt>{{matchOddsData?.min_stake}}
                      / {{matchOddsData?.max_stake}}</dt>
                  </dl>
                </div>
                <a id="minMaxButton" class="bet-limit" (click)="betLimit();"></a>
                <dl id="betsHead" class="bets-selections-head">
                  <dt>
                    <a class="a-depth">Markets Depth</a>
                    <p>
                      <span>Matched</span>
                      <strong>PTE {{matchOddsData?.totalMatched}}</strong>
                    </p>
                  </dt>
                  <dd class="mode-land"></dd>
                  <dd class="mode-land"></dd>

                  <dd>Back</dd>
                  <dd>Lay</dd>

                </dl>
                <div *ngFor="let runner of matchOddsData.runners; let i = index;">
                  <dl class="bets-selections" style="border-bottom: 1px solid #7e97a7;">
                    <dt>
                      <h4 class="runnerName">{{runner?.selection_name}}</h4>
                      <ul id="winLoss">
                        <li class="lose"
                          [ngClass]="{'lose':(runner.win_loss+ this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))<0,'win':(runner.win_loss+ this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))>0}">
                          {{ (runner.win_loss+this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id)) != 0 ? ((runner.win_loss+this.matchModel.calculateProfitLossSelection(runner.selectionId,matchOddsData.market_id))|
                          number : '0.0-2') : ''}}</li>
                      </ul>
                    </dt>
                    <dd id="suspend" class="suspend" style="display: none;">
                      <p>Suspend</p>
                    </dd>
                    <dd id="back_1" style="cursor:pointer" *ngIf="runner?.ex?.availableToBack?.length > 0"
                      (click)="getOddsValue((runner.ex.availableToBack[0].price),runner.ex.availableToBack[0].size,1,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData)">
                      <div class="value back-1" style="width:100%" data-bs-toggle="collapse" data-bs-target="#betSlip1"
                        aria-expanded="false">
                        <a>{{(runner?.ex?.availableToBack[0]
                          !=undefined?runner?.ex?.availableToBack[0]?.price
                          :'--')}} <span>{{(runner?.ex?.availableToBack[0]
                            !=undefined?runner?.ex?.availableToBack[0]?.size
                            :'--')}}</span></a>
                      </div>

                    </dd>
                    <dd id="back_1" style="cursor:pointer"
                      *ngIf="runner?.ex?.availableToBack?.length == undefined ||runner?.ex?.availableToBack?.length == 0">
                      <div class="value back-1" style="width:100%" data-bs-toggle="collapse" data-bs-target="#betSlip1"
                        aria-expanded="false">
                        <a>-- <span>--</span></a>
                      </div>
                    </dd>
                    <dd id="lay_1" style="cursor:pointer" *ngIf="runner?.ex?.availableToLay?.length > 0"
                      (click)="getOddsValue((runner.ex.availableToLay[0].price),runner.ex.availableToLay[0].size,0,runner.name,runner.selectionId,matchOddsData.market_id,i,matchOddsData)">
                      <div class="value lay-1" style="width:100%">
                        <a>{{(runner?.ex?.availableToLay[0]
                          !=undefined?runner?.ex?.availableToLay[0]?.price
                          :'--')}} <span>{{(runner?.ex?.availableToLay[0]
                            !=undefined?runner?.ex?.availableToLay[0]?.size
                            :'--')}}</span></a>
                      </div>
                      <div id="lay_1" class="spnd-fncy large"
                        *ngIf="runner?.status == 'SUSPENDED' && (matchOddsData.status != 'SUSPENDED')"><span
                          class="stats-text">Suspended</span></div>
                    </dd>
                    <dd id="lay_2" class="count" style="cursor:pointer"
                      *ngIf="runner?.ex?.availableToLay?.length == undefined || runner?.ex?.availableToLay?.length == 0">
                      <div class="value lay-1" style="width:100%">
                        <a>--<span>--</span></a>
                      </div>
                    </dd>
                  </dl>
                  <dl class="bet_slip-wrap" id="betSlip1"
                    *ngIf="matchOddsData.market_id == market && runner.selectionId == selection "
                    [ngClass]="arrayObj.is_back == 1 ? 'back' : 'lay'">
                    <ng-container *ngFor="let back of this.matchModel.tempArray;let index=index">
                      <ul class="btn-list">
                        <li id="inputOdds" class="input-num">
                          <a id="oddsDown" class="icon-minus disable"
                            style="cursor:pointer;height: 12.93333vw;width:13vw;pointer-events: none;"
                            (click)="setOddsDec(index)"></a>
                          <input type="number" name="" id="" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" min="0" step="0.01"
                            [(ngModel)]="back.odds" id="ShowBetPrice" formcontrolname="odds"
                            [ngModelOptions]="{standalone: true}" name="quant[2]" (keyup)="choc(back?.odds)"
                            value="{{back?.odds}}" style="width: 20vw;" disabled>
                          <a id="oddsUp" class="icon-plus disable"
                            style="cursor:pointer;height: 12.93333vw;width:13vw;pointer-events: none;"
                            (click)="setOddsInc(index)"></a>
                        </li>
                        <li id="inputStake" class="input-num">
                          <a id="stakeDown" class="icon-minus" style="cursor:pointer;height: 12.93333vw;width:13vw;"
                            (click)="back.stake = back.stake - 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"></a>
                          <input type="number" class="oddinput" type="number" class="form-control" min="0" id="stackVal"
                            (focus)="setbtn(index)" (click)="updateMarketTimer()"
                            (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);" pattern="[0-9]*"
                            step="1" id="stakeValue" formcontrolname="stake" placeholder="0" class="oddinput"
                            [(ngModel)]="(back.stake < 0) ? 0 :back.stake" [ngModelOptions]="{standalone: true}"
                            style="width: 20vw;">
                          <a id="stakeUp" class="icon-plus" style="cursor:pointer;height: 12.93333vw;width:13vw;"
                            (click)="back.stake = back.stake + 100; calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"></a>
                        </li>
                      </ul>
                      <ul class="coin-list">
                        <li *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                          (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);"><a
                            class="btn">{{stake.value}}</a></li>
                      </ul>
                      <ul class="btn-list">
                        <li><a id="cancel" class="btns" (click)="this.matchModel.tempArray=[];clearAll();updateMarketTimer();matchRunner()">Cancel</a></li>
                        <li><a id="placeBet" class="btn-send" [ngClass]="{'disable':back.stake <= 0}"
                            (click)="saveBet(this.matchModel.tempArray,inplayStatus)">Place Bet</a></li>
                      </ul>
                    </ng-container>

                  </dl>
                </div>
                <div class="news-section" *ngIf="matchOddsData?.news != undefined">
                  <span>News: </span><marquee>{{matchOddsData?.news}}</marquee>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
  </div>
  <app-footer-main></app-footer-main>
</div>

<ng-template #Terms>
  <div class="side-wrap rules-pop">
    <div class="side-head">
      <h3 id="fancyBetHeader" class="">Rules of Fancy Bets</h3>
      <a class="close ui-link" data-bs-dismiss="modal" (click)="modalRef.hide()">Close</a>
    </div>
    <div class="side-content rules-content">
      <p>1.&nbsp;Cricket General :- ➢ If a ball is not bowled during a competition, series or match then all bets will
        be void except for those on any market that has been unconditionally determined (e.g. in the 'Completed Match'
        market).</p>
      <p>2.&nbsp;Cricket General :- If a match is shortened by weather, all bets will be settled according to the
        official result (including for limited overs matches, the result determined by the Duckworth Lewis method).</p>
      <p>3.&nbsp;Cricket General :- In the event of a match being decided by a bowl-off or toss of the coin, all bets
        will be void except for those on markets that have been unconditionally determined.</p>
      <p>4.&nbsp;Cricket Test matches :- If a match starts but is later abandoned for any reason other than weather
        (which may include but is not limited to: dangerous or unplayable wicket or outfield; pitch vandalism; strike or
        boycott; crowd protests/violence; stadium damage; acts of terrorism; and acts of God), Betfair reserves the
        right to void all bets, except for those on markets that have been unconditionally determined.</p>
      <p>5. In case anyone is found using 2 different IDs and logging in from same IP his winning in both accounts will
        be cancelled.</p>
      <p>6.&nbsp;Cricket Test matches :- If the match is not scheduled to be completed within five days after the
        original scheduled completion date, then all bets on markets for this event will be void, except for bets on any
        markets that have been unconditionally determined.</p>
      <p>7.&nbsp;Cricket Limited Over matches :- If a match is declared (No Result), bets will be void on all markets
        for the event except for those markets which have been unconditionally determined or where the minimum number of
        overs have been bowled as laid out in the market specific information.</p>
      <p>8.&nbsp;Cricket Limited Over matches :- In the event of a new toss taking place on a scheduled reserve day for
        a limited overs match all bets that were placed after 30 minutes before the original scheduled start of play on
        the first day will be made void. This rule relates to all markets except those that have been unconditionally
        determined (e.g. in the win the toss and toss combination markets).</p>
      <p>9.&nbsp;Multiple Bets :- Multiple Bets With Same Time And Same User Will Be Voided Immediately.</p>
      <h3>Cricket Bookmaker</h3>
      <p>1. Due to any reason any team will be getting advantage or disadvantage we are not concerned.</p>
      <p>2. We will simply compare both teams 25 overs score higher score team will be declared winner in ODI (25 over
        comparison)</p>
      <p>3. We will simply compare both teams 10 overs higher score team will be declared winner in T20 matches (10 over
        comparison)</p>
      <p>4. Any query about the result or rates should be contacted within 7 days of the specific event, the same will
        not be considered valid post 7 days from the event.</p>
      <h3><strong>Rules Of Fancy Bets</strong></h3>
      <p>1. Once all session/fancy bets are completed and settled there will be no reversal even if the Match is Tied or
        is Abandoned.</p>
      <p>2. Advance Session or Player Runs and all Fancy Bets are only valid for 20/50 overs full match each side.
        (Please Note this condition is applied only in case of Advance Fancy Bets only).</p>
      <p>4. Under the rules of Session/Fancy Bets if a market gets Suspended for any reason whatsoever and does not
        resume then all previous Bets will remain Valid and become HAAR/JEET bets.</p>
      <p>5. Incomplete Session Bet will be cancelled but Complete Session will be settled.</p>
      <p>6.&nbsp;In the case of Running Match getting Cancelled/ No Result/ Abandoned but the session is complete it
        will still be settled. Player runs / fall of wicket / Only Over will be also settled at the figures where match
        gets stopped due to rain for the inning (D/L) , cancelled , abandoned , no result.</p>
      <p>7. If a player gets Retired Hurt and one ball is completed after you place your bets then all the betting till
        then is and will remain valid.</p>
      <p>8.&nbsp;Should a Technical Glitch in Software occur, we will not be held responsible for any losses.</p>
      <p>9.&nbsp;Should there be a power failure or a problem with the Internet connection at our end and session/fancy
        market does not get suspended then our decision on the outcome is final.</p>
      <p>10. All decisions relating to settlement of wrong market being offered will be taken by management. Management
        will consider all actual facts and decision taken will be full in final.</p>
      <p>11.Any bets which are deemed of being suspicious, including bets which have been placed from the stadium or
        from a source at the stadium maybe voided at anytime. The decision of whether to void the particular bet in
        question or to void the entire market will remain at the discretion of Company. The final decision of whether
        bets are suspicious will be taken by Company and that decision will be full and final.</p>
      <p>12.&nbsp;Any sort of cheating bet , any sort of Matching (Passing of funds), Court Siding (Ghaobaazi on
        commentary), Sharpening, Commission making is not allowed in Company, If any company User is caught in any of
        such act then all the funds belonging that account would be seized and confiscated. No argument or claim in that
        context would be entertained and the decision made by company management will stand as final authority.</p>
      <p>13.&nbsp;Fluke hunting/Seeking is prohibited in Company , All the fluke bets will be reversed. Cricket
        commentary is just an additional feature and facility for company user but company is not responsible for any
        delay or mistake in commentary.</p>
      <p>14. In case anyone is found using 2 different IDs and logging in from same IP his winning in both accounts will
        be cancelled.</p>
      15.&nbsp;If any case wrong rate has been given in fancy ,that particular bets will be cancelled (Wrong
      Commentary).
      <p>&nbsp;</p>
      <p>16.&nbsp;In case customer make bets in wrong fancy we are not liable to delete, no changes will be made and
        bets will be considered as confirm bet.</p>
      <p>17.&nbsp;Dot Ball Market Rules</p>
      <ul>
        <li>Wides Ball - Not Count</li>
        <li>No Ball - Not Count</li>
        <li>Leg Bye - Not Count as A Dot Ball</li>
        <li>Bye Run - Not Count as A Dot Ball</li>
        <li>Out - Any Type of WKT Not Count as A Dot Ball</li>
      </ul>
      <p>18.&nbsp;Penalty Runs - Any Penalty Runs in the Match Will be Counted While Settling in our Exchange.</p>
      <p>19. All Test In Future Also ...All Full Match Markets Me Minimum 300 Overs Play Or Match Result Needed
        Otherwise All Full Match Fancy Voided</p>
      <p>20. If match stoped by any reason running over in the match will count as complete over in Single over
        market(only overs runs) . Atleast one ball to be bowled needed in the over</p>
      <p>21. In Test/Odi/T20 partnership If one batsman Will injured Then partnership Will continued With next batsman.
      </p>
      <h3><strong>Rules Of Line Market</strong></h3>
      <p>1.How many runs will be scored in the stated number of overs? Line market: This market operate with a bet delay
        of 1 second; All bets are struck at 2.0 The 'price' at which your bet is placed defines the number of runs bet
        upon. *YES Line* (buy) bets win when more runs are scored in the specified number of overs than the 'price' at
        which the bet is matched. *NO Line* (Sell) bets win when fewer runs are scored in the specified number of overs
        than the 'price' at which the bet is matched. This market will be settled if 5 overs have been completed in the
        innings, the batting team is bowled out or has reached the target to win the match.</p>
      <p>2. How many runs will be scored in the stated number of overs? Line market:This market operate with a bet delay
        of 1 second; All bets are struck at 2.0 . The 'price' at which your bet is placed defines the number of runs bet
        upon. *YES Line* (Buy) bets win when more runs are scored in the specified number of overs than the 'price' at
        which the bet is matched. *NO Line* (Sell) bets win when fewer runs are scored in the specified number of overs
        than the 'price' at which the bet is matched. Special rules for Overs Runs Line markets: Bets will be void -
        regardless of whether the outcome of the bet is already unconditionally determined - when any of the following
        are the case: -if the scheduled number of overs for the innings is reduced by rain interruption and, at
        settlement time, the actual number of overs bowled is less than the number of overs stipulated at the head of
        these rules. - if the scheduled number of overs for the innings is reduced for any other reason after the
        innings has begun and at settlement time, that new number of scheduled overs is less than the number of overs
        stipulated at the head of these rules. Please note if the batting side reach their target within the 20 overs or
        have been bowled out and the innings hasn’t been reduced in overs to less than the stipulated number at the head
        of the rules, the market will be settled.</p>
      <h3><strong>Rules Of PSL 2022 Full Series Fancy</strong></h3>
      <p>Total 34 Match Counted Start To Finals All Counted</p>
      <p>(1) Agar Koi Bhi Match Rain Ke Wajah Se Ya Power Fail Se Total 40 Overs Se Kam Khela Gaya To Fix Fancy Jo Hum
        Ne Tay Ki Hai Wo Add Kar Di Jayegi - 38.5 Over Counted As Under 40 Overs Match 39.1 Counted as Full 40 Over
        Match.</p>
      <p>(2) Bina Rain Ke Under 40 Overs Result Aata Hai To Scoreboard Ke Hisaab Se All Fancy Update Ki Jayegi.</p>
      <p>(3) By Chance Covid Ya Kisi Bhi Act Of God Reason Se Tournament Under 25 Match Intrupted Ho Jaata Hai To All
        Series Fancy Voided Plus Ho Or Minus.</p>
      <p>(4) Jo Fancy Ka Result Aa Gaya Jo Settle Ho Gyee Wo Void Nahi Hogi Wo Valid Hai.</p>
      <p>Over 25 Match All Fancy Settled <br>Non Playing Match Me Fix All Fancy Added </p>
      <p>Please Read All Fix Fancy Before Play<br>( All Fix Fancy Added In Full Washout Or Under 39.1 Overs Played Rain
        Affected Match )</p>
      <p>C And B Also Count In Series Catch Outs</p>
      <p>12 Wickets Per Match Added<br>27 Fours Per Match Added<br>11 Sixes Per Match Added<br>2 Fifty Per Match
        Added<br>8 Wides Per Match Added<br>14 Extras Per Match Added<br>8 Catch Per Match Added<br>2 Bowled Per Match
        Added<br>1 LBW Per Match Added<br>1 Runout Per match Added</p>
      <h3><strong>IPL 2022 Series Fancy Rules</strong></h3>
      <p>Total 74 Match Counted Start To Finals All Counted</p>
      <p>(1) Agar Koi Bhi Match Rain Ke Wajah Se Ya Power Fail Se Total 40 Overs Se Kam Khela Gaya To Fix Fancy Jo Hum
        Ne Tay Ki Hai Wo Add Kar Di Jayegi - 38.5 Over Counted As Under 40 Overs Match 39.1 Counted as Full 40 Over
        Match.</p>
      <p>(2) Bina Rain Ke Under 40 Overs Result Aata Hai To Scoreboard Ke Hisaab Se All Fancy Update Ki Jayegi.</p>
      <p>(3) By Chance Covid Ya Kisi Bhi Act Of God Reason Se Tournament Under 40 Match Intrupted Ho Jaata Hai Aur 30
        Day Re Start Nahi Hota Hai To All Series Fancy Voided Plus Ho Or Minus.</p>
      <p>(4) Jo Fancy Ka Result Aa Gaya Jo Settle Ho Gyee Wo Void Nahi Hogi Wo Valid Hai.</p>
      <p>Over 50 Match All Fancy Settled <br>Non Playing Match Me Fix All Fancy Added </p>
      <p>Please Read All Fix Fancy Before Play<br>( All Fix Fancy Added In Full Washout Or Under 39.1 Overs Played Rain
        Affected Match )</p>
      <p>C And B Also Count In Series Catch Outs</p>
      <p>12 Wickets Per Match Added<br>28 Fours Per Match Added<br>12 Sixes Per Match Added<br>2 Fifty Per Match
        Added<br>8 Wides Per Match Added<br>16 Extras Per Match Added<br>8 Catch Per Match Added<br>2 Bowled Per Match
        Added<br>1 LBW Per Match Added<br>1 Runout Per match Added<br>1 No Ball Per Match Added<br>1 Duck Per Match
        Added</p>
      <p>Fab Four vs Poor Sixes Bookmaker Rules</p>
      <p>70 Match Counted <br>In This Market <br>4 Team Set Vs 6 Team Set</p>
      <p>You Bet On Which Group Wins More Matches</p>
      <p>Example If Fab Four Teams Win 34 match And poor Six wins 37 match means poor six win Market</p>
      <p>If Both Group Win Same Matches Than Table Top Teams Group Win Market</p>
      <h3><strong>TEST Match Rules</strong></h3>
      <p>1. All Test In Future Also ...Minimum 300 overs has been played or the match has been won by any team otherwise
        all these fancy will be deleted</p>
      <p>2.1st innig Adv Runs is not completed for example: If You are Placing Advance Bets in 1st innigs 80 over runs
        ADV in case team-A declares or all-out at 70 over next 10 over counted in Team-B's 1st inning.</p>
      <p>3. Test match both advance fancy batsman run, Fall Of Wkt Runs, is valid on both team's 1st innings.</p>
      <p>4.In case due to weather situation match has been stopped all lambi trades will be deleted.</p>
    </div>
    <ul class="btn-list">
      <li>
        <a class="btn ui-link" data-bs-dismiss="modal" (click)="modalRef.hide()">OK</a>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #fancyPosition>
  <div>
    <div class="modal-header">
      <h5 class="modal-title">Book</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="modalRef.hide();"></button>
    </div>
    <div class="modal-body p-0">
      <div class="book-popup">
        <table class="book-tbl">
          <thead class="bg-default">
            <th>Run</th>
            <th>Amount</th>
          </thead>
          <tbody>
            <tr *ngFor="let fancyPos of fancyPosData;let i=index;"
              [ngClass]="{'lay-1':(fancyPos.value)<0,'back-1':(fancyPos.value)>0}">
              <td>{{fancyPos.key}}</td>
              <td>{{fancyPos.value}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-template>