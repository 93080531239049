import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { SportService } from '../services/sport.service';
import { resolve } from 'q';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../services/report.service';
@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit {
  MatchList: any;
  betList: boolean;
  openBetsLength: number = 0;
  dataSource: any;
  backData: any;
  layData: any;
  pageSize: number;
  searchMatch: any;
  showBet: any = 0;
  constructor(private reportService: ReportService, private cdRef: ChangeDetectorRef, private route: ActivatedRoute, private router: Router, private sport: SportService, private toastr: ToastrService) { }

  ngOnInit(): void {
    let a = window.screen.width;
    if (a > 767) {
      this.getSportList();
    }
  }
  openBets(params) {
    this.pageSize = 200
    let data = {
      page: 1,
      limit: this.pageSize,
      search: {
        "match_id": params.match_id,
        "delete_status": 0
      }
    }
    this.reportService.openBets(data).subscribe((res) => {
      if (res.status) {
        if (res.data[0].data) {
          this.betList = true
          this.dataSource = res.data[0].data;
          this.openBetsLength = res.data[0].data.length;
          this.backData = this.dataSource.filter(t => t.is_back == 1);
          this.layData = this.dataSource.filter(t => t.is_back == 0);
          this.cdRef.detectChanges();
        } else {
          this.openBetsLength = 0;
        }


      } else {
        this.toastr.error(res.msg);
        this.openBetsLength = 0;
      }
    })
  }
  getSportList() {
    let data = {
      type: 'openBets',
      search: {}
    };
    this.reportService.eventList(data).subscribe((res) => {
      if (res.status == true) {
        this.MatchList = res.data[0].matches;
        this.betList = false;
        this.cdRef.detectChanges();
      } else {
        this.toastr.error(res.msg);
      }
    })
  }
  showBetHistory(data) {
    if (data == 1) {
      this.showBet = 1;
      this.cdRef.detectChanges();
    } else {
      this.showBet = 0;
      this.cdRef.detectChanges();
    }
  }
}
