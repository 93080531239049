import { Component, OnInit,TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-footer-main',
  templateUrl: './footer-main.component.html',
  styleUrls: ['./footer-main.component.scss']
})
export class FooterMainComponent implements OnInit {
  modalRef: BsModalRef;
  data: any;
  constructor(private router: Router,private modalService: BsModalService,private route: ActivatedRoute,) { 
    let a  = window.location.pathname.split('/');
    this.data = a[1]
  }

  ngOnInit(): void {
  }
  dashboard(){
    this.router.navigate(['dashboard'])
  }
  
  inplay(){
    this.router.navigate(['inplay'])
  }
  openModalLoginCnfm(loginCnfm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginCnfm,
      Object.assign({}, { class: 'loginCnfm-modal modal-lg',ignoreBackdropClick: true })
    );
  }
  login(){
    this.router.navigate(['login'])
  }
}
