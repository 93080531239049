<div class="app-body">
  <div id="loading" class="loading-wrap" style="display: none;">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>
  <app-header></app-header>
  <div class="main_wrap_container">
    <div class="col-left">
      <div class="sub_path">
        <div class="path">
          <a  class="path-back">...</a>
          <ul>
            <li class="path-last"><a >Account Info</a></li>
          </ul>
        </div>
        <ul class="menu-list" data-widget="tree">
          <li>
            <a (click)="info()" class="select">
              <span>My Profile</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="rollComission()">
              <span>Rolling Commision</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="statement()">
              <span>Account Statement</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/betHistory','openBets']">
              <span>Bets History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="profitLoss()">
              <span>Profit & Loss</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="history()">
              <span>Password History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="activity()">
              <span>Activity Log</span><span class="pull-right-container"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div id="centerColumn" class="col-center report">

      <h2>Account Details</h2>
      <div class="event-left">
        <div class="profile-wrap">
          <h3>About You</h3>
          <dl>
            <dt>Name</dt>
            <dd>{{userDetails?.name}}</dd>

            <dt>Commission</dt>
            <dd>{{userDetails?.match_commission}}</dd>

            <dt>Exposure Limit</dt>
            <dd>{{userDetails?.exposure_limit}}</dd>

            <dt>Mobile Number</dt>
            <dd>{{(userDetails?.mobile == null) ? 'Not Available' : userDetails?.mobile}}</dd>

            <dt>Password</dt>
            <dd><span class="me-3">*********</span><a (click)="openModalChangePwd(changePwd);" data-bs-toggle="modal"
                role="button" class="favor-set">Edit</a></dd>

                <dt>Languages</dt>
                <dd><select  id="lang" ><option  value="en" selected="">English </option></select></dd>
          </dl>
        </div>

        <div class="profile-wrap"><h3>Address</h3>
          <dl>
            <dt>Address</dt><dd>--</dd>
            <dt>Town/City</dt><dd>--</dd>
            <dt>Country</dt><dd>--</dd>
            <dt>Country/State</dt><dd>--</dd>
            <dt>&gt;Postcode</dt><dd>--</dd>
            <dt>Time Zone</dt><dd>IST</dd>
          </dl>
        </div>

      </div>


      <div class="event-right">
        <div class="profile-wrap">
          <h3>Contact Details</h3>
          <dl><dt>Primary number</dt><dd>--</dd></dl>
        </div>
          <div class="profile-wrap">
            <h3>Setting</h3>
            <dl>
              <dt>Currency</dt><dd>INR</dd>
              <dt>Odds Format</dt><dd>--</dd>
            </dl>
          </div>
            <div class="profile-wrap">
              <h3>Commission</h3>
              <dl>
                <dt>Comm charged</dt><dd>0.0 %</dd>
              </dl>
            </div>
            </div>
    </div>
  </div>
  <app-footer-main></app-footer-main>
</div>
<ng-template #changePwd>
  <a class="close_pop" data-dismiss="modal" (click)="modalRef.hide()" *ngIf="pass != true">close_pop</a>
  <h3>Change Password</h3>
  <form [formGroup]='changePasswordForm' (ngSubmit)='onSubmitChangePassword()'>
    <dl class="form_list">
      <dt>Your Password</dt>
      <dd>
        <input type="password" placeholder="Enter" formControlName='old_password'>
        <span class="must">＊</span>
      </dd>
      <dt>New Password</dt>
      <dd>
        <input id="newPassword" type="password" placeholder="Enter" formControlName='new_password'>
        <span class="must">＊</span>
      </dd>
      <dt>New Password Confirm</dt>
      <dd>
        <input id="newPasswordConfirm" type="password" placeholder="Enter" formControlName='confirm_password'>
        <span class="must">＊</span>
      </dd>
      <dd><a type="submit" class="btn-send">Change</a></dd>
    </dl>
  </form>
</ng-template>