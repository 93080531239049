import { Component, OnInit, ElementRef, ViewChild, TemplateRef, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { OpenHeaderComponent } from '../open-header/open-header.component';
import { OpenSidebarComponent } from '../open-sidebar/open-sidebar.component';
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from '../services/open-api.service';

@Component({
  selector: 'app-open-inplay',
  templateUrl: './open-inplay.component.html',
  styleUrls: ['./open-inplay.component.scss'],
  providers: [OpenHeaderComponent, OpenSidebarComponent],
})
export class OpenInplayComponent implements OnInit {
  modalRef: BsModalRef;
  userData: any;
  parameter: any;
  firstData: any;
  firstDataLength: any;
  homeData: any;
  dataLength: any;
  allMatchesfirstData: any;
  selectedBackBet: any
  selectedLayBet: any
  selectedLayBetDetails: any;
  selectedBackBetDetails: any;
  betType: any;
  loading: boolean = false;
  constructor(private open: OpenApiService, private cdRef: ChangeDetectorRef, private modalService: BsModalService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.data == undefined) {
        this.parameter = 'inplay'
        this.userData = {
          inplay: true
        };
        this.homematches();
      } else {
        this.parameter = params.data;
        if (this.parameter == 'inplay') {
          this.userData = {
            inplay: true
          };
        } else if (this.parameter == 'today') {
          this.userData = {
            today: true
          };
        } else if (this.parameter == 'tomorrow') {
          this.userData = {
            tomorrow: true
          };
        }
        this.homematches();
      }
    })
  }

  homematches() {
    this.open.getHomeMatchesList(this.userData).subscribe(res => {
      if (res.status) {
        this.firstData = res.data;
        this.allMatchesfirstData = res.data;
        this.firstDataLength = this.firstData.length;
        for (let i = 0; i < this.firstDataLength; i++) {
          this.firstData[i].manualInplay = this.firstData[i].inplay
        }
        let matches = this.firstData;
        matches = matches.sort((a: any, b: any) =>
          new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
        );
        matches = matches.reduce((groupSport, sports) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});
        this.homeData = matches;
        this.cdRef.detectChanges();
      } else {
        this.allMatchesfirstData = []
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    })
  }

  goToDashboard(data) {
    this.router.navigate(['openInplay/' + data]);
  }
  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['openDetail']);
  }

  filterBySport(sportId) {
    if (sportId === "All") {
      this.allMatchesfirstData = this.firstData
    } else {
      this.allMatchesfirstData = this.firstData.filter(element => element.sport_id == sportId)
    }
  }
}
