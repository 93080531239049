<!-- sky design -->
<div class="loginModal">
    <div class="modal-content">
        <div class="lgoin-main">
            <div class="login-head">
                <a class="close ui-link" [routerLink]="['/openHome/']"></a>
                <h1><img src="{{logo}}" alt=""></h1>
                <div class="betfair-wrap">
                    <p>Powered by</p>
                    <span>
                    </span>
                </div>
            </div>
            <form [formGroup]='addUserFrom' class="form-login">
                <dl class="">
                    <dd>
                        <input formControlName="user_name" placeholder="UserName" type="text" required>
                        <div *ngIf="isControlIsValid('user_name')"  class="validationErrorMessage">
                            <div *ngIf="isControlHasErros('user_name')">{{ isControlHasErros('user_name')}}</div>
                          </div>
                    </dd>
                   
                    <dd class="inner-addon right-addon">
                        <input [type]="showPassword ? 'text' : 'password'" formControlName="password" placeholder="Password" type="password" required>
                      
                            <i alt="show" class="fa fa-eye eye-show"
                                (click)="showPassword = !showPassword"
                                [class.hide]="showPassword"></i>
                            <i alt="hide" class="fa fa-eye-slash eye-hide"
                                (click)="showPassword = !showPassword"
                                [class.hide]="!showPassword"></i>
                      
                        <div *ngIf="isControlIsValid('password')" class="validationErrorMessage">
                            <div *ngIf="isControlHasErros('password')">{{ isControlHasErros('password')}}</div>
                          </div>
                    </dd>
                    

                      <dd>
                        <input placeholder="Mobile" type="password" formControlName='mobile' maxlength='10' type="text"  required>
                        <div *ngIf="isControlIsValid('mobile')" class="validationErrorMessage">
                            <div *ngIf="isControlHasErros('mobile')">{{ isControlHasErros('mobile')}}</div>
                          </div>
                    </dd>
                    <div *ngIf="!isControlIsValid('mobile')" class="validationErrorMessage">
                        <span style="font-size: 12px;color: black;">Note: Please enter correct number, this number is used to reset password</span>
                      </div>
                    

                      <div *ngIf="referCode != ''" class="form-group m-b-20 inner-addon right-addon">
                        <div class="input-group">
                            <input placeholder="ReferCode" class="form-control password-box" formControlName='refer_code' type="text" readonly>
                               
                            </div>
                            
                    </div>
                    <div *ngIf="referCode == ''" class="form-group m-b-20 inner-addon right-addon mb-3 mt-2">
                        <div class="input-group">
                            <input placeholder="ReferCode" class="form-control password-box" formControlName='refer_code' type="text">
                            </div>
                            
                    </div>
                    
                    <dd *ngIf="showResponseMessage"><p class="error">{{showResponseMessage}}</p></dd>
                    <dd>
                        <a class="btn-send ui-link" (click)="onSubmitLogin()">Register</a>
                    </dd>
                </dl>
            </form>

            <ul class="policy-link">
                <li><a>Privacy Policy</a></li>
                <li><a>Terms and Conditions</a></li>
                <li><a>Rules and Regulations</a></li>
                <li><a>KYC</a></li>
                <li><a>Responsible Gaming</a></li>
                <li><a>About Us</a></li>
                <li><a>Self-exclusion Policy</a></li>
                <li><a>Underage Policy</a></li>
            </ul>
            <!-- <div class="support-wrap extend-support">
                <div class="extend-btn">
                    <img src="assets/sky_img/support_black.svg" title="customer" class="support-customer">
                    <a >Customer support1</a>
                    <a  class="split-line">support2</a>
                </div>
                <div class="extend-btn">
                    <img src="assets/sky_img/whatsapp_black.svg" title="WhatsApp" class="support-whatsapp">
                    <a >+0000000000</a>
                    <a  class="split-line">+0000000000</a>
                </div>
                <div class="extend-btn">
                    <img src="assets/sky_img/telegram_black.svg" title="Telegram" class="support-telegram">
                    <a >***********</a>
                    <a  class="split-line">***********</a>
                </div>
                <div class="support-social">
                    <div class="social-btn">
                        <img src="assets/sky_img/skype_black.svg" title="Skype" class="support-skype">
                        <a >Skype</a>
                    </div>
                    <div class="social-btn">
                        <img src="assets/sky_img/email_black.svg" title="Email" class="support-mail">
                        <a >Email</a>
                    </div>
                    <div class="social-btn">
                        <img src="assets/sky_img/instagram_black.svg" title="Instagram" class="support-ig">
                        <a >Instagram</a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
