import { Component, OnChanges, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from "@angular/router";
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { UsersService } from '../services/users.service';
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportService } from '../services/report.service';
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from '../header/header.component';
import { resolve } from 'q';
import { SocketServiceService } from '../services/socket-service.service';
import { ThemeService } from '../theme/theme.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  providers: [HeaderComponent],
})
export class InfoComponent implements OnInit {
  @ViewChild('changePwd', { static: false }) public privacyPopup: TemplateRef<any>;
  userDetails: any;
  modalRef: BsModalRef;
  changePasswordForm: FormGroup;
  submitted = false;
  constructor(private sportService: SportService, private router: Router, private cookie: CookieService, private ref: ChangeDetectorRef,
    private loginService: LoginService, private fb: FormBuilder, private usersService: UsersService, private reportService: ReportService,
    private socketService: SocketServiceService, private toastr: ToastrService, private modalService: BsModalService,
    private head: HeaderComponent, private themService: ThemeService) { }

  ngOnInit(): void {
    // this.userDetails = JSON.parse(localStorage.getItem('userDetails')); 
    this.createChangePasswordFrom();
    this.profile();
  }

  profile() {
    this.usersService.profile().subscribe((res) => {
      if (res.status) {
        this.userDetails = res.data
      }
    })
  }
  openModalChangePwd(changePwd: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      changePwd,
      Object.assign({}, { class: 'pop_box passwordModal', ignoreBackdropClick: true }),
    );
  }
  openModalrollComission(rollComission: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      rollComission,
      Object.assign({}, { class: 'pop_box commissionModal', ignoreBackdropClick: true }),
    );
  }
  createChangePasswordFrom() {
    this.changePasswordForm = this.fb.group({

      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]

    })
  }

  statement() {
    this.router.navigate(['statement'])
  }
  profitLoss() {
    this.router.navigate(['profit-loss'])
  }
  info() {
    this.router.navigate(['info'])
  }
  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  rollComission() {
    this.router.navigate(['rollComission'])
  }

  get f() { return this.changePasswordForm.controls; }

  onSubmitChangePassword() {
    this.submitted = true;

    if (this.changePasswordForm.invalid) {
      return;
    }
    let data = {
      "newPassword": this.changePasswordForm.value.new_password
    }
    if (this.changePasswordForm.controls['new_password'].value == this.changePasswordForm.controls['confirm_password'].value) {
      this.usersService.changePassword(data).subscribe((result) => {
        if (result.status == true) {
          this.userDetails.is_change_password = '1';
          localStorage.setItem('userDetails', JSON.stringify(this.userDetails));
          this.submitted = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          this.modalRef.hide();
          this.head.logoutUser();
        } else {
          this.toastr.error(result.msg, '', {
            timeOut: 10000,
          });
        }
      }, (err) => {
        console.log(err);
      });
    }

    else {
      this.toastr.error("Password and confirm password did not match", '', {
        timeOut: 10000,
      });
    }
  }

}
