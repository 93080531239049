import { Component, OnChanges, OnInit, TemplateRef ,ViewChild,ChangeDetectorRef,ChangeDetectionStrategy} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router,ActivatedRoute } from "@angular/router";
import * as moment from 'moment';
import { OpenApiService } from '../services/open-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { resolve } from 'q';
import { ThemeService } from '../theme/theme.service';
import Swal from 'sweetalert2';
import { LoginService } from '../services/login.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-open-header',
  templateUrl: './open-header.component.html',
  styleUrls: ['./open-header.component.scss']
})
export class OpenHeaderComponent implements OnInit {
  Form: FormGroup;
  loginButtonDisable = false
  submitted = false;
  transactionPassword: string;
  showResponseMessage;
  public showPassword: boolean;
  private clickTimeout = null;
  public Base_Url = environment['adminServerUrl'];
  a: any;
  logo: string;
  allSportsList: any;
  userData: any;
  homeData: any;
  modalRef: BsModalRef;
  searchSport: string;
  loading: boolean;
  domainName: string;
  domainData: string;
  sportsList: any;
  b:any;
  c: any;
  firstData: any;
  firstDataLength: any;
  path: string;
  sportsName: any;
  tPassword:any;
  constructor( private router: Router,private ref: ChangeDetectorRef, private open : OpenApiService,
   private fb: FormBuilder, private toastr: ToastrService, private modalService: BsModalService,private loginService: LoginService,
   private route: ActivatedRoute, private themService: ThemeService) { 
      let a = this.router.url;
      let b = a.split('/')
      this.path = b[1]
      this.route.params.subscribe(params => {
        this.sportsName = params.sportName
        
      })
    }

  ngOnInit(): void {
    this.createRandomTransactionPassword()
    this.domainName =window.location.origin;
    this.domainData = this.getDomainWithoutSubdomain(this.domainName);
    let a = window.screen.width;
    if( a > 767){
      this.homematchesOpen()
    }
    this.getLoginLogo()
    this.createFrom();
  }
  getDomainWithoutSubdomain (url){
    try {
      const urlParts = new URL(url).hostname.split('.');
      return urlParts
        .slice(0)
        .slice(-(urlParts.length === 4 ? 3 : 2))
        .join('.');
    } catch (error) {
      console.log("55",error);
      return "";
    }
  }
  getLoginLogo(){
    let data = {
      key :btoa(this.domainData)
    }
    this.loginService.getLogo(data).subscribe((res)=>{
      if (res.status) {
        if (res.data != null) {
          if((res.data).hasOwnProperty('self_host')){
            if((res.data).self_host == true){
              this.logo = this.Base_Url + res.data.description;
              localStorage.setItem('logo', this.logo)
            } else {
              this.logo = res.data.description;
              localStorage.setItem('logo', this.logo)
            } 
          } else {
            this.logo = this.Base_Url + res.data.description;
            localStorage.setItem('logo', this.logo)
          }
        }
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe:[false]
    })
    if(JSON.parse(localStorage.getItem('RememberMe'))){
      this.Form.patchValue({
        user_name:localStorage.getItem('user_name'),
        password:localStorage.getItem('password'),
        isRememberMe:localStorage.getItem('RememberMe')
      })
    }
  }
  get f() { return this.Form.controls; }
  async onSubmitLogin(from?: string) {
    this.loginButtonDisable = true
    if (this.clickTimeout) {
      this.setClickTimeout(() => { });
    } else {
      this.setClickTimeout(() => {
        if (from == 'login') {
          this.headerSingleLoginClick()
        } else {
          this.handleSingleLoginClick();
        }
      })  
    }
  }
  public handleSingleLoginClick() {
    this.loading = true;   
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if(this.tPassword == this.transactionPassword){
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.loading = false;
          this.modalRef.hide();
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
           this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.loading = false;
          this.showResponseMessage = result.msg;
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loginButtonDisable = false;
        this.loading = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loginButtonDisable = false;
        this.loading = false;
        this.showResponseMessage = 'Invalid Captcha';
        this.createRandomTransactionPassword();
    }
   
  }
  public headerSingleLoginClick() {
    this.loading = true;  
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if(this.tPassword == this.transactionPassword){
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.loading = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
           this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.loading = false;
          this.showResponseMessage = result.msg;
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loading = false;
        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loading = false;
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }
  }
  public setClickTimeout(callback) {
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async redirectToDashboard() {
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }
  async setCokkies(result) {
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    var acctoken = localStorage.getItem('userAccessToken');
    this.router.navigate(['dashboard'])
  }
  openModalLoginCnfm(loginCnfm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginCnfm,
      Object.assign({}, { class: 'loginCnfm-modal gamblingModal',ignoreBackdropClick: true })
    );
  }
  openModalLoginCnfmHead(loginCnfmHead: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginCnfmHead,
      Object.assign({}, { class: 'loginCnfm-modal gamblingModal',ignoreBackdropClick: true })
    );
  }
  openModalLoginPopup(loginPopup: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginPopup,
      Object.assign({}, { class: 'loginModal modal-dialog-centered modal-fullscreen-lg-down',ignoreBackdropClick: true })
    );
  }
  openModalLoginDesktop(loginDesktop: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginDesktop,
      Object.assign({}, { class: 'loginModalDesktop loginModal modal-dialog-centered modal-fullscreen-lg-down',ignoreBackdropClick: true })
    );
  }
  getAllSportsListData() {
    let userData = {};
    this.open.getAllSportsList(userData).subscribe(res => {
      this.sportsList = res.data;
      for(let i=0; i <this.sportsList.length;i++){
        this.sportsList[i].live = 0
        for(let j=0;j < Object.keys(this.c).length;j++){
          if(this.sportsList[i].name == Object.keys(this.c)[j]){
            this.b = Object.values(this.c)[j];
            this.sportsList[i].live = this.b.length
          }
        }
      }
      this.allSportsList = this.sportsList
      this.allSportsList = (this.allSportsList).sort(function(a, b){return b.sport_id - a.sport_id});
      this.ref.detectChanges();
    })
  }
  homematches() {
    this.userData = {}
    this.open.getSearchMatchList(this.userData).subscribe(res => {
      if (res.status) {
        this.homeData = res.data;
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    })
  }
  dashboard() {
    this.router.navigate(['openHome'])
  }
  inplay() {
    this.router.navigate(['openInplay'])
  }
  multi() {
    this.router.navigate(['openMulti'])
  }
  goToDashboard(sportName, id, data) {
      this.router.navigate(['openDashboard/' + sportName]);
  }

homematchesOpen() {
    this.userData = {
    }
    this.open.getHomeMatchesList(this.userData).subscribe(res => {
      if (res.status) {
          this.firstData = res.data;
          this.firstDataLength = this.firstData.length;
          for (let i = 0; i < this.firstDataLength; i++) {
            this.firstData[i].manualInplay = this.firstData[i].inplay
          }
            let matches;
              matches = this.firstData.filter(t => t.inplay == true || t.manualInplay == true);
              matches = matches.reduce((groupSport: { [x: string]: any; }, sports: { sport_name: any; }) => {
                const { sport_name } = sports;
                groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
                return groupSport;
              }, {});
            this.c = matches;
            this.getAllSportsListData();
      } 
    }, (err) => {
      console.log(err);
    })
  }

  createRandomTransactionPassword() {
    //initialize a variable having alpha-numeric characters
    var chars = "0123456789";

    //specify the length for the new string to be generated
    var string_length = 4;
    var randomstring = '';

    //put a loop to select a character randomly in each iteration
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    this.transactionPassword = randomstring;
  }
}
