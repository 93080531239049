import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SportService } from '../services/sport.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  allSportsList: any;
  userDetails: any;
  allSeriesList: any;
  logo: string;
  matchList: any[] = [];
  seriesId: any;
  sportList: boolean = true;
  isShowMenu: boolean[] = [];
  constructor(public toastr: ToastrService, private router: Router, private sport: SportService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.logo = window.location.hostname;
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    let a = window.screen.width;
    if (a > 767) {
      this.getAllSportsListData()
    }
  }
  getAllSportsListData() {
    let userData = {};
    this.sport.getAllSportsList(userData).subscribe(res => {
      if (res.status == true) {
        this.sportList = true;
        this.allSportsList = res.data;
        this.allSportsList = (this.allSportsList).sort(function (a, b) { return b.sport_id - a.sport_id });
        this.cdRef.detectChanges();
      } else {
        this.toastr.error(res.msg);
      }

    })
  }

  status: boolean = false;
  headerNav() {
    this.status = !this.status;
  }
  dashboard() {
    this.router.navigate(['dashboard'])
  }
  inplay() {
    this.router.navigate(['inplay'])
  }
  reports() {
    this.router.navigate(['reports/openBets'])
  }


  getSeriesList(id) {
    let data = {
      sport_id: id
    };
    this.sport.getSeriesList(data).subscribe(res => {
      this.sportList = false;
      this.allSeriesList = res.data
      this.isShowMenu = Array(this.allSeriesList.length).fill(false);
      this.matchList = Array(this.allSeriesList.length).fill([]);
    })
  }
  getMatchList(data, index) {
    let userData = {
      series_id: data.series_id
    };
    this.isShowMenu[index] = !this.isShowMenu[index]
    this.sport.getHomeMatchesList(userData).subscribe(res => {
      if (res.status) {
        this.seriesId = data.series_id
        this.matchList[index] = res.data;
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    })
  }
  goToDashboard(series) {
    if (series.sport_id == -100) {
      localStorage.setItem('selectedMatch', JSON.stringify(series));
      this.router.navigate(['exchDetail']);
    } else {
      this.router.navigate(['home/' + series.sport_id + '/' + series.series_id])
    }
  }

  goToCasino(series) {
    if(series.sport_id == -100){
      this.router.navigate(['casino'])
    } else {
    this.router.navigate(['snova/' + series.sport_id + '/' + series.name + '/' + series.providerCode])
    }
  }
  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['match-detail/' + matchData.match_id]);
  }
}
