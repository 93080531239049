<div class="main-wrapper d-none">
  <app-header></app-header>
  <div class="app-body position-relative">
    <app-sidebar></app-sidebar>
    <main class="main">
      <div class="row">
        <div class="col-md-12">
          <div class="divider-top">
            <div class="live-casino">
              <div class="row g-2">
                <div class="col-md-6 col-12 game-item" *ngFor="let gameList of exchangeGameList;let i = index;"
                  (click)="gotoexchangeGame(gameList)">
                  <div class="casino-icon">
                    <a >
                      <img src="assets/Casino/{{gameList?.match_id}}.jpg" alt="" class="img-fluid">
                      <div class="casino-name">{{gameList?.match_name}}</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <app-right-sidebar></app-right-sidebar>
  </div>
  <app-footer-main></app-footer-main>
</div>

<div class="app-body">
  <app-header></app-header>
  <div class="full-wrap">
    <div class="col-left">
      <app-sidebar></app-sidebar>
    </div>

    <div id="centerColumn" class="col-center report">

      <h2>Casino</h2>
      <div class="live-casino">
        <div class="game-item" *ngFor="let gameList of exchangeGameList;let i = index;"
          (click)="gotoexchangeGame(gameList)">
          <div class="casino-icon">
            <a>
              <img src="assets/Casino/{{gameList?.match_id}}.jpg" alt="" class="img-fluid">
              <div class="casino-name">{{gameList?.match_name}}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-main></app-footer-main>
</div>