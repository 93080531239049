import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import * as bank from '../../bank.json';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-withdraw-detail',
  templateUrl: './withdraw-detail.component.html',
  styleUrls: ['./withdraw-detail.component.scss']
})
export class WithdrawDetailComponent implements OnInit {

  bankList: any;
  searchBank: any;
  userDetails: any;
  payMethodList: any=[];
  walletBalance: any;
  updatedBalance: number;
  amount: any;
  loading: boolean = false;
  methodDetail: any;
  methodId: any;
  dateTime = new Date();
  statementStartDate = new Date()
  statementEndDate = new Date();
  count: any = 0;
  Form: FormGroup;
  upiForm:FormGroup;
  submitted: boolean = false;
  accountType: any;
  show_list_type:any='Bank';
  saveIndex:any=0;
  activeTabIndex:any=0;
  constructor(private fb: FormBuilder, private toastr: ToastrService, private cdRef: ChangeDetectorRef, private sport: SportService,private router: Router) { }

  ngOnInit(): void {
    this.statementStartDate.setHours(0, 0, 0, 0);
    this.statementEndDate.setHours(23, 59, 59);
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.depositMethod();
    this.getUserBalance();
    this.getStatement();
    this.withdrawFrom();
    this.upiDetailFrom();
    this.bankList = bank['default']?.message;
  }


  info() {
    this.router.navigate(['info'])
  }

  rollComission() {
    this.router.navigate(['rollComission'])
  }

  statement() {
    this.router.navigate(['statement'])
  }

  profitLoss() {
    this.router.navigate(['profit-loss'])
  }

  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }

  hideShowBankStatus: boolean = true;
  hideShowBankdetail() {
    this.showBankFormStatus = false;
    this.hideShowBankStatus = !this.hideShowBankStatus;
  }
  get f() { return this.Form.controls; }
  get u() { return this.upiForm.controls; }
  withdrawFrom() {
    this.Form = this.fb.group({
      user_id: ['', Validators.required],
      method_id: ['', Validators.required],
      bank_holder_name: ['', Validators.required],
      bank_name: ['', Validators.required],
      ifsc_code: ['', [Validators.required,this.validateIFSCCode]],
      account_no: ['', [Validators.required,this.validateAccountNumber]]
    });
    this.Form.controls['user_id'].setValue(this.userDetails._id);
  }

  upiDetailFrom() {
    this.upiForm = this.fb.group({
      user_id: ['', Validators.required],
      method_id: ['', Validators.required],
      bank_holder_name: ['', Validators.required],
      others: ['', Validators.required]
    });
    this.upiForm.controls['user_id'].setValue(this.userDetails._id);
  }

  // Custom validator for Account Number
  validateAccountNumber(control) {
    // Implement your custom validation logic for account numbers
    // Example: Check for a specific format
    const accountNumberRegex = /^[0-9]{9,18}$/;
    return accountNumberRegex.test(control.value) ? null : { invalidAccountNumber: true };
  }

  // Custom validator for IFSC Code
  validateIFSCCode(control) {
    // Implement your custom validation logic for IFSC codes
    // Example: Check for a specific format
    const ifscCodeRegex = /^[A-Z]{4}\d{7}$/;
    return ifscCodeRegex.test(control.value) ? null : { invalidIFSCCode: true };
  }
  
  showBankFormStatus: boolean = false;
  showBankForm() {
    this.hideShowBankStatus = false;
    this.showBankFormStatus = !this.showBankFormStatus;
  }
  selectBank(bank) {
    this.Form.controls['bank_name'].setValue(bank);
  }

  showDetails(index:any)
  {
    this.activeTabIndex=index;
    this.show_list_type=this.payMethodList[index].name;
    this.saveIndex=index;
     this.showDetail(this.payMethodList[this.saveIndex]);
  }

  addDetails(index:any)
  {
    this.accountType=this.payMethodList[index].name;
    this.methodId=this.payMethodList[index]._id;
    this.Form.controls['method_id'].setValue(this.payMethodList[index]._id);
    this.upiForm.controls['method_id'].setValue(this.payMethodList[index]._id);
  }

  depositMethod() {
    let data = {
      user_id: this.userDetails._id,
      domain_id: this.userDetails.domain_id,
      type: 'WITHDRAW'
    };
    
    this.sport.paymentMethodList(data).subscribe((res) => {
      if (res.status == true) {
        this.payMethodList = res.data;
        this.showDetail(this.payMethodList[this.saveIndex]);
      } else {
        this.toastr.error('Withdraw Method is coming Soon.')
      }
    })
  }
  getStatement() {
    let data = {
      "statement_type": '1',
      "user_id": this.userDetails._id,
      "from_date": this.statementStartDate.toISOString(),
      "to_date": this.statementEndDate.toISOString()
    }
    this.sport.getStatement(data).subscribe((res) => {
      if (res.status == true) {
        let response = res.data[0].data;
        for (let i = 0; i < response.length; i++) {
          if (response[i].credit_debit < 0) {
            this.count = this.count + 1;
          }
        }
        this.cdRef.detectChanges();
      } else {
        this.count = 0;
      }
    })
  }
  addPaymentMethod() {
    this.submitted = true;
    if(this.accountType==='Bank')
    {
      if (this.Form.invalid) {
        return;
      }
      this.sport.addUserPaymentDetail(this.Form.value).subscribe((res) => {
        if (res.status == true) {
          this.toastr.success(res.msg);
          this.hideShowBankdetail();
          this.accountType='';
          this.showDetail(this.payMethodList[this.saveIndex]);
        } else {
          this.toastr.error(res.msg)
        }
      })
    }
    else{
      if (this.upiForm.invalid) {
        return;
      }
      this.sport.addUserPaymentDetail(this.upiForm.value).subscribe((res) => {
        if (res.status == true) {
          this.toastr.success(res.msg);
          this.hideShowBankdetail();
          this.accountType='';
          this.upiForm.controls['bank_holder_name'].setValue('');
          this.upiForm.controls['others'].setValue('');
          this.showDetail(this.payMethodList[this.saveIndex]);
        } else {
          this.toastr.error(res.msg)
        }
      })
    }
    
  }
  withdraw() {
    this.loading = true;
    this.cdRef.detectChanges();
    if (this.amount > 0) {
      let data = {
        user_id: this.userDetails._id,
        parent_id: this.userDetails.parent_id,
        amount: parseInt(this.amount),
        crdr: 2,
        remark: 'wallet'
      }
      this.sport.paymentWithdraw(data).subscribe((res) => {
        if (res.status == true) {
          this.amount = 0;
          this.getStatement();
          this.toastr.success(res.msg)
        } else {
          this.toastr.error(res.msg)
        }
        this.loading = false;
      })
    } else {
      this.toastr.error('Please Enter Amount ...')
      this.loading = false;
    }
  }
  getUserBalance() {
    let data = {};
    this.sport.getUserBalance(data).subscribe(response => {
      if (response.status == true) {
        this.walletBalance = response.data;
        this.updatedBalance = (this.walletBalance.balance) - 100;
        this.cdRef.detectChanges();
      } else {
        this.toastr.error(response.msg, '', {
          timeOut: 10000,
        });
        this.cdRef.detectChanges();
      }
    })
  }
  
  delete(list) {
    if (confirm('Delete this account detail')) {
      let param = {
        user_id: this.userDetails._id,
        _id: list._id
      }
      this.sport.removeUserPaymentDetail(param).subscribe((res) => {
        if (res.status == true) {
          this.toastr.success(res.msg);
          this.showDetail(this.payMethodList[this.saveIndex]);
        } else {
          this.toastr.error(res.msg)
        }
      })
    }
  }
  showDetail(param) {
    this.methodId = param;
    this.cdRef.detectChanges();
    let data = {
      user_id: this.userDetails._id,
      method_id: param._id
    }
    this.sport.paymentWithdrawDetail(data).subscribe((res) => {
      this.methodDetail = res.data;
    })
  }

}
