<div class="app-body">
  <!-- Loading Wrap -->
  <div id="loading" class="loading-wrap d-none-mobile" *ngIf="loading == true">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>

  <div class="loading-overlay d-none-desktop" id="loading" *ngIf="loading == true">
    <div class="loading-wrap">
      <div class="loading">
        <div></div>
        <div></div>
      </div>
      <p>Loading...
      </p>
    </div>
  </div>

  <app-header></app-header>
  <div class="full-wrap">
    <div id="centerColumn" class="col-center inplay">
      <div class="marquee-box"><h4 _ngcontent-eft-c39="">News</h4>
        <div class="marquee"><marquee width="100%" direction="left"><a href="#">🏆𝐈𝐂𝐂 𝐂𝐑𝐈𝐂𝐊𝐄𝐓 𝐖𝐎𝐑𝐋𝐃 𝐂𝐔𝐏 𝟐𝟎𝟐𝟑 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 🏆||𝐒𝐔𝐏𝐄𝐑 50 𝐂𝐔𝐏 𝐂𝐑𝐈𝐂𝐊𝐄𝐓 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 ||🎮 𝐕𝐈𝐑𝐓𝐔𝐀𝐋 𝐂𝐑𝐈𝐂𝐊𝐄𝐓 𝐁𝐄𝐓𝐒 𝐀𝐕𝐀𝐈𝐋𝐀𝐁𝐋𝐄 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄🎮 ||🎾 𝐓𝐄𝐍𝐍𝐈𝐒 𝐒𝐄𝐓 𝐖𝐈𝐍𝐍𝐄𝐑 𝐌𝐀𝐑𝐊𝐄𝐓 𝐀𝐕𝐀𝐈𝐋𝐀𝐁𝐋𝐄 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 🎾 ||🏀 𝐏𝐑𝐄𝐌𝐈𝐔𝐌 𝐒𝐏𝐎𝐑𝐓𝐒𝐁𝐎𝐎𝐊 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 🏀||Toss Fancy Market Available for all Matches|| </a></marquee></div></div>
      <!-- Message -->
      <div id="message" class="message-wrap success">
        <a class="btn-close">Close</a>
        <p></p>
      </div>
      <!-- Center Column -->
      <ul class="tab col3 d-none-mobile" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active" id="pills-inplay-tab" [class.active]='parameter == "inplay"' data-bs-toggle="pill"
            data-bs-target="#pills-inplay" role="tab" aria-controls="pills-inplay" aria-selected="true"
            (click)="goToDashboard('inplay')">In-Play</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="pills-today-tab" [class.active]='parameter == "today"' data-bs-toggle="pill"
            data-bs-target="#pills-today" role="tab" aria-controls="pills-today" aria-selected="false"
            (click)="goToDashboard('today')">Today</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="pills-tomorrow-tab" [class.active]='parameter == "tomorrow"' data-bs-toggle="pill"
            data-bs-target="#pills-tomorrow" role="tab" aria-controls="pills-tomorrow" aria-selected="false"
            (click)="goToDashboard('tomorrow')">Tomorrow</a>
        </li>

      </ul>
      <div class="tab-wrap d-lg-none">
        <ul id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="pills-inplay-tab" [class.active]='parameter == "inplay"'
              data-bs-toggle="pill" data-bs-target="#pills-inplay" role="tab" aria-controls="pills-inplay"
              aria-selected="true" (click)="goToDashboard('inplay')">In-Play</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-today-tab" [class.active]='parameter == "today"' data-bs-toggle="pill"
              data-bs-target="#pills-today" role="tab" aria-controls="pills-today" aria-selected="false"
              (click)="goToDashboard('today')">Today</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-tomorrow-tab" [class.active]='parameter == "tomorrow"' data-bs-toggle="pill"
              data-bs-target="#pills-tomorrow" role="tab" aria-controls="pills-tomorrow" aria-selected="false"
              (click)="goToDashboard('tomorrow')">Tomorrow</a>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="pills-inplay-tab" tabindex="0">
          <div class="tabcontent-wrap" *ngFor="let homeMatch of homeData | keyvalue;let i = index;">
            <div class="game-wrap col3">
              <h3>
                <a id="eventType" class="to-expand">{{homeMatch?.key}}</a>
              </h3>
              <ul class="slip-head d-none-mobile">
                <li class="col-game"></li>
                <li class="col-matched">Matched</li>
                <li class="col-visit">1</li>
                <li class="col-draw">x</li>
                <li class="col-home">2</li>
                <li class="col-info"></li>
              </ul>
              <div class="game-list d-none-mobile">
                <dl class="game-list-col" *ngFor="let matchData of homeMatch.value,let j = index;">
                  <dt (click)="matchDetail(matchData)">
                    <span class="img-span"
                      [ngClass]="matchData.inplay || matchData?.manualInplay == true ? 'icon-in_play' : 'icon-no_play'"></span>
                    <span class="game-low_liq" style="display: none;">Low Liquidity</span>
                    <a href="javascript:void(0)">{{matchData?.match_name}}</a>
                    <span class="game-list-time" *ngIf="matchData?.inplay == true || matchData?.manualInplay == true">
                      <span class="in_play">In-Play</span>
                    </span>
                    <span class="game-list-time" *ngIf="matchData?.inplay != true ">
                      <span>{{matchData?.match_date | date :'yyyy-MM-dd HH:mm'}}</span>
                    </span>
                    <span class="game-live" id="streamingIcon" style="display: inline-flex;"
                      *ngIf="matchData?.has_tv_url">Live</span>
                    <span class="game-fancy in-play" *ngIf="matchData?.enable_fancy == 1 ">Fancy</span>
                    <span class="game-bookmaker" *ngIf="matchData?.enable_fancy == 1 ">BookMaker</span>
                  </dt>
                  <dd class="col-matched">PTE {{matchData?.totalMatched}}</dd>
                  <dd class="col-visit">
                    <a class="btn-back" side="Back"
                      (click)="getOddsValue((matchData?.runners[0]?.ex?.availableToBack[0]?.price),matchData?.runners[0]?.ex?.availableToBack[0]?.size,1,matchData?.runners[0]?.name,matchData?.runners[0]?.selectionId,matchData.market_id,i,matchData)">
                      {{matchData?.runners[0] == undefined ? 0 :
                      matchData?.runners[0]?.ex?.availableToBack[0]?.price}}</a>
                    <a class="btn-lay" side="Lay"
                      (click)="getOddsValue((matchData?.runners[0]?.ex?.availableToLay[0]?.price),matchData?.runners[0]?.ex?.availableToLay[0]?.size,0,matchData?.runners[0]?.name,matchData?.runners[0]?.selectionId,matchData.market_id,i,matchData)">
                      {{matchData?.runners[0]
                      == undefined ? 0 :
                      matchData?.runners[0]?.ex?.availableToLay[0]?.price}}
                    </a>
                  </dd>
                  <dd class="col-draw">
                    <a class="btn-back" side="Back"
                      (click)="getOddsValue((matchData?.runners[2]?.ex?.availableToBack[0]?.price),matchData?.runners[2]?.ex?.availableToBack[0]?.size,1,matchData?.runners[2]?.name,matchData?.runners[2]?.selectionId,matchData.market_id,i,matchData)">
                      {{matchData?.runners[2] == undefined ? '--' :
                      matchData?.runners[2]?.ex?.availableToBack[0]?.price}}
                    </a>
                    <a class="btn-lay" side="Lay"
                      (click)="getOddsValue((matchData?.runners[2]?.ex?.availableToLay[0]?.price),matchData?.runners[2]?.ex?.availableToLay[0]?.size,0,matchData?.runners[2]?.name,matchData?.runners[2]?.selectionId,matchData.market_id,i,matchData)">
                      {{matchData?.runners[2]
                      == undefined ? '--' :
                      matchData?.runners[2]?.ex?.availableToLay[0]?.price}}
                    </a>
                  </dd>
                  <dd class="col-home">
                    <a class="btn-back" side="Back"
                      (click)="getOddsValue((matchData?.runners[1]?.ex?.availableToBack[0]?.price),matchData?.runners[1]?.ex?.availableToBack[0]?.size,1,matchData?.runners[1]?.name,matchData?.runners[1]?.selectionId,matchData.market_id,i,matchData)">
                      {{matchData?.runners[1]?.ex?.availableToBack[0]?.price
                      == undefined ? 0 :
                      matchData?.runners[1]?.ex?.availableToBack[0]?.price}}
                    </a>
                    <a class="btn-lay" side="Lay"
                      (click)="getOddsValue((matchData?.runners[1]?.ex?.availableToLay[0]?.price),matchData?.runners[1]?.ex?.availableToLay[0]?.size,0,matchData?.runners[1]?.name,matchData?.runners[1]?.selectionId,matchData.market_id,i,matchData)">
                      {{matchData?.runners[1]?.ex?.availableToLay[0]?.price
                      ==
                      undefined ? 0 :
                      matchData?.runners[1]?.ex?.availableToLay[0]?.price}}
                    </a>
                  </dd>
                  <dd class="col-info">
                    <a class="add-pin"
                      [ngClass]="{'pin-on':(matchData?.my_favorites).length != 0,'pin-off':(matchData?.my_favorites).length == 0}"
                      (click)="favourite(matchData.match_id)">Pin</a>
                  </dd>
                </dl>

              </div>

<!--New desing start-->
<div id="overWrap" class="over-wrap">
  <div class="game-wrap col3" id="inPlayEventType_4">
    <h3>
      <a id="eventType" apptoggleclass="" class="to-expand">Cricket</a>
    </h3>
    <ul class="slip-head">
      <li class="col-game"></li>
      <li class="col-visit">1</li>
      <li class="col-draw">x</li>
      <li class="col-home">2</li>
      <li class="col-info"></li>
    </ul>
    <div id="inplayGameList" class="game-list">
      <dl class="game-list-col" style="display: block;" id="">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#/fullmarkets/28569726">ICC Cricket World Cup </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span>
          </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="multiMarketPin_28569726" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col" style="display: block;" id="">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#/fullmarkets/32762226">NSW Blues <span>v</span> Western Australia </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span>
          </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.01&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.03&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">26&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">110&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="multiMarketPin_32762226" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col" style="display: block;" id="">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#/fullmarkets/32763543">Bangladesh Women <span>v</span> Pakistan Women </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span>
          </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">27&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">32&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.03&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.04&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="multiMarketPin_32763543" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col" style="display: block;" id="">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#/fullmarkets/32719787">Melbourne Stars WBBL <span>v</span> Sydney Thunder WBBL </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span>
          </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1000&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.01&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="multiMarketPin_32719787" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col" style="display: block;" id="">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#/fullmarkets/32744845">New Zealand <span>v</span> Pakistan </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span>
          </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.46&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.47&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">3.1&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">3.2&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="multiMarketPin_32744845" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col" style="display: block;" id="">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#/fullmarkets/1710528940">Melbourne Stars SRL T20 <span>v</span> Brisbane Heat SRL T20 </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span>
          </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="sportsBookEIcon_4" class="game-E">
            <i></i>Cricket </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="multiMarketPin_1710528940" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col" style="display: block;" id="">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#/fullmarkets/32769074">Punjab <span>v</span> Delhi </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span>
          </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.75&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.79&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">2.26&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.34&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="multiMarketPin_32769074" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col" style="display: block;" id="">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#/fullmarkets/1111041127">Australia T10 <span>v</span> South Africa T10 </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span>
          </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="streamingIcon" class="game-live">Live</span>
          <span id="sportsBookEIcon_4" class="game-E">
            <i></i>Cricket </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="multiMarketPin_1111041127" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col" style="display: block;" id="">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#/fullmarkets/1111040639">Australia T10 <span>v</span> India T10 </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span>
          </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="streamingIcon" class="game-live">Live</span>
          <span id="sportsBookEIcon_4" class="game-E">
            <i></i>Cricket </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="multiMarketPin_1111040639" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col" style="display: block;" id="">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#/fullmarkets/32755452">England <span>v</span> Australia </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span>
          </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">2.46&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.48&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.68&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.69&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="multiMarketPin_32755452" class="add-pin">Pin</a>
        </dd>
      </dl>
    </div>
  </div>
</div>
<!--New desing End-->





              <ul class="highlight-list mobile-highlight-list d-lg-none">
                <li [ngClass]="{'inplay-on':(matchData?.inplay) == true,'inplay-off':(matchData?.inplay) != true}"
                  *ngFor="let matchData of homeMatch.value,let j = index;">
                  <a id="info">
                    <dl (click)="matchDetail(matchData)">
                      <dt>
                        <span class="game-live" *ngIf="matchData?.has_tv_url">game-live</span>
                        <span class="game-fancy" *ngIf="matchData?.enable_fancy == 1 ">Fancy</span>
                        <span class="game-bookmaker" *ngIf="matchData?.enable_fancy == 1 ">BookMaker</span>
                        <span class="time"
                          *ngIf="matchData?.inplay == true || matchData?.manualInplay == true"   [ngClass]="matchData.inplay ? 'green' : ''">In-Play</span>
                        <span class="time" *ngIf="matchData?.inplay != true ">
                          <span>{{matchData?.match_date | date :'yyyy-MM-dd HH:mm'}}</span>
                        </span>
                      </dt>
                      <dd id="eventName">{{matchData?.match_name}}</dd>
                    </dl>
                  </a>
                  <a [ngClass]="{'pin-on':(matchData?.my_favorites).length != 0,'pin-off':(matchData?.my_favorites).length == 0}"
                    (click)="favourite(matchData.match_id)"></a>
                </li>
              </ul>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="col-right accordion" id="betslipRisgtDiv">
      <div class="slip-wrap accordion-item">
        <h3 id="betSlipHeading" data-bs-toggle="collapse" data-bs-target="#betSlipCollapse" aria-expanded="true"
          aria-controls="betSlipCollapse">
          <a class="to-expand">Bet Slip</a>
        </h3>
        <ul id="loadingMsg" class="loading" style="display: none;">
          <li><img src="assets/sky_img/loading40.gif"></li>
          <li id="countDownTime">Place Bets
          </li>
        </ul>
        <div id="betSlipCollapse" class="accordion-collapse collapse show" data-bs-parent="#betslipRisgtDiv">
          <ul id="loadingMsg" class="loading" style="display: none">
            <li><img src="assets/sky_img/loading40.gif"></li>
            <li id="countDownTime">Place Bets</li>
          </ul>
          <p id="noBetSlipInfo" *ngIf="!betslipStatus">Click on the odds to add selections to the betslip.
          </p>
          <div id="betSlipBoard" class="bet_slip" *ngIf="betslipStatus">
            <ng-container *ngFor="let back of matchModel.tempArray;let index=index">
              <div id="betList" class="slip-list" style="max-height:calc(100% - 86px);">
                <dd id="stakePopupList" class="col-stake_list" stakepopuplisttype="template" style="display: none;">
                  <ul>
                    <li><a class="btn" id="selectStake_1" stake="4" style="cursor:pointer;">4</a></li>
                    <li><a class="btn" id="selectStake_2" stake="30" style="cursor:pointer;">30</a></li>
                    <li><a class="btn" id="selectStake_3" stake="50" style="cursor:pointer;">50</a></li>
                    <li><a class="btn" id="selectStake_4" stake="200" style="cursor:pointer;">200</a></li>
                    <li><a class="btn" id="selectStake_5" stake="500" style="cursor:pointer;">500</a></li>
                    <li><a class="btn" id="selectStake_6" stake="1000" style="cursor:pointer;">1000</a></li>
                  </ul>
                  <p id="totalStakeP" style="display: none;">Total Stake: <strong id="totalStake"></strong></p>
                </dd>
                <div id="oddsTipsPopup" class="tips-popup" style="display: none">min 1.01</div>
                <div id="stakeTipsPopup" class="tips-popup" style="display: none">Min 4.00</div>
                <div>
                  <ul id="backSlipHeader" class="slip-head" name="slipHeader" *ngIf="arrayObj?.is_back == 1">
                    <li class="col-bet">Back (Bet For)</li>
                    <li id="oddsHeader" class="col-odd">Odds</li>
                    <li id="runsHeader" class="col-odd" style="display: none">Unit:Runs</li>
                    <li class="col-stake">Stake</li>
                    <li class="col-profit">Profit</li>
                  </ul>
                  <ul id="laySlipHeader" class="slip-head" name="slipHeader" *ngIf="arrayObj?.is_back == 0">
                    <li class="col-bet">Lay (Bet Against)</li>
                    <li id="oddsHeader" class="col-odd">Odds</li>
                    <li id="runsHeader" class="col-odd" style="display: none">Unit:Runs</li>
                    <li class="col-stake">Stake</li>
                    <li class="col-profit">Liability</li>
                  </ul>
                  <div>
                    <h4><img [ngClass]="arrayObj?.inplay ? 'icon-in_play' : 'icon-no_play'"
                        src="/assets/sky_img/transparent.gif">{{matchData.match_name}}</h4>
                    <dl [ngClass]="arrayObj?.is_back == 1 ? 'slip-back ' : 'slip-lay'">
                      <dt>
                        <a id="delete" class="delete">delete</a>
                        <span class="short-amount">{{back?.selection_name}} </span>
                        <span id="marketName"> Match Odds</span>
                      </dt>
                      <dd class="col-odd">
                        <!-- <input id="odds" type="text" maxlength="6" autocomplete="off" tabindex="0" [(ngModel)]="selectedBetDetails.price"> -->
                        <input type="text" class="form-control" *ngIf="back?.isManual=='0'"
                          ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" min="0" step="0.01" [(ngModel)]="back.odds"
                          id="ShowBetPrice" formcontrolname="odds"
                          class="calProfitLoss odds-input form-control CommanBtn ng-untouched ng-pristine ng-valid"
                          [ngModelOptions]="{standalone: true}" name="quant[2]" (keyup)="choc(back?.odds)"
                          value="{{back?.odds}}" readonly>
                        <ul class="odd-add">
                          <li><a id="oddsUp" class="up">up</a></li>
                          <li><a id="oddsDown" class="down">down</a></li>
                        </ul>
                        <ul id="oddsTipsPoint"></ul>
                      </dd>
                      <dd class="col-stake">
                        <!-- <input id="inputStake" type="text" value="" maxlength="7" tabindex="0" [(ngModel)]="back.odds"> -->
                        <input type="number" class="form-control" min="0" id="stackVal" (focus)="setbtn(index)"
                          (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"
                          pattern="[0-9]*" step="1" id="stakeValue" formcontrolname="stake" placeholder="0"
                          class="calProfitLoss stake-input f0orm-control CommanBtn ng-untouched ng-pristine ng-valid"
                          [(ngModel)]="back.stake" [ngModelOptions]="{standalone: true}">
                        <ul id="listPoint"></ul>
                      </dd>
                      <dd id="profitLiability" class="col-profit" *ngIf="arrayObj?.is_back == 1">{{
                        back.is_back == 0 ?
                        back.stake
                        :((back.odds*back.stake)-back.stake)=='NaN'?0:
                        ((back.odds*back.stake)-back.stake)|
                        number :
                        '0.0-2'}}</dd>
                      <dd id="profitLiability" class="col-profit" *ngIf="arrayObj?.is_back == 0">{{back.is_back
                        == 0 ?
                        ((back.odds*back.stake)-back.stake)=='NaN'?0:
                        (((back.odds*back.stake)-back.stake)|
                        number : '0.0-2') :
                        back.stake}}</dd>
                      <dd id="stakePopupList" class="col-stake_list" style="display: block;">
                        <ul>
                          <li *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                            (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">
                            <a class="btns">{{stake.value}}</a></li>
                        </ul>
                        <p id="totalStakeP" style="display: none;">Total Stake: <strong id="totalStake"></strong></p>
                      </dd>
                      <dd id="asianHandicapForecast" class="bet-forecast" style="display: none;">
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_1"></span>
                          <span id="winLoss_1"></span>
                        </div>
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_2"></span>
                          <span id="winLoss_2"></span>
                        </div>
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_3"></span>
                          <span id="winLoss_3"></span>
                        </div>
                      </dd>

                      <!-- dynamic min bet for match odds -->
                      <!-- <dd class="keep-option">
                                          <p class="dynamic-min-bet">Min Bet: <strong id="dynamicMinBet">6</strong></p>
                                      </dd> -->
                      <dd id="inningsRuns" class="inn_runs" style="display: none;">
                        <dl>
                          <dt id="ifRuns"></dt>
                          <dd><span id="runsPL" class="green"></span></dd>
                          <dt id="ifLess"></dt>
                          <dd><span id="lessPL" class="green"></span></dd>
                        </dl>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div id="betSlipFullBtn" class="full_btn">
                <dl class="sum" *ngIf="arrayObj?.is_back == 1">
                  <dt>Liability
                  </dt>
                  <dd>
                    <span id="total"><a [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}">{{back.is_back
                        == 0 ?
                        ((back.odds*back.stake)-back.stake)=='NaN'?0:
                        (((back.odds*back.stake)-back.stake)|
                        number : '0.0-2') :
                        back.stake}}</a></span>
                  </dd>
                </dl>
                <dl class="sum" *ngIf="arrayObj?.is_back == 0">
                  <dt>Profit
                  </dt>
                  <dd>
                    <span id="total"><a [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}">{{
                        back.is_back == 0 ?
                        back.stake
                        :((back.odds*back.stake)-back.stake)=='NaN'?0:
                        ((back.odds*back.stake)-back.stake)|
                        number :
                        '0.0-2'}}</a></span>
                  </dd>
                </dl>

                <ul class="btn-wrap">
                  <li><a id="cancelAll" (click)="this.matchModel.tempArray=[];clearAll();betslipStatus = false;"
                      class="btns" style="cursor:pointer;" tabindex="0">Cancel All
                    </a></li>
                  <li *ngIf="arrayObj?.is_fancy == 0" (click)="saveBet(this.matchModel.tempArray,inplayStatus)"><a
                      id="placeBets" class="btn-send " [ngClass]="{'disable':back.stake <= 0}" style="cursor:pointer;"
                      tabindex="0">Place Bets
                    </a></li>
                  <li *ngIf="arrayObj?.is_fancy == 1" [ngClass]="{'disable':back.stake <= 0}"
                    (click)="openFancyBets(this.matchModel.tempArray);"><a id="placeBets" class="btn-send disable"
                      style="cursor:pointer;" tabindex="0">Place Bets
                    </a></li>
                </ul>

                <ul class="slip-option">
                  <li><input id="comfirmBets" type="checkbox" style="margin-right:5px;"><label for="comfirmBets">Please
                      confirm
                      your bets.
                    </label></li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <app-right-sidebar></app-right-sidebar>
    </div>
  </div>
  <app-footer-main></app-footer-main>
</div>