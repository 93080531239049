<div class="sub_path d-none d-lg-block">
  <div *ngIf="sportList == true">
    <div (click)="getAllSportsListData()" class="path">
      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
      <span class="float-right">Sports</span>
    </div>

    <ul class="menu-list" data-widget="tree">
      <li class="treeview" *ngFor="let sports of allSportsList;let i= index">
        <a (click)="sports.sport_id <= -100 ? openModalLoginPopup(loginPopup) : getSeriesList(sports.sport_id)">
          <span>{{sports.name}}</span>
          <i class="fa fa-angle-right"></i>
        </a>
      </li>
    </ul>
  </div>
  <div *ngIf="sportList == false">
    <div class="top-head-sport">
      <div class="row" (click)="getAllSportsListData()" class="path">
      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
      <span class="float-right">Sports</span>
      </div>
    </div>
    <ul class="menu-list">
      <li class="treeview" *ngFor="let sports of allSportsList;let i= index">
        <ul class="menu-last" data-widget="tree">
          <li class="treeview" *ngFor="let series of allSeriesList;let j= index" (click)="getMatchList(series, j)">
            <a>
              <span>{{series.series_name}}</span>
              <i class="fa fa-angle-right"></i>
            </a>
            <ul class="menu-list" [ngClass]="{'display-list': isShowMenu[j]}">
              <li *ngFor="let match of matchList[j];let k= index" (click)="matchDetail(match)">
                <a class="sub-menu-item"> {{match.match_name}} </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

<!-- Non Gambling Modal -->
<ng-template #loginCnfm>
  <div>
    <div class="modal-header border-bottom-0 pb-0">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="modalRef.hide()"></button>
    </div>
    <div class="modal-body text-center">
      <div class="confirm-text">
        <div class="text1">Non-Gambling Territories.</div>
        <hr class="confirm-line">
        <div class="text2">Connecting to our site from non gambling countries, it will be User's
          responsibility to ensure that their use of the service is lawful.</div>
      </div>
      <div class="confirm-text mb-3">
        <div class="text1">Underage gambling is prohibited.</div>
        <hr class="confirm-line">
        <div class="text2">Please confirm if you are 18 years old and above as of today.</div>
      </div>
      <div class="modal-footer border-top-0 justify-content-center">
        <button class="btn btn-light" data-bs-target="#loginModal" data-bs-toggle="modal"
          (click)="modalRef.hide();openModalLoginPopup(loginPopup)">Confirm</button>
        <button type="button" class="btn btn-outline-light" data-bs-dismiss="modal"
          (click)="modalRef.hide();">Exit</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- Login Modal -->

<ng-template #loginPopup>
  <div class="lgoin-main dialog-wrap-w login_to_go">
      <div class="kv">
          <img src="{{logo}}" alt="{{logo}}">
      </div>
      <form action="" [formGroup]='Form' novalidate="" class="login-panel">
          <dl class="">
              <dt>Please login to continue</dt>
              <dd>
                  <input formControlName="user_name" placeholder="UserName" required="" type="text">
              </dd>
              <dd>
                  <input formControlName="password" placeholder="Password" required="" type="password">
              </dd>
              <dd class="valid-code">
                  <input type="text" id="validCode" pattern="[0-9]*" placeholder="Validation Code" autocomplete="off"
                      [(ngModel)]="tPassword" [ngModelOptions]="{standalone: true}">
                  <span class="code">{{transactionPassword}}</span>
              </dd>
              <dd style="margin-bottom: 0;"><span
                      class="err-invalid-modal error-message">{{showResponseMessage}}</span></dd>
              <dd>
                  <a class="btn-send ui-link" (click)="onSubmitLogin()">Login</a>
              </dd>
          </dl>
      </form>
      <a id="close" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide();"></a>
  </div>
</ng-template>