<nav class="mobile-footer-menu d-block d-lg-none">
  <ul>
    <li>
      <a [routerLink]="['/dashboard']" class="ui-link" [ngClass]="{'active':data ==  'sportsMain' }"><img
          src="assets/sky_img/sport-menu.svg" alt=""> Sports</a>
    </li>
    <li>
      <a [routerLink]="['/inplay']" class="ui-link" [ngClass]="{'active':data ==  'inplay' }"><img
          src="assets/sky_img/inplay-menu.svg" alt=""> In-Play</a>
    </li>
    <li class="main-nav">
      <a [routerLink]="['/home/']" class="ui-link active" [ngClass]="{'active':data ==  'home' }"><img
          src="assets/sky_img/home-menu.svg" alt=""> Home</a>
    </li>
    <li>
      <a [routerLink]="['/multiMarket']" class="ui-link" [ngClass]="{'active':data ==  'multiMarket' }"><img
          src="assets/sky_img/multimarket-menu.svg" alt=""> Multi Markets</a>
    </li>
    <li>
      <a [routerLink]="['/myAccount']" class="ui-link" [ngClass]="{'active':data ==  'myAccount' }"><img
          src="assets/sky_img/acc-menu.svg" alt=""> Account</a>
    </li>
  </ul>
</nav>