import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DateTime } from '../../dateTime';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ActivatedRoute, Router } from '@angular/router';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { SocketServiceService } from '../services/socket-service.service';
import { ReportService } from '../services/report.service';
import { environment } from '../../environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-eventpl',
  templateUrl: './eventpl.component.html',
  styleUrls: ['./eventpl.component.scss']
})
export class EventplComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  pageSize: number = 50;
  statementsTotalItems: any;
  statementsPageIndex: number;
  timeStatement: Date;
  dateStatement: Date;
  endDateStatement: Date;
  endTimeStatement: Date;
  endTime: any;
  userDetails: any;
  fromDate: any;
  toDate: any;
  sportPlList: any;
  profitloss: any;
  totalPL: any;
  commission: any;
  public Base_Url = environment['adminServerUrl'];
  sportPlListLength: any;
  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private sport: SportService, private reportService: ReportService,
    private cookie: CookieService, private toastr: ToastrService, private socketService: SocketServiceService, private router: Router) { }
  @ViewChild('popoverRef') private _popoverRef: PopoverDirective;
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  time: Date;
  date: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  dateTime = new Date();
  statementStartDate = new Date()
  statementEndDate = new Date()
  public token = localStorage.getItem('userAccessToken');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.statementStartDate.setHours(0, 0, 0, 0);
    this.statementEndDate.setHours(23, 59, 59)
    const that = this;
    this.fromDate = this.statementStartDate.toISOString();
    this.toDate = this.statementEndDate.toISOString();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      searching: false,
      paging: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters1: any, callback) => {
        let dataTablesParameters = {
          "from_date": this.statementStartDate.toISOString(),
          "to_date": this.statementEndDate.toISOString()
        }
        dataTablesParameters1.search.value != "" ? dataTablesParameters["search"] = (dataTablesParameters1.search.value) : ""
        that.http.post<DataTablesResponse>(this.Base_Url + 'report/sportsP_L', dataTablesParameters, { headers: this.reqHeader })
          .subscribe(resp => {
            if (resp["status"]) {
              this.sportPlList = resp.data;
              this.sportPlListLength = this.sportPlList.length;
              for (let i = 0; i < this.sportPlList.length; i++) {
                this.sportPlList[i].totalPL = (this.sportPlList[i].total + this.sportPlList[i].commission)
              }
              this.profitloss = this.sportPlList.reduce(
                (a: number, b) => a + b.profitloss, 0);
              this.totalPL = this.sportPlList.reduce(
                (a: number, b) => a + b.totalPL, 0);
              this.commission = this.sportPlList.reduce(
                (a: number, b) => a + b.commission, 0);
              callback({
                recordsTotal: this.sportPlListLength,
                recordsFiltered: this.sportPlListLength,
                data: this.sportPlList
              });

            } else {
              this.toastr.error(resp["msg"]);
              this.sportPlList = []
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: this.sportPlList
              });
            }


          });
      },
      columns: [
        {
          title: 'Sport Name',
          data: 'sport_name',
          "render": function (data, type, row) {
            var content = '';
            content = content + '<span style="color: #14805e !important;cursor: pointer;">' + row.sport_name + '</span>';
            return content;
          }
        },
        {
          title: 'Profit/Loss',
          data: 'total',
          "render": function (data, type, row) {
            var content = ''
            if (row.total != null && row.total < 0) {
              content = content + '<span class="text-center text-danger">' + (row.total).toFixed(2) + '</span>';
            } else if (row.total != null && row.total >= 0) {
              content = content + '<span class="text-center text-success">' + (row.total).toFixed(2) + '</span>';
            } else {
              content = '';
            }
            return content;
          }

        },
        {
          title: 'Commission',
          data: 'commission',
          "render": function (data, type, row) {
            var content = ''
            if (row.commission != null && row.commission < 0) {
              content = content + '<span class="text-center text-danger">' + (row.commission).toFixed(2) + '</span>';
            } else if (row.commission != null && row.commission >= 0) {
              content = content + '<span class="text-center text-success">' + (row.commission).toFixed(2) + '</span>';
            } else {
              content = '';
            }
            return content;
          }
        },
        {
          title: 'Total P&L',
          data: 'totalPL',
          "render": function (data, type, row) {
            var content = ''
            if (row.totalPL != null && row.totalPL < 0) {
              content = content + '<span class="text-center text-danger">' + (row.totalPL).toFixed(2) + '</span>';
            } else if (row.totalPL != null && row.totalPL >= 0) {
              content = content + '<span class="text-center text-success">' + (row.totalPL).toFixed(2) + '</span>';
            } else {
              content = '';
            }
            return content;
          }
        }],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $('td', row).off('click');
        $('td', row).on('click', () => {
          this.eventWisePl(data);
        });
        $(row).addClass('text-center');
        return row;
      }
    };
  }
  dateSelectionDone() {
    this.isDateVisible = false;
  }
  updateDateStatement() {
    if (this.dateStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
    if (!this.timeStatement) {
      this.timeStatement = this.dateStatement;
    }
  }
  updateEndDateStatement() {
    if (this.endDateStatement) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
    if (!this.endTimeStatement) {
      this.endTimeStatement = this.endDateStatement;
    }
  }
  updateTimeStatement() {
    if (this.timeStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
  }
  updateEndTimeStatement() {
    if (this.endTime) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
  }
  updateDate() {

    if (this.date) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
    if (!this.time) {
      this.time = this.date;
    }
  }

  updateTime() {
    if (this.time) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
  }

  showDate() {
    this.isDateVisible = true;
  }

  showTime() {
    this.isDateVisible = false;
  }

  close() {
    this._popoverRef.hide();
  }

  now() {
    this.dateTime = DateTime.now(this.date);
    this.time = this.dateTime;
  }

  today() {
    this.statementStartDate = DateTime.now(this.date);
    this.statementEndDate = DateTime.now(this.date);
  }
  clear() {
    this.time = void 0;
    this.date = void 0;
    this.dateTime = void 0;
  }
  pageChange(event?: any) {
    if (event) {
      this.statementsPageIndex = event
    } else {
      this.statementsPageIndex = 1
    }
  }

  eventpl(from?: string) {
    let data = {}
    if (from == 'filterBtnClick') {
      if (this.statementStartDate) {
        data["from_date"] = this.statementStartDate.toISOString()
        this.fromDate = this.statementStartDate.toISOString()
      }
      if (this.statementEndDate) {
        data["to_date"] = this.statementEndDate.toISOString()
        this.toDate = this.statementEndDate.toISOString()
      }
    }
    this.rerender();
  }
  eventWisePl(data) {
    let a = this.Base_Url.split('api/v1/');
    // let b = 'http://192.168.0.9:4200/' + 'plMatchwise/' + data.sport_id + '/'+ data.sport_name + '/'+ this.fromDate + '/'+ this.toDate + '/' + data.type;
    this.router.navigate(['plMatchwise/' + data.sport_id + '/' + data.sport_name + '/' + this.fromDate + '/' + this.toDate + '/' + data.type])
    // window.open(b, '_blank', 'location=yes,height=800,width=1200,scrollbars=yes,status=yes');
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  statement() {
    this.router.navigate(['statement'])
  }
  profitLoss() {
    this.router.navigate(['profit-loss'])
  }
  info() {
    this.router.navigate(['info'])
  }
  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  rollComission() {
    this.router.navigate(['rollComission'])
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
