import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { OpenApiService } from '../services/open-api.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerData: any;
  modalRef: BsModalRef;
  contentReceived: any;
  domainName: string;
  public Base_Url = environment['adminServerUrl'];
  constructor(private modalService: BsModalService, private open: OpenApiService) { }

  ngOnInit(): void {
    this.domainName =window.location.origin;
    this.footerContent();
  }
  footerContent() {
    this.open.footerContent().subscribe((res) => {
      if (res.status) {
        this.footerData = res.data;
      }
    })
  }
  openModalLoginCnfm(param, loginCnfm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginCnfm,
      Object.assign({}, { class: 'loginCnfm-modal', ignoreBackdropClick: true })
    );
    let data = {
      slug: param
    }
    this.open.popUpContent(data).subscribe((res) => {
      if (res.status) {
        this.contentReceived = res.data.description;
      }
    })
  }
  DownloadApk(){
    let c = (this.domainName).split('//')
    let a = btoa(c[1]);
    let b = this.Base_Url + 'content/download-mobile-app?key=' + a;
    window.open(b,'_blank')
  }
}
