import { Component, OnInit } from '@angular/core';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {
  public Base_Url = environment['adminServerUrl'];
  payDetails: any;
  userDetails: any;
  selectData: any;
  payLength: any;
  paymentMethodId: any;
  payMethodList: any;
  img_size:any;
  amount: any;

  constructor(private sportService: SportService,private toastr: ToastrService,private router: Router) {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
   }

  ngOnInit(): void {
    this.depositMethod();
  }

  info() {
    this.router.navigate(['info'])
  }

  rollComission() {
    this.router.navigate(['rollComission'])
  }

  statement() {
    this.router.navigate(['statement'])
  }

  profitLoss() {
    this.router.navigate(['profit-loss'])
  }

  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }

  depositMethod() {
    let data = {
      user_id: this.userDetails._id,
      domain_id: this.userDetails.domain_id,
      type: 'DEPOSIT'
    };
    this.sportService.paymentMethodList(data).subscribe((res) => {
      if (res.status == true) {
        this.payMethodList = res.data;
        if(this.payMethodList != undefined){
          this.detail(this.payMethodList[0])
        }
      } else {
        this.toastr.error(res.msg)
      }
    })
  }

  detail(list) {
    this.selectData = list.category;
    this.paymentMethodId = list._id
    let data = {
      user_id: this.userDetails._id,
      domain_id: this.userDetails.domain_id,
      method_id: list._id
    };
    this.sportService.paymentDetail(data).subscribe((res) => {
      if(res.status){
        this.payDetails = res.data;
        this.payLength = (this.payDetails)?.length;
        for(let i = 0; i < this.payDetails?.length ; i++){
          if((this.payDetails[i]).hasOwnProperty('self_host')){
           this.payDetails[i].payment = true;
         if((this.payDetails[i]).self_host == true){
           this.payDetails[i].payment_qr = this.Base_Url + (this.payDetails[i]).payment_qr;
         } else {
           this.payDetails[i].payment_qr = (this.payDetails[i]).payment_qr;
         } 
       } else {
         this.payDetails[i].payment = false;
       }
        }
      } else {
        this.toastr.error(res.error)
      }
    })
  
  }
  file: File = null;
  imageSrc: any;

  processFile(imageInput: any) { 
    this.file = imageInput.files[0];
    let a = imageInput.files[0];
    let size:any=(a.size/1000000);
    this.img_size=parseFloat(size).toFixed(2);
    console.warn(this.img_size,'MB');
    const reader = new FileReader();
    reader.onload = (e: any) => this.imageSrc = reader.result;
    reader.readAsDataURL(a);
  }

  paymentDeposit() {
    if ((this.payDetails)?.length != 0 || this.payDetails != undefined) {
      if (this.paymentMethodId != undefined) {
        if (this.amount >= 100) {
          if(this.img_size>5.00)
          {
            this.toastr.error('Oops! The size limit for images is below 5.0 MB. Plz select img below 5MB and try again.');
            return;
          }
          const formData: FormData = new FormData();
          formData.append('image', this.file);
          formData.append('user_id', this.userDetails._id);
          formData.append('parent_id', this.userDetails.parent_id);
          formData.append('payment_method_id', this.paymentMethodId);
          formData.append('amount', this.amount);
          formData.append('crdr', '1');
          formData.append('remark', 'wallet');
          this.sportService.paymentDeposit(formData).subscribe((res) => {
            if (res.status) {
              this.toastr.success(res.msg);
              this.file = undefined;
            } else {
              this.toastr.error(res.msg)
            }
          })
        } else {
          this.toastr.error('Minimum deposit amount is 100 .')
        }
      } else {
        this.toastr.error('Plz select any payment mode...')
      }
    } else {
      this.toastr.error('Plz select any Payment method')
    }
  }

  close() {
    this.file = undefined;
    this.imageSrc = '';
  }  

}
