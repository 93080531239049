<div class="app-body">

  <!-- Loading Wrap -->
  <div id="loading" class="loading-wrap d-none-mobile" *ngIf="loading == true">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>
  
  <div class="loading-overlay d-none-desktop" id="loading" *ngIf="loading == true">
    <div class="loading-wrap">
      <div class="loading">
        <div></div>
        <div></div>
      </div>
      <p>Loading...
      </p>
    </div>
  </div>

  <app-header></app-header>
  <div class="full-wrap d-none-mobile">
    <div class="col-left">
      <app-sidebar></app-sidebar>
    </div>

    <div id="centerColumn" class="col-center">
      <!-- Message -->
      <div id="message" class="message-wrap success">
        <a class="btn-close">Close</a>
        <p></p>
      </div>


      <div class="over-wrap">
        <div class="game-highlight-wrap col3" *ngIf="dataLength != 0">
          <h3>Sports Highlights
            <div class="highlight-sorting">
              <label>View by</label>
              <div class="select">
                <select id="viewType" name="View">
                  <option value="competitionName" orderby="asc" priority="asc">Competition</option>
                  <option value="openDateTime" orderby="asc" inplay="desc" selected="">Time</option>
                  <option value="totalMatched" orderby="desc">Matched</option>
                </select>
              </div>
            </div>
          </h3>
          <ul class="slip-head">
            <li class="col-game"></li>
            <li class="col-matched"></li>
            <li class="col-visit">1</li>
            <li class="col-draw">x</li>
            <li class="col-home">2</li>
            <li class="col-info"></li>
          </ul>

          <div class="game-list">
            <div id="eventBoard">
              <dl class="game-list-col" *ngFor="let matchData of homeData;let i = index;">
                <dt (click)="matchDetail(matchData)">
                  <span class="img-span" [ngClass]="matchData.inplay ? 'icon-in_play' : 'icon-no_play'"></span>
                  <a >{{matchData?.match_name}}</a>
                  <span class="game-list-time" *ngIf="matchData?.inplay == true || matchData?.manualInplay == true">
                    <span class="in_play">In-Play</span>
                  </span>
                  <span class="game-list-time"
                    *ngIf="matchData?.inplay != true || matchData?.manualInplay != true">{{(matchData?.match_date | date
                    :'dd-MM-yyyy HH:mm')}}</span>
                  <span class="game-live" id="streamingIcon" style="display: inline-flex;"
                    *ngIf="matchData.has_tv_url">Live</span>
                  <span class="game-fancy in-play" *ngIf="matchData.enable_fancy === 1">Fancy</span>
                  <span class="game-bookmaker" *ngIf="matchData.enable_fancy === 1">BookMaker</span>
                </dt>
                <dd class="col-matched">PTE0</dd>
                <dd class="col-visit">
                  <div class="suspend"
                    *ngIf=" matchData.status == 'SUSPENDED' || matchData.status == 'CLOSED' || matchData.status == 'closed' || matchData.status == 'Closed'">
                    <span class="stats-text">Suspend</span></div>
                  <div class="suspend" *ngIf=" matchData.status != 'SUSPENDED' && matchData.is_lock == true"><span
                      class="stats-text">Lock</span></div>
                  <a class="btn-back" side="Back"
                    (click)="getOddsValue((matchData?.runners[0]?.ex?.availableToBack[0]?.price),matchData?.runners[0]?.ex?.availableToBack[0]?.size,1,matchData?.runners[0]?.name,matchData?.runners[0]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[0]?.ex?.availableToBack[0] == undefined ? 0 :
                    matchData?.runners[0]?.ex?.availableToBack[0]?.price}}</a>
                  <a class="btn-lay" side="Lay"
                    (click)="getOddsValue((matchData?.runners[0]?.ex?.availableToLay[0]?.price),matchData?.runners[0]?.ex?.availableToLay[0]?.size,0,matchData?.runners[0]?.name,matchData?.runners[0]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[0]?.ex?.availableToBack[0]
                    == undefined ? 0 :
                    matchData?.runners[0]?.ex?.availableToLay[0]?.price}}
                  </a>
                </dd>
                <dd class="col-draw">
                  <div class="suspend"
                    *ngIf=" matchData.status == 'SUSPENDED' || matchData.status == 'CLOSED' || matchData.status == 'closed' || matchData.status == 'Closed'">
                    <span class="stats-text">Suspend</span></div>
                  <div class="suspend" *ngIf=" matchData.status != 'SUSPENDED' && matchData.is_lock == true"><span
                      class="stats-text">Lock</span></div>
                  <a class="btn-back" side="Back"
                    (click)="getOddsValue((matchData?.runners[2]?.ex?.availableToBack[0]?.price),matchData?.runners[2]?.ex?.availableToBack[0]?.size,1,matchData?.runners[2]?.name,matchData?.runners[2]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[2]?.ex?.availableToBack[0] == undefined ? '--' :
                    matchData?.runners[2]?.ex?.availableToBack[0]?.price}}
                  </a>
                  <a class="btn-lay" side="Lay"
                    (click)="getOddsValue((matchData?.runners[2]?.ex?.availableToLay[0]?.price),matchData?.runners[2]?.ex?.availableToLay[0]?.size,0,matchData?.runners[2]?.name,matchData?.runners[2]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[2]?.ex?.availableToLay[0]
                    == undefined ? '--' :
                    matchData?.runners[2]?.ex?.availableToLay[0]?.price}}
                  </a>
                </dd>
                <dd class="col-home">
                  <div class="suspend"
                    *ngIf=" matchData.status == 'SUSPENDED' || matchData.status == 'CLOSED' || matchData.status == 'closed' || matchData.status == 'Closed'">
                    <span class="stats-text">Suspend</span></div>
                  <div class="suspend" *ngIf=" matchData.status != 'SUSPENDED' && matchData.is_lock == true"><span
                      class="stats-text">Lock</span></div>
                  <a class="btn-back" side="Back"
                    (click)="getOddsValue((matchData?.runners[1]?.ex?.availableToBack[0]?.price),matchData?.runners[1]?.ex?.availableToBack[0]?.size,1,matchData?.runners[1]?.name,matchData?.runners[1]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[1]?.ex?.availableToBack[0]?.price
                    == undefined ? 0 :
                    matchData?.runners[1]?.ex?.availableToBack[0]?.price}}
                  </a>
                  <a class="btn-lay" side="Lay"
                    (click)="getOddsValue((matchData?.runners[1]?.ex?.availableToLay[0]?.price),matchData?.runners[1]?.ex?.availableToLay[0]?.size,0,matchData?.runners[1]?.name,matchData?.runners[1]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[1]?.ex?.availableToLay[0]?.price
                    ==
                    undefined ? 0 :
                    matchData?.runners[1]?.ex?.availableToLay[0]?.price}}
                  </a>
                </dd>
                <dd class="col-info">
                  <a class="add-pin" title="Add to Multi Markets"
                    [ngClass]="{'pin-on':(matchData?.my_favorites).length != 0,'pin-off':(matchData?.my_favorites).length == 0}"
                    (click)="favourite(matchData.match_id)">Pin</a>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div id="multiMarketsWrap" *ngIf="dataLength == 0">
          <div class="no-data">
            <h3>Multi Markets</h3>
            <p>There are currently no followed multi markets.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-right accordion" id="betslipRisgtDiv">
      <div class="slip-wrap accordion-item">
        <h3 id="betSlipHeading" data-bs-toggle="collapse" data-bs-target="#betSlipCollapse" aria-expanded="true"
          aria-controls="betSlipCollapse">
          <a class="to-expand">Bet Slip</a>
        </h3>
        <ul id="loadingMsg" class="loading" style="display: none;">
          <li><img src="assets/sky_img/loading40.gif"></li>
          <li id="countDownTime">Place Bets
          </li>
        </ul>
        <div id="betSlipCollapse" class="accordion-collapse collapse show" data-bs-parent="#betslipRisgtDiv">
          <ul id="loadingMsg" class="loading" style="display: none">
            <li><img src="assets/sky_img/loading40.gif"></li>
            <li id="countDownTime">Place Bets</li>
          </ul>
          <div id="betSlipBoard" class="bet_slip" *ngIf="betslipStatus">
            <ng-container *ngFor="let back of matchModel.tempArray;let index=index">
              <div id="betList" class="slip-list" style="max-height:calc(100% - 86px);">
                <dd id="stakePopupList" class="col-stake_list" stakepopuplisttype="template" style="display: none;">
                  <ul>
                    <li><a class="btn" id="selectStake_1" stake="4" style="cursor:pointer;">4</a></li>
                    <li><a class="btn" id="selectStake_2" stake="30" style="cursor:pointer;">30</a></li>
                    <li><a class="btn" id="selectStake_3" stake="50" style="cursor:pointer;">50</a></li>
                    <li><a class="btn" id="selectStake_4" stake="200" style="cursor:pointer;">200</a></li>
                    <li><a class="btn" id="selectStake_5" stake="500" style="cursor:pointer;">500</a></li>
                    <li><a class="btn" id="selectStake_6" stake="1000" style="cursor:pointer;">1000</a></li>
                  </ul>
                  <p id="totalStakeP" style="display: none;">Total Stake: <strong id="totalStake"></strong></p>
                </dd>
                <div id="oddsTipsPopup" class="tips-popup" style="display: none">min 1.01</div>
                <div id="stakeTipsPopup" class="tips-popup" style="display: none">Min 4.00</div>
                <div>
                  <ul id="backSlipHeader" class="slip-head" name="slipHeader" *ngIf="arrayObj?.is_back == 1">
                    <li class="col-bet">Back (Bet For)</li>
                    <li id="oddsHeader" class="col-odd">Odds</li>
                    <li id="runsHeader" class="col-odd" style="display: none">Unit:Runs</li>
                    <li class="col-stake">Stake</li>
                    <li class="col-profit">Profit</li>
                  </ul>
                  <ul id="laySlipHeader" class="slip-head" name="slipHeader" *ngIf="arrayObj?.is_back == 0">
                    <li class="col-bet">Lay (Bet Against)</li>
                    <li id="oddsHeader" class="col-odd">Odds</li>
                    <li id="runsHeader" class="col-odd" style="display: none">Unit:Runs</li>
                    <li class="col-stake">Stake</li>
                    <li class="col-profit">Liability</li>
                  </ul>
                  <div>
                    <h4><img [ngClass]="arrayObj?.inplay ? 'icon-in_play' : 'icon-no_play'"
                        src="/assets/sky_img/transparent.gif">{{matchData.match_name}}</h4>
                    <dl [ngClass]="arrayObj?.is_back == 1 ? 'slip-back ' : 'slip-lay'">
                      <dt>
                        <a id="delete" class="delete">delete</a>
                        <span class="short-amount">{{back?.selection_name}} </span>
                        <span id="marketName"> Match Odds</span>
                      </dt>
                      <dd class="col-odd">
                        <input type="text" class="form-control" *ngIf="back?.isManual=='0'"
                          ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" min="0" step="0.01" [(ngModel)]="back.odds"
                          id="ShowBetPrice" formcontrolname="odds"
                          class="calProfitLoss odds-input form-control CommanBtn ng-untouched ng-pristine ng-valid"
                          [ngModelOptions]="{standalone: true}" name="quant[2]" (keyup)="choc(back?.odds)"
                          value="{{back?.odds}}" readonly>
                        <ul class="odd-add">
                          <li><a id="oddsUp" class="up">up</a></li>
                          <li><a id="oddsDown" class="down">down</a></li>
                        </ul>
                        <ul id="oddsTipsPoint"></ul>
                      </dd>
                      <dd class="col-stake">
                        <input type="number" class="form-control" min="0" id="stackVal" (focus)="setbtn(index)"
                          (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"
                          pattern="[0-9]*" step="1" id="stakeValue" formcontrolname="stake" placeholder="0"
                          class="calProfitLoss stake-input f0orm-control CommanBtn ng-untouched ng-pristine ng-valid"
                          [(ngModel)]="back.stake" [ngModelOptions]="{standalone: true}">
                        <ul id="listPoint"></ul>
                      </dd>
                      <dd id="profitLiability" class="col-profit" *ngIf="arrayObj?.is_back == 1">{{
                        back.is_back == 0 ?
                        back.stake
                        :((back.odds*back.stake)-back.stake)=='NaN'?0:
                        ((back.odds*back.stake)-back.stake)|
                        number :
                        '0.0-2'}}</dd>
                      <dd id="profitLiability" class="col-profit" *ngIf="arrayObj?.is_back == 0">{{back.is_back
                        == 0 ?
                        ((back.odds*back.stake)-back.stake)=='NaN'?0:
                        (((back.odds*back.stake)-back.stake)|
                        number : '0.0-2') :
                        back.stake}}</dd>
                      <dd id="stakePopupList" class="col-stake_list" style="display: block;">
                        <ul>
                          <li *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                            (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">
                            <a class="btns">{{stake.value}}</a></li>
                        </ul>
                        <p id="totalStakeP" style="display: none;">Total Stake: <strong id="totalStake"></strong></p>
                      </dd>
                      <dd id="asianHandicapForecast" class="bet-forecast" style="display: none;">
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_1"></span>
                          <span id="winLoss_1"></span>
                        </div>
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_2"></span>
                          <span id="winLoss_2"></span>
                        </div>
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_3"></span>
                          <span id="winLoss_3"></span>
                        </div>
                      </dd>

                      <dd id="inningsRuns" class="inn_runs" style="display: none;">
                        <dl>
                          <dt id="ifRuns"></dt>
                          <dd><span id="runsPL" class="green"></span></dd>
                          <dt id="ifLess"></dt>
                          <dd><span id="lessPL" class="green"></span></dd>
                        </dl>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div id="betSlipFullBtn" class="full_btn">
                <dl class="sum" *ngIf="arrayObj?.is_back == 1">
                  <dt>Liability
                  </dt>
                  <dd>
                    <span id="total"><a [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}">{{back.is_back
                        == 0 ?
                        ((back.odds*back.stake)-back.stake)=='NaN'?0:
                        (((back.odds*back.stake)-back.stake)|
                        number : '0.0-2') :
                        back.stake}}</a></span>
                  </dd>
                </dl>
                <dl class="sum" *ngIf="arrayObj?.is_back == 0">
                  <dt>Profit
                  </dt>
                  <dd>
                    <span id="total"><a [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}">{{
                        back.is_back == 0 ?
                        back.stake
                        :((back.odds*back.stake)-back.stake)=='NaN'?0:
                        ((back.odds*back.stake)-back.stake)|
                        number :
                        '0.0-2'}}</a></span>
                  </dd>
                </dl>

                <ul class="btn-wrap">
                  <li><a id="cancelAll" (click)="this.matchModel.tempArray=[];clearAll();betslipStatus = false;"
                      class="btns" style="cursor:pointer;" tabindex="0">Cancel All
                    </a></li>
                  <li *ngIf="arrayObj?.is_fancy == 0" (click)="saveBet(this.matchModel.tempArray,inplayStatus)"><a
                      id="placeBets" class="btn-send " [ngClass]="{'disable':back.stake <= 0}" style="cursor:pointer;"
                      tabindex="0">Place Bets
                    </a></li>
                  <li *ngIf="arrayObj?.is_fancy == 1" [ngClass]="{'disable':back.stake <= 0}"
                    (click)="openFancyBets(this.matchModel.tempArray);"><a id="placeBets" class="btn-send disable"
                      style="cursor:pointer;" tabindex="0">Place Bets
                    </a></li>
                </ul>

                <ul class="slip-option">
                  <li><input id="comfirmBets" type="checkbox" style="margin-right:5px;"><label for="comfirmBets">Please
                      confirm
                      your bets.
                    </label></li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <app-right-sidebar></app-right-sidebar>
    </div>
  </div>

  <!-- mobile design -->
  <div class="mian-wrap d-none-desktop footer-condition">
    <div class="wrap-highlight_list" *ngIf="firstDataLength != 0">
      <h3>Highlights</h3>
      <div class="tab-content">
        <ul class="highlight-list">
          <li [ngClass]="matchData.inplay ? 'inplay-on' : 'inplay-off'"
            *ngFor="let matchData of homeData;let i = index;">
            <a>
              <dl>
                <dt>
                  <span id="streamingIcon" class="game-live" style="display: flex;">game-live</span>
                  <span id="fancyBetIcon" class="game-fancy in-play" style="display: flex;">
                    <pre>in-play</pre>Fancy
                  </span>
                  <span id="bookMakerIcon" class="game-bookmaker in-play" style="display: flex;">
                    <pre>in-play</pre>BookMaker
                  </span>
                  <span id="dateTimeInfo" class="time" *ngIf="matchData.inplay"    [ngClass]="matchData.inplay ? 'green' : ''">In-Play</span>
                  <span class="time"
                    *ngIf="matchData?.inplay != true || matchData?.manualInplay != true">{{(matchData?.match_date | date
                    :'dd-MM-yyyy HH:mm')}}</span>
                </dt>
                <dd id="eventName">{{matchData.match_name}}</dd>
              </dl>
            </a>
            <a id="multiMarketPin" title="Add to Multi Markets"
              [ngClass]="{'pin-on':(matchData?.my_favorites).length != 0,'pin-off':(matchData?.my_favorites).length == 0}"
              (click)="favourite(matchData.match_id)"></a>
          </li>
        </ul>
      </div>
    </div>
    <div id="multiMarketsWrap" *ngIf="firstDataLength == 0">
      <div class="no-data">
        <h3>Multi Markets</h3>
        <p>There are currently no followed multi markets.</p>
      </div>
    </div>
    <app-open-footer></app-open-footer>
  </div>
  <app-footer-main></app-footer-main>
</div>