<div class="matched-wrap accordion-item">
  <h3 id="matchedBetHeading" data-bs-toggle="collapse" data-bs-target="#matchedBetCollapse" aria-expanded="true"
    aria-controls="matchedBetCollapse">
    <a id="openBetsRefresh" class="slip_refresh" style="cursor:pointer;">Refresh</a>
    <a class="to-expand" style="cursor:pointer;">Open Bets
    </a>
  </h3>
  <div id="matchedBetCollapse" class="accordion-collapse collapse show" data-bs-parent="#betslipRisgtDiv">
    <div class="message-bet">
      <h4 id="oneClickMsg" class="" style="display: none"></h4>
    </div>
    <ul id="processingImg" class="loading" style="display: none;">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Processing...
      </li>
    </ul>
    <div id="matchSection" style="height: calc(100% - 25px)">
      <div id="openBetSelectionBox" class="slip-sort">
        <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchMatch" (change)="openBets(searchMatch)">
          <ng-option *ngFor="let match of MatchList" [value]="match">{{match.match_name}}</ng-option>
        </ng-select>
      </div>
      <div id="openBetSlip" class="bet_slip over-wrap" style="height: calc(100% - 35px);">

        <ul id="txnHead" class="slip-head" *ngIf="openBetsLength != 0">
          <li class="col-bet"><strong>Matched</strong></li>
        </ul>
        <div class="slip-list" *ngIf="openBetsLength != 0">
          <ul class="matched-head">
            <li class="col-bet">Back (Bet For)</li>
            <li class="col-odd">Odds</li>
            <li class="col-stake">Stake</li>
            <li class="col-profit">Profit</li>
          </ul>
          <dl *ngFor="let bet of backData;let i = index;"
            [ngClass]="{'slip-lay':bet.is_back == 0,'slip-back':bet.is_back == 1,'showInfo':showBet == 1}">
            <dd id="betInfo" class="refer" *ngIf="showBet == 1">
              <span id="betId">Ref: {{bet?.bet_id}}</span>
              <span id="placeDate">{{bet?.createdAt | date :'dd-MM-yyyy HH:mm:ss'}}</span>
            </dd>
            <dt>
              <span class="slip-type">{{bet.is_back == 0 ? 'Lay' : "Back"}}</span>
              <p class="slip-predict"><span class="short-amount">{{bet?.selection_name}}</span>
                <span>{{bet?.market_name}}</span>
              </p>
            </dt>
            <dd class="col-odd">{{bet?.odds}}</dd>
            <dd class="col-stake">{{bet?.stack}}</dd>
            <dd class="col-profit"> {{bet?.p_l | number : '0.0-2'}}</dd>
          </dl>

          <ul class="matched-head">
            <li class="col-bet">Lay (Bet Against)</li>
            <li class="col-odd">Odds</li>
            <li class="col-stake">Stake</li>
            <li class="col-profit">Liability</li>
          </ul>
          <dl *ngFor="let bet of layData;let i = index;"
            [ngClass]="{'slip-lay':bet.is_back == 0,'slip-back':bet.is_back == 1,'showInfo':showBet == 1}">
            <dd class="refer" *ngIf="showBet == 1">
              <span id="betId">Ref: {{bet?.bet_id}}</span>
              <span id="placeDate">{{bet?.createdAt | date :'dd-MM-yyyy HH:mm:ss'}}</span>
            </dd>
            <dt>
              <span class="slip-type">{{bet.is_back == 0 ? 'Lay' : "Back"}}</span>
              <p class="slip-predict"><span class="short-amount">{{bet.is_fancy == 1 ? (bet?.selection_name) :
                  (bet?.selection_name) }}</span>
                <span>{{bet?.market_name}}</span>
              </p>
            </dt>
            <dd class="col-odd">{{bet?.odds}}</dd>
            <dd class="col-stake">{{bet?.stack}}</dd>
            <dd class="col-profit red">{{bet?.liability | number : '0.0-2'}}</dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</div>