import { Component, OnInit, TemplateRef ,ViewChild} from '@angular/core';
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { LoginService } from '../services/login.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../services/users.service';
import { HeaderComponent } from '../header/header.component';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  providers:[HeaderComponent]
})
export class MyAccountComponent implements OnInit {
  userDetails: any;
  @ViewChild('changePwd', {static: false}) public privacyPopup: TemplateRef<any>;
  submitted: boolean;
  changePasswordForm: FormGroup;
  modalRef: BsModalRef;
  constructor(private router:Router,private loginService:LoginService, private toastr : ToastrService,
    private modalService: BsModalService,private usersService: UsersService,private fb: FormBuilder,
    private head: HeaderComponent) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.createChangePasswordFrom();
    this.profile();
  }
  profile(){
    this.usersService.profile().subscribe((res) =>{
      if(res.status){
        this.userDetails = res.data
      }
    })
  }
  statement() {
    this.router.navigate(['statement'])
  }
  profitLoss() {
   this.router.navigate(['profit-loss'])
  }
  info() {
    this.router.navigate(['info'])
  }
  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  rollComission() {
    this.router.navigate(['rollComission'])
  }

  logoutUser() {
    let data = {}
    this.loginService.logoutUser(data).subscribe(result => {
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        })
        this.loginService.clearLocalStorage()
        this.router.navigate(['openHome']);
        window.location.reload();
        window.location.replace('openHome');
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
        this.loginService.clearLocalStorage()
        this.router.navigate(['openHome']);
        window.location.reload();
        window.location.replace('openHome');
      }
    })
  }
  openModalChangePwd(changePwd: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      changePwd,
      Object.assign({}, { class: 'pop_box passwordModal',ignoreBackdropClick: true}),
    );
  }

  onSubmitChangePassword() {
    this.submitted = true;

    if (this.changePasswordForm.invalid) {
      return;
    }
    let data = {
      "newPassword": this.changePasswordForm.value.new_password
    }
    
    
    if (this.changePasswordForm.controls['new_password'].value == this.changePasswordForm.controls['confirm_password'].value) {
      this.usersService.changePassword(data).subscribe((result) => {
        if (result.status == true) {
          this.userDetails.is_change_password='1';
          localStorage.setItem('userDetails',JSON.stringify(this.userDetails));
          this.submitted = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          this.modalRef.hide();
          this.head.logoutUser();
        } else {
          this.toastr.error(result.msg, '', {
            timeOut: 10000,
          });
        }
      }, (err) => {
        console.log(err);
      });
    }

    else {
      this.toastr.error("Password and confirm password did not match", '', {
        timeOut: 10000,
      });
    }
  }


  createChangePasswordFrom() {
    this.changePasswordForm = this.fb.group({

      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]

    })
  }


}
