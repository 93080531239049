<!-- skyHeader -->

<div class="top">
  <div class="header full-wrap d-none d-lg-block">
    <h1 class="top-logo" (click)="dashboard()">
      <img src="assets/sky_img/logo-skyexch.webp" alt="">
    </h1>
    <div ngbDropdown id="searchWrap" class="search-wrap dropdown d-none d-lg-block">
      <div class="input-group">
        <form class="d-flex position-relative" (click)="homematches()">
          <input ngbDropdownToggle class="search-input dropdown-toggle" id="dropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false" type="text" autocomplete="off" [(ngModel)]="searchSport"
            [ngModelOptions]="{standalone: true}" placeholder="Search Events">
          <span class="float-icon clearall-icon" (click)="searchSport = ''"><i class="bi bi-x-lg"></i></span>
          <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a ngbDropdownItem class="dropdown-item" *ngFor="let sport of homeData |search: searchSport :'match_name'">
              <div class="event-info" (click)="matchDetail(sport)">
                <div class="event-team">
                  <div class="team-name">{{sport?.match_name}}</div>
                </div>
              </div>
            </a>
          </div>
        </form>
      </div>
    </div>
    <ul class="account-wrap">
      <li class="main-wallet">
        <a id="multiWallet" class="a-wallet">
          <ul id="accountCredit">
            <li><span>Main Balance</span> <span id="betCredit"> PTH {{walletBalance?.balance| number : '0.0-2'}}</span>
            </li>
            <li><span>Exposure</span> <span id="totalExposure"
                [ngClass]="{'exp-list': walletBalance?.liability < 0 ,'winning-list': walletBalance?.liability >= 0}"
                (click)="openModalExposure(exposure)"> {{walletBalance?.liability| number : '0.0-2'}} </span></li>
          </ul>

        </a>
        <a id="menuRefresh" class="a-refresh" title="Refresh Main Wallet" (click)="getUserBalance()">
          <i class="fa fa-undo"></i>
        </a>
      </li>
      <li class="account dropdown">
        <a id="accountPopup" href="javascript:void(0)" class="dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-user"></i> My Account</a>
        <ul id="account_pop" class="dropdown-menu">
          <li>
            <h4>{{userDetails?.user_name}} <span class="gmt" title="Time Zone">GMT+5:30</span></h4>
          </li>
          <li><a [routerLink]="['/info']" >My Profile</a></li>
          <li><a [routerLink]="['/statement']" >Account Statement</a></li>
          <li><a [routerLink]="['/deposit']" >Deposit</a></li>
          <li><a [routerLink]="['/withdraw']" >Withdraw</a></li>
          <li><a [routerLink]="['/betHistory','openBets']" >My Bets</a></li>
          <li><a [routerLink]="['/betHistory','openBets']" >Bets History</a></li>
          <li><a [routerLink]="['/profit-loss']" >Profit &amp; Loss</a></li>
          <li><a [routerLink]="['/history']" >Password History</a></li>
          <li><a [routerLink]="['/activity']" >Activity Log</a></li>
          <li class="logout"><a id="logout" (click)="logoutUser()">Logout<img src="assets/sky_img/transparent.gif"></a>
          </li>

        </ul>
      </li>
    </ul>
  </div>
  <div class="menu-wrap d-none d-lg-block">
    <div class="full-wrap">
      <ul class="menu">
        <li class="ver-menu"><a (click)="dashboard()" [class.select]='path == "dashboard" '>Home</a></li>
        <li class="ver-menu"><a (click)="inplay()" [class.select]='path == "inplay" '>In-Play</a></li>
        <li class="ver-menu"><a (click)="multi()" [class.select]='path == "multiMarket" '>Multi Markets</a></li>
        <li class="ver-menu" *ngFor="let sports of allSportsList;let i= index"
          (click)="goToDashboard(sports.name,sports.sport_id,sports)">
          <a [ngClass]="{'newlaunch':sports.sport_id <= -100 }"
            [class.select]='(sports.sport_id <= -100)?  (path == "casino") :(sportsName == sports.name) '>{{sports?.name}}
            <span class="tag-live" *ngIf="sports.sport_id > -100 "><strong></strong>
              {{sports?.live}}</span><img class="icon-tennis" src="assets/sky_img/transparent.gif"></a>
        </li>
      </ul>

      <ul class="setting-wrap">
        <li>
          <a class="one_click">
            One Click Bet
          </a>
        </li>
        <li class="dropdown">
          <a class="setting dropdown-toggle" data-bs-toggle="dropdown" (click)="getUserMatchStackDetails();">
            Setting
            <i class="fa fa-cog"></i>
          </a>

          <div id="set_pop" class="dropdown-menu slip_set-pop">
            <div class="set-content">
              <dl class="stake-set">
                <dt>Stake</dt>
                <dd *ngFor="let stack of userMatchStack?.match_stack;let k=index;trackBy:trackByFn">
                  <input type="text" [value]="stack" [(ngModel)]="match_stack[k]" name="stack_value"
                    [ngModelOptions]="{standalone: true}">
                </dd>
              </dl>
              <ul class="btn-wrap">
                <li><a id="closeSet" class="btns" style="cursor:pointer;" (click)="closeSlipSet();">Cancel</a></li>
                <li class="col-send"><a id="coinSave" class="btn-send" style="cursor:pointer;"
                    (click)="updateUserMatchStack()">Save</a></li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <header class="header d-lg-none">
    <ul>
      <li class="li-tv_bet">
        <a id="openTV" class="a-open_tv ui-link" style="display: none;">
          <img src="assets/sky_img/transparent.gif">
        </a>
        <a id="openBetsBtn" class="a-open_bets ui-link" (click)="openModalopenBets(openBets);getSportList()"
          data-bs-toggle="modal">
          <img src="assets/sky_img/transparent.gif"> Bets
        </a>
      </li>
      <li class="main-wallet">
        <a class="a-wallet ui-link">
          <ul id="accountCredit">
            <li><span>Main</span><span id="betCredit">PTH {{walletBalance?.balance| number : '0.0-2'}}</span></li>
            <li><span>Exposure</span><span id="totalExposure"
                [ngClass]="{'exp-list': walletBalance?.liability < 0 ,'winning-list': walletBalance?.liability >= 0}"
                (click)="openModalExposure(exposure)">{{walletBalance?.liability| number : '0.0-2'}}</span></li>
          </ul>
        </a>
        <a class="a-refresh ui-link" title="Refresh Main Wallet" (click)="getUserBalance()">
          <!-- <img src="assets/sky_img/transparent.gif"> -->
          <i class="fa fa-undo"></i>
        </a>
      </li>
      <li>
        <a class="a-setting ui-link" (click)="getUserMatchStackDetails();openModalChangeStack(stackValue);"
          data-bs-toggle="modal">
          <!-- <img src="assets/sky_img/transparent.gif"> -->
          <i class="fa fa-cog"></i>
        </a>
      </li>
    </ul>

  </header>
</div>
<div class="marquee-box" *ngIf="adminData?.length != 0">
  <h4>News</h4>
  <div class="marquee">
    <marquee>
      <a>{{adminHeading}}</a>
      <a *ngIf="agentData?.length != 0">| {{agentHeading}} |</a>
    </marquee>
  </div>
</div>

<!-- skyHeader End -->

<ng-template #exposure>
  <a class="close_pop" data-dismiss="modal" aria-label="Close" (click)="modalRef.hide()">close_pop</a>
  <h3>Exposure Detail</h3>
  <div class="wrap-refer_edit">
    <table class="table01">
      <thead class="bg-default">
        <th>Sport Name</th>
        <th>Event Name</th>
        <th>Market Name</th>
        <th>Trade</th>
      </thead>
      <tbody *ngFor="let data of exposureData;let i = index;" style="text-align: center;">
        <tr *ngIf="i < (expoLength-1)">
          <td>{{data?.sport_name}}</td>
          <td style="color: blue;" (click)="goToDetail(data)">{{data?.match_name}}</td>
          <td>{{data?.event_name}}</td>
          <td>1</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>


<ng-template #stackValue>

  <div class="side-wrap setting-wrap">
    <div class="side-head">
      <h3 class="a-setting">
        <img src="assets/sky_img/transparent.gif" alt="">
        Setting
      </h3>
      <a class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"></a>
    </div>
    <div class="side-content">
      <h3>Stake</h3>
      <dl id="editCustomizeStakeList" class="setting-block stake-setting">
        <dt>Quick Stakes</dt>
        <dd *ngFor="let stack of userMatchStack?.match_stack;let k=index;trackBy:trackByFn">
          <div class="ui-input-text">
            <input type="text" [value]="stack" [(ngModel)]="match_stack[k]" name="stack_value"
              [ngModelOptions]="{standalone: true}">
          </div>
        </dd>
      </dl>
      <ul class="btn-list">
        <li (click)="modalRef.hide()">
          <a id="settingCancel" class="btns">Cancel</a>
        </li>
        <li (click)="updateUserMatchStack()">
          <a id="settingSave" class="btn-send">Save</a>
        </li>
      </ul>
    </div>
  </div>
</ng-template>


<ng-template #openBets>
  <div class="side-wrap">
    <div class="side-head">
      <h3 class="a-open_bets">
        <img src="assets/sky_img/transparent.gif" alt="">
        Open Bets
      </h3>
      <a class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide()"></a>
    </div>
    <div class="side-content open-bets">
      <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchMatch" (change)="openBet(searchMatch)">
        <ng-option *ngFor="let match of MatchList" [value]="match">{{match.match_name}}</ng-option>
      </ng-select>

      <h3 *ngIf="openBetsLength != 0">Matched</h3>
      <div class="slip-list">
        <div class="col-md-12 p-0" *ngIf="openBetsLength != 0">
          <div class="bet_slip over-wrap racing-tab">
            <div class="matchunmatch-tab tab-container">

              <div class="tab-content" id="myTabContent" *ngIf="betList == true">
                <div class="tab-pane fade show active" id="matched1-tab-pane" role="tabpanel"
                  aria-labelledby="matched1-tab" tabindex="0">
                  <div class="slip-list">
                    <ul class="matched-head">
                      <li class="col-bet">Back (Bet For)</li>
                      <li class="col-odd">Odds</li>
                      <li class="col-stake">Stake</li>
                      <li class="col-profit">Profit</li>
                    </ul>
                    <dl style="display: block;" *ngFor="let bet of backData;let i = index;"
                      [ngClass]="{'slip-lay':bet.is_back == 0,'slip-back':bet.is_back == 1,'showInfo':showBet == 1}">
                      <dd class="refer refershow" id="betInfo" *ngIf="showBet == 1">
                        <span id="betId">Ref:{{bet?.bet_id}}</span>
                        <span id="placeDate">{{bet?.createdAt | date :'dd-MM-yyyy HH:mm:ss'}}</span>
                      </dd>
                      <dt>
                        <span class="slip-type">{{bet.is_back == 0 ? 'Lay' : "Back"}} </span>
                        <span class="short-amount">{{bet?.selection_name}}</span>
                        <span id="marketName" class="short-amount">{{bet?.market_name}}</span>

                      </dt>
                      <dd class="col-odd">{{bet?.odds}}</dd>
                      <dd class="col-stake">{{bet?.stack}}</dd>
                      <dd class="col-profit"><span>{{bet?.p_l | number : '0.0-2'}}</span></dd>
                    </dl>
                    <ul class="matched-head">
                      <li class="col-bet">Lay (Bet Against)</li>
                      <li class="col-odd">Odds</li>
                      <li class="col-stake">Stake</li>
                      <li class="col-profit">Liability</li>
                    </ul>
                    <dl style="display: block;" *ngFor="let bet of layData;let i = index;"
                      [ngClass]="{'slip-lay':bet.is_back == 0,'slip-back':bet.is_back == 1,'showInfo':showBet == 1}"
                      class="">
                      <dd class="refer refershow" id="betInfo" *ngIf="showBet == 1">
                        <span id="betId">Ref:{{bet?.bet_id}}</span>
                        <span id="placeDate">{{bet?.createdAt | date :'dd-MM-yyyy HH:mm:ss'}}</span>
                      </dd>
                      <dt>
                        <span class="slip-type">Lay</span>
                        <span class="short-amount">{{bet.is_fancy == 1 ? (bet?.selection_name) : (bet?.selection_name)
                          }}</span>
                        <span id="marketName" class="short-amount">{{bet?.market_name}}</span>

                      </dt>
                      <dd class="col-odd">{{bet?.odds}}</dd>
                      <dd class="col-stake">{{bet?.stack}}</dd>
                      <dd class="col-profit"><span>{{bet?.liability | number : '0.0-2'}}</span></dd>
                    </dl>
                  </div>
                  <div class="full_btn">
                    <ul class="slip-option">
                      <li style="list-style: none !important;">
                        <input id="showBetInfo" name="openOption" type="checkbox"
                          (click)="showBet == 0 ? showBetHistory(1):showBetHistory(0) "
                          class="ng-valid ng-dirty ng-touched"><label for="showBetInfo">Bet Info</label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="tab-pane fade" id="unmatched1-tab-pane" role="tabpanel" aria-labelledby="unmatched1-tab"
                  tabindex="0">

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #changePwd>

  <div class="modal-header">
    <h5 class="modal-title">Change Password</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef.hide()"></button>
  </div>
  <div class="modal-body" [formGroup]='changePasswordForm' (ngSubmit)='onSubmitChangePassword()'>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="mb-1">Old Password <span class="asterisk_input"></span></label>
          <input type="password" placeholder="Old Password" class="form-control" formControlName='old_password'>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="mb-1">New Password <span class="asterisk_input"></span></label>
          <input type="password" placeholder="Password" class="form-control" formControlName='new_password'>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-1">Confirm Password <span class="asterisk_input"></span></label>
          <input type="password" placeholder="Password" class="form-control" formControlName='confirm_password'>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-dark btn-sm" (click)="onSubmitChangePassword()">Yes</button>
  </div>

</ng-template>