<div class="footer"> 
        <div id="supportWrap" class="support-wrap">
         <div class="support-service">
           <a id="support_whatsapp" class="support-whatsapp open">
             <img src="assets/sky_img/transparent.gif" title="WhatsApp">
           </a>
           <a id="support_fb" class="support-fb">
             <img src="assets/sky_img/transparent.gif" title="Facebook">
           </a>
           <a id="support_email" class="support-mail">
             <img src="assets/sky_img/transparent.gif" title="Email">
           </a>
           <a id="support_telegram" class="support-telegram">
             <img src="assets/sky_img/transparent.gif" title="Telegram">
           </a>
           <a id="support_skype" class="support-skype">
             <img src="assets/sky_img/transparent.gif" title="Skype">
           </a>
           <a id="support_instagram" class="support-ig">
             <img src="assets/sky_img/transparent.gif" title="Instagram">
           </a>
         </div>
         <div class="support-info">
           <div id="supportDetail_whatsapp" class="support-detail open">
             <a href="https://wa.me/+00 00000 00000">+00 00000 00000 </a>
           </div>
           <div id="supportDetail_fb" class="support-detail open"></div>
           <div id="supportDetail_email" class="support-detail">
             <a href="mailto:info@skyexch.live">info@skyexch.live</a>
           </div>
           <div id="supportDetail_telegram" class="support-detail">
             <a>
               <span>www.t.me/officialskyexchinfo</span>
             </a>
             <a>
               <span>www.t.me/skyexchcustomersupport</span>
             </a>
           </div>
           <div id="supportDetail_skype" class="support-detail">
             <a href="unsafe:skype:skyexchofficial?chat">skyexchofficial</a>
           </div>
         </div>
       </div>
     <div class="browser-wrap">
        <img src="assets/sky_img/icon-browser-B.png" alt="">
       <br> Our website works best in the latest and latest previous versions of these browsers:Google Order. Firefox
     </div>
     <app-policy-link _nghost-eft-c47="">
       <ul _ngcontent-eft-c47="" class="policy-link">
         <li _ngcontent-eft-c47="">
           <a _ngcontent-eft-c47="">Privacy Policy</a>
         </li>
         <li _ngcontent-eft-c47="">
           <a _ngcontent-eft-c47="">Terms and Conditions</a>
         </li>
         <li _ngcontent-eft-c47="">
           <a _ngcontent-eft-c47="">Rules and Regulations</a>
         </li>
         <li _ngcontent-eft-c47="">
           <a _ngcontent-eft-c47="">KYC</a>
         </li>
         <li _ngcontent-eft-c47="">
           <a _ngcontent-eft-c47="">Responsible Gaming</a>
         </li>
         <li _ngcontent-eft-c47="">
           <a _ngcontent-eft-c47="">About Us</a>
         </li>
       </ul>
     </app-policy-link>
     <div class="app-link" style="display: block;">
       <div class="power-wrap" style="width: 290px; text-align: left;">
         <h3 id="powerWrapW">
           <span></span>
           <img src="assets/sky_img/logo-betfair.png" alt="">
         </h3>
         <div class="licence_embed">
           <a href="/assets/apk/skyexch.apk">
             <img src="assets/sky_img/btn-appdl.png" alt="">
           </a>
           <p>v1.11 - 2022-03-23 - 3.1MB</p>
         </div>
       </div>
     </div>
 
    <!-- <div class="support-wrap extend-support">
        <div class="extend-wrap">
            <div class="extend-btn">
                <img src="assets/sky_img/support.svg" title="customer" class="support-customer hide-mobile">
                <img src="assets/sky_img/mobile-support.svg" title="customer" class="support-customer hide-desktop">
                <a >Customer support1</a>
                <a  class="split-line">support2</a>
            </div>
            <div class="extend-btn">
				<img src="assets/sky_img/whatsapp.svg" title="WhatsApp" class="support-whatsapp hide-mobile">
                <img src="assets/sky_img/mobile-whatsapp.svg" title="WhatsApp" class="support-whatsapp hide-desktop">
				<a >+0000000000</a>
				<a  class="split-line">+0000000000</a>
			</div>
            <div class="extend-btn hide-desktop">
                <img src="assets/sky_img/mobile-telegram.svg" title="Telegram" class="support-telegram">
                <a >***********</a>
                <a  class="split-line">***********</a>
            </div>
        </div>
        <div class="extend-btn hide-mobile">
            <img src="assets/sky_img/telegram.svg" title="Telegram" class="support-telegram">
            <a >***********</a>
            <a  class="split-line">***********</a>
        </div>

        <div class="extend-wrap hide-mobile">
            <div class="support-social">
                <img src="assets/sky_img/skype.svg" title="Skype" class="support-skype">
                <a >Skype</a>
            </div>
            <div class="support-social">
                <img src="assets/sky_img/email.svg" title="Email" class="support-mail">
                <a  >email</a>
            </div>
            <div class="support-social">
                <img src="assets/sky_img/instagram.svg" title="Instagram" class="support-ig">
                <a >Instagram</a>
            </div>
        </div>
        <div class="support-social hide-desktop">
            <div class="social-btn">
                <img src="assets/sky_img/mobile-skype.svg" title="Skype" class="support-skype">
                <a >Skype</a>
            </div>
            <div class="social-btn">
                <img src="assets/sky_img/mobile-email.svg" title="Email" class="support-mail">
                <a  >Email</a>
            </div>
            <div class="social-btn">
                <img src="assets/sky_img/mobile-instagram.svg" title="Instagram" class="support-ig">
                <a >Instagram</a>
            </div>
        </div>
    </div> -->

    <!-- <div class="power-wrap hide-mobile">
        <h3>
            <span>Powered by <img src="assets/sky_img/logo-betfair.png"></span>
        </h3>
        <div class="licence_embed">
            <iframe src="https://licensing.gaming-curacao.com/validator/?lh=7a83475c9e54450a218a18bd28e33fad&amp;template=seal" width="150" height="50" style="border:none;"></iframe>
        </div>
        <p>Skyexchange is operated by Sky Infotech N.V. a limited liability company incorporated under the laws of Curacao with
            company Registration number 152377 with registered office at Abraham de Veerstraat 9 , Curacao P.O Box 3421 and is licensed
            and regulated by the Curacao authority as the regulatory body responsible holding a (Sub-license with License number 365/JAZ
            Sub-License GLH- OCCHKTW0707072017 granted on 6.07.2017).
            <br>
            Players are requested not to contact any untrusted sources for skyexchange.com accounts as this is an online site and they can only
            register independently without any agents. Only deposit through the account details generated by the system or provided by our
            official support team.
        </p>
    </div> -->
    <!-- <div class="browser-wrap hide-mobile">
        <img src="assets/sky_img/icon-browser-B.png" alt=""><br>
        Our website works best in the newest and last prior version of these browsers: <br>Google Chrome. Firefox
    </div>
    <ul class="policy-link">
        <li><a >Privacy Policy</a></li>
        <li><a >Terms and Conditions</a></li>
        <li><a >Rules and Regulations</a></li>
        <li><a >KYC</a></li>
        <li><a >Responsible Gaming</a></li>
        <li><a >About Us</a></li>
        <li><a >Self-exclusion Policy</a></li>
        <li><a >Underage Policy</a></li>
    </ul>
    <div class="app-link hide-mobile">
        <a ><img src="assets/sky_img/btn-appdl.png" alt=""></a>
        <p>v1.11 - 2022-03-23 - 3.1MB</p>
    </div>
    <div class="extra-wrap hide-desktop">
        <div class="power-wrap-b">
            <span>Powered by</span>
            <img src="assets/sky_img/logo-betfair.png" alt="">
        </div>
        <div class="appdl-link-ios" (click)="DownloadApk()">
            <a  >
                <img src="assets/sky_img/btn-appdl-android.png" alt="">
            </a>
            <p>v1.00 - 2019-04-10 - 0.4MB</p>
        </div>
    </div>
</div> -->

  <ng-template #loginCnfm>
    <div class="login-popup-content ">
        <div class="header-popup">
            <span class="close" (click)="modalRef.hide();"><i class="fa fa-close" style="font-size:24px"></i></span>
        </div>
        <div class="login-panel-container confirm-container " [innerHTML]="contentReceived">
        </div>
        <div class="confirm-button">
            <button class="btn-verification btn-confirm" (click)="modalRef.hide();">
                <span>OK</span>
            </button>
        </div>
    </div>
</ng-template>
<!-- 
<div class="col-md-12">
    <div class="inner-footer">
        <div class="support-wrap">
            <dl class="support-mail">
                <a class="rules-btn-home" *ngFor="let data of footerData;let i = index;" (click)="openModalLoginCnfm(data.slug,loginCnfm)" [ngClass]="{'arrow':i != 0}">{{data?.title}}</a>
              
            </dl>
        </div>
    </div>
</div> -->
</div>