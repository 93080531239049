<div class="app-body">
    <div id="loading" class="loading-wrap" style="display: none;">
      <ul class="loading">
        <li><img src="assets/sky_img/loading40.gif"></li>
        <li>Loading...
        </li>
        <br>
        <li><span id="progress"></span></li>
      </ul>
    </div>
    <app-header></app-header>
    <div class="full-wrap">
      <div class="col-left">
        <!-- <app-sidebar></app-sidebar> -->
        <div class="sub_path">
          <div class="path">
            <a class="path-back">...</a>
            <ul>
              <li class="path-last"><a>My Account</a></li>
            </ul>
          </div>
          <ul class="menu-list" data-widget="tree">
            <li>
              <a (click)="info()">
                <span>My Profile</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a (click)="rollComission()">
                <span>Rolling Commision</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a (click)="statement()" class="select">
                <span>Account Statement</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/betHistory','openBets']">
                <span>Bets History</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a (click)="profitLoss()">
                <span>Profit & Loss</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a (click)="history()">
                <span>Password History</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a (click)="activity()">
                <span>Activity Log</span><span class="pull-right-container"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
  
      <div id="centerColumn" class="col-center report">
        <h2>Withdraw Amount </h2>
        
        <div class="function-wrap">
          <div class="row">
            <div class="card">
                <div class="card-header"><h4 class="mb-0">Withdraw Amount</h4></div>
                <div class="card-body container-fluid container-fluid-5">
                  <div id="pills-tabContent">
                    <div  id="withdraw">
                        <div class="kyc-staus-box status-box">
                            <span class="withdrawable-amt">
                                Your withdrawable amount is ₹
                                <span class="amount">{{updatedBalance < 0 ? '0' : updatedBalance}}</span>
                                </span>
                        </div>
                        <div class="tab mx-1" [ngClass]="showBankFormStatus ?  'addBank-hide':'addBank-show'">
                            <ng-container *ngFor="let x of payMethodList;let i=index">
                                <button [class.active]="i == activeTabIndex" class="tablinks" id="add_active{{i}}" name="show_list_type" (click)="showDetails($event.target.value)"  [value]="i">{{ x.name }}</button>
                            </ng-container>
                            <div style="float:right;"><div class="bank-details">
                                <button class="tablinks active" (click)="showBankForm()" > <i class="fas fa-plus"></i>
                                    Add bank</button>
                               
                            </div></div>
                         </div>

                        <form class="col s12 mx-1 tabcontent" [ngClass]="hideShowBankStatus ? 'bankForm-show' : 'bankForm-hide'">
                            <div class="m-0">
                                <div class="bankList-table">
                                    <table class="dataTable">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <ng-container *ngIf="show_list_type==='Bank' else upi_list">
                                                    <th>Bank Name</th>
                                                    <th>Acc Number</th>
                                                    <th>IFSC Code</th>
                                                </ng-container>
                                                <ng-template #upi_list>
                                                    <th>Number</th>
                                                </ng-template>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let list of methodDetail;let i = index;">
                                                <td> {{list?.bank_holder_name}} </td>
                                                <ng-container *ngIf="show_list_type==='Bank' else upi_list">
                                                    <td> {{list?.bank_name}} </td>
                                                    <td> {{list?.account_no}} </td>
                                                    <td> {{list?.ifsc_code}} </td>

                                                </ng-container>
                                                <ng-template #upi_list>
                                                    <td> {{list?.others}} </td>
                                                </ng-template>
                                                <td>
                                                    <a (click)="delete(list)" class="btn"> Remove </a>
                                                </td>
                                                
                                            </tr>
                                            <tr *ngIf="methodDetail?.length==0">
                                                <td colspan="3">No data found!</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="row p-0 select-bank">
                                <div class="input-field col s12 m6 m-0">
                                    <div class="bank-details">
                                        <a class="add-bank" (click)="showBankForm()">
                                            <i class="fas fa-plus"></i>
                                            Add bank
                                        </a>
                                    </div>
                                </div>
                            </div> -->
                            
                        </form>
                        <div style="width:40%;" class="form-group mb-0" [ngClass]="showBankFormStatus ? 'addBank-show' : 'addBank-hide'">
                            <select class="form-select"  (change)="addDetails($event.target.value)" aria-label="Default select example">
                                <option value="" disabled selected>Please Select Account Type</option>
                                <ng-container *ngFor="let x of payMethodList;let i=index">
                                  <option class="select-option" [value]="i">{{ x.name }}</option>
                                </ng-container>
                              </select>
                          </div>

                        <form  *ngIf="accountType==='Bank' else elseUpi" class="col s12" [formGroup]='Form'>
                            <div class="row m-0 withdraw-bank">
                                    <div  class="input-field col s12 m6 m-0 p-0 pr-10">
                                        <div class="bank-dropdown" style="margin-right:12px;">
                                            <label class="active">Bank Details <span
                                                    class="text-danger">*</span>:</label>
                                            <div class="select-wrapper">
                                                <ng-select [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="searchBank" class="select-dropdown dropdown-trigger"
                                                    (change)="selectBank(searchBank) " placeholder="Select Bank">
                                                    <ng-option *ngFor="let list of bankList;let i = index;"
                                                        [value]="list.bn">{{list.bn}}</ng-option></ng-select>
                                            </div>
                                        </div>
                                        <div class="alert_base" *ngIf="submitted && f.bank_name.status == 'INVALID'">
                                            <span>* Please enter Bank Name</span>
                                        </div>
                                        <div class="bank-ifsc">
                                            <div class="input-field col s12 p-0">
                                                <input placeholder="Enter branch IFSC" type="text"
                                                    formControlName="ifsc_code" class="validate">
                                                <label class="active">Branch IFSC <span class="text-danger">*</span>:
                                                </label>
                                            </div>
                                        </div>
                                        <div class="alert_base" *ngIf="submitted && f.ifsc_code.hasError('required')">
                                            <span>* Please enter IFSC Code</span>
                                        </div>
                                        <div class="alert_base" *ngIf="submitted && f.ifsc_code.hasError('invalidIFSCCode')">
                                            <span>* Invalid IFSC Code.</span>
                                        </div>
                                    </div>
                                    <div class="input-field col s12 m6 m-0 p-0 pl-10">
                                        <div class="bank-account-no">
                                            <div class="input-field col s12 p-0" style="margin-right:12px;">
                                                <input placeholder="Enter bank account no" type="text"
                                                    formControlName="account_no" class="validate">
                                                <label class="active">Bank Account No <span
                                                        class="text-danger">*</span>: </label>
                                            </div>
                                        </div>
                                        <div class="alert_base" *ngIf="submitted && f.account_no.hasError('required')">
                                            <span>* Please enter Account Number</span>
                                        </div>
                                        <div class="alert_base" *ngIf="submitted && f.account_no.hasError('invalidAccountNumber')">
                                            <span>* Invalid Account Number.</span>
                                        </div>
    
                                        <div class="bank-ac-name">
                                            <div class="input-field col s12 p-0" >
                                                <input placeholder="Enter name" type="text"
                                                    formControlName="bank_holder_name" class="validate">
                                                <label class="active">Account Holder Name<span
                                                        class="text-danger">*</span>: </label>
                                            </div>
                                        </div>
                                        <div class="alert_base"
                                            *ngIf="submitted && f.bank_holder_name.status == 'INVALID'">
                                            <span>* Please enter Holder Name</span>
                                        </div>
                                    </div>
                            </div>
                        </form>

                        <!-- upi/paytm/phonepay -->
                        <ng-template #elseUpi>

                            <form  *ngIf="accountType" class="col s12" [formGroup]='upiForm'>
                                <div class="row m-0 withdraw-bank">
                                    <div class="col-md-10">
                                        <div class="form-group row">
                                            <label class="col-md-4">Name  
                                                <span class="asterisk_input"></span>
                                            </label>
                                            <div class="col-md-7">
                                                <input class="form-control" formControlName="bank_holder_name" placeholder="Name.." type="text">
                                                <div class="alert_base"
                                            *ngIf="submitted && u.bank_holder_name.status == 'INVALID'">
                                            <span>* Please enter Name</span>
                                        </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-10" style="margin-top:14px;">
                                        <div class="form-group row">
                                            <label class="col-md-4">Add UPI Number
                                                <span class="asterisk_input"></span>
                                            </label>
                                            <div class="col-md-7">
                                                <input class="form-control" formControlName="others" placeholder="UPI Number.." type="text">
                                            </div>
                                            <div class="alert_base"
                                            *ngIf="submitted && u.others.status == 'INVALID'">
                                            <span>* Please enter UPI Number</span>
                                        </div>
                                        </div>
                                    </div>
                                                
                                </div>
                            </form>
                        </ng-template>
                       
                        <div class="col s12 submit-btn mt-10"
                            [ngClass]="showBankFormStatus ? 'addBank-show' : 'addBank-hide'">
                            <a class="btn mr-10 right" *ngIf="accountType" (click)="addPaymentMethod()"><span class="animate-btn">Add
                                    {{accountType}}</span></a>
                            <a class="mr-10 can-btn" (click)="hideShowBankdetail();accountType=''"> Back </a>
                        </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        
      </div>
  
  
   
    <app-footer-main></app-footer-main>
  </div>
