<div class="app-body footer-condition footer-hide-desk">
  <app-open-header></app-open-header>
  <!-- Loading Wrap -->
  <div id="loading" class="loading-wrap d-none-mobile" *ngIf="loading == true">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>
  
  <div class="loading-overlay d-none-desktop" id="loading" *ngIf="loading == true">
    <div class="loading-wrap">
      <div class="loading">
        <div></div>
        <div></div>
      </div>
      <p>Loading...
      </p>
    </div>
  </div>

  <div class="full-wrap">
    <div id="centerColumn" class="col-center inplay">
      <!-- Message -->
      <div id="message" class="message-wrap success">
        <a class="btn-close">Close</a>
        <p></p>
      </div>
      <!-- Center Column -->
      <ul class="tab col3 d-none-mobile" id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <a class="nav-link active" id="pills-inplay-tab" [class.active]='parameter == "inplay"' data-bs-toggle="pill"
            data-bs-target="#pills-inplay" role="tab" aria-controls="pills-inplay" aria-selected="true"
            (click)="goToDashboard('inplay')">In-Play</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="pills-today-tab" [class.active]='parameter == "today"' data-bs-toggle="pill"
            data-bs-target="#pills-today" role="tab" aria-controls="pills-today" aria-selected="false"
            (click)="goToDashboard('today')">Today</a>
        </li>
        <li class="nav-item" role="presentation">
          <a class="nav-link" id="pills-tomorrow-tab" [class.active]='parameter == "tomorrow"' data-bs-toggle="pill"
            data-bs-target="#pills-tomorrow" role="tab" aria-controls="pills-tomorrow" aria-selected="false"
            (click)="goToDashboard('tomorrow')">Tomorrow</a>
        </li>
      </ul>
      <div class="tab-wrap d-lg-none">
        <ul id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active " id="pills-inplay-tab" [class.active]='parameter == "inplay"'
              data-bs-toggle="pill" data-bs-target="#pills-inplay" role="tab" aria-controls="pills-inplay"
              aria-selected="true" (click)="goToDashboard('inplay')">In-Play</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-today-tab" [class.active]='parameter == "today"' data-bs-toggle="pill"
              data-bs-target="#pills-today" role="tab" aria-controls="pills-today" aria-selected="false"
              (click)="goToDashboard('today')">Today</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-tomorrow-tab" [class.active]='parameter == "tomorrow"' data-bs-toggle="pill"
              data-bs-target="#pills-tomorrow" role="tab" aria-controls="pills-tomorrow" aria-selected="false"
              (click)="goToDashboard('tomorrow')">Tomorrow</a>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="pills-inplay-tab" tabindex="0">
          <div class="over-wrap" *ngFor="let homeMatch of homeData | keyvalue;let i = index;">
            <div class="game-wrap col3">
              <h3>
                <a id="eventType" class="to-expand">{{homeMatch?.key}}</a>
              </h3>
              <ul class="slip-head d-none-mobile">
                <li class="col-game"></li>
                <li class="col-matched"></li>
                <li class="col-visit">1</li>
                <li class="col-draw">x</li>
                <li class="col-home">2</li>
                <li class="col-info"></li>
              </ul>
              <div class="game-list d-none-mobile">
                <dl class="game-list-col" *ngFor="let matchData of homeMatch.value,let j = index;">
                  <dt (click)="matchDetail(matchData)">
                    <span class="img-span"
                      [ngClass]="matchData.inplay || matchData?.manualInplay == true ? 'icon-in_play' : 'icon-no_play'"></span>
                    <span class="game-low_liq" style="display: none;">Low Liquidity</span>
                    <a href="javascript:void(0)">{{matchData?.match_name}}</a>
                    <span class="game-list-time" *ngIf="matchData?.inplay == true || matchData?.manualInplay == true">
                      <span class="in_play">In-Play</span>
                    </span>
                    <span class="game-list-time" *ngIf="matchData?.inplay != true ">
                      <span>{{matchData?.match_date | date :'yyyy-MM-dd HH:mm'}}</span>
                    </span>
                    <span class="game-live" id="streamingIcon" style="display: inline-flex;"
                      *ngIf="matchData?.has_tv_url">Live</span>
                    <span class="game-fancy in-play" *ngIf="matchData?.enable_fancy == 1 ">Fancy</span>
                    <span class="game-bookmaker" *ngIf="matchData?.enable_fancy == 1 ">BookMaker</span>
                  </dt>
                  <dd class="col-matched"></dd>
                  <dd class="col-visit">
                    <div class="suspend" style="display: none;">
                      <span>Suspend</span>
                    </div>
                    <a class="btn-back" side="Back"> {{matchData?.runners[0] == undefined ? '--' :
                      matchData?.runners[0]?.ex?.availableToBack[0]?.price}}</a>
                    <a class="btn-lay" side="Lay">
                      {{matchData?.runners[0]
                      == undefined ? '--' :
                      matchData?.runners[0]?.ex?.availableToLay[0]?.price}}
                    </a>
                  </dd>
                  <dd class="col-draw">
                    <div class="suspend" style="display: none;">
                      <span>Suspend</span>
                    </div>
                    <a class="btn-back" side="Back">
                      {{matchData?.runners[2] == undefined ? '--' :
                      matchData?.runners[2]?.ex?.availableToBack[0]?.price}}
                    </a>
                    <a class="btn-lay" side="Lay">
                      {{matchData?.runners[2]
                      == undefined ? '--' :
                      matchData?.runners[2]?.ex?.availableToLay[0]?.price}}
                    </a>
                  </dd>
                  <dd class="col-home">
                    <div class="suspend" style="display: none;">
                      <span>Suspend</span>
                    </div>
                    <a class="btn-back" side="Back">
                      {{matchData?.runners[1]?.ex?.availableToBack[0]?.price
                      == undefined ? 0 :
                      matchData?.runners[1]?.ex?.availableToBack[0]?.price}}
                    </a>
                    <a class="btn-lay" side="Lay">
                      {{matchData?.runners[1]?.ex?.availableToLay[0]?.price
                      ==
                      undefined ? 0 :
                      matchData?.runners[1]?.ex?.availableToLay[0]?.price}}
                    </a>
                  </dd>
                  <dd class="col-info">
                    <a class="add-pin" title="Add to Multi Markets">Pin</a>
                  </dd>
                </dl>

              </div>

              <ul class="highlight-list mobile-highlight-list d-lg-none">
                <li *ngFor="let matchData of homeMatch.value,let j = index;"
                  [ngClass]="{'inplay-on':(matchData?.inplay) == true,'inplay-off':(matchData?.inplay) != true}">
                  <a id="info" (click)="matchDetail(matchData)">
                    <dl>
                      <dt>
                        <span class="game-live" *ngIf="matchData.has_tv_url">game-live</span>
                        <span class="game-fancy" *ngIf="matchData?.enable_fancy == 1 ">Fancy</span>
                        <span class="game-bookmaker" *ngIf="matchData?.enable_fancy == 1 ">BookMaker</span>
                        <span class="time"
                          *ngIf="matchData?.inplay == true || matchData?.manualInplay == true"   [ngClass]="matchData.inplay ? 'green' : ''">In-Play</span>
                        <span class="time" *ngIf="matchData?.inplay != true ">{{matchData?.match_date | date
                          :'yyyy-MM-dd HH:mm'}}</span>
                      </dt>
                      <dd id="eventName">{{matchData?.match_name}}</dd>
                    </dl>
                  </a>
                  <a class="pin-off"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-right accordion" id="betslipRisgtDiv">
      <div class="slip-wrap accordion-item">
        <h3 id="betSlipHeading" data-bs-toggle="collapse" data-bs-target="#betSlipCollapse" aria-expanded="true"
          aria-controls="betSlipCollapse">
          <a class="to-expand">Bet Slip</a>
        </h3>
        <ul id="loadingMsg" class="loading" style="display: none;">
          <li><img src="assets/sky_img/loading40.gif"></li>
          <li id="countDownTime">Place Bets
          </li>
        </ul>
        <div id="betSlipCollapse" class="accordion-collapse collapse show" data-bs-parent="#betslipRisgtDiv">
          <ul id="loadingMsg" class="loading" style="display: none">
            <li><img src="assets/sky_img/loading40.gif"></li>
            <li id="countDownTime">Place Bets</li>
          </ul>
        </div>
      </div>

      <div class="matched-wrap accordion-item d-none">
        <h3 id="matchedBetHeading" data-bs-toggle="collapse" data-bs-target="#matchedBetCollapse" aria-expanded="true"
          aria-controls="matchedBetCollapse">
          <a id="openBetsRefresh" class="slip_refresh" style="cursor:pointer;">Refresh</a>
          <a class="to-expand" style="cursor:pointer;">Open Bets
          </a>
        </h3>
        <div id="matchedBetCollapse" class="accordion-collapse collapse show" data-bs-parent="#betslipRisgtDiv">
          <div class="message-bet">
            <h4 id="oneClickMsg" class="" style="display: none"></h4>
          </div>
          <ul id="processingImg" class="loading" style="display: none;">
            <li><img src="assets/sky_img/loading40.gif"></li>
            <li>Processing...
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <app-open-footer></app-open-footer>
</div>