<div class="main-wrapper">
  <app-header></app-header>
  <div class="app-body position-relative">
    <app-sidebar></app-sidebar>
    <main class="main">
      <div class="row">
        <div class="col-md-12">
          <div class="divider-top">
            <div class="card card-accent-primary">
              <div class="card-header account-detail-head">Profit/Loss Bets</div>
              <div class="card-body account-stat-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="table-responsive">
                      <table class="row-border table table-bordered tableData" datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger">
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <app-right-sidebar></app-right-sidebar>
  </div>
  <app-footer-main></app-footer-main>
</div>

<!-- Date Time Picker -->
<ng-template #popTemplateStatementStart>
  <datepicker [hidden]="!isDateVisible" [(ngModel)]="dateStatement" [ngModelOptions]="{standalone: true}"
    [minDate]="minDate" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateDateStatement()"
    (selectionDone)="dateSelectionDone()">
  </datepicker>
  <timepicker [hidden]="!isDateVisible" [(ngModel)]="timeStatement" [ngModelOptions]="{standalone: true}"
    [min]="minTime" [max]="maxTime" (ngModelChange)="updateTimeStatement()" [showMeridian]="isMeridian"
    showSeconds="true">
  </timepicker>

  <div style="padding:5px">
    <span class="btn-group pull-left" style="margin-right:10px;">
      <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
      <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
    </span>
    <span class="btn-group pull-right">
      <button type=button class="btn btn-sm btn-success" (click)="close()">Close</button>
    </span> <span class=clearfix></span>
  </div>
</ng-template>
<ng-template #popTemplateStatementEnd>
  <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDateStatement" [ngModelOptions]="{standalone: true}"
    [minDate]="dateStatement" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateEndDateStatement()"
    (selectionDone)="dateSelectionDone()">
  </datepicker>
  <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTimeStatement" [ngModelOptions]="{standalone: true}"
    [min]="minTime" [max]="maxTime" (ngModelChange)="updateEndTimeStatement()" [showMeridian]="isMeridian"
    showSeconds="true">
  </timepicker>

  <div style="padding:5px">
    <span class="btn-group pull-left" style="margin-right:10px;">
      <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
      <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
    </span>
    <span class="btn-group pull-right">
      <button type=button class="btn btn-sm btn-success" (click)="closeEndDatepicker()">Close</button>
    </span> <span class=clearfix></span>
  </div>
</ng-template>