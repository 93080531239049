<div class="app-body">
  <!-- Loading Wrap -->
  <div id="loading" class="loading-wrap d-none-mobile" *ngIf="loading == true">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>
  
  <div class="loading-overlay d-none-desktop" id="loading" *ngIf="loading == true">
    <div class="loading-wrap">
      <div class="loading">
        <div></div>
        <div></div>
      </div>
      <p>Loading...
      </p>
    </div>
  </div>

  <app-header></app-header>
  <!-- desktop design -->
  <div class="full-wrap d-none-mobile dashboard-page-design">
    <div class="col-left">
      <app-sidebar></app-sidebar>
    </div>

    <div id="centerColumn" class="col-center">
      <!-- Message -->
      <div id="message" class="message-wrap success">
        <a class="btn-close">Close</a>
        <p></p>
      </div>
      <div class="marquee-box"><h4 _ngcontent-eft-c39="">News</h4>
        <div class="marquee"><marquee width="100%" direction="left"><a href="#">🏆𝐈𝐂𝐂 𝐂𝐑𝐈𝐂𝐊𝐄𝐓 𝐖𝐎𝐑𝐋𝐃 𝐂𝐔𝐏 𝟐𝟎𝟐𝟑 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 🏆||𝐒𝐔𝐏𝐄𝐑 50 𝐂𝐔𝐏 𝐂𝐑𝐈𝐂𝐊𝐄𝐓 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 ||🎮 𝐕𝐈𝐑𝐓𝐔𝐀𝐋 𝐂𝐑𝐈𝐂𝐊𝐄𝐓 𝐁𝐄𝐓𝐒 𝐀𝐕𝐀𝐈𝐋𝐀𝐁𝐋𝐄 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄🎮 ||🎾 𝐓𝐄𝐍𝐍𝐈𝐒 𝐒𝐄𝐓 𝐖𝐈𝐍𝐍𝐄𝐑 𝐌𝐀𝐑𝐊𝐄𝐓 𝐀𝐕𝐀𝐈𝐋𝐀𝐁𝐋𝐄 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 🎾 ||🏀 𝐏𝐑𝐄𝐌𝐈𝐔𝐌 𝐒𝐏𝐎𝐑𝐓𝐒𝐁𝐎𝐎𝐊 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 🏀||Toss Fancy Market Available for all Matches|| </a></marquee></div></div>
      <div class="over-wrap">
        <div class="promo-banner">
          <div id="carouselExampleControls" class="carousel slide suny" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let image of banner;let i = index;"
                [ngClass]="(i === 0) ? 'active' : ''" data-bs-interval="5000">
                <img src="{{image.bannerUrl}}" class="d-block w-100" alt="...">
              </div>
            </div>
          </div>
        </div>


        <div class="game-highlight-wrap col3">
          <h3>Sports Highlights
            <div class="highlight-sorting">
              <label>View by</label>
              <div class="select">
                <select id="viewType" name="View">
                  <option value="competitionName" orderby="asc" priority="asc">Competition</option>
                  <option value="openDateTime" orderby="asc" inplay="desc" selected="">Time</option>
                  <option value="totalMatched" orderby="desc">Matched</option>
                </select>
              </div>
            </div>
          </h3>
          <ul class="slip-head">
            <li class="col-game"></li>
            <li class="col-matched">Matched</li>
            <li class="col-visit">1</li>
            <li class="col-draw">x</li>
            <li class="col-home">2</li>
            <li class="col-info"></li>
          </ul>

          <div class="game-list">

            <div id="eventBoard">
              <dl class="game-list-col" *ngFor="let matchData of homeData;let i = index;">
                <dt (click)="matchDetail(matchData,1)">
                  <span class="img-span " [ngClass]="matchData.inplay ? 'icon-in_play' : 'icon-no_play'"></span>
                  <span class="game-low_liq" style="display: none;">Low Liquidity</span>
                  <a >{{matchData.match_name}}</a>
                  <span class="game-list-time">
                    <span class="in_play" *ngIf="matchData.inplay">In-Play</span>
                    <span *ngIf="matchData.inplay != true">{{matchData?.match_date | date :'yyyy-MM-dd HH:mm'}}</span>
                  </span>
                  <span class="game-live" id="streamingIcon" style="display: inline-flex;"
                    *ngIf="matchData.has_tv_url">Live</span>
                  <span class="game-fancy in-play" *ngIf="matchData.enable_fancy === 1">Fancy</span>
                  <span class="game-bookmaker" *ngIf="matchData.enable_fancy === 1">BookMaker</span>
                </dt>
                <dd class="col-matched">PTE {{matchData?.totalMatched}}</dd>
                <dd class="col-visit">
                  <div class="suspend"
                    *ngIf=" matchData.status == 'SUSPENDED' || matchData.status == 'CLOSED' || matchData.status == 'closed' || matchData.status == 'Closed'">
                    <span class="stats-text">Suspend</span></div>
                  <div class="suspend" *ngIf=" matchData.status != 'SUSPENDED' && matchData.is_lock == true"><span
                      class="stats-text">Lock</span></div>
                  <a class="btn-back" side="Back"
                    (click)="getOddsValue((matchData?.runners[0]?.ex?.availableToBack[0]?.price),matchData?.runners[0]?.ex?.availableToBack[0]?.size,1,matchData?.runners[0]?.name,matchData?.runners[0]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[0] == undefined ? 0 :
                    matchData?.runners[0]?.ex?.availableToBack[0]?.price}}
                  </a>
                  <a class="btn-lay" side="Lay"
                    (click)="getOddsValue((matchData?.runners[0]?.ex?.availableToLay[0]?.price),matchData?.runners[0]?.ex?.availableToLay[0]?.size,0,matchData?.runners[0]?.name,matchData?.runners[0]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[0]
                    == undefined ? 0 :
                    matchData?.runners[0]?.ex?.availableToLay[0]?.price}}
                  </a>
                </dd>
                <dd class="col-draw">
                  <div class="suspend"
                    *ngIf=" matchData.status == 'SUSPENDED' || matchData.status == 'CLOSED' || matchData.status == 'closed' || matchData.status == 'Closed'">
                    <span class="stats-text">Suspend</span></div>
                  <div class="suspend" *ngIf=" matchData.status != 'SUSPENDED' && matchData.is_lock == true"><span
                      class="stats-text">Lock</span></div>
                  <a class="btn-back" side="Back"
                    (click)="getOddsValue((matchData?.runners[2]?.ex?.availableToBack[0]?.price),matchData?.runners[2]?.ex?.availableToBack[0]?.price,1,matchData?.runners[0]?.name,matchData?.runners[0]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[2] == undefined ? '--' :
                    matchData?.runners[2]?.ex?.availableToBack[0]?.price}}</a>
                  <a class="btn-lay" side="Lay"
                    (click)="getOddsValue((matchData?.runners[2]?.ex?.availableToLay[0]?.price),matchData?.runners[2]?.ex?.availableToLay[0]?.price,0,matchData?.runners[0]?.name,matchData?.runners[0]?.selectionId,matchData.market_id,i,matchData)">{{matchData?.runners[2]
                    == undefined ? '--' :
                    matchData?.runners[2]?.ex?.availableToLay[0]?.price}}</a>
                </dd>
                <dd class="col-home">
                  <div class="suspend"
                    *ngIf=" matchData.status == 'SUSPENDED' || matchData.status == 'CLOSED' || matchData.status == 'closed' || matchData.status == 'Closed'">
                    <span class="stats-text">Suspend</span></div>
                  <div class="suspend" *ngIf=" matchData.status != 'SUSPENDED' && matchData.is_lock == true"><span
                      class="stats-text">Lock</span></div>
                  <a class="btn-back" side="Back"
                    (click)="getOddsValue((matchData?.runners[1]?.ex?.availableToBack[0]?.price),matchData?.runners[1]?.ex?.availableToBack[0]?.price,1,matchData?.runners[0]?.name,matchData?.runners[0]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[1]?.ex?.availableToBack[0]?.price
                    == undefined ? 0 :
                    matchData?.runners[1]?.ex?.availableToBack[0]?.price}}
                  </a>
                  <a class="btn-lay" side="Lay"
                    (click)="getOddsValue((matchData?.runners[1]?.ex?.availableToLay[0]?.price),matchData?.runners[1]?.ex?.availableToLay[0]?.price,0,matchData?.runners[0]?.name,matchData?.runners[0]?.selectionId,matchData.market_id,i,matchData)">
                    {{matchData?.runners[1]?.ex?.availableToLay[0]?.price
                    == undefined ? 0 :
                    matchData?.runners[1]?.ex?.availableToLay[0]?.price}}</a>
                </dd>
                <dd class="col-info">
                  <a class="add-pin" title="Add to Multi Markets"
                    [ngClass]="{'pin-on':matchData?.fav == true,'pin-off':matchData?.fav != false}"
                    (click)="favourite(matchData.match_id)">Pin</a>
                </dd>
              </dl>

            </div>
          </div>
        </div>



        

<!--New Design start-->
<div id="gameHighlightWrap" class="game-highlight-wrap col3">
  <h3>Highlights </h3>
  <ul class="slip-head">
    <li class="col-game"></li>
    <li class="col-visit">1</li>
    <li class="col-draw">x</li>
    <li class="col-home">2</li>
    <li class="col-info"></li>
  </ul>
  <div class="game-list">
    <div id="eventBoard">
      <dl class="game-list-col">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">ICC Cricket World Cup </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span> 14:00 </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Bangladesh Women <span>v</span> Pakistan Women </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span> 09:00 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">17.5&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">21&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.05&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.06&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">New Zealand <span>v</span> Pakistan </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span> 10:30 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.17&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.18&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">6.4&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">6.8&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Punjab <span>v</span> Delhi </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span> 11:00 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">2.08&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.14&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.88&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.92&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">India T10 <span>v</span> Bangladesh T10 </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span> 12:40 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="streamingIcon" class="game-live">Live</span>
          <span id="sportsBookEIcon_4" class="game-E">
            <i></i>Cricket </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">South Africa T10 <span>v</span> Bangladesh T10 </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span> 12:50 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="streamingIcon" class="game-live">Live</span>
          <span id="sportsBookEIcon_4" class="game-E">
            <i></i>Cricket </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
       
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Western Province <span>v</span> Lions </a>
          <span id="dateTimeInfo" class="game-list-time"> 13:30 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">North West <span>v</span> Warriors </a>
          <span id="dateTimeInfo" class="game-list-time"> 13:30 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-in_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">England <span>v</span> Australia </a>
          <span id="dateTimeInfo" class="game-list-time">
            <span class="in_play">In-Play </span> 14:00 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">2.46&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.48&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.67&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.69&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Islamabad United SRL T20 <span>v</span> Peshawar Zalmi SRL T20 </a>
          <span id="dateTimeInfo" class="game-list-time"> 15:30 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="sportsBookEIcon_4" class="game-E">
            <i></i>Cricket </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Assam <span>v</span> Baroda </a>
          <span id="dateTimeInfo" class="game-list-time"> 16:30 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.92&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.04&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.98&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.1&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Gujarat Titans SRL T20 <span>v</span> Mumbai Indians SRL T20 </a>
          <span id="dateTimeInfo" class="game-list-time"> 18:00 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="sportsBookEIcon_4" class="game-E">
            <i></i>Cricket </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Combined Campuses And Colleges <span>v</span> Leeward Islands H </a>
          <span id="dateTimeInfo" class="game-list-time"> 18:30 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">2.42&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.64&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.61&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.7&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>      
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Jamaica Scorpions <span>v</span> Guyana Harpy Eagles </a>
          <span id="dateTimeInfo" class="game-list-time"> 22:30 </span>
          <span id="streamingIcon" class="game-live">Live</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">2.52&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.98&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.51&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.65&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Karachi Kings SRL T20 <span>v</span> Lahore Qalanders SRL T20 </a>
          <span id="dateTimeInfo" class="game-list-time"> 23:00 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
          <span id="sportsBookEIcon_4" class="game-E">
            <i></i>Cricket </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">--&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">--&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>      
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Sydney Sixers WBBL <span>v</span> Melbourne Renegades WBBL </a>
          <span id="dateTimeInfo" class="game-list-time"> Tomorrow 08:10 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.83&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.06&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.96&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.22&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Lahore Region Whites <span>v</span> Rawalpindi Region </a>
          <span id="dateTimeInfo" class="game-list-time"> Tomorrow 10:00 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.1&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">110&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.1&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">110&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Multan Region <span>v</span> Peshawar Region </a>
          <span id="dateTimeInfo" class="game-list-time"> Tomorrow 10:00 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.1&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">110&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.1&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">110&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Faisalabad Region <span>v</span> Karachi Region Whites </a>
          <span id="dateTimeInfo" class="game-list-time"> Tomorrow 10:00 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.1&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">110&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.1&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">110&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">FA Tribal Areas <span>v</span> Lahore Region Blues </a>
          <span id="dateTimeInfo" class="game-list-time"> Tomorrow 10:00 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.1&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">110&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.1&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">110&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Oman <span>v</span> Nepal </a>
          <span id="dateTimeInfo" class="game-list-time"> Tomorrow 10:45 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.81&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">3.5&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.5&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.72&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Perth Scorchers WBBL <span>v</span> Adelaide Strikers WBBL </a>
          <span id="dateTimeInfo" class="game-list-time"> Tomorrow 11:55 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.82&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.9&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">2.12&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.22&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">India <span>v</span> South Africa </a>
          <span id="dateTimeInfo" class="game-list-time"> Tomorrow 14:00 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.59&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.6&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">2.66&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.7&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">TT Red Force <span>v</span> Barbados Pride </a>
          <span id="dateTimeInfo" class="game-list-time"> Tomorrow 22:30 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.13&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">10.5&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.11&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">8.8&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Wellington Firebirds <span>v</span> Northern Districts </a>
          <span id="dateTimeInfo" class="game-list-time"> 2023-11-06 03:00 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.05&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">100&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.05&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">100&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.05&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">100&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Canterbury Kings <span>v</span> Auckland Aces </a>
          <span id="dateTimeInfo" class="game-list-time"> 2023-11-06 03:00 </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.05&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">100&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.05&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">100&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.05&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">100&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Otago Volts <span>v</span> Central Stags </a>
          <span id="dateTimeInfo" class="game-list-time"> 2023-11-06 03:00 </span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.05&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">100&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.05&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">100&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.05&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">100&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Sydney Thunder WBBL <span>v</span> Brisbane Heat WBBL </a>
          <span id="dateTimeInfo" class="game-list-time"> 2023-11-06 12:40 </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.8&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.52&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.66&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.26&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Bangladesh <span>v</span> Sri Lanka </a>
          <span id="dateTimeInfo" class="game-list-time"> 2023-11-06 14:00 </span>
          <span id="fancyBetIcon" class="game-fancy in-play" style="cursor: pointer; display: inline-flex;">Fancy</span>
          <span id="bookMakerIcon" class="game-bookmaker in-play" style="cursor: pointer; display: inline-flex;">Bookmaker</span>
          <span id="sportsBookIcon_2" class="game-sportsbook" style="display: inline-flex;">Premium Cricket</span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">2.68&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">2.82&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.55&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.59&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">Australia <span>v</span> Afghanistan </a>
          <span id="dateTimeInfo" class="game-list-time"> 2023-11-07 14:00 </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.23&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.26&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">4.8&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">5.4&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
      <dl class="game-list-col disabled">
        <dt id="eventInfo" style="cursor: pointer;">
          <img id="playIcon" src="" class="icon-no_play">
          <span id="lowLiquidityTag" class="game-low_liq" style="display: none;">Low Liquidity</span>
          <a id="vsName" href="#">England <span>v</span> Netherlands </a>
          <span id="dateTimeInfo" class="game-list-time"> 2023-11-08 14:00 </span>
        </dt>
        <dd id="selectTemp" class="col-visit">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">1.1&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">1.11&nbsp;</a>
        </dd>
        <dd class="col-draw">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">&nbsp;</a>
        </dd>
        <dd class="col-home">
          <div class="suspend" style="display: none;">
            <span>Suspend</span>
          </div>
          <a id="btnBack" side="Back" class="btn-back">10.5&nbsp;</a>
          <a id="btnLay" side="Lay" class="btn-lay">11&nbsp;</a>
        </dd>
        <dd class="col-info">
          <a id="" class="add-pin">Pin</a>
        </dd>
      </dl>
    </div>
  </div>
</div>
<!--New Design end-->

        <app-footer></app-footer>
      </div>

    </div>

    <div class="col-right accordion" id="betslipRisgtDiv">
      <div class="slip-wrap accordion-item">
        <h3 id="betSlipHeading" data-bs-toggle="collapse" data-bs-target="#betSlipCollapse" aria-expanded="true"
          aria-controls="betSlipCollapse">
          <a class="to-expand">Bet Slip</a>
        </h3>
        <ul id="loadingMsg" class="loading" style="display: none;">
          <li><img src="assets/sky_img/loading40.gif"></li>
          <li id="countDownTime">Place Bets
          </li>
        </ul>
        <div id="betSlipCollapse" class="accordion-collapse collapse show" data-bs-parent="#betslipRisgtDiv">
          <ul id="loadingMsg" class="loading" style="display: none">
            <li><img src="assets/sky_img/loading40.gif"></li>
            <li id="countDownTime">Place Bets</li>
          </ul>
          <p id="noBetSlipInfo" *ngIf="!betslipStatus">Click on the odds to add selections to the betslip.
          </p>
          <div id="betSlipBoard" class="bet_slip" *ngIf="betslipStatus">
            <ng-container *ngFor="let back of matchModel.tempArray;let index=index">
              <div id="betList" class="slip-list" style="max-height:calc(100% - 86px);">
                <div>
                  <ul id="backSlipHeader" class="slip-head" name="slipHeader" *ngIf="arrayObj?.is_back == 1">
                    <li class="col-bet">Back (Bet For)</li>
                    <li id="oddsHeader" class="col-odd">Odds</li>
                    <li id="runsHeader" class="col-odd" style="display: none">Unit:Runs</li>
                    <li class="col-stake">Stake</li>
                    <li class="col-profit">Profit</li>
                  </ul>
                  <ul id="laySlipHeader" class="slip-head" name="slipHeader" *ngIf="arrayObj?.is_back == 0">
                    <li class="col-bet">Lay (Bet Against)</li>
                    <li id="oddsHeader" class="col-odd">Odds</li>
                    <li id="runsHeader" class="col-odd" style="display: none">Unit:Runs</li>
                    <li class="col-stake">Stake</li>
                    <li class="col-profit">Liability</li>
                  </ul>
                  <div>
                    <h4><img [ngClass]="arrayObj?.inplay ? 'icon-in_play' : 'icon-no_play'"
                        src="/assets/sky_img/transparent.gif">{{matchData.match_name}}</h4>
                    <dl [ngClass]="arrayObj?.is_back == 1 ? 'slip-back ' : 'slip-lay'">
                      <dt>
                        <a id="delete" class="delete">delete</a>
                        <span class="short-amount">{{back?.selection_name}} </span>
                        <span id="marketName"> Match Odds</span>
                      </dt>
                      <dd class="col-odd">
                        <input type="text" class="form-control" *ngIf="back?.isManual=='0'"
                          ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/" min="0" step="0.01" [(ngModel)]="back.odds"
                          id="ShowBetPrice" formcontrolname="odds"
                          class="calProfitLoss odds-input form-control CommanBtn ng-untouched ng-pristine ng-valid"
                          [ngModelOptions]="{standalone: true}" name="quant[2]" (keyup)="choc(back?.odds)"
                          value="{{back?.odds}}" readonly>
                        <ul class="odd-add">
                          <li><a id="oddsUp" class="up">up</a></li>
                          <li><a id="oddsDown" class="down">down</a></li>
                        </ul>
                        <ul id="oddsTipsPoint"></ul>
                      </dd>
                      <dd class="col-stake">
                        <input type="number" class="form-control" min="0" id="stackVal" (focus)="setbtn(index)"
                          (input)="calculateP_lOnStackOnInput(back.stake,back.stake,back.is_back,back);"
                          pattern="[0-9]*" step="1" id="stakeValue" formcontrolname="stake" placeholder="0"
                          class="calProfitLoss stake-input f0orm-control CommanBtn ng-untouched ng-pristine ng-valid"
                          [(ngModel)]="back.stake" [ngModelOptions]="{standalone: true}">
                        <ul id="listPoint"></ul>
                      </dd>
                      <dd id="profitLiability" class="col-profit" *ngIf="arrayObj?.is_back == 1">{{
                        back.is_back == 0 ?
                        back.stake
                        :((back.odds*back.stake)-back.stake)=='NaN'?0:
                        ((back.odds*back.stake)-back.stake)|
                        number :
                        '0.0-2'}}</dd>
                      <dd id="profitLiability" class="col-profit" *ngIf="arrayObj?.is_back == 0">{{back.is_back
                        == 0 ?
                        ((back.odds*back.stake)-back.stake)=='NaN'?0:
                        (((back.odds*back.stake)-back.stake)|
                        number : '0.0-2') :
                        back.stake}}</dd>
                      <dd id="stakePopupList" class="col-stake_list" style="display: block;">
                        <ul>
                          <li *ngFor="let stake of userMatchStack | keyvalue;let k = index;"
                            (click)="calculateP_lOnStack(((back.odds*back.stake)-back.stake),stake.value,back.is_back,back);">
                            <a class="btns">{{stake.value}}</a></li>
                        </ul>
                        <p id="totalStakeP" style="display: none;">Total Stake: <strong id="totalStake"></strong></p>
                      </dd>
                      <dd id="asianHandicapForecast" class="bet-forecast" style="display: none;">
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_1"></span>
                          <span id="winLoss_1"></span>
                        </div>
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_2"></span>
                          <span id="winLoss_2"></span>
                        </div>
                        <div class="bet-forecast-info">
                          <span id="forecastDesc_3"></span>
                          <span id="winLoss_3"></span>
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
              <div id="betSlipFullBtn" class="full_btn">
                <dl class="sum" *ngIf="arrayObj?.is_back == 1">
                  <dt>Liability
                  </dt>
                  <dd>
                    <span id="total"><a [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}">{{back.is_back
                        == 0 ?
                        ((back.odds*back.stake)-back.stake)=='NaN'?0:
                        (((back.odds*back.stake)-back.stake)|
                        number : '0.0-2') :
                        back.stake}}</a></span>
                  </dd>
                </dl>
                <dl class="sum" *ngIf="arrayObj?.is_back == 0">
                  <dt>Profit
                  </dt>
                  <dd>
                    <span id="total"><a [ngClass]="{'red':(bet?.liability)<0,'green':(bet?.liability)>0}">{{
                        back.is_back == 0 ?
                        back.stake
                        :((back.odds*back.stake)-back.stake)=='NaN'?0:
                        ((back.odds*back.stake)-back.stake)|
                        number :
                        '0.0-2'}}</a></span>
                  </dd>
                </dl>

                <ul class="btn-wrap">
                  <li><a id="cancelAll" (click)="this.matchModel.tempArray=[];clearAll();betslipStatus = false;"
                      class="btns" style="cursor:pointer;" tabindex="0">Cancel All
                    </a></li>
                  <li *ngIf="arrayObj?.is_fancy == 0" (click)="saveBet(this.matchModel.tempArray,inplayStatus)"><a
                      id="placeBets" class="btn-send " [ngClass]="{'disable':back.stake <= 0}" style="cursor:pointer;"
                      tabindex="0">Place Bets
                    </a></li>
                  <li *ngIf="arrayObj?.is_fancy == 1" [ngClass]="{'disable':back.stake <= 0}"
                    (click)="openFancyBets(this.matchModel.tempArray);"><a id="placeBets" class="btn-send disable"
                      style="cursor:pointer;" tabindex="0">Place Bets
                    </a></li>
                </ul>

                <ul class="slip-option">
                  <li><input id="comfirmBets" type="checkbox" style="margin-right:5px;"><label for="comfirmBets">Please
                      confirm
                      your bets.
                    </label></li>
                </ul>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <app-right-sidebar></app-right-sidebar>
    </div>
  </div>

  <!-- mobile design -->
  <div class="mian-wrap d-none-desktop">

    <div class="promo-banner">
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" *ngFor="let image of banner;let i = index;" [ngClass]="(i === 0) ? 'active' : ''"
            data-bs-interval="5000">
            <img src="{{image.bannerUrl}}" class="d-block w-100" alt="...">
          </div>
        </div>
      </div>
    </div>
    <div class="highlight-fix">
      <a class="a-search" (click)="openModalSearch(search)">Search</a>
      <div class="wrap-highlight ps ps--theme_default ps--active-x">
        <ul id="pills-tab" role="tablist">
          <li role="presentation" *ngFor="let sports of mobileAllSportsList;let i= index"
            (click)="goToDashboard(sports.name,sports.sport_id,sports)">
            <a class="active" id="pills-inplay-tab{{sports.sport_id}}" data-bs-toggle="pill"
              [class.active]='sportName == sports.name' data-bs-target="#pills-inplay" role="tab"
              aria-controls="pills-inplay" aria-selected="true">
              <span class="tag-live" *ngIf="sports.sport_id > 0"><strong></strong> {{sports?.live}}</span>
              <img src="assets/images/icon/{{sports.sport_id}}-w.svg" class="icon-cricket inactive-img">
              <img src="assets/images/icon/{{sports.sport_id}}-b.svg" class="icon-cricket active-img"> {{sports.name}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="wrap-highlight_list">
      <h3>Highlights</h3>
      <div class="tab-content" id="pills-tabContent">
        <ul class="highlight-list tab-pane fade show active" id="pills-inplay" role="tabpanel"
          aria-labelledby="pills-inplay-tab" tabindex="0">
          <li id="noDatali" style="display: none;">There are no events to be displayed.</li>
          <li *ngFor="let matchData of homeData;let i = index;"
            [ngClass]="{'inplay-on':(matchData?.inplay) == true,'inplay-off':(matchData?.inplay) != true}">
            <a>
              <dl>
                <dt>
                  <span id="streamingIcon" class="game-live" style="display: flex;"
                    *ngIf="matchData.has_tv_url">game-live</span>
                  <span id="fancyBetIcon" class="game-fancy in-play" style="display: flex;"
                    *ngIf="matchData.enable_fancy == 1">
                    <pre>in-play</pre>Fancy
                  </span>
                  <span id="bookMakerIcon" class="game-bookmaker in-play" style="display: flex;"
                    *ngIf="matchData.enable_fancy == 1">
                    <pre>in-play</pre>BookMaker
                  </span>
                  <span id="dateTimeInfo" class="time" *ngIf="matchData.inplay"   [ngClass]="matchData.inplay ? 'green' : ''">In-Play</span>
                  <span class="time" *ngIf="matchData.inplay != true">{{matchData?.match_date | date :'yyyy-MM-dd
                    HH:mm'}}</span>
                </dt>
                <dd id="eventName" (click)="matchDetail(matchData,1)">{{matchData.match_name}}</dd>
              </dl>
            </a>
            <a id="multiMarketPin"
              [ngClass]="{'pin-on':matchData?.fav == true,'pin-off':matchData?.fav != false}"
              (click)="favourite(matchData.match_id)"></a>
          </li>
        </ul>
      </div>
    </div>
    <app-footer></app-footer>
  </div>


</div>

<!-- Search Modal -->
<ng-template #search>
  <div ngbDropdown class="search-top dropdown">
    <form class="d-flex position-relative">
      <span class="search-back ui-link"></span>
      <input ngbDropdownToggle class="form-control" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false" type="text" autocomplete="off" [(ngModel)]="searchSport"
        [ngModelOptions]="{standalone: true}" placeholder="Search Events">
      <span class="search-clear" (click)="searchSport = '';modalRef.hide()"></span>
      <span class="search-collapse"></span>
      <div ngbDropdownMenu class="dropdown-menu search-div" aria-labelledby="dropdownMenuLink">
        <a ngbDropdownItem class="dropdown-item" *ngFor="let sport of homeData |search: searchSport :'match_name'">
          <div class="event-info" (click)="matchDetail(sport,2)">
            <div class="event-team">
              <div class="team-name">{{sport?.match_name}}</div>
            </div>
          </div>
        </a>
      </div>

    </form>
  </div>
</ng-template>