<div class="app-body">
  <div id="loading" class="loading-wrap" style="display: none;">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>
  <app-header></app-header>
  <div class="main_wrap_container acountstatementpage">
    <div class="col-left">
      <div class="sub_path">
        <div class="path">
          <a  class="path-back">...</a>
          <ul>
            <li class="path-last"><a >My Account</a></li>
          </ul>
        </div>
        <ul class="menu-list" data-widget="tree">
          <li>
            <a (click)="info()">
              <span>My Profile</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="rollComission()">
              <span>Rolling Commision</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="statement()">
              <span>Account Statement</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/betHistory','openBets']">
              <span>Bets History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="profitLoss()" class="select">
              <span>Profit & Loss</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="history()">
              <span>Password History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="activity()">
              <span>Activity Log</span><span class="pull-right-container"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div id="centerColumn" class="col-center report">

      <h2>Profit & Loss</h2>
      <ul class="tab col3">
        <li><a [routerLink]="['/betHistory','openBets']">Current Bets</a> </li>
        <li><a [routerLink]="['/betHistory','settledBets']">Bets History</a></li>
        <li><a class="select" [routerLink]="['/profit-loss']">Profit & Loss</a></li>
      </ul>
      <div class="tab-content">
        <div id="filter-tabContent" class="tab-content">
          <div class="tab-pane fade show active" id="filter-fancyBet" role="tabpanel"
            aria-labelledby="filter-fancyBet-tab" tabindex="0">
            <div class="function-wrap">
              <ul class="input-list">
                <li class="time-list">Period</li>
                <li class="time-list">
                  <input type="text" class="cal-input" [popover]="popTemplateStatementStart" placement="bottom"
                    [outsideClick]="true" #popoverRef="bs-popover"
                    value="{{statementStartDate | date:'yyyy-MM-dd HH:mm:ss'}}" (click)="showDate()">
                  to
                  <input type="text" class="cal-input" [popover]="popTemplateStatementEnd" placement="bottom"
                    [outsideClick]="true" #popoverRef="bs-popover"
                    value="{{statementEndDate | date:'yyyy-MM-dd HH:mm:ss'}}" (click)="showDate()">
                </li>
              </ul>
              <ul class="input-list">
                <li>
                  <a class="btn-send" type="button" (click)="eventpl('filterBtnClick')">Get P & L</a>
                </li>
              </ul>
            </div>
            <div class="report-responsive">
              <table class="row-border hover table01" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"></table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer-main class="d-lg-none"></app-footer-main>
</div>


<!-- Date Time Picker -->
<ng-template #popTemplateStatementStart>
  <datepicker [hidden]="!isDateVisible" [(ngModel)]="dateStatement" [ngModelOptions]="{standalone: true}"
    [minDate]="minDate" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateDateStatement()"
    (selectionDone)="dateSelectionDone()">
  </datepicker>
  <timepicker [hidden]="!isDateVisible" [(ngModel)]="timeStatement" [ngModelOptions]="{standalone: true}"
    [min]="minTime" [max]="maxTime" (ngModelChange)="updateTimeStatement()" [showMeridian]="isMeridian"
    showSeconds="true">
  </timepicker>

  <div style="padding:5px">
    <span class="btn-group pull-left" style="margin-right:10px;">
      <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
      <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
    </span>
    <span class="btn-group pull-right">
      <button type=button class="btn btn-sm btn-success" (click)="close()">Close</button>
    </span> <span class=clearfix></span>
  </div>
</ng-template>
<ng-template #popTemplateStatementEnd>
  <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDateStatement" [ngModelOptions]="{standalone: true}"
    [minDate]="dateStatement" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateEndDateStatement()"
    (selectionDone)="dateSelectionDone()">
  </datepicker>
  <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTimeStatement" [ngModelOptions]="{standalone: true}"
    [min]="minTime" [max]="maxTime" (ngModelChange)="updateEndTimeStatement()" [showMeridian]="isMeridian"
    showSeconds="true">
  </timepicker>

  <div style="padding:5px">
    <span class="btn-group pull-left" style="margin-right:10px;">
      <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
      <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
    </span>
    <span class="btn-group pull-right">
      <button type=button class="btn btn-sm btn-success" (click)="closeEndDatepicker()">Close</button>
    </span> <span class=clearfix></span>
  </div>
</ng-template>