<!-- sky design -->
<div class="loginModal">
    <div class="modal-content">
        <div class="lgoin-main">
            <div class="login-head">
                <a class="close ui-link" [routerLink]="['/openHome/']"></a>
                <h1><img src="{{logo}}" alt=""></h1>
                <div class="betfair-wrap">
                    <p>Powered by</p>
                    <span>
                    </span>
                </div>
            </div>
            <form action="" [formGroup]='Form' class="form-login">
                <dl class="">
                    <dd>
                        <input formControlName="user_name" placeholder="UserName" required="" type="text">
                    </dd>
                    <dd>
                        <input formControlName="password" placeholder="Password" required="" type="password">
                    </dd>
                    <dd>
                        <input type="text" id="validCode" pattern="[0-9]*" placeholder="Validation Code"
                            autocomplete="off" [(ngModel)]="tPassword" [ngModelOptions]="{standalone: true}">
                        <span class="code">{{transactionPassword}}</span>
                    </dd>
                    <dd *ngIf="showResponseMessage"><span class="error">{{showResponseMessage}}</span></dd>
                    <dd>
                        <a class="btn-send ui-link" (click)="onSubmitLogin()">Login</a>
                    </dd>
                </dl>
            </form>

            <ul class="policy-link">
                <li><a>Privacy Policy</a></li>
                <li><a>Terms and Conditions</a></li>
                <li><a>Rules and Regulations</a></li>
                <li><a>KYC</a></li>
                <li><a>Responsible Gaming</a></li>
                <li><a>About Us</a></li>
                <li><a>Self-exclusion Policy</a></li>
                <li><a>Underage Policy</a></li>
            </ul>
        </div>
    </div>
</div>