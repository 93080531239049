<div class="app-body footer-condition footer-hide-desk">
  <!-- Loading Wrap -->
  <div id="loading" class="loading-wrap d-none-mobile" *ngIf="loading == true">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>
  
  <div class="loading-overlay d-none-desktop" id="loading" *ngIf="loading == true">
    <div class="loading-wrap">
      <div class="loading">
        <div></div>
        <div></div>
      </div>
      <p>Loading...
      </p>
    </div>
  </div>
  <app-open-header></app-open-header>
  <div class="full-wrap">
    <div class="col-left">
      <app-open-sidebar></app-open-sidebar>
    </div>

    <div id="centerColumn" class="col-center">
      <!-- Message -->
      <div id="message" class="message-wrap success">
        <a class="btn-close">Close</a>
        <p></p>
      </div>

      <div class="over-wrap">
        <div id="multiMarketsWrap">
          <div class="no-data">
            <h3>Multi Markets</h3>
            <p>There are currently no followed multi markets.</p>
          </div>
        </div>
      </div>
    </div>

  </div>

  <app-open-footer></app-open-footer>
</div>