import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DateTime } from '../../dateTime';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ReportService } from '../services/report.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-bet-history',
  templateUrl: './bet-history.component.html',
  styleUrls: ['./bet-history.component.scss']
})
export class BetHistoryComponent implements AfterViewInit, OnDestroy, OnInit {
  public Base_Url = environment['adminServerUrl'];
  moment: any = moment;
  @ViewChild('popoverRef') private _popoverRef: PopoverDirective;
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  timeStatement: Date;
  dateStatement: Date;
  endDateStatement: Date;
  endTimeStatement: Date;
  endTime: any;
  fromDate: any;
  toDate: any;
  time: Date;
  date: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  dateTime = new Date();
  statementStartDate = new Date()
  statementEndDate = new Date()
  betType: any = "unsettle";
  matchType: any = "unsettle";
  SportList: any;
  sportData: any;
  searchSport: any;
  itemsPerPage: number = 30;
  currentPage: number = 1;
  totalItems: number = 0;
  settleBetFilter: boolean = false;
  settledBetData: any = [];
  public token = localStorage.getItem('userAccessToken');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  reportstype: {};
  constructor(private http: HttpClient, private reportService: ReportService, private toastr: ToastrService,
    private activatedRoute: ActivatedRoute, private cdRef: ChangeDetectorRef, private router: Router) { }

  statement() {
    this.router.navigate(['statement'])
  }
  profitLoss() {
    this.router.navigate(['profit-loss'])
  }
  info() {
    this.router.navigate(['info'])
  }
  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  rollComission() {
    this.router.navigate(['rollComission'])
  }
  ngOnInit(): void {
    this.statementStartDate.setHours(0, 0, 0, 0);
    this.statementEndDate.setHours(23, 59, 59);
    this.activatedRoute.params.subscribe(params => {
      if (params.type == undefined) {
        this.betType = {};
        this.matchType = {};
        this.getSportsList();
        this.cdRef.detectChanges();
      } else {
        this.betType = params.type;
        this.matchType = params.type;;
        this.getSportsList();
        this.cdRef.detectChanges();
      }

    })

    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 30,
      searching: false,
      serverSide: true,
      processing: true,
      ajax: (dataTablesParameters1: any, callback) => {
        let dataTablesParameters = {
          "from_date": this.statementStartDate.toISOString(),
          "to_date": this.statementEndDate.toISOString(),
          page: (dataTablesParameters1.start / dataTablesParameters1.length) + 1,
          limit: dataTablesParameters1.length,
          type: this.matchType == "settledBets" ? 'settled' : 'unsettle',
          "search": {
            "sport_id": this.sportData
          },
        }
        that.http.post<DataTablesResponse>(this.Base_Url + 'bet/userSettledBetList', dataTablesParameters, { headers: this.reqHeader })
          .subscribe(resp => {
            if (resp["status"]) {
              if (resp.data[0].metadata.length != 0) {
                this.settledBetData = resp.data[0].data;
                this.settleBetFilter = false;
                for (let i = 0; i < this.settledBetData.length; i++) {
                  this.settledBetData[i].updateDateTime = moment(this.settledBetData[i].createdAt).utcOffset("+5:30").format('DD-MM-YYYY HH:mm:ss');
                  this.settledBetData[i].updateType = this.settledBetData[i].is_back == 1 ? 'Back' : 'Lay';
                  if (this.settledBetData[i].is_fancy == 0) {
                    this.settledBetData[i].updateOdds = this.settledBetData[i].odds;
                  } else {
                    this.settledBetData[i].updateOdds = this.settledBetData[i].odds + '/' + this.settledBetData[i].size;
                  }
                }
                this.totalItems = resp.data[0].metadata[0].total
                this.currentPage = resp.data[0].metadata[0].page
                callback({
                  recordsTotal: resp.data[0].metadata[0].total,
                  recordsFiltered: resp.data[0].metadata[0].total,
                  data: this.settledBetData
                });
              } else {
                this.settledBetData = []
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: this.settledBetData
                });
              }
            } else {
              this.toastr.error(resp["msg"]);
              this.settledBetData = []
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: this.settledBetData
              });
            }


          });
      },
      columns: [
        {
          title: 'Sport Name',
          data: 'sport_name'
        },
        {
          title: 'Event Name',
          data: 'match_name'
        },
        {
          title: 'Market Name',
          data: 'market_name'
        },
        {
          title: 'Selection',
          data: 'selection_name'
        },
        {
          title: 'Type',
          data: 'updateType',
          "render": function (data, type, row) {
            var content = ''
            if (row.updateType == "Lay") {
              content = content + '<span class="text-center text-danger" style="font-weight : 600 ">' + row.updateType + '</span>';
            } else {
              content = content + '<span class="text-center text-success" style="font-weight : 600 ">' + row.updateType + '</span>';
            }
            return content;
          }
        },
        {
          title: 'Odds Req.',
          data: 'updateOdds'
        },
        {
          title: 'Stake',
          data: 'stack',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span  style="font-weight : 600 ">' + row.stack + '</span>';

            return content;
          }
        },
        {
          title: 'Placed Time',
          data: 'updateDateTime'
        },
      ],
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        $(row).addClass('text-center');
        return row;
      }
    };
  }
  dateSelectionDone() {
    this.isDateVisible = false;
  }
  updateDateStatement() {
    if (this.dateStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
    if (!this.timeStatement) {
      this.timeStatement = this.dateStatement;
    }
  }
  updateEndDateStatement() {
    if (this.endDateStatement) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
    if (!this.endTimeStatement) {
      this.endTimeStatement = this.endDateStatement;
    }
  }
  updateTimeStatement() {
    if (this.timeStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
  }
  updateEndTimeStatement() {
    if (this.endTime) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
  }
  updateDate() {

    if (this.date) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
    if (!this.time) {
      this.time = this.date;
    }
  }

  updateTime() {
    if (this.time) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
  }

  showDate() {
    this.isDateVisible = true;
  }

  showTime() {
    this.isDateVisible = false;
  }

  close() {
    this._popoverRef.hide();
  }

  now() {
    this.dateTime = DateTime.now(this.date);
    this.time = this.dateTime;
  }

  today() {
    this.statementStartDate = DateTime.now(this.date);
    this.statementEndDate = DateTime.now(this.date);
  }
  clear() {
    this.time = void 0;
    this.date = void 0;
    this.dateTime = void 0;
  }

  selectMatchType() {
    this.matchType = this.betType
    this.getSportsList();
    this.getHistory();
  }
  getSportsList() {
    let data = {
      type: this.matchType == "unsettle" ? 'openBets' : 'settledBets',
      search: {}
    };
    this.reportService.eventList(data).subscribe((res) => {
      if (res.status == true) {
        this.SportList = res.data[0].sports;
      } else {
        this.toastr.error(res.msg);
      }
    })
  }
  getFilterData(data) {
    this.sportData = data;
    this.rerender();
  }

  getHistory(from?: string) {
    this.rerender();
  }
  pageChange(event?: any) {
    if (event) {
      this.currentPage = event
    } else {
      this.currentPage = 1
    }
    this.rerender()
  }

  settledBets(from?: string) {
    let data = {
      page: this.currentPage,
      limit: this.itemsPerPage,
      "search": {
        "sport_id": this.sportData
      }
    }
    if (from == 'filterBtnClick') {
      if (this.statementStartDate) {
        data["from_date"] = this.statementStartDate.toISOString()
      }
      if (this.statementEndDate) {
        data["to_date"] = this.statementEndDate.toISOString()
      }
    }
    if (data.search.sport_id == undefined) {
      delete data.search.sport_id;
    }
    this.rerender();
  }
  openBets(from?: string) {
    let data = {
      page: this.currentPage,
      limit: this.itemsPerPage,
      "search": {
        "sport_id": this.sportData
      },
    }
    if (data.search.sport_id == undefined) {
      delete data.search.sport_id;
    }
    if (from == 'filterBtnClick') {
      if (this.statementStartDate) {
        data["from_date"] = this.statementStartDate.toISOString()
      }
      if (this.statementEndDate) {
        data["to_date"] = this.statementEndDate.toISOString()
      }
    }
    this.rerender();


  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
