    <div class="sub_path d-none d-lg-block"> 
      <div class="col" class="path sidebartitle">
        <!-- <i class="fa fa-ellipsis-v" aria-hidden="true"></i> -->
        <span class="">Sports</span>
      </div>
      <ul class="menu-list" data-widget="tree" style="cursor:pointer">
        <li class="allSportsList">
          <a href="#">
            <span>All Sports</span>
            
          </a>
        </li>
      </ul>
      <div *ngIf="sportList == true">
        <div class="col" (click)="getAllSportsListData()" class="path sidebartitle text-right">
          <!-- <i class="fa fa-ellipsis-v" aria-hidden="true"></i> -->
          <span class="">Sports</span>
        </div>
    
        <ul class="menu-list menusectiontwo" data-widget="tree" style="cursor:pointer">
          <li class="treeview" *ngFor="let sports of allSportsList;let i= index">
            <a (click)="sports.sport_id <= -100 ? goToCasino(sports) : getSeriesList(sports.sport_id)">
              <span>{{sports.name}}</span>
              
            </a>
          </li>
        </ul>
      </div>
      <div *ngIf="sportList == false">
        <div class="top-head-sport">
          <div class="row" (click)="getAllSportsListData()" class="path sidebartitle">
            <!-- <i class="fa fa-ellipsis-v" aria-hidden="true"></i> -->
            <span class="">Sports</span>
          </div>
        </div>
        <ul class="menu-list" data-widget="tree" style="cursor:pointer">
          <li class="treeview" *ngFor="let series of allSeriesList;let j= index" (click)="getMatchList(series,j)">
            <a>
              <span>{{series.name}}</span>
              <i class="fa fa-angle-right"></i>
            </a>
            <ul class="menu-list" [ngClass]="{'display-list': isShowMenu[j]}">
              <li *ngFor="let match of matchList[j];let k= index" (click)="matchDetail(match)"><a class="sub-menu-item">{{match.match_name}} </a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

    