import { Component, OnChanges, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { OpenHeaderComponent } from '../open-header/open-header.component';
import { OpenSidebarComponent } from '../open-sidebar/open-sidebar.component';
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OpenApiService } from '../services/open-api.service';
import { LoginService } from '../services/login.service';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-open-dashboard',
  templateUrl: './open-dashboard.component.html',
  styleUrls: ['./open-dashboard.component.scss'],
  providers: [OpenHeaderComponent, OpenSidebarComponent],
})
export class OpenDashboardComponent implements OnInit {
  Form: FormGroup;
  public Base_Url = environment['adminServerUrl'];
  loginButtonDisable = false
  submitted = false;
  transactionPassword: string;
  showResponseMessage;
  public showPassword: boolean;
  private clickTimeout = null;
  a: any;
  modalRef: BsModalRef;
  allSportsList: any;
  userData: any;
  parameter: any;
  firstData: any;
  firstDataLength: any;
  updateFirstData: any = [];
  homeData: any;
  dataLength: any;
  loading: boolean;
  logo: string;
  sportName: any;
  banner: any;
  selectedBackBet: any
  selectedLayBet: any
  selectedLayBetDetails: any;
  selectedBackBetDetails: any;
  betType: any;
  sportsList: any;
  b: any;
  c: any;
  constructor(private open: OpenApiService, private cdRef: ChangeDetectorRef, private route: ActivatedRoute, private fb: FormBuilder,
    private router: Router, private toastr: ToastrService, private modalService: BsModalService, private loginService: LoginService,) {
    this.getBanner();
    this.route.params.subscribe(params => {
      if (params.sportName == undefined) {
        this.userData = {};
        this.parameter = 'Cricket';
        this.sportName = 'Cricket';
        this.homematches();
      } else {
        this.parameter = params.sportName;
        this.sportName = params.sportName;
        this.homematches();
      }

    })
  }

  ngOnInit(): void {
    this.logo = localStorage.getItem('logo')
    this.createFrom()
  }
  getAllSportsListData() {
    let userData = {};
    this.open.getAllSportsList(userData).subscribe(res => {
      this.sportsList = res.data;
      let matches = (this.homeData).filter((t: any) => t.inplay == true || t.manualInplay == true);
      matches = matches.reduce((groupSport: { [x: string]: any; }, sports: { sport_name: any; }) => {
        const { sport_name } = sports;
        groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
        return groupSport;
      }, {});
      this.c = matches
      for (let i = 0; i < this.sportsList.length; i++) {
        this.sportsList[i].live = 0
        for (let j = 0; j < Object.keys(this.c).length; j++) {
          if (this.sportsList[i].name == Object.keys(this.c)[j]) {
            this.b = Object.values(this.c)[j];
            this.sportsList[i].live = this.b.length
          }
        }
      }
      this.allSportsList = this.sportsList
      this.allSportsList = (this.allSportsList).sort(function (a, b) { return b.sport_id - a.sport_id });
      this.cdRef.detectChanges();
    })
  }

  homematches() {
    this.userData = {
    }
    this.open.getHomeMatchesList(this.userData).subscribe(res => {
      if (res.status) {
        this.firstData = res.data;
        this.firstDataLength = this.firstData.length;
        for (let i = 0; i < this.firstDataLength; i++) {
          this.firstData[i].manualInplay = this.firstData[i].inplay
        }
        let matches;
        if (this.parameter == undefined || this.parameter == 'All') {
          matches = this.firstData;
          this.dataLength = matches.length;
        } else if (this.parameter == 'inplay') {
          matches = this.firstData.filter(t => t.inplay == true || t.manualInplay == true);
          this.dataLength = matches.length;
        } else {
          matches = this.firstData.filter(t => t.sport_name == this.parameter);
          this.dataLength = matches.length;
        }
        matches = matches.sort((a: any, b: any) =>
          new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
        );
        this.homeData = matches;
        this.getAllSportsListData();
      }
    }, (err) => {
      console.log(err);
    })
  }
  goToDashboard(sportName, id, data) {
    if (id == -100) {
      this.router.navigate(['login']);
    } else if (id < -100) {
      this.router.navigate(['snova/' + id + '/' + data.name + '/' + data.providerCode])
    } else if (id > -100) {
      this.router.navigate(['openDashboard/' + sportName]);
      this.homematches();
    }
  }
  matchDetail(matchData, type) {
    if (type == 2) {
      this.modalRef.hide();
    }
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['openDetail']);
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe: [false]
    })
    if (JSON.parse(localStorage.getItem('RememberMe'))) {
      this.Form.patchValue({
        user_name: localStorage.getItem('user_name'),
        password: localStorage.getItem('password'),
        isRememberMe: localStorage.getItem('RememberMe')
      })
    }
  }
  get f() { return this.Form.controls; }
  async onSubmitLogin() {
    this.loading = true;
    this.loginButtonDisable = true
    if (this.clickTimeout) {
      this.setClickTimeout(() => { });
    } else {
      this.setClickTimeout(() =>
        this.handleSingleLoginClick());
    }
  }
  public handleSingleLoginClick() {
    //The actual action that should be performed on click      
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loading = false;
      this.loginButtonDisable = false
      return;
    }

    this.loginService.submitlogin(this.Form.value).subscribe((result) => {
      if (result.status == true) {
        this.loading = false;
        this.modalRef.hide();
        this.toastr.success(result.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        })
        this.setCokkies(result);
        localStorage.setItem('userDetails', JSON.stringify(result.data));
        localStorage.setItem('operator', JSON.stringify(result.operatorId));
        this.loginButtonDisable = false
        this.router.navigate(['dashboard'])
      } else {
        this.loading = false;
        this.showResponseMessage = result.msg;
        this.loginButtonDisable = false;
      }
    }, (err) => {
      console.log(err);
      this.loading = false;
      this.loginButtonDisable = false;
    });
  }
  public setClickTimeout(callback) {
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async redirectToDashboard() {
    // var acctoken = await this.cookie.get('accessToken');
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }
  async setCokkies(result) {
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    var acctoken = localStorage.getItem('userAccessToken');
    this.router.navigate(['dashboard'])
  }
  openModalLoginCnfm(loginCnfm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginCnfm,
      Object.assign({}, { class: 'loginCnfm-modal gamblingModal', ignoreBackdropClick: true })
    );
  }
  openModalLoginPopup(loginPopup: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginPopup,
      Object.assign({}, { class: 'loginModal modal-dialog-centered modal-fullscreen-lg-down', ignoreBackdropClick: true })
    );
  }
  openModalSearch(search: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      search,
      Object.assign({}, { class: 'searchModal', ignoreBackdropClick: true })
    );
  }
  getBanner() {
    this.open.getBannerImage().subscribe((res) => {
      if (res.status == true) {
        let a = res.data;
        if (a.length != 0) {
          for (let i = 0; i < a.length; i++) {
            if((a[i]).hasOwnProperty('self_host')){
             if((a[i].self_host == true)){
               a[i].bannerUrl = this.Base_Url + a[i].description;
             } else {
               a[i].bannerUrl =  a[i].description;
             }
            } else {
             a[i].bannerUrl = this.Base_Url + a[i].description;
            }
          }
          this.banner = a;
          localStorage.setItem('Banner', JSON.stringify(this.banner))
        }
      }
    })
  }

  openModalLoginDesktop(loginDesktop: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginDesktop,
      Object.assign({}, { class: 'loginModalDesktop loginModal modal-dialog-centered modal-fullscreen-lg-down', ignoreBackdropClick: true })
    );
  }

  goToLogin() {
    this.router.navigate(['login']);
  }
}
