<div class="app-body">
  <app-header></app-header>
  <div class="main_wrap_container acountstatementpage commisionpage">
    <div class="col-left">
      <div class="sub_path">
        <div class="path">
          <a class="path-back">...</a>
          <ul>
            <li class="path-last"><a >Account Info</a></li>
          </ul>
        </div>
        <ul class="menu-list" data-widget="tree">
          <li>
            <a (click)="info()">
              <span>My Profile</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="rollComission()" class="select">
              <span>Rolling Commision</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="statement()">
              <span>Account Statement</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/betHistory','openBets']">
              <span>Bets History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="profitLoss()">
              <span>Profit & Loss</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="history()">
              <span>Password History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="activity()">
              <span>Activity Log</span><span class="pull-right-container"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div id="centerColumn" class="col-center report">

      <h2>Rolling Commission</h2>

      <div class="function-wrap">
        <ul class="input-list">
          <li class="time-list">Period</li>
          <li class="time-list">
            <input type="text" class="cal-input" [popover]="popTemplateStatementStart" placement="bottom"
              [outsideClick]="true" #popoverRef="bs-popover" value="{{statementStartDate | date:'yyyy-MM-dd HH:mm:ss'}}"
              (click)="showDate()">
            to
            <input type="text" class="cal-input" [popover]="popTemplateStatementEnd" placement="bottom"
              [outsideClick]="true" #popoverRef="bs-popover" value="{{statementEndDate | date:'yyyy-MM-dd HH:mm:ss'}}"
              (click)="showDate()">
          </li>
          <li>
            <ng-select [ngModelOptions]="{standalone: true}" [(ngModel)]="searchSport"
              (change)="getFilterData(searchSport.sport_id)">
              <ng-option *ngFor="let sport of SportList" [value]="sport">{{sport.sport_name}}</ng-option>
            </ng-select>
          </li>
          <li></li>
        </ul>
        <ul class="input-list">
          <li><a class="btn-send" (click)="rollCommission('filterBtnClick')">Get Commission</a></li>
        </ul>
      </div>
      <div class="report-responsive">
        <table class="table01" datatable>
          <thead>
            <tr class="text-center">
              <th>Type</th>
              <th>Total Stack</th>
              <th>Total Commission</th>
            </tr>
          </thead>
          <tbody *ngIf="rollCom?.length != 0">
            <tr *ngFor="let data of rollCom;let i = index;" style="text-align: center;">
              <td>{{data?.event_name}}</td>
              <td> {{data.stack > '0' ? (data.stack | number :'0.0-2') : '0'}}</td>
              <td>{{data.user_commission_pl < '0' ? (data.user_commission_pl | number :'0.0-2') : '0' }}</td>
            </tr>
          </tbody>
          <tbody *ngIf="rollCom?.length == 0">
            <tr>
              <td class="no-data-available" colspan="3">No data!</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <app-footer-main></app-footer-main>
</div>


<!-- Date Time Picker -->
<ng-template #popTemplateStatementStart>
  <datepicker [hidden]="!isDateVisible" [(ngModel)]="dateStatement" [ngModelOptions]="{standalone: true}"
    [minDate]="minDate" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateDateStatement()"
    (selectionDone)="dateSelectionDone()">
  </datepicker>
  <timepicker [hidden]="!isDateVisible" [(ngModel)]="timeStatement" [ngModelOptions]="{standalone: true}"
    [min]="minTime" [max]="maxTime" (ngModelChange)="updateTimeStatement()" [showMeridian]="isMeridian"
    showSeconds="true">
  </timepicker>

  <div style="padding:5px">
    <span class="btn-group pull-left" style="margin-right:10px;">
      <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
      <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
    </span>
    <span class="btn-group pull-right">
      <button type=button class="btn btn-sm btn-success" (click)="close()">Close</button>
    </span> <span class=clearfix></span>
  </div>
</ng-template>
<ng-template #popTemplateStatementEnd>
  <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDateStatement" [ngModelOptions]="{standalone: true}"
    [minDate]="dateStatement" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateEndDateStatement()"
    (selectionDone)="dateSelectionDone()">
  </datepicker>
  <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTimeStatement" [ngModelOptions]="{standalone: true}"
    [min]="minTime" [max]="maxTime" (ngModelChange)="updateEndTimeStatement()" [showMeridian]="isMeridian"
    showSeconds="true">
  </timepicker>

  <div style="padding:5px">
    <span class="btn-group pull-left" style="margin-right:10px;">
      <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
      <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
    </span>
    <span class="btn-group pull-right">
      <button type=button class="btn btn-sm btn-success" (click)="closeEndDatepicker()">Close</button>
    </span> <span class=clearfix></span>
  </div>
</ng-template>