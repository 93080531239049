import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-multi-market',
  templateUrl: './open-multi-market.component.html',
  styleUrls: ['./open-multi-market.component.scss']
})
export class OpenMultiMarketComponent implements OnInit {
  loading:boolean=false;
  constructor() { }

  ngOnInit(): void {
  }

}
