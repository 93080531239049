<div class="main-wrapper">
    <app-header></app-header>

    <div class="col-center gamehall">
        <div class="over-wrap">
            <div class="promo-banner-wrap active">
                <div class="promo-banner">
                    <ul class="slides">
                        <li class="banner">
                            <a><img src="assets/sky_img/kv-skyexchang-skyfair-m.jpg" alt=""></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="gamehall-wrap-simple">
                <a (click)="dashboard()">
                    <!-- <dl class="on_live">
                        <dt>
                            <p class="live_icon"><span></span> Live</p>
                        </dt>
                        <dd>
                            <p>Cricket</p> <span id="count">3</span>
                        </dd>
                        <dd>
                            <p>Soccer</p> <span id="count">3</span>
                        </dd>
                        <dd>
                            <p>Tennis</p> <span id="count">3</span>
                        </dd>
                        <dd>
                            <p>E-Soccer</p> <span id="count">3</span>
                        </dd>
                        <dd>
                            <p>Kabaddi</p> <span id="count">3</span>
                        </dd>
                    </dl> -->
                    <dl class="entrance-title">
                        <dt>Sports</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_sports.png" alt="">
                </a>

                <a (click)="error()">
                    <dl class="entrance-title">
                        <dt>Kabaddi</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_kabaddi.png" alt="">
                </a>
                <!-- <a class="entrance">
                    <img src="assets/sky_img/banner_skyexchangeBlog.png" alt="">
                </a> -->
                <a (click)="error()">
                    <dl class="entrance-title">
                        <dt>Virtual Cricket</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_virtualsports.png" alt="">
                </a>
                <a (click)="casino()">
                    <dl class="entrance-title">
                        <dt>Live Casino</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_casino.png" alt="">
                </a>
                <a class="entrance-half" *ngFor="let game of gameList;" (click)="casino()">
                    <dl class="entrance-title">
                        <dt>{{game?.gameName}}</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/Casino/{{game?.gameId}}.jpg" alt="">
                </a>
                <!-- <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Supernowa</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_supernowa-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>7mojos</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_7mojos-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>HORSEBOOK</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_horsebook-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Minesweeper</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_minesweeper-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Sky Trader</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_skytrader-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>TeenPatti 20-20</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_TeenPatti2020-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>NumberKing</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_NumberKing-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Big small</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_BigSmall-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>TeenPatti Joker</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_TeenPattiJoker-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>7up7down</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_7up7down-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Dragon & Tiger</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_DragonNTiger-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Callbreak Quick</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_CallbreakQuick-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Sic Bo</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_SicBo-Jili-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Baccarat</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_Baccarat-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Bonus Dice</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_BonusDice-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Heist</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_Heist-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>5 Card Poker</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_5CardPoker-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Color Game</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_ColorGame-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>32 Cards</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_32card-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Rummy</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_rummy-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>BetGames</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_betgames-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Andar Bahar</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_andarBahar-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Sicbo</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_sicbo-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>7 UP 7 Down</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_sevenUpDown-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Coin Toss</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_CoinToss-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Teen Patti</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_teenPatti-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Card Matka</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_cardMatka-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Number Matka</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_numberMatka-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Binary</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_binary-half.png" alt="">
              </a>
              <a  class="entrance-half">
                  <dl class="entrance-title">
                      <dt>Bpoker</dt>
                      <dd>Play Now</dd>
                  </dl>
                  <img src="assets/sky_img/banner_bpoker-half.png" alt="">
              </a> -->
            </div>
        </div>
    </div>
    <app-footer></app-footer>
    <app-footer-main></app-footer-main>
</div>

<!-- Search Modal -->
<ng-template #search>
    <div ngbDropdown class="search-top dropdown">
        <form class="d-flex position-relative">
            <span class="float-icon search-icon" style="color:#000 !important"><i class="bi bi-search"></i></span>
            <input ngbDropdownToggle class="form-control" id="dropdownMenuLink" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false" type="text" autocomplete="off" [(ngModel)]="searchSport"
                [ngModelOptions]="{standalone: true}">
            <span class="float-icon clearall-icon" (click)="searchSport = '';modalRef.hide()"><i
                    class="bi bi-x-lg"></i></span>
            <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <a ngbDropdownItem class="dropdown-item"
                    *ngFor="let sport of homeData |search: searchSport :'match_name'">
                    <div class="event-info" (click)="matchDetailDrop(sport)">
                        <div class="event-team">
                            <div class="team-name">{{sport?.match_name}}</div>
                        </div>
                    </div>
                </a>
            </div>

        </form>
    </div>
</ng-template>