import { Component, OnInit, ElementRef, TemplateRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { HeaderComponent } from '../header/header.component';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { Router, ActivatedRoute } from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SportService } from '../services/sport.service';
import * as moment from 'moment';
import { resolve } from 'q';
import { ToastrService } from 'ngx-toastr';
import { SocketServiceService } from '../services/socket-service.service';
import { LoginService } from '../services/login.service'
import { CookieService } from 'ngx-cookie-service';
import { ReportService } from '../services/report.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Match } from '../model/match';
import { UsersService } from '../services/users.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [HeaderComponent, SidebarComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  moment: any = moment;
  homeData: any;
  public timer: any;
  allSportsList: any;
  modalRef: BsModalRef;
  userData: any;
  firstData: any;
  parameter: any;
  a: any = [];
  runnerData: any;
  combineData: any;
  Data: any;
  finalData: any;
  isSocket;
  runnerDataLength: any;
  firstDataLength: any;
  updateRunnerData: any = [];
  dataLength: any;
  marketId: any = [];
  callType = 1;
  marketData: any = [];
  socketData: any;
  updateFirstData: any = [];
  adminHeading: string;
  allData: any;
  adminData: any;
  mobileAllSportsList: any;
  sportName: any;
  banner: any;
  selectedBackBet: any
  selectedLayBet: any
  selectedLayBetDetails: any;
  selectedBackBetDetails: any;
  betType: any;


  activeBetTab: string = 'all';
  activeScoreTab: string = 'scoreBoard';
  activeScoreDiv: string = 'scoreBoardDiv';
  fancytimer: any;
  fancy: any = [];
  matchDetailLength: number;
  betslipinfo: boolean;
  betPrice: any;
  arrayObj: any = [];
  public IsToggle = false;
  private oddBetSlipValArray: any = [];
  public oneClickSelectedBet;
  stakeIds: any;
  stackval: any;
  isActive: boolean;
  total_liability: any;
  config_max_odd_limit: any;
  setRef: any;
  userDetails: any;
  userMatchStack: any;
  user_id: any;
  betData: any;
  matchedBets: any;
  fancyBets: any;
  liveTv: string;
  graphicTv: string;
  liveUrl: SafeResourceUrl;
  graphicTvUrl: SafeResourceUrl;
  match_id: any;
  matchData: any;
  matchName: any;
  matchDate: any;
  tv: boolean = false;
  graph: boolean = false;
  scoreData: any;
  scoreBoard: any;
  scoreLength: any;
  score: boolean;
  perball: any;
  callingFancyType = 1
  selection: any;
  market: any;
  matchLength: any;
  inplayStatus: boolean;
  teamPositionData: any;
  selectedAllBetFilter: any;
  selectedPopAllBetFilter: any;
  selectedFancyBetFilter: any;
  selectedMatchBetFilter: any
  allBetData: any
  graphicScore: boolean;
  allPopBetData: any;
  selectedMarket: any;
  showFancyList: boolean;
  matchedBetsLength: any;
  fancyBetsLength: any;
  allBetDataLength: any;
  disablePlaceBet: boolean = false;
  disableFancyPlaceBet: boolean = false;
  loading: boolean = false;
  fancyLoading: boolean;
  fancyPosData: any;
  displayfancyPositionBox: Array<boolean> = []
  selectedFancyMarket: any;
  applyUserValidation: boolean;
  fancyLiability: any;
  twenty: boolean;
  param: any;
  betSize: any;
  marketIds: any = [];
  marketRunnerData: any;
  matchDetailFirst: any;
  runnerObjectData: any = [];
  objectData: any;
  marketObjectData: any;
  sampleObjectData: any;
  sportsSettingValues: any;
  showSetting: boolean;
  sessionSetting: any;
  fancySetting: boolean;
  check_event_limit: any;
  fancyIdValidation: any;
  fancyLength: any;
  betDataPopup: boolean;
  message: string;
  fancyDataArray = [];
  fancyArray: any;
  fancyRunnerData: any;
  primaryFancy: any;
  ring: boolean = false;
  ringOn: boolean = false;
  walletBalance: any;
  currentExpo: number;
  currentBalance: number;
  detailCalling = 1;
  fancyId: any = [];
  fancyDta: any;
  createFancyType: number = 1;
  fancyTimer: any;
  pageSize: number;
  searchMatch: any;
  cuurency: string;
  popFancyId: any;
  popShow: boolean;
  showBet: any = 0;
  tvId: any = 0;
  exposuerLimit: any;
  marketLiability: any;
  fancyExpoLiability: any;
  marketCurrentData: any;
  marketPL: number;
  marketLiabilityCondition: boolean;
  currentFancyLiability: any;
  fancySource: any;
  totalMaxLoss: number;
  volumeLimit: boolean = false;
  minVolLimit: boolean = false;
  betslipStatus: boolean;
  sportsList: any;
  b: any;
  homeDataCount: any;
  userDetail: any;
  constructor(private loginService: LoginService, private modalService: BsModalService, private reportService: ReportService, private cdRef: ChangeDetectorRef, private cookie: CookieService, private socketService: SocketServiceService, private route: ActivatedRoute,
    private head: HeaderComponent, private router: Router, private sportService: SportService, private toastr: ToastrService, public matchModel: Match, private usersService: UsersService,) {
    this.socketStatus();
    this.socketService.onSuccess.subscribe(res => {
      this.socketSuccess(res);
    })
  }
  socketStatus() {
    this.sportService.getSocketStatus().subscribe((res) => {
      this.isSocket = res.is_socket;
      this.socketService.setUpSocketConnection();
      this.socketListenersUser();
      this.socketError();
      this.route.params.subscribe(params => {
        if (params.sportName == undefined) {
          this.userData = {};
          this.parameter = 'Cricket';
          this.sportName = 'Cricket';
          this.homematches();
          this.cdRef.detectChanges();
        } else {
          this.parameter = params.sportName;
          this.sportName = params.sportName;
          this.homematches();
          this.cdRef.detectChanges();
        }

      })
    })
  }
  async ngOnInit() {
    this.banner = JSON.parse(localStorage.getItem('Banner'))
    this.userDetail = JSON.parse(localStorage.getItem('userDetails'))
    let a = window.screen.width;
    window.scrollTo(0, 0)
    this.getNews();
    this.getUserBalance();
    this.getUserMatchStackDetails();
  }
  socketOnEvent(eventName, callback) {
    this.socketService.socket.on(eventName, data => callback(data));
  }

  socketEmitEvent(eventName, data) {
    this.socketService.socket.emit(eventName, data);
  }
  socketError() {
    this.socketService.socket.on(`error`, (res) => {
      if (res.status == false) {
        this.toastr.error(res.msg)
      }
    })
  }
  socketSuccess(res) {
    if (res.status == true) {
      if (res.event_code == "subscribe_event") {
        this.marketData = [];
        for (let i = 0; i < this.marketId.length; i++) {
          this.socketOnEvent(this.marketId[i], result => {
            if (result.status == true) {
              this.marketData = result.data;
              this.runnerDataLength = this.marketData.length;
              this.runnerData = this.marketData;
              for (let i = 0; i < this.firstData.length; i++) {
                if (this.firstData[i].marketId == this.runnerData.marketId) {
                  this.firstData[i].runners = this.runnerData.runners;
                  this.firstData[i].status = this.runnerData.status;
                  this.firstData[i].inplay = this.runnerData.inplay;
                  this.firstData[i].totalMatched = this.runnerData.totalMatched;
                  this.socketData = this.firstData[i]
                  break
                }
              }
              for (let i = 0; i < this.homeData.length; i++) {
                if (this.homeData[i].marketId == this.socketData.marketId) {
                  this.homeData[i].runners = this.socketData.runners;
                  this.homeData[i].status = this.socketData.status;
                  this.homeData[i].inplay = this.socketData.inplay;
                  this.homeData[i].totalMatched = this.socketData.totalMatched;
                }
              }
              this.cdRef.detectChanges();
            }
          })
        }
      } else {
        this.toastr.success(res.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        })
      }
    }
  }
  getRunnerEmit() {
    this.marketId = []
    for (let i = 0; i < this.homeData.length; i++) {
      this.marketId.push(this.homeData[i].marketId)
    }
    let event = {
      "eventIds": this.marketId
    }
    this.socketEmitEvent('subscribe_event', event);
  }

  marketAdded() {
    this.userData = {};
    this.socketEmitEvent('new_market_added', this.userData);
  }
  ngOnDestroy() {
    this.socketService.socket.close();
  }
  socketListenersUser() {
    this.socketOnEvent(`homeMatches`, res => {
      this.firstData = res.data;
      this.firstDataLength = this.firstData.length
      for (let i = 0; i < this.firstData.length; i++) {
        this.marketId.push(this.firstData[i].marketId)
      }
      this.getRunnerEmit();
    });

    this.socketOnEvent(`subscribe_event`, res => {
    });
    this.socketOnEvent(`unsubscribe_event`, res => {
    });
    this.socketOnEvent(`new_market_added`, res => {
      if (res.status == true) {
        this.toastr.success(res.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        })
        this.homematches();
      }
    });
    this.socketOnEvent(`socket_state_changed`, res => {
      this.isSocket = res.is_socket
      this.route.params.subscribe(params => {
        if (params.sportName == undefined) {
          this.userData = {};
          this.homematches();
        } else {
          this.parameter = params.sportName;
          this.homematches();
        }
      })
      if (this.isSocket == 0) {
        this.marketId = [];
        for (let i = 0; i < this.firstData.length; i++) {
          this.marketId.push(this.firstData[i].marketId)
        }
        let event = {
          "eventIds": this.marketId
        }
        this.socketEmitEvent('unsubscribe_event', event);
      } else {
        clearTimeout(this.timer);
      }
    });
  }

  getHomeMatchCallEvent(param) {
    this.userData = {};
    this.socketEmitEvent('homeMatches', this.userData);
  }
  homematches() {
    this.sportService.getHomeMatchesList(this.userData).subscribe(res => {
      if (res.status) {
        this.firstData = res.data;
        this.firstDataLength = this.firstData.length;
        for (let i = 0; i < this.firstDataLength; i++) {
          this.firstData[i].manualInplay = this.firstData[i].inplay;
          if ((this.firstData[i].my_favorites).length != 0) {
            for (let j = 0; j < (this.firstData[i].my_favorites).length; j++) {
              let isFounded = (this.firstData[i].my_favorites).some(ai => ai === this.userDetail._id);
              if (isFounded == true) {
                this.firstData[i].fav = true;
              } else {
                this.firstData[i].fav = false
              }
            }
          } else {
            this.firstData[i].fav = false
          }
          this.updateRunnerData[i] = {
            "marketId": this.firstData[i].marketId,
            "status": "SUSPENDED",
            "inplay": false,
            "runners": [
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              },
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              }
            ]
          }
        }
        const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }));
        this.homeData = a3;
        if (this.firstData.length != 0) {
          this.runners();
        }
        this.cdRef.detectChanges();


      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
        if (res.logout == true) {
          this.head.logoutUser();
        }
      }
    }, (err) => {
      console.log(err);
    },
      () => {
        if (this.router.url.split('?')[0] == '/dashboard') {
          if (this.isSocket != 1) {
            this.timer = setTimeout(() => resolve(this.homematches()), 10000);
          } else {
            clearTimeout(this.timer);
          }
        }
      })
  }
  runners() {
    this.userData = {};
    this.sportService.runnerList(this.userData).subscribe((res) => {
      if (res.status) {
        this.runnerDataLength = res.data.length;
        this.runnerData = res.data;
        for (let i = 0; i < this.firstDataLength; i++) {
          for (let j = 0; j < this.runnerDataLength; j++) {
            if (this.firstData[i].marketId == this.runnerData[j].marketId) {
              this.updateRunnerData[i] = []
              this.updateRunnerData[i] = (this.runnerData[j]);
              break
            } else {
              this.updateRunnerData[i] = {
                "marketId": this.firstData[i].marketId,
                "status": "SUSPENDED",
                "inplay": false,
                "totalMatched": 0,
                "runners": [
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  },
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  }
                ]
              }
            }
          }
        }
        const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }))
        this.a = a3;
        let matches;
        if (this.parameter == undefined || this.parameter == 'All') {
          matches = this.a;
          this.dataLength = matches.length;
        } else if (this.parameter == 'inplay') {
          matches = this.a.filter(t => t.inplay == true || t.manualInplay == true);
          this.dataLength = matches.length;
        } else {
          matches = this.a.filter(t => t.sport_name == this.parameter);
          this.dataLength = matches.length;
        }
        matches = matches.sort((a: any, b: any) =>
          new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
        );
        let c = matches.filter((t: any) => t.inplay == true || t.manualInplay == true);
        c = c.reduce((groupSport: { [x: string]: any; }, sports: { sport_name: any; }) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});
        this.homeDataCount = c;
        this.homeData = matches;
        this.cdRef.detectChanges();
        let b = window.screen.width;
      if( b < 767){
        this.getAllSportsList();
      }
      this.marketId = []
        this.getRunnerEmit();
        this.a = [];
      } else {
        for (let i = 0; i < this.firstDataLength; i++) {
          this.updateRunnerData[i] = {
            "marketId": this.firstData[i].marketId,
            "status": "SUSPENDED",
            "inplay": false,
            "totalMatched": 0,
            "runners": [
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              },
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              }
            ]
          }
        }
        const a3 = this.firstData.map(t1 => ({ ...t1, ...this.updateRunnerData.find(t2 => t2.marketId === t1.marketId) }));
        this.a = a3;
        let matches;
        if (this.parameter == undefined || this.parameter == 'All') {
          matches = this.a;
          this.dataLength = matches.length;
        } else if (this.parameter == 'inplay') {
          matches = this.a.filter(t => t.inplay == true || t.manualInplay == true);
          this.dataLength = matches.length;
        } else {
          matches = this.a.filter(t => t.sport_name == this.parameter);
          this.dataLength = matches.length;
        }
        matches = matches.sort((a: any, b: any) =>
          new Date(a.match_date).getTime() - new Date(b.match_date).getTime()
        );
        let c = matches.filter((t: any) => t.inplay == true || t.manualInplay == true);
        c = c.reduce((groupSport: { [x: string]: any; }, sports: { sport_name: any; }) => {
          const { sport_name } = sports;
          groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
          return groupSport;
        }, {});
        this.homeDataCount = c;
        this.homeData = matches;
        this.cdRef.detectChanges();
        let b = window.screen.width;
        if( b < 767){
          this.getAllSportsList();
        }
        this.marketId = []
        this.getRunnerEmit();
        this.a = [];
      }
    }, (err) => {
      console.log(err);
    })
  }
  scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 250), behavior: 'smooth' });
  }

  scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 250), behavior: 'smooth' });
  }
  getAllSportsList() {
    let userData = {};
    this.sportService.getAllSportsList(userData).subscribe(res => {
      this.sportsList = res.data;
      for (let i = 0; i < this.sportsList.length; i++) {
        this.sportsList[i].live = 0

        for (let j = 0; j < Object.keys(this.homeDataCount).length; j++) {
          if (this.sportsList[i].name == Object.keys(this.homeDataCount)[j]) {
            this.b = Object.values(this.homeDataCount)[j];
            this.sportsList[i].live = this.b.length
          }
        }
      }
      this.mobileAllSportsList = this.sportsList
      this.mobileAllSportsList = (this.mobileAllSportsList).sort(function (a, b) { return b.sport_id - a.sport_id });
      this.cdRef.detectChanges()
    })
  }
  goToInplay(sportName) {
    this.router.navigate(['dashboard/' + sportName]);
  }

  goToDashboard(sportName, id, data) {
    if (id == -100) {
      this.router.navigate(['casino']);
    }else if (id == 'QT'){
      this.router.navigate(['qtech'])
    } else if (id < -100) {
      this.router.navigate(['snova/' + id + '/' + data.name + '/' + data.providerCode])
    } else if (id > -100) {
      this.router.navigate(['dashboard/' + sportName]);
    }
  }

  matchDetail(matchData,type) {
    if(type == 2){
      this.modalRef.hide();
    }
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['match-detail']);
  }
  goToAll(sportName) {
    this.router.navigate(['dashboard/' + sportName]);
  }

  getNews() {
    let data = {};
    this.reportService.getNews(data).subscribe((res) => {
      if (res.status) {
        if (res.data == null) {
          this.adminHeading = '';
        } else {
          this.allData = res.data;
          this.adminData = this.allData.filter(t => t.user_type_id == 0);
          if (this.adminData.length != 0) {
            this.adminHeading = this.adminData[0].heading;
          }
        }
      }
    })
  }

  favourite(id) {
    let data = {
      match_id: id
    };
    this.sportService.favourite(data).subscribe((res) => {
      if (res.status == true) {
        this.toastr.success(res.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        });
        this.homematches();
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
  openModalSearch(search: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      search,
      Object.assign({}, { class: 'searchModal', ignoreBackdropClick: true })
    );
  }

  //betSlip relates code 

  marketSettingApi(sport_id, match_id) {
    let data = {
      "sport_id": sport_id,
      "match_id": match_id,
    };
    this.sportService.marketSetting(data).subscribe((res) => {
      if (res.status == true) {
        this.exposuerLimit = res.exposure_limit;
        this.check_event_limit = res.check_event_limit
        if (this.check_event_limit == true) {
          this.sportsSettingValues = res.data;
          this.sessionSetting = res.data.session;
          this.fancySetting = true;
        } else {
          // this.sessionSetting = res.data.session;
          this.sportsSettingValues = res.data.market;
          this.sessionSetting = res.data.session;
          this.fancySetting = false;
        }
        this.showSetting = true;
      } else {
        this.applyUserValidation = false;
        this.showSetting = false;
      }

    })
  }

  applyValidation() {
    let data = {};
    this.sportService.applyValidation(data).subscribe((res) => {
      if (res.status == true) {
        this.applyUserValidation = res.applyValidation;
      } else {
        this.applyUserValidation = false;
      }
    })
  }

  // Calculation on Bet

  getOddsValue(price, size, isback, name, id, marketid, index, marketitem) {
    this.matchData = marketitem;
    this.marketCurrentData = marketitem;
    this.ring = false;
    this.betslipinfo = true;
    this.betslipStatus = true;
    this.betPrice = price;
    this.selection = id;
    this.market = marketid;
    this.arrayObj = {
      selection_id: id,
      market_id: marketid,
      size: size,
      odds: price,
      stake: 0,
      is_back: isback,
      is_fancy: 0,
      MarketName: marketitem.name,
      placeName: name,
      isManual: 0,
      is_session_fancy: 'N',
      inplay: marketitem.inplay,
    };
    // localStorage.setItem('type',type);
    localStorage.setItem('betList', JSON.stringify(this.arrayObj));
    this.matchModel.setUser(this.arrayObj);
    this.getBetSlipDataForOdds();
  }

  getBetSlipDataForOdds() {

    this.matchModel.tempArray = [];
    let oddBetSlipVal = JSON.parse(localStorage.getItem('betList'));
    this.oddBetSlipValArray.push(oddBetSlipVal);
    this.matchModel.tempArray.push(oddBetSlipVal);
    if (this.IsToggle) {
      this.matchModel.tempArray[0].stake = this.oneClickSelectedBet;
      this.matchModel.tempArray[0].p_l = ((this.matchModel.tempArray[0].odds * this.matchModel.tempArray[0].stake) - this.matchModel.tempArray[0].stake);
      //  this.matchModel.loading1 = true;
      // this.Place_Order(this.matchModel.tempArray);
    }
  }

  calculateP_lOnStackOnInput(stake, stkbtn, isback, back) {

    if (stake <= 0) {
      back.stake = 0;
    } else {
      this.stakeIds = this.matchModel.stakeIds;
      this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
      back.stake = parseFloat(stkbtn);

      if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
        this.isActive = true;
        back.p_l = ((back.odds * back.stake) - back.stake);
        if (isback == 0) {
          this.total_liability = back.p_l;
        } else {
          this.total_liability = back.stake;

        }
        this.matchModel.calculateProfitLoss(back);
        if (back.priceVal <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
          if (back.priceVal > 0) {
            const tempback = back;
            this.isActive = true;
            this.total_liability = 0;
            if (back.isManual) {
              const pval = back.pricefinal + 1;
              back.p_l = ((pval * back.stake) - back.stake);
            } else {
              back.p_l = ((back.priceVal * back.stake) - back.stake);
            }
            this.matchModel.ProfitLoss = back.p_l;


          }

        }
      } else {
        let msg = '';
        if (back.is_session_fancy == 'Y') {
          msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
        } else {
          msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
        }
        back.stake = parseFloat(back.max_bet_liability);
        this.isActive = false;
      }
    }

  }
  calculateP_lOnStack(stake, stkbtn, isback, back) {

    this.stakeIds = this.matchModel.stakeIds;
    this.stackval = (back.stake == '' || back.stake == null) ? 0 : back.stake;
    back.stake = parseFloat(stkbtn) + parseFloat(this.stackval);
    if (parseFloat(back.stake) <= parseFloat(back.max_bet_liability) || true) {
      this.isActive = true;
      back.p_l = ((back.odds * back.stake) - back.stake);
      if (isback == 0) {
        this.total_liability = back.p_l;
      } else {
        this.total_liability = back.stake;
      }
      this.matchModel.calculateProfitLoss(back);
      if (back.odds <= this.config_max_odd_limit || back.is_session_fancy == 'Y' || true) {
        if (back.odds > 0) {
          const tempback = back;
          this.isActive = true;
          this.total_liability = 0;
          if (back.isManual) {
            const pval = back.pricefinal + 1;
            back.p_l = ((pval * back.stake) - back.stake);
          } else {
            back.p_l = ((back.odds * back.stake) - back.stake);
          }
          this.matchModel.ProfitLoss = back.p_l;


        }
      }
    } else {
      let msg = '';
      if (back.is_session_fancy == 'Y') {
        msg = 'Max session bet liability is ' + parseFloat(back.max_bet_liability);
      } else {
        msg = 'Max market bet liability is ' + parseFloat(back.max_bet_liability);
      }
      back.stake = parseFloat(back.max_bet_liability);
      this.isActive = false;
    }
  }

  getUserMatchStackDetails() {
    this.sportService.getUserMatchStack(this.userDetails._id).subscribe(response => {
      if (response.status == true) {
        this.userMatchStack = response.data.match_stack;
      } else {
        this.toastr.error(response.msg);
      }

    })
  }

  clearAll = function () {
    this.betslipinfo = false;
    this.betslipStatus = false;
    this.IsShowBetInfo = false;
    this.total_liability = 0;
    this.isActive = false;
    for (let i = 0; i < this.matchModel.stakeIds.length; i++) {
      this.matchModel.stake2['field_' + this.matchModel.stakeIds[i].UId] = 0;
    }
  };

  saveBet(betValue, status) {
    let a = this.marketCurrentData.runners;
    if (betValue[0].is_back == 1) {
      this.marketLiability = betValue[0].stake;
      this.marketPL = (betValue[0].odds - 1) * betValue[0].stake
    } else {
      this.marketPL = betValue[0].stake;
      this.marketLiability = (betValue[0].odds - 1) * betValue[0].stake
    }
    this.loading = true
    if (this.applyUserValidation == true) {
      this.validateStakeValue(betValue[0].stake, betValue[0])
      this.validateStakeAmountwithOldBets(betValue[0].stake)
    } else {
      this.disablePlaceBet = false;
    }
    if (betValue[0].MarketName == "Match Odds") {
      let a;
      if (this.sportsSettingValues[betValue[0].market_id].volume_stake_enable) {
        betValue[0].size = (betValue[0].size).toString()
        if ((betValue[0].size).endsWith("K")) {
          betValue[0].size = parseFloat((betValue[0].size).replace('K', '')) * 1000;
        } else if ((betValue[0].size).endsWith("M")) {
          betValue[0].size = parseFloat((betValue[0].size).replace('M', '')) * 1000000;
        } else {
          betValue[0].size = parseFloat(betValue[0].size)
        }
        if (status == true) {
          if (this.sportsSettingValues[betValue[0].market_id].min_volume_limit != undefined && this.sportsSettingValues[betValue[0].market_id].min_volume_limit != 0) {
            if (betValue[0].size > this.sportsSettingValues[betValue[0].market_id].min_volume_limit) {
              this.minVolLimit = true;
            } else {
              this.minVolLimit = false;
              this.toastr.error("Volume limit is low. Can't accept bet.")
            }
          } else {
            this.minVolLimit = true;
          }
          if (betValue[0].size > 0 && betValue[0].size <= 10000) {
            if (this.sportsSettingValues[betValue[0].market_id].inplay_max_volume_stake_0_10 != undefined && this.sportsSettingValues[betValue[0].market_id].inplay_max_volume_stake_0_10 != 0) {
              if (betValue[0].stake <= this.sportsSettingValues[betValue[0].market_id].inplay_max_volume_stake_0_10) {
                this.volumeLimit = true;
              } else {
                this.toastr.error("Volume limit is over.")
                this.betslipStatus = false
                this.loading = false
              }
            } else {
              this.volumeLimit = true;
            }

          } else if (betValue[0].size > 10000 && betValue[0].size <= 40000) {
            if (this.sportsSettingValues[betValue[0].market_id].inplay_max_volume_stake_10_40 != undefined && this.sportsSettingValues[betValue[0].market_id].inplay_max_volume_stake_10_40 != 0) {
              if (betValue[0].stake <= this.sportsSettingValues[betValue[0].market_id].inplay_max_volume_stake_10_40) {
                this.volumeLimit = true;
              } else {
                this.toastr.error("Volume limit is over.")
                this.betslipStatus = false
                this.loading = false
              }
            } else {
              this.volumeLimit = true;
            }
          } else if (betValue[0].size > 40000) {
            if (this.sportsSettingValues[betValue[0].market_id].inplay_max_volume_stake_40 != undefined && this.sportsSettingValues[betValue[0].market_id].inplay_max_volume_stake_40 != 0) {
              if (betValue[0].stake <= this.sportsSettingValues[betValue[0].market_id].inplay_max_volume_stake_40) {
                this.volumeLimit = true;
              } else {
                this.toastr.error("Volume limit is over.")
                this.betslipStatus = false
                this.loading = false
              }
            } else {
              this.volumeLimit = true;
            }
          }
        } else {
          if (this.sportsSettingValues[betValue[0].market_id].min_volume_limit != undefined && this.sportsSettingValues[betValue[0].market_id].min_volume_limit != 0) {
            if (betValue[0].size > this.sportsSettingValues[betValue[0].market_id].min_volume_limit) {
              this.minVolLimit = true;
            } else {
              this.minVolLimit = false;
              this.toastr.error("Volume limit is low. Can't accept bet.")
            }
          } else {
            this.minVolLimit = true;
          }

          if (betValue[0].size > 0 && betValue[0].size <= 10000) {
            if (this.sportsSettingValues[betValue[0].market_id].max_volume_stake_0_10 != undefined && this.sportsSettingValues[betValue[0].market_id].max_volume_stake_0_10 != 0) {
              if (betValue[0].stake <= this.sportsSettingValues[betValue[0].market_id].max_volume_stake_0_10) {
                this.volumeLimit = true;
              } else {
                this.toastr.error("Volume limit is over.")
                this.betslipStatus = false
                this.loading = false
              }
            } else {
              this.volumeLimit = true;
            }
          } else if (betValue[0].size > 10000 && betValue[0].size <= 40000) {
            if (this.sportsSettingValues[betValue[0].market_id].max_volume_stake_10_40 != undefined && this.sportsSettingValues[betValue[0].market_id].max_volume_stake_10_40 != 0) {
              if (betValue[0].stake <= this.sportsSettingValues[betValue[0].market_id].max_volume_stake_10_40) {
                this.volumeLimit = true;
              } else {
                this.toastr.error("Volume limit is over.")
                this.betslipStatus = false
                this.loading = false
              }
            } else {
              this.volumeLimit = true;
            }
          } else if (betValue[0].size > 40000) {
            if (this.sportsSettingValues[betValue[0].market_id].max_volume_stake_40 != undefined && this.sportsSettingValues[betValue[0].market_id].max_volume_stake_40 != 0) {
              if (betValue[0].stake <= this.sportsSettingValues[betValue[0].market_id].max_volume_stake_40) {
                this.volumeLimit = true;
              } else {
                this.toastr.error("Volume limit is over.")
                this.betslipStatus = false
                this.loading = false
              }
            } else {
              this.volumeLimit = true;
            }
          }
        }
      } else {
        this.volumeLimit = true;
      }
    } else {
      this.volumeLimit = true;
      this.minVolLimit = true;
    }
    if (!this.disablePlaceBet && this.volumeLimit && this.minVolLimit) {
      if (this.betPrice == betValue[0].odds) {
        let betData = {
          'market_id': betValue[0].market_id,
          'selection_id': betValue[0].selection_id,
          'odds': betValue[0].odds,
          'stack': betValue[0].stake,
          'is_back': JSON.parse(betValue[0].is_back),
        };
        this.sportService.saveBet(betData).subscribe((data) => {
          if (data.status == false) {
            this.loading = false
            this.matchModel.tempArray = [];
            this.toastr.error(data.msg, '', {
              timeOut: 10000,
            })
            this.betslipinfo = false;
            this.betslipStatus = false
          } else {
            this.toastr.success(data.msg, '', {
              positionClass: 'toast-bottom-right',
              timeOut: 1000
            });
            this.loading = false
            if (this.ringOn == true) {
              this.ring = true;
            }
            this.matchModel.tempArray = [];
            this.betslipStatus = false;
            let params = JSON.parse(localStorage.getItem('matchData'))
          }
        }, (err) => {
        });
      } else {
        this.betslipStatus = false
        this.loading = false
        this.toastr.error('Bet Price is changed , Please try again', '', {
          timeOut: 10000,
        });
      }
    } else {
      this.disablePlaceBet = false;
      this.betslipinfo = false;
      this.betslipStatus = false;
      this.loading = false
    }
  }

  validateStakeValue(stake, back) {
    this.toastr.clear();
    this.disablePlaceBet = false;
    if (this.sportsSettingValues != undefined) {
      if (this.check_event_limit == true) {
        if (!this.matchData.inplay) {
          if (stake > this.sportsSettingValues[this.market].market_advance_bet_stake) {
            this.disablePlaceBet = true
            this.loading = false
            this.toastr.error('Before inplay stake amount limit is exceded ' + this.sportsSettingValues[this.market].market_advance_bet_stake, '', {
              timeOut: 10000,
            });
          }
        }
        // let profit = back.is_back == 0 ? back.stake : ((back.odds * back.stake) - back.stake) == NaN ? 0 : ((back.odds * back.stake) - back.stake).toFixed(2)
        // if (profit > this.sportsSettingValues[this.market].market_max_profit) {
        //   this.disablePlaceBet = true
        //   this.loading =false
        //   this.toastr.error('Profit value (' + this.sportsSettingValues[this.market].market_max_profit + ') limit is exceded', '', {
        //     timeOut: 10000,
        //   });
        // }
        if (this.sportsSettingValues[this.market].market_min_odds_rate > back.odds || back.odds > this.sportsSettingValues[this.market].market_max_odds_rate) {
          this.disablePlaceBet = true
          this.loading = false
          this.toastr.error('Odd Rate value is not in min and max range(' + this.sportsSettingValues[this.market].market_min_odds_rate + '-' + this.sportsSettingValues[this.market].market_max_odds_rate + ')', '', {
            timeOut: 10000,
          });
        }
        if (this.sportsSettingValues[this.market].market_min_stack > stake || stake > this.sportsSettingValues[this.market].market_max_stack) {
          this.disablePlaceBet = true
          this.loading = false
          this.toastr.error('Stake value is not in min and max range(' + this.sportsSettingValues[this.market].market_min_stack + '-' + this.sportsSettingValues[this.market].market_max_stack + '), please enter valid stake value', '', {
            timeOut: 10000,
          });
        }
      } else {
        if (!this.matchData.inplay) {
          if (stake > this.sportsSettingValues.market_advance_bet_stake) {
            this.disablePlaceBet = true
            this.loading = false
            this.toastr.error('Before inplay stake amount limit is exceded ' + this.sportsSettingValues.market_advance_bet_stake, '', {
              timeOut: 10000,
            });
          }
        }
        // let profit = back.is_back == 0 ? back.stake : ((back.odds * back.stake) - back.stake) == NaN ? 0 : ((back.odds * back.stake) - back.stake).toFixed(2)
        // if (profit > this.sportsSettingValues[this.market].market_max_profit) {
        //   this.disablePlaceBet = true
        //   this.loading =false
        //   this.toastr.error('Profit value (' + this.sportsSettingValues[this.market].market_max_profit + ') limit is exceded', '', {
        //     timeOut: 10000,
        //   });
        // }
        if (this.sportsSettingValues.market_min_odds_rate > back.odds || back.odds > this.sportsSettingValues.market_max_odds_rate) {
          this.disablePlaceBet = true
          this.loading = false
          this.toastr.error('Odd Rate value is not in min and max range(' + this.sportsSettingValues.market_min_odds_rate + '-' + this.sportsSettingValues.market_max_odds_rate + ')', '', {
            timeOut: 10000,
          });
        }
        if (this.sportsSettingValues.market_min_stack > stake || stake > this.sportsSettingValues.market_max_stack) {
          this.disablePlaceBet = true
          this.loading = false
          this.toastr.error('Stake value is not in min and max range(' + this.sportsSettingValues.market_min_stack + '-' + this.sportsSettingValues.market_max_stack + '), please enter valid stake value', '', {
            timeOut: 10000,
          });
        }
      }
    } else {
      this.disablePlaceBet = false;
    }

  }

  validateStakeAmountwithOldBets(stakeAmount) {
    if (!this.matchData.inplay) {
      if (this.sportsSettingValues != undefined) {
        if (this.check_event_limit != true) {
          if (this.betData !== undefined) {
            let existingBets = this.betData.filter(t => t.market_id == this.market && t.delete_status != 2);
            let sumofStake = 0
            if (existingBets.length != 0) {
              existingBets.forEach(element => {
                sumofStake = element.stack + sumofStake
              });
            }
            sumofStake = sumofStake + stakeAmount
            if (sumofStake > this.sportsSettingValues.market_advance_bet_stake) {
              this.disablePlaceBet = true
              this.loading = false
              this.toastr.error('Market Advance bet stake amount limit is exceded ' + this.sportsSettingValues.market_advance_bet_stake, '', {
                timeOut: 10000,
              });
            }
          }
        } else {
          if (this.betData !== undefined) {
            let existingBets = this.betData.filter(t => t.market_id == this.market && t.delete_status != 2);
            let sumofStake = 0
            if (existingBets.length != 0) {
              existingBets.forEach(element => {
                sumofStake = element.stack + sumofStake
              });
            }
            sumofStake = sumofStake + stakeAmount
            if (sumofStake > this.sportsSettingValues[this.market].market_advance_bet_stake) {
              this.disablePlaceBet = true
              this.loading = false
              this.toastr.error('Market Advance bet stake amount limit is exceded ' + this.sportsSettingValues[this.market].market_advance_bet_stake, '', {
                timeOut: 10000,
              });
            }
          }
        }
      } else {
        this.disableFancyPlaceBet = false;
        this.disablePlaceBet = false;
      }
    } else {
      this.disablePlaceBet = false
    }
  }

  getUserBalance() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    let data = {};
    this.usersService.getUserBalance(data).subscribe(response => {
      this.usersService.updateUserBalanceSubject(response.data)
      this.walletBalance = response.data;
      this.currentExpo = ((this.walletBalance.liability)) * (this.userDetails.point);
      this.currentBalance = (this.walletBalance.balance) * (this.userDetails.point)
      // if(this.currentExpo <= 0 && this.currentBalance >= 100){

      // } else if (this.currentExpo < -100 && this.currentBalance >= 0) {

      // } else if (this.currentExpo == 0 && this.currentBalance >= 100){

      // } else {
      //   let  message = "Your main Balance is low.Plz refill your account..!";
      //  Swal.fire({
      //   title: 'Balance',
      //   text: message,
      //   icon: 'warning',
      //   showCancelButton: false,
      //   confirmButtonText: 'Yes',
      //   allowOutsideClick: false,
      //   allowEscapeKey: false,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     this.cookie.delete('userId');
      //     this.cookie.delete('accessToken');
      //     this.cookie.delete('refreshToken');
      //     this.loginService.clearLocalStorage()
      //     this.router.navigate(['openDashboard']);
      //     window.location.reload();
      //     window.location.replace('openDashboard');
      //   }
      // })

      // }
    }, (err) => {
    },
      () => {
        if (this.router.url.split('?')[0] == '/match-detail') {
          // this.timer = setTimeout(() => resolve(this.getUserBalance()), 5000);
        }
      })
  }
}
