<div class="app-body">
    <div id="loading" class="loading-wrap" style="display: none;">
      <ul class="loading">
        <li><img src="assets/sky_img/loading40.gif"></li>
        <li>Loading...
        </li>
        <br>
        <li><span id="progress"></span></li>
      </ul>
    </div>
    <app-header></app-header>
    <div class="main_wrap_container depostipage">
      <div class="col-left">
        <!-- <app-sidebar></app-sidebar> -->
        <div class="sub_path">
          <div class="path">
            <a class="path-back">...</a>
            <ul>
              <li class="path-last"><a >My Account</a></li>
            </ul>
          </div>
          <ul class="menu-list" data-widget="tree">
            <li>
              <a (click)="info()">
                <span>My Profile</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a (click)="rollComission()">
                <span>Rolling Commision</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a (click)="statement()" class="select">
                <span>Account Statement</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a [routerLink]="['/betHistory','openBets']">
                <span>Bets History</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a (click)="profitLoss()">
                <span>Profit & Loss</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a (click)="history()">
                <span>Password History</span><span class="pull-right-container"></span>
              </a>
            </li>
            <li>
              <a (click)="activity()">
                <span>Activity Log</span><span class="pull-right-container"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
  
      <div id="centerColumn" class="col-center report">
        <h2>Deposite </h2>
        <div class="card-body container-fluid container-fluid-5">
            <div class="payment-method">
              <form novalidate="" class="ng-untouched ng-pristine ng-invalid">
                <div class="bank-transfer">
                  <div class="select-transfer">
                    <a *ngFor="let list of payMethodList;let i = index;" (click)="detail(list)"
                      [class.active]='list?.category == selectData'>
                      <img alt="" class="responsive-img" src="{{Base_Url}}bank_logo/{{list?.image}}">
                    </a>
                  </div>
                  <div class="pay-details">
                    <div class="title"> Make your payment on the details below </div>
                    <div class="phone-pay-details">
                      <table *ngIf="payLength != 0">
                        <tbody *ngFor="let detail of payDetails; let i = index;">
                          <ng-container *ngIf="detail?.payment == false">
                            <tr id="payment0" *ngIf="detail?.method_name == 'Bank'">
                              <td class="title">Bank Name</td>
                              <td class="sub-title right-align">{{detail?.bank_name}}</td>
                            </tr>
                            <tr id="payment0" *ngIf="detail?.method_name == 'Bank'">
                              <td class="title">Account Holder Name </td>
                              <td class="sub-title right-align"> <span style="cursor: pointer;" ngxClipboard
                                  [cbContent]="'Content to be copied.'" (cbOnSuccess)="contentCopied($event)"
                                  (click)="copyContent(detail.bank_holder_name)">{{detail?.bank_holder_name}} &nbsp;<i
                                    class="fa fa-file" aria-hidden="true"></i></span></td>
                            </tr>
                            <tr id="payment0" *ngIf="detail?.method_name == 'Bank'">
                              <td class="title">Account Number </td>
                              <td class="sub-title right-align"><span style="cursor: pointer;" ngxClipboard
                                  [cbContent]="'Content to be copied.'" (cbOnSuccess)="contentCopied($event)"
                                  (click)="copyContent(detail.account_no)">{{detail?.account_no}} &nbsp;<i class="fa fa-file"
                                    aria-hidden="true"></i></span></td>
                            </tr>
                            <tr id="payment0" *ngIf="detail?.method_name == 'Bank'">
                              <td class="title">IFSC Code </td>
                              <td class="sub-title right-align"><span style="cursor: pointer;" ngxClipboard
                                  [cbContent]="'Content to be copied.'" (cbOnSuccess)="contentCopied($event)"
                                  (click)="copyContent(detail.ifsc_code)">{{detail?.ifsc_code}} &nbsp;<i class="fa fa-file"
                                    aria-hidden="true"></i></span></td>
                            </tr>
                            <tr id="payment0" *ngIf="detail?.method_name != 'Bank'">
                              <td class="title">Name </td>
                              <td class="sub-title right-align"><span style="cursor: pointer;" ngxClipboard
                                  [cbContent]="'Content to be copied.'" (cbOnSuccess)="contentCopied($event)"
                                  (click)="copyContent(detail.bank_holder_name)">{{detail?.bank_holder_name}} &nbsp;<i
                                    class="fa fa-file" aria-hidden="true"></i></span></td>
                            </tr>
                            <tr id="payment0" *ngIf="detail?.method_name != 'Bank'">
                              <td class="title">Number </td>
                              <td class="sub-title right-align"><span style="cursor: pointer;" ngxClipboard
                                  [cbContent]="'Content to be copied.'" (cbOnSuccess)="contentCopied($event)"
                                  (click)="copyContent(detail.others)">{{detail?.others}} &nbsp;<i class="fa fa-file"
                                    aria-hidden="true"></i></span></td>
                            </tr>
                          </ng-container>
                            <tr *ngIf="detail?.payment == true">
                              <td>QR</td>
                              <td>
                                <img src="{{detail?.payment_qr}}" style="height:200px;width:200px">
                              </td>
                            </tr>
                        </tbody>
                      </table>
                      <table *ngIf="payLength == 0">
                        <div style="color: #000;">No Data Found.</div>
                      </table>
                    </div>
                  </div>
                  <div class="utr-amount-details">
                    <div class="left-content">
                      <div class="amount-bg">
                        <div class="amount input-field">
                          <input type="number" [(ngModel)]="amount" [ngModelOptions]="{standalone: true}" maxlength="8"
                            class="validate" placeholder="Enter Amount" min="0">
                        </div>
                        <div class="center-align add-amount"> you can add upto
                          <strong>100000,</strong> minimum <strong>100</strong> required
                        </div>
                      </div>
                    </div>
                    <div class="right-content pay-details">
                      <div class="upload_ss">
                        <form novalidate="" class="choose-file mb-15 ng-untouched ng-pristine ng-valid">
                          <div class="file-field upload-id">
                            <div class="btn">
                              <i aria-hidden="true" class="fa fa-times-circle-o closeIcon" style="font-size: 30px;"
                                *ngIf="file != undefined" (click)="close()"></i>
                              <span *ngIf="file == undefined"><a><i class="fas fa-plus"></i></a></span>
                              <span class="upload-title" *ngIf="file == undefined">Click here to upload Payment
                                Screenshot</span>
                              <input type="file" class="form-control selection-option" accept="image/*" #imageInput
                                name="image" (change)="processFile(imageInput)" *ngIf="file == undefined">
                              <img id="blah" [src]="imageSrc || ''" alt="your image" *ngIf="file != undefined">
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div class="user-warning-note">
                    <div class="title"><i class="fas fa-exclamation-triangle"></i> note: </div>
                    <div class="note-content"> Our bank accounts or payment details keep changing check the payment details
                      before depositing. If you deposited in other account witch is not currently listed on site then we are
                      not responsible for your loss. </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="submit-div center-align">
              <a class="btn-success btn" (click)="paymentDeposit()">
                Submit
              </a>
            </div>
          </div>
      </div>
    </div>
    <app-footer-main></app-footer-main>
  </div>
  
  <!-- Date Time Picker -->
  <!-- <ng-template #popTemplateStatementStart>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="dateStatement" [ngModelOptions]="{standalone: true}"
      [minDate]="minDate" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateDateStatement()"
      (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="timeStatement" [ngModelOptions]="{standalone: true}"
      [min]="minTime" [max]="maxTime" (ngModelChange)="updateTimeStatement()" [showMeridian]="isMeridian"
      showSeconds="true">
    </timepicker>
  
    <div style="padding:5px">
      <span class="btn-group pull-left" style="margin-right:10px;">
        <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
        <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
      </span>
      <span class="btn-group pull-right">
        <button type=button class="btn btn-sm btn-success" (click)="close()">Close</button>
      </span> <span class=clearfix></span>
    </div>
  </ng-template>
  <ng-template #popTemplateStatementEnd>
    <datepicker [hidden]="!isDateVisible" [(ngModel)]="endDateStatement" [ngModelOptions]="{standalone: true}"
      [minDate]="dateStatement" [maxDate]="todayDateTime" [showWeeks]="false" (ngModelChange)="updateEndDateStatement()"
      (selectionDone)="dateSelectionDone()">
    </datepicker>
    <timepicker [hidden]="!isDateVisible" [(ngModel)]="endTimeStatement" [ngModelOptions]="{standalone: true}"
      [min]="minTime" [max]="maxTime" (ngModelChange)="updateEndTimeStatement()" [showMeridian]="isMeridian"
      showSeconds="true">
    </timepicker>
  
    <div style="padding:5px">
      <span class="btn-group pull-left" style="margin-right:10px;">
        <button type=button class="btn btn-sm btn-info" [hidden]="!isDateVisible" (click)="today()">Today</button>
        <button type=button class="btn btn-sm btn-danger" (click)="clear()">Clear</button>
      </span>
      <span class="btn-group pull-right">
        <button type=button class="btn btn-sm btn-success" (click)="closeEndDatepicker()">Close</button>
      </span> <span class=clearfix></span>
    </div>
  </ng-template> -->