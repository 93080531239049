import { Component, OnInit } from '@angular/core';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-qtech',
  templateUrl: './qtech.component.html',
  styleUrls: ['./qtech.component.scss']
})
export class QtechComponent implements OnInit {
  banner: any;
  casinoList: any;
  providerGames: any;
  iUrl: any;

  constructor(private sport: SportService, private toastr: ToastrService ) { }

  ngOnInit(): void {
    this.banner = JSON.parse(localStorage.getItem('Banner'));
    this.getProvider();
    this.getProviderGame('EZU')
  }
  getProvider(){
    this.sport.getQtechProvider().subscribe((res) => {
      if(res.status){
        this.casinoList = res.data;
      }  else {
        this.toastr.error(res.msg)
      }
    })
  }

  getProviderGame(id){
    this.sport.getProviderGameList(id).subscribe((res) => {
      if(res.status){
        this.providerGames = res.data.items
      } else {
        this.toastr.error(res.msg)
      }
    })
  }

  gotoexchangeGame(data){
    let param = {
      providerCode: data.provider['id'],
      gameId: data.id
    }
    this.sport.qtechLaunch(param).subscribe((res) => {
      if(res.status){
        this.iUrl = res.data.url;
        window.open(this.iUrl,"_blank")
      } else {
        this.toastr.error(res.msg)
      }
    })
  }
}
