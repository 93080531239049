<div class="main-wrapper">
  <app-header></app-header>
  <div class="app-body position-relative">
    <app-sidebar></app-sidebar>
    <main class="main">
      <div class="row">
        <div class="col-md-12">
          <div class="divider-top">
            <div class="live-casino">
              <div class="row g-2">
                <div class="col col-4 game-item" *ngFor="let gameList of exchangeGameList;let i = index;"
                  (click)="startGame(gameList)" style="cursor: pointer;">
                  <div class="casino-icon">
                    <a>
                      <img src="{{gameList?.thumb}}" alt="" class="img-fluid">
                      <div class="casino-name">{{gameList?.name}}</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <app-right-sidebar></app-right-sidebar>
  </div>
  <app-footer-main></app-footer-main>
</div>