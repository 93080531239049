import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReportService } from '../services/report.service';

@Component({
  selector: 'app-activitylog',
  templateUrl: './activitylog.component.html',
  styleUrls: ['./activitylog.component.scss']
})
export class ActivitylogComponent implements OnInit {
  onlineUserDetails: any;
  userDetails: any;
  constructor(private reportService: ReportService,
    private toastr: ToastrService, private router: Router) { }

  ngOnInit(): void {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.getOnlineUsersList();
  }
  statement() {
    this.router.navigate(['statement'])
  }
  profitLoss() {
    this.router.navigate(['profit-loss'])
  }
  info() {
    this.router.navigate(['info'])
  }
  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  rollComission() {
    this.router.navigate(['rollComission'])
  }
  getOnlineUsersList() {
    let data = {}
    this.reportService.userActivity(data).subscribe(res => {
      if (res.status) {
        this.onlineUserDetails = res.data
      } else {
        this.onlineUserDetails = [];
        this.toastr.error(res.msg);
      }
    })
  }
}
