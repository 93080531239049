import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHeaderResponse, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public Base_Url = environment['adminServerUrl'];
  public token = localStorage.getItem('userAccessToken');
  userBalance: any;
  private userBalanceSubjectName = new Subject<any>();
  constructor(private http: HttpClient, private cookie: CookieService) { }

  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });

  changePassword( data) {
    return this.http.post<any>(this.Base_Url + 'user/updatePassword/' , data, { headers: this.reqHeader });
  }
  profile() {
    return this.http.get<any>(this.Base_Url + 'user/myProfile', { headers: this.reqHeader });
  }
  getUserBalance(id) {
    return this.http.post<any>(this.Base_Url + 'user/getBalanceCRef',id, { headers: this.reqHeader });
  }

  getGlobalSettings() {
    return this.http.get<any>(this.Base_Url + 'globalsetting/getGlobalSettingDetails', { headers: this.reqHeader });
  }

  returnUserBalance() {
    return this.userBalanceSubjectName.asObservable();
  }
  updateUserBalanceSubject(data) {
    this.userBalanceSubjectName.next(data);
  }
}
