import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { SocketServiceService } from '../services/socket-service.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-supernova',
  templateUrl: './supernova.component.html',
  styleUrls: ['./supernova.component.scss']
})
export class SupernovaComponent implements OnInit {
  exchangeGameList: any;
  sportName: any;
  code: any;
  call: any = 1;
  constructor(private loginService: LoginService, private cdRef: ChangeDetectorRef, private cookie: CookieService, private socketService: SocketServiceService, private route: ActivatedRoute, private router: Router, private http: HttpClient, private sport: SportService, private toastr: ToastrService) {
    this.route.params.subscribe(params => {
      this.sportName = params.name;
      this.code = params.code;
      if (this.call == 2) {
        this.gameList()
      }
    })
  }

  ngOnInit(): void {
    this.gameList();
  }


  gameList() {
    let data = {
      providerCode: this.code
    }
    this.sport.supernovaGameList(data).subscribe((res) => {
      if (res.status == true) {
        this.exchangeGameList = res.data;
        this.call = 2;
      } else {
        this.toastr.error(res.msg);
      }
    })
  }

  startGame(data) {
    let param = {
      "gameData": {
        "providerCode": data.providerCode,
        "gameCode": data.code
      }
    }
    this.sport.startSupernovaGame(param).subscribe((res) => {
      if (res.status == true) {
        window.open(res.url, '_blank', 'location=yes,height=800,width=1200,scrollbars=yes,status=yes');
      } else {
        this.toastr.error(res.msg);
      }

    })
  }
}
