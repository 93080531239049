import { Component, OnChanges, OnInit, TemplateRef ,ViewChild,ChangeDetectorRef,ChangeDetectionStrategy} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router,ActivatedRoute } from "@angular/router";
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { UsersService } from '../services/users.service';
import { SportService } from '../services/sport.service';
import { LoginService } from '../services/login.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { resolve } from 'q';
import { SocketServiceService } from '../services/socket-service.service';
import { ThemeService } from '../theme/theme.service';
import { ReportService } from '../services/report.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('changePwd', {static: true}) public privacyPopup: TemplateRef<any>;
  modalRef: BsModalRef;
  userDetails: any;
  moment: any = moment;
  timer: any;
  changePasswordForm: FormGroup;
  submitted = false;
  user_id: string;
  walletBalance: any;
  isSocket;
  userMatchStack: any;
  match_stack: any[] = [];
  public token = localStorage.getItem('userAccessToken');
  activeThem = 'oceanBlueThemProps';
  homeData: any;
  userData: any;
  runnerData: any;
  firstData: any;
  parameter: any;
  a: any = [];
  searchSport: string;
  exposureData: any;
  expoLength: any;
  expo_User_name: any;
  logo: string;
  message: string;
  popData: boolean;
  pass: boolean = false;
  expoRouteData: any;
  allSportsList: any;
  currentExpo: number;
  currentBalance: number;
  betList: boolean;
  MatchList: any;
  dataSource: any;
  openBetsLength: any = 0;
  backData: any;
  layData: any;
  adminHeading: string;
  allData: any;
  adminData: any;
  pageSize: number;
  expoCurrentData: any;
  showBet:any = 0;
  isRefSpinerShow : boolean = false;
  isSetting: boolean = false;
  sportsList: any;
  b: any;
  firstDataLength: any;
  runnerDataLength: any;
  updateRunnerData: any = [];
  c: any;
  path: string;
  path1: string;
  path2: string;
  sportsName: any;
  agentData: any;
  agentHeading: string;
  agentHead: any = [];
  constructor(private sportService: SportService, private router: Router, private cookie: CookieService,private ref: ChangeDetectorRef, 
    private loginService: LoginService, private fb: FormBuilder, private usersService: UsersService, private reportService: ReportService,
    private socketService: SocketServiceService, private toastr: ToastrService, private modalService: BsModalService,
    private route: ActivatedRoute,private themService: ThemeService) {
      let a = this.router.url;
      let b = a.split('/')
      this.path = b[1]
      this.route.params.subscribe(params => {
        this.sportsName = params.sportName
        
      })
    this.isRefSpinerShow = false;
  }

  async ngOnInit() {
    this.getNews();
    this.logo =localStorage.getItem('logo');
    this.getUserBalance()
    let a = window.screen.width;
    if (a > 767) {
      this. getSportList();
      this.homematches();
    }
    this.usersService.returnUserBalance().subscribe
      (data => { 
        this.walletBalance = data;
      });
    await this.socketService.setUpSocketConnection();
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.createChangePasswordFrom();
    // this.getUserMatchStackDetails()
    this.isSocket = this.cookie.get('is_socket');
    if (this.isSocket == null || this.isSocket == undefined || this.isSocket == '') {
      this.getGlobalSettings();
    }
    if(this.userDetails.is_change_password == '0'){
      this.pass = true;
      this.openModalChangePwd(this.privacyPopup);
    }
    localStorage.openpages = Date.now();
    // let message = "Close current tab and Go back to Previous tab and refresh ....";
    // var onLocalStorageEvent = function (e) {
    //   if (e.key == "openpages") {
    //     localStorage.page_available = Date.now();
    //   }
    //   if (e.key == "page_available") {
    //     Swal.fire({
    //       title: 'Restricted',
    //       text: message,
    //       icon: 'warning',
    //       showCancelButton: false,
    //       showConfirmButton: false,
    //       allowOutsideClick: false,
    //       allowEscapeKey: false,
    //     }).then((result) => {
    //     })
    //   }
    // };
    // window.addEventListener('storage', onLocalStorageEvent, false);
  }
  socketOnEvent(eventName, callback) {
    // this.socketService.socket.on(eventName, data => callback(data));
  }
  getNews() {
    let data = {};
    this.reportService.getNews(data).subscribe((res) => {
      if (res.status) {
        if (res.data == null) {
          this.adminHeading = '';
        } else {
          this.allData = res.data;
          this.adminData = this.allData.filter(t => t.user_type_id == 0);
          if (this.adminData.length != 0) {
            this.adminHeading = this.adminData[0].heading;
          }
          this.agentData = this.allData.filter(t => t.user_type_id != 0);
          if (this.agentData.length != 0) {
            this.agentHeading = '';
            for (let i = 0; i < this.agentData.length; i++) {
              this.agentHead.push(this.agentData[i].heading);
            }
            this.agentHeading = this.agentHead.join(' | ');
          }
        }
      }
    })
  }
  slipSetting(){
    this.isSetting = !this.isSetting;
  }
  closeSlipSet(){
    this.isSetting = false;
  }
  isAccCredit: boolean = false;
  accCreditSett(){
    this.isAccCredit = !this.isAccCredit;
  }
  accCreditSettMobile(){
    this.isAccCredit = !this.isAccCredit;
  }
  accCreditSettClose(){
    this.isAccCredit = false;
  }
  myaccClick(){
    this.status = !this.status;
  }
  getAllSportsListData() {
    let userData = {};
    this.sportService.getAllSportsList(userData).subscribe(res => {
      this.sportsList = res.data;
      let matches = (this.homeData).filter((t:any) => t.inplay == true || t.manualInplay == true);
      matches = matches.reduce((groupSport: { [x: string]: any; }, sports: { sport_name: any; }) => {
        const { sport_name } = sports;
        groupSport[sport_name] = [...groupSport[sport_name] || [], sports];
        return groupSport;
      }, {});
      this.c = matches;
      for(let i=0; i <this.sportsList.length;i++){
        this.sportsList[i].live = 0
        for(let j=0;j < Object.keys(this.c).length;j++){
          if(this.sportsList[i].name == Object.keys(this.c)[j]){
            this.b = Object.values(this.c)[j];
            this.sportsList[i].live = this.b.length
          }
        }
      }
      this.allSportsList = this.sportsList
      this.allSportsList = (this.allSportsList).sort(function(a, b){return b.sport_id - a.sport_id});
      // this.allSportsList = res.data
      this.ref.detectChanges();
    })
  }
  socketEmitEvent(eventName, data) {
    // this.socketService.socket.emit(eventName, data);
  }
  goToDashboard(sportName, id, data) {
    if (id == -100) {
      this.router.navigate(['casino']);
    } else if (id == 'QT'){
      this.router.navigate(['qtech'])
    } else  if (id < -100) {
      this.router.navigate(['snova/' + id + '/' + data.name + '/' + data.providerCode])
    } else if(id > -100){
      this.router.navigate(['dashboard/' + sportName]);
    }
  }
  goToAll(sportName) {
    this.router.navigate(['dashboard/' + sportName]);
  }
  stackSetting() {
    this.status = !this.status;
  }

  logoutListenUser() {
    this.logoutUserFromAllDevices()
    this.socketOnEvent(`logoutUserFromAllDevices${this.userDetails._id}`, data => {
      if (data.status == true && data.caller_identifier != this.token) {
        this.cookie.delete('userId');
        this.cookie.delete('is_socket');
        this.loginService.clearLocalStorage()
        this.router.navigate(['openHome'])
        window.location.reload();
        window.location.replace('openHome');
      } else {
        this.toastr.error(data.msg, '', {
          timeOut: 10000,
        });
      }
    });

  }
  logoutUserFromAllDevices() {
    this.socketEmitEvent('logoutUserFromAllDevices', { user_id: this.userDetails._id, caller_identifier: this.cookie.get('accessToken') });
  }



  status: boolean = false;
  sideNavEvent() {
    this.status = !this.status;
  }
  dashboard() {
    this.router.navigate(['dashboard'])
  }
  inplay() {
    this.router.navigate(['inplay'])
  }
  statement() {
    this.router.navigate(['statement'])
  }
  profitLoss() {
   this.router.navigate(['profit-loss'])
  }
  info() {
    this.router.navigate(['info'])
  }
  multi() {
    this.router.navigate(['multiMarket'])
  }
  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  rollComission() {
    this.router.navigate(['rollComission'])
  }
  myBet() {
    this.router.navigate(['my-bet'])
  }

  openModalChangePwd(changePwd: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      changePwd,
      Object.assign({}, { class: 'passwordModal',ignoreBackdropClick: true}),
    );
  }
  openModalChangeStack(stackValue: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      stackValue,
      Object.assign({}, { class: 'chipsModal displayPop' })
    );
  }
  openModalopenBets(openBets: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      openBets,
      Object.assign({}, { class: 'openBetsModal modal-dialog modal-dialog-centered modal-fullscreen' })
    );
  }

  createChangePasswordFrom() {
    this.changePasswordForm = this.fb.group({
      old_password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    })
  }

  get f() { return this.changePasswordForm.controls; }

  onSubmitChangePassword() {
    this.submitted = true;

    if (this.changePasswordForm.invalid) {
      return;
    }
    let data = {
      "newPassword": this.changePasswordForm.value.new_password
    }
    if (this.changePasswordForm.controls['new_password'].value == this.changePasswordForm.controls['confirm_password'].value) {
      this.usersService.changePassword(data).subscribe((result) => {
        if (result.status == true) {
          this.userDetails.is_change_password='1';
          localStorage.setItem('userDetails',JSON.stringify(this.userDetails));
          this.submitted = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          });
          this.modalRef.hide();
          this.logoutUser();
        } else {
          this.toastr.error(result.msg, '', {
            timeOut: 10000,
          });
        }
      }, (err) => {
        console.log(err);
      });
    }
    else {
      this.toastr.error("Password and confirm password did not match", '', {
        timeOut: 10000,
      });
    }
  }

  logoutUser() {
    let data = {};
    this.loginService.logoutUser(data).subscribe(result => {
      if (result.status == true) {
        this.toastr.success(result.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        })
        this.cookie.delete('userId');
        this.loginService.clearLocalStorage()
        this.router.navigate(['openHome']);
        window.location.reload();
        window.location.replace('openHome');
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
        this.cookie.delete('userId');
        this.loginService.clearLocalStorage()
        this.router.navigate(['openHome']);
        window.location.reload();
        window.location.replace('openHome');
      }
    })


  }

  getUserBalance() {
    this.isRefSpinerShow = true;
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    let data = {};
    this.usersService.getUserBalance(data).subscribe(response => {
      this.isRefSpinerShow = false;
      if (response.status == true) {
        this.isRefSpinerShow = false;
        this.walletBalance = response.data;
        this.usersService.userBalance = response.data;
        this.currentExpo = (this.walletBalance.liability)*(this.userDetails.point);
        this.currentBalance = (this.walletBalance.balance)*(this.userDetails.point)
        // if(this.currentExpo <= 0 && this.currentBalance >= 100){

        // } else if (this.currentExpo < -100 && this.currentBalance >= 0) {

        // } else if (this.currentExpo == 0 && this.currentBalance >= 100){

        // } else {
        //  let  message = "Your main Balance is low.Plz refill your account..!";
        //  Swal.fire({
        //   title: 'Balance',
        //   text: message,
        //   icon: 'warning',
        //   showCancelButton: false,
        //   confirmButtonText: 'Yes',
        // allowOutsideClick: false,
        // allowEscapeKey: false,
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     this.cookie.delete('userId');
        //     this.cookie.delete('accessToken');
        //     this.cookie.delete('refreshToken');
        //     this.loginService.clearLocalStorage()
        //     this.router.navigate(['openDashboard']);
        //     window.location.reload();
        //     window.location.replace('openDashboard');
        //   }
        // })

        // }
        this.ref.detectChanges();
      } else {
        this.isRefSpinerShow = false;
        this.toastr.error(response.msg, '', {
          timeOut: 10000,
        });
        this.ref.detectChanges();
      }


    }, (err) => {
    })
  }
  getUserMatchStackDetails() {
    this.sportService.getUserMatchStack(this.userDetails._id).subscribe(response => {
      if (response.status == true) {
        this.userMatchStack = response.data
        this.match_stack = this.userMatchStack.match_stack
      } else {
        this.toastr.error(response.msg, '', {
          timeOut: 10000,
        });
      }

    })
  }

  updateUserMatchStack() {
    let stackRequest = {
      "match_stack": this.match_stack
    }
    this.sportService.updateUserMatchStack(stackRequest).subscribe(result => {
      if (result.status == true) {
        this.submitted = false;
        this.toastr.success(result.msg, '', {
          positionClass: 'toast-bottom-right',
          timeOut: 1000
        });
        this.modalRef.hide();
      } else {
        this.toastr.error(result.msg, '', {
          timeOut: 10000,
        });
      }
    })
  }

  openModalExposure(exposure: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      exposure,
      Object.assign({}, { class: 'pop_box exposureModal modal-dialog' })
    );
    let data = {};
    this.sportService.getExposure(data).subscribe((res) => {
      if (res.status == true) {
        this.popData = true;
        this.exposureData = res.data;
        this.expoLength = this.exposureData.length;
        this.expo_User_name = res.user_name;
      } else {
        this.message = "No Record Found ....";
        this.popData = false;
      }
    })
  }


  getGlobalSettings() {
    this.usersService.getGlobalSettings().subscribe(response => {
      if (response.status == true) {
        this.cookie.delete('is_socket');
        this.cookie.set('is_socket', response.data.is_socket);
      }
    }, error => {
      console.log(error)
    })
  }

  toggleThem() {
    // refactor this ugly code :) for demo only
    if (this.activeThem !== 'darkThemProps') {
      this.themService.setActiveThem('darkThemProps');
      this.activeThem = 'darkThemProps';
    } else {
      this.themService.setActiveThem('oceanBlueThemProps');
      this.activeThem = 'oceanBlueThemProps';
    }
  }

  goToDetail(data){
    let param = {
      match_id: data.match_id
    }
    this.sportService.getHomeMatchesList(param).subscribe(res => {
      if (res.status) {
        if((res.data).length != 0){
          this.expoCurrentData = res.data[0];
          localStorage.setItem('matchData', JSON.stringify(this.expoCurrentData));
          this.modalRef.hide();
          if (this.router.url.split('?')[0] == '/match-detail') {
            window.location.reload();
          } else {
            this.router.navigate(['match-detail']);
          }
        } else {
          this.toastr.error("Results are not announced yet...")
        }
        
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
      }
    }, (err) => {
      console.log(err);
    })
  }
  searchFilter(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['match-detail']);
  }

  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    if ((this.router.url).split('?')[0] == '/match-detail') {
      window.location.reload();
    } else {
      this.router.navigate(['match-detail']);
    }

  }
  trackByFn(index: any, item: any) {
    return index;
  }


  expoRoute( id){
    let data = {
      "event": "match",
      "filter": {
          "match_id":id.match_id
      },
      "projection": [
        "enable_fancy",
        "inplay",
        "is_created",
        "is_lock",
        "is_active",
        "match_date",
        "bet_count"
      ]
    }
    this.sportService.getExposureRoute(data).subscribe((res) => {
      this.expoRouteData = res.data;
      this.expoRouteData.manualInplay = res.data.inplay;
    let a3 = {...id, ...this.expoRouteData};
      localStorage.setItem('matchData', JSON.stringify(a3));
      this.router.navigate(['match-detail']);
      this.modalRef.hide();
    })
  }
  getSportList() {
    let data = {
      type: 'openBets',
      search: {}
    };
    this.reportService.eventList(data).subscribe((res) => {
      if (res.status == true) {
        this.MatchList = res.data[0].matches;
        this.betList = false;
      } else {
        this.toastr.error(res.msg);
      }
    })
  }
  openBet(params) {
    this.pageSize = 30
    let data = {
      page: 1,
      limit: this.pageSize,
      search: {
        "match_id": params.match_id,
        "delete_status":0
      }
    }
    this.reportService.openBets(data).subscribe((res) => {
      if (res.status) {
        if (res.data[0].data) {
          this.betList = true
          this.dataSource = res.data[0].data;
          this.openBetsLength = res.data[0].data.length;
          this.backData = this.dataSource.filter(t => t.is_back == 1);
          this.layData = this.dataSource.filter(t => t.is_back == 0);
        } else {
          this.openBetsLength = 0;
        }


      } else {
        this.toastr.error(res.msg);
        this.openBetsLength = 0;
      }
    })
  }
  showBetHistory(data){
    if(data == 1){
      this.showBet = 1;
      this.ref.detectChanges();
    }else {
      this.showBet = 0;
      this.ref.detectChanges();
    }
  }

  homematches() {
    this.userData = {};
    this.sportService.getHomeMatchesList(this.userData).subscribe(res => {
      if (res.status) {
        this.firstData = res.data;
        this.firstData.forEach((matDta: any) => {
          matDta.manualInplay = matDta.inplay;
        });
        this.firstDataLength = this.firstData.length;
        for (let i = 0; i < this.firstDataLength; i++) {
          if (this.firstData[i].my_favorites.includes(this.userDetails.user_id)) {
            this.firstData[i].favourite = true;
          }
        }
        this.runners();
      } else {
        this.toastr.error(res.msg, '', {
          timeOut: 10000,
        });
        if (res.logout == true) {
          this.router.navigate(['login']);
          window.location.reload();
          window.location.replace('login');
        }
      }
    }, (err) => {
      console.log(err);
    })
  }

  runners() {
    this.userData = {};
    this.sportService.runnerList(this.userData).subscribe((res) => {
      if (res.status) {
        this.runnerDataLength = res.data.length;
        this.runnerData = res.data;
        for (let i = 0; i < this.firstData.length; i++) {
          for (let j = 0; j < this.runnerData.length; j++) {
            if (this.firstData[i].marketId == this.runnerData[j].marketId) {
              this.updateRunnerData[i] = []
              this.updateRunnerData[i] = (this.runnerData[j]);
              break
            } else {
              this.updateRunnerData[i] = {
                "marketId": this.firstData[i].marketId,
                "status": "SUSPENDED",
                "inplay": false,
                "runners": [
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  },
                  {
                    "ex": {
                      "availableToBack": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "availableToLay": [
                        {
                          "price": "--",
                          "size": "--"
                        }
                      ],
                      "tradedVolume": []
                    }
                  }
                ]
              }
            }
          }
        }
        const a3 = this.firstData.map((t1: any) => ({ ...t1, ...this.updateRunnerData.find((t2: any) => t2.marketId === t1.marketId) }))
        this.a = a3
        let matches;
        matches = this.a.map((data: any) => {
          if (!Array.isArray(data.runners))
            data.runners = data.runners.runners;
          return data;
        })
        this.homeData = matches;
        this.getAllSportsListData();
        this.a = [];
      } else {
        for (let i = 0; i < this.firstData.length; i++) {
          this.updateRunnerData[i] = {
            "marketId": this.firstData[i].marketId,
            "status": "SUSPENDED",
            "inplay": false,
            "runners": [
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              },
              {
                "ex": {
                  "availableToBack": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "availableToLay": [
                    {
                      "price": "--",
                      "size": "--"
                    }
                  ],
                  "tradedVolume": []
                }
              }
            ]
          }
        }
        const a3 = this.firstData.map((t1: any) => ({ ...t1, ...this.updateRunnerData.find((t2: any) => t2.marketId === t1.marketId) }))
        this.a = a3
        let matches;
        matches = this.a.map((data: any) => {
          if (!Array.isArray(data.runners))
            data.runners = data.runners.runners;
          return data;
        })
        this.homeData = matches;
        this.getAllSportsListData();
        this.a = [];
      }
    }, (err) => {
      console.log(err);
      this.getAllSportsListData();
    })
  }
}
