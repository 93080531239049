<div class="app-body">
    <app-header></app-header>
    <div class="full-wrap">
      <div class="col-left">
        <app-sidebar></app-sidebar>
      </div>
  
      <div id="centerColumn" class="col-center report">
  
        <!-- <div class="menu-wrap d-none d-lg-block">
            <div class="full-wrap">
              <ul class="menu">
                <li class="ver-menu" *ngFor="let provider of casinoList;let i = index;" (click)="getProviderGame(provider?.providerCode)">
                  <a >{{sports?.name}}
                    {{provider?.name}}</a>
                </li>
              </ul>
            </div>
          </div> -->
          <div class="wrap-highlight ps ps--theme_default ps--active-x">
            <ul id="pills-tab" role="tablist" style="display: -webkit-box;">
              <li role="presentation"*ngFor="let provider of casinoList;let i = index;" (click)="getProviderGame(provider?.providerCode)">
                <a class="active" data-bs-toggle="pill"
                   data-bs-target="#pills-inplay" role="tab"
                  aria-controls="pills-inplay" aria-selected="true">{{provider?.name}}
                </a>
              </li>
            </ul>
          </div>
        <!-- <ul>
            <li *ngFor="let provider of casinoList;let i = index;" (click)="getProviderGame(provider?.providerCode)">
              <a  class="active" id="btn-{{i}}" tab-content="#item-1">{{provider?.name}}</a>
            </li>
        </ul> -->
        <div class="live-casino">
          <div class="game-item"  *ngFor="let list of providerGames;let i = index;" (click)="gotoexchangeGame(list)">
            <div class="casino-icon">
              <a>
                <img [src]="list.images[1].url" alt="" class="img-fluid">
                <div class="casino-name">{{list?.name}}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer-main></app-footer-main>
  </div>