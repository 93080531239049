import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { SocketServiceService } from '../services/socket-service.service';
import { ReportService } from '../services/report.service';
import { environment } from '../../environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';
import { Subject } from 'rxjs';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements AfterViewInit, OnDestroy, OnInit {
  pageSize: number = 50;
  moment: any = moment;
  statementsTotalItems: any;
  statementsPageIndex: number;
  historyData: any;
  searchQuery: any;
  public Base_Url = environment['adminServerUrl'];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  public token = localStorage.getItem('userAccessToken');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  constructor(private http: HttpClient, private route: ActivatedRoute, private cdRef: ChangeDetectorRef, private sport: SportService, private reportService: ReportService,
    private cookie: CookieService, private toastr: ToastrService, private socketService: SocketServiceService, private router: Router) { }

  ngOnInit(): void {
    // this.history();
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      serverSide: true,
      searching: false,
      processing: true,
      ajax: (dataTablesParameters1: any, callback) => {
        let dataTablesParameters = {
          page: (dataTablesParameters1.start / dataTablesParameters1.length) + 1,
          limit: dataTablesParameters1.length,
        }
        dataTablesParameters1.search.value != "" ? dataTablesParameters["search"] = (dataTablesParameters1.search.value) : ""
        that.http.post<DataTablesResponse>(this.Base_Url + 'user/getPasswordChangedHistory', dataTablesParameters, { headers: this.reqHeader })
          .subscribe(resp => {
            if (resp["status"]) {
              if ((resp.data).length != 0) {
                this.historyData = resp.data;
                for (let i = 0; i < this.historyData.length; i++) {
                  this.historyData[i].updateDateTime = moment(this.historyData[i].createdAt).utcOffset("+5:30").format('DD-MM-YYYY HH:mm:ss');
                }
                callback({
                  recordsTotal: this.historyData.length,
                  recordsFiltered: this.historyData.length,
                  data: this.historyData
                });
              } else {
                this.historyData = []
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: this.historyData
                });
              }
            } else {
              this.toastr.error(resp["msg"]);
              this.historyData = []
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: this.historyData
              });
            }


          });
      },
      columns: [{ title: 'Date/Time', data: 'updateDateTime' }, { title: 'Remark', data: 'comment' }],
    };
  }
  history() {
    let data = {
      page: this.statementsPageIndex,
      limit: this.pageSize,
      search: this.searchQuery
    }
    if (data.search == '') {
      delete data.search;
    }
    this.rerender()
  }
  statement() {
    this.router.navigate(['statement'])
  }
  profitLoss() {
    this.router.navigate(['profit-loss'])
  }
  info() {
    this.router.navigate(['info'])
  }
  historydiv() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  rollComission() {
    this.router.navigate(['rollComission'])
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
