<div class="app-body">
  <app-open-header></app-open-header>

  <!-- Loading Wrap -->
  <div id="loading" class="loading-wrap d-none-mobile" *ngIf="loading == true">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>

  <div class="loading-overlay d-none-desktop" id="loading" *ngIf="loading == true">
    <div class="loading-wrap">
      <div class="loading">
        <div></div>
        <div></div>
      </div>
      <p>Loading...
      </p>
    </div>
  </div>
  
  <!-- desktop design -->
  <div class="full-wrap d-none-mobile">
    <div class="col-left">
      <app-open-sidebar></app-open-sidebar>
    </div>

    <div id="centerColumn" class="col-center">
      <!-- Message -->
      <div id="message" class="message-wrap success">
        <a class="btn-close">Close</a>
        <p></p>
      </div>

      <div class="over-wrap">
        <div class="promo-banner">
          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item" *ngFor="let image of banner;let i = index;"
                [ngClass]="(i === 0) ? 'active' : ''" data-bs-interval="5000">
                <img src="{{image.bannerUrl}}" class="d-block w-100" alt="...">
              </div>
            </div>
          </div>
        </div>

        <div class="game-highlight-wrap col3">
          <h3>Sports Highlights
            <div class="highlight-sorting">
              <label>View by</label>
              <div class="select">
                <select id="viewType" name="View">
                  <option value="competitionName" orderby="asc" priority="asc">Competition</option>
                  <option value="openDateTime" orderby="asc" inplay="desc" selected="">Time</option>
                  <option value="totalMatched" orderby="desc">Matched</option>
                </select>
              </div>
            </div>
          </h3>
          <ul class="slip-head">
            <li class="col-game"></li>
            <li class="col-matched"></li>
            <li class="col-visit">1</li>
            <li class="col-draw">x</li>
            <li class="col-home">2</li>
            <li class="col-info"></li>
          </ul>

          <div class="game-list">
            <div id="noDataDiv" class="no-data" *ngIf="homeData?.length == 0">
              <p>There are no events to be displayed.
              </p>
            </div>

            <div id="eventBoard" *ngIf="homeData?.length != 0">
              <dl class="game-list-col" *ngFor="let matchData of homeData;let i = index;">
                <dt (click)="matchDetail(matchData,1)">
                  <span class="img-span" [ngClass]="matchData.inplay ? 'icon-in_play' : 'icon-no_play'"></span>
                  <span class="game-low_liq" style="display: none;">Low Liquidity</span>
                  <a href="javascript:void(0)">{{matchData.match_name}}</a>
                  <span class="game-list-time" *ngIf="matchData.inplay">
                    <span class="in_play">In-Play</span>
                  </span>
                  <span class="game-list-time"
                    *ngIf="matchData?.inplay != true || matchData?.manualInplay != true">{{(matchData?.match_date | date
                    :'dd-MM-yyyy HH:mm')}}</span>
                  <span class="game-live" id="streamingIcon" style="display: inline-flex;"
                    *ngIf="matchData.has_tv_url">Live</span>
                  <span class="game-fancy in-play" *ngIf="matchData.enable_fancy === 1">Fancy</span>
                  <span class="game-bookmaker" *ngIf="matchData.enable_fancy === 1">BookMaker</span>
                </dt>
                <dd class="col-matched"></dd>
                <dd class="col-visit">
                  <div class="suspend"
                    *ngIf=" matchData.status == 'SUSPENDED' || matchData.status == 'CLOSED' || matchData.status == 'closed' || matchData.status == 'Closed'">
                    <span class="stats-text">Suspend</span></div>
                  <div class="suspend" *ngIf=" matchData.status != 'SUSPENDED' && matchData.is_lock == true"><span
                      class="stats-text">Lock</span></div>
                  <a class="btn-back" side="Back">
                    {{matchData?.runners[0]?.ex?.availableToBack[0] == undefined ? 0 :
                    matchData?.runners[0]?.ex?.availableToBack[0]?.price}}</a>
                  <a class="btn-lay" side="Lay">
                    {{matchData?.runners[0]?.ex?.availableToBack[0]
                    == undefined ? 0 :
                    matchData?.runners[0]?.ex?.availableToLay[0]?.price}}
                  </a>
                </dd>
                <dd class="col-draw">
                  <div class="suspend"
                    *ngIf=" matchData.status == 'SUSPENDED' || matchData.status == 'CLOSED' || matchData.status == 'closed' || matchData.status == 'Closed'">
                    <span class="stats-text">Suspend</span></div>
                  <div class="suspend" *ngIf=" matchData.status != 'SUSPENDED' && matchData.is_lock == true"><span
                      class="stats-text">Lock</span></div>
                  <a class="btn-back" side="Back">
                    {{matchData?.runners[2]?.ex?.availableToBack[0] == undefined ? '--' :
                    matchData?.runners[2]?.ex?.availableToBack[0]?.price}}
                  </a>
                  <a class="btn-lay" side="Lay">
                    {{matchData?.runners[2]?.ex?.availableToLay[0]
                    == undefined ? '--' :
                    matchData?.runners[2]?.ex?.availableToLay[0]?.price}}
                  </a>
                </dd>
                <dd class="col-home">
                  <div class="suspend"
                    *ngIf=" matchData.status == 'SUSPENDED' || matchData.status == 'CLOSED' || matchData.status == 'closed' || matchData.status == 'Closed'">
                    <span class="stats-text">Suspend</span></div>
                  <div class="suspend" *ngIf=" matchData.status != 'SUSPENDED' && matchData.is_lock == true"><span
                      class="stats-text">Lock</span></div>
                  <a class="btn-back" side="Back">
                    {{matchData?.runners[1]?.ex?.availableToBack[0]?.price
                    == undefined ? 0 :
                    matchData?.runners[1]?.ex?.availableToBack[0]?.price}}
                  </a>
                  <a class="btn-lay" side="Lay">
                    {{matchData?.runners[1]?.ex?.availableToLay[0]?.price
                    ==
                    undefined ? 0 :
                    matchData?.runners[1]?.ex?.availableToLay[0]?.price}}
                  </a>
                </dd>
                <dd class="col-info">
                  <a class="add-pin" title="Add to Multi Markets">Pin</a>
                </dd>
              </dl>
            </div>
          </div>
        </div>

        <app-open-footer></app-open-footer>
      </div>

    </div>

    <div class="col-right accordion" id="betslipRisgtDiv">
      <div class="slip-wrap accordion-item">
        <h3 id="betSlipHeading" data-bs-toggle="collapse" data-bs-target="#betSlipCollapse" aria-expanded="true"
          aria-controls="betSlipCollapse">
          <a class="to-expand">Bet Slip</a>
        </h3>
        <ul id="loadingMsg" class="loading" style="display: none;">
          <li><img src="assets/sky_img/loading40.gif"></li>
          <li id="countDownTime">Place Bets
          </li>
        </ul>
        <div id="betSlipCollapse" class="accordion-collapse collapse show" data-bs-parent="#betslipRisgtDiv">
          <ul id="loadingMsg" class="loading" style="display: none">
            <li><img src="assets/sky_img/loading40.gif"></li>
            <li id="countDownTime">Place Bets</li>
          </ul>
        </div>
      </div>

      <div class="matched-wrap accordion-item d-none">
        <h3 id="matchedBetHeading" data-bs-toggle="collapse" data-bs-target="#matchedBetCollapse" aria-expanded="true"
          aria-controls="matchedBetCollapse">
          <a id="openBetsRefresh" class="slip_refresh" style="cursor:pointer;">Refresh</a>
          <a class="to-expand" style="cursor:pointer;">Open Bets
          </a>
        </h3>
        <div id="matchedBetCollapse" class="accordion-collapse collapse show" data-bs-parent="#betslipRisgtDiv">
          <div class="message-bet">
            <h4 id="oneClickMsg" class="" style="display: none"></h4>
          </div>
          <ul id="processingImg" class="loading" style="display: none;">
            <li><img src="assets/sky_img/loading40.gif"></li>
            <li>Processing...
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- mobile design -->
  <div class="mian-wrap d-none-desktop footer-condition">

    <div class="promo-banner">
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" *ngFor="let image of banner;let i = index;" [ngClass]="(i === 0) ? 'active' : ''"
            data-bs-interval="5000">
            <img src="{{image.bannerUrl}}" class="d-block w-100" alt="...">
          </div>
        </div>
      </div>
    </div>
    <div class="highlight-fix">
      <a class="a-search" (click)="openModalSearch(search)">Search</a>
      <div class="wrap-highlight ps ps--theme_default ps--active-x">
        <ul id="pills-tab" role="tablist">
          <li role="presentation" *ngFor="let sports of allSportsList;let i= index"
            (click)="goToDashboard(sports.name,sports.sport_id,sports)">
            <a class="active" id="pills-inplay-tab" [class.active]='sportName == sports.name' data-bs-toggle="pill"
              data-bs-target="#pills-inplay" role="tab" aria-controls="pills-inplay" aria-selected="true">
              <span class="tag-live"><strong></strong> {{sports?.live}}</span>
              <img src="assets/images/icon/{{sports.sport_id}}-w.svg" class="icon-cricket inactive-img">
              <img src="assets/images/icon/{{sports.sport_id}}-b.svg" class="icon-cricket active-img">
              {{sports.name}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="wrap-highlight_list">
      <h3>Highlights</h3>
      <div class="tab-content" id="pills-tabContent">
        <ul class="highlight-list tab-pane fade show active" id="pills-inplay" role="tabpanel"
          aria-labelledby="pills-inplay-tab" tabindex="0">
          <li id="noDatali" style="display: none;">There are no events to be displayed.</li>
          <li
            *ngFor="let matchData of homeData;let i = index;"  [ngClass]="matchData.inplay ? 'inplay-on' : 'inplay-off'">
            <a>
              <dl>
                <dt>
                  <span id="streamingIcon" class="game-live" style="display: flex;"
                    *ngIf="matchData.has_tv_url">game-live</span>
                  <span id="fancyBetIcon" class="game-fancy in-play" style="display: flex;">
                    <pre>in-play</pre>Fancy
                  </span>
                  <span id="bookMakerIcon" class="game-bookmaker in-play" style="display: flex;">
                    <pre>in-play</pre>BookMaker
                  </span>
                  <span id="dateTimeInfo" class="time" *ngIf="matchData.inplay"  [ngClass]="matchData.inplay ? 'green' : ''">In-Play</span>
                  <span id="dateTimeInfo" class="time" *ngIf="matchData.inplay != true">{{matchData?.match_date | date
                    :'yyyy-MM-dd HH:mm'}}</span>
                </dt>
                <dd id="eventName" (click)="matchDetail(matchData,1)">{{matchData.match_name}}</dd>
              </dl>
            </a>
            <a id="multiMarketPin" class="pin-off" (click)="goToLogin()"></a>
          </li>
        </ul>
      </div>
    </div>
    <app-open-footer></app-open-footer>
  </div>


</div>

<!-- Search Modal -->
<ng-template #search>
  <div ngbDropdown class="search-top dropdown">
    <form class="d-flex position-relative">
      <span class="search-back ui-link"></span>
      <input ngbDropdownToggle class="form-control" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
        aria-expanded="false" type="text" autocomplete="off" [(ngModel)]="searchSport"
        [ngModelOptions]="{standalone: true}" placeholder="Search Events">
      <span class="search-clear" (click)="searchSport = '';modalRef.hide()"></span>
      <span class="search-collapse"></span>
      <div ngbDropdownMenu class="dropdown-menu  search-div" aria-labelledby="dropdownMenuLink">
        <a ngbDropdownItem class="dropdown-item" *ngFor="let sport of homeData |search: searchSport :'match_name'">
          <div class="event-info" (click)="matchDetail(sport,2)">
            <div class="event-team">
              <div class="team-name">{{sport?.match_name}}</div>
            </div>
          </div>
        </a>
      </div>

    </form>
  </div>
</ng-template>