<div class="app-body">
  <div id="loading" class="loading-wrap" style="display: none;">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>
  <app-header></app-header>
  <div class="main_wrap_container withdrawpage">
    <div class="col-left">
      <!-- <app-sidebar></app-sidebar> -->
      <div class="sub_path">
        <div class="path">
          <a class="path-back">...</a>
          <ul>
            <li class="path-last"><a>My Account</a></li>
          </ul>
        </div>
        <ul class="menu-list" data-widget="tree">
          <li>
            <a (click)="info()">
              <span>My Profile</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="rollComission()">
              <span>Rolling Commision</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="statement()" class="select">
              <span>Account Statement</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/betHistory','openBets']">
              <span>Bets History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="profitLoss()">
              <span>Profit & Loss</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="history()">
              <span>Password History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="activity()">
              <span>Activity Log</span><span class="pull-right-container"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div id="centerColumn" class="col-center report">
      <h2>Withdraw 24*7  <span tabindex="0" class="px-3" style="float:right;cursor: pointer;background:linear-gradient(180deg, #2A3A43 27%, #1C282D 83%);color: white;border-radius: 3px;padding:3px"
        (click)="withdrawdetails()">Add Bank Account</span></h2>
      
      <div class="function-wrap">
        <div class="row">
          <div class="col-md-12 row">
            <div class="col-md-4">
              <div class="form-group"><label>Withdrawal Amount <span class="asterisk_input"></span></label><input
                  class="form-control" [(ngModel)]="amount" (keyup)="validate()" value="{{amount}}"
                  name="withdrawAmount" type="text"></div>
            </div>
            <div class="col-md-12 mt-2 feedata loading-main-row"
              *ngIf="showPopUp == true && amount != 0 && showPopUpError == false">
              <div class="alert alert-success m-0">
                <div class="feedata">
                  <strong class="light-grey-text">Amount: {{amount}}</strong>
                  <strong class="light-grey-text">Processing Time: 2 Hours</strong>
                  <strong class="light-grey-text">Fee: 0%</strong>
                  <strong class="light-grey-text">Withdraw Amount: {{amount}}</strong>
                </div>
              </div>

              <div class="loading-text" *ngIf="loading == true">
                <div class="loading-wrap" id="loading">
                  <ul class="loading">
                    <li>
                      <div class="loadingio-spinner-dual-ball-srpf31lqtit">
                        <div class="ldio-dayih5pen3j">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </li>
                    <li>Loading...</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-2 feedata" *ngIf="showPopUpError == true">
              <div class="alert alert-danger m-0">
                <div class="feedata">
                  <strong class="light-grey-text">You have Insufficient Balance.</strong>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-3">
              <div class="form-group"><button style="width:17%;" class="btn btn-primary mr-2 instant"
                  (click)="withdrawData()" [disabled]="index == 1">Withdraw Coins</button></div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body account-stat-body">

        <div class="row mt-3">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>Withdrawal Type</th>
                    <th>Request Time</th>
                    <th>Status</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let list of detailList ; let i = index;">
                    <td>{{list?.amount}}</td>
                    <td>NORMAL</td>
                    <td> {{list.generated_at | date :'dd-MM-yyyy HH:mm a'}} </td>
                    <td><span class="badge"
                        [ngClass]="{'badge-danger':list?.status == 'REJECTED','yellow': list?.status == 'PENDING'}">{{list?.status}}</span>
                    </td>
                    <td class="text-center"><span>-</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
  <!-- </div> -->
  <app-footer-main></app-footer-main>
</div>