import { Component, OnInit } from '@angular/core';
import exchangeGameList from '../../data.json';
import { Router,ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-home', 
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  gameList: any;
  constructor(private router: Router,private toastr: ToastrService,) {}

  ngOnInit() {
    this.gameList = exchangeGameList.data
  }
  dashboard(){
    this.router.navigate(['dashboard'])
  }
  error(){
    this.toastr.error('Coming Soon');
  }
  casino(){
    this.router.navigate(['casino'])
  }
 }
