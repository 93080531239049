import { Component, OnInit,TemplateRef } from '@angular/core';
import {Router} from "@angular/router";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../environments/environment';
 
@Component({
  selector: 'app-open-footer',
  templateUrl: './open-footer.component.html',
  styleUrls: ['./open-footer.component.scss']
})
export class OpenFooterComponent implements OnInit {
  modalRef: BsModalRef;
  logo: string;
  data:any;
  domainName: string;
  public Base_Url = environment['adminServerUrl'];
  constructor(private router: Router,private modalService: BsModalService,) { 
    let a  = window.location.pathname.split('/');
    this.data = a[1]
  }

  ngOnInit(): void {
    this.domainName =window.location.origin;
    this.logo = localStorage.getItem('logo')
  }
  dashboard(){
    this.router.navigate(['dashboard'])
  }
  
  inplay(){
    this.router.navigate(['inplay'])
  }
  openModalLoginCnfm(loginCnfm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginCnfm,
      Object.assign({}, { class: 'loginCnfm-modal gamblingModal',ignoreBackdropClick: true })
    );
  }
  openModalLoginPopup(loginPopup: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginPopup,
      Object.assign({}, { class: 'loginModal modal-dialog-centered modal-fullscreen-lg-down',ignoreBackdropClick: true })
    );
  }
  login(){
    this.router.navigate(['login'])
  }
  DownloadApk(){
    let c = (this.domainName).split('//')
    let a = btoa(c[1]);
    let b = this.Base_Url + 'content/download-mobile-app?key=' + a;
    window.open(b,'_blank')
  }
}
