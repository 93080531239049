import { Component, OnInit,TemplateRef} from '@angular/core';
import { OpenApiService } from '../services/open-api.service';
import { environment } from '../../environments/environment';
import exchangeGameList from '../../data.json';
import { Router,ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
@Component({
  selector: 'app-open-home',
  templateUrl: './open-home.component.html',
  styleUrls: ['./open-home.component.scss'],
})
export class OpenHomeComponent implements OnInit {

  public Base_Url = environment['adminServerUrl'];
  loading: boolean;
  logo: string;
  banner: any;
  gameList: any;
  Form: FormGroup;
  transactionPassword: string;
  private clickTimeout = null;
  modalRef: BsModalRef;
  loginButtonDisable = false
  submitted: boolean = false;
  a: any;
  tPassword: string;
  showResponseMessage: any;
  size: any;
  constructor(private open : OpenApiService,private router: Router,private loginService: LoginService,private toastr: ToastrService,private fb: FormBuilder,private modalService: BsModalService,) { 
    this.gameList = exchangeGameList.data
    this.getBanner();
  }

  ngOnInit(): void {
    this.size = window.screen.width;
    this.logo = localStorage.getItem('logo')
    this.createFrom();
  }
  getBanner(){
    this.open.getBannerImage().subscribe((res) => {
      if (res.status == true) {
        let a = res.data;
        if (a.length != 0) {
          for (let i = 0; i < a.length; i++) {
            if((a[i]).hasOwnProperty('self_host')){
             if((a[i].self_host == true)){
               a[i].bannerUrl = this.Base_Url + a[i].description;
             } else {
               a[i].bannerUrl =  a[i].description;
             }
            } else {
             a[i].bannerUrl = this.Base_Url + a[i].description;
            }
          }
          this.banner = a;
          localStorage.setItem('Banner', JSON.stringify(this.banner))
        }
      }
    })
  }
  dashboard(){
    this.router.navigate(['openDashboard'])
  }
  error(){
    this.toastr.error('Coming Soon');
  }
  login(){
    this.router.navigate(['login'])
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe:[false]
    })
    if(JSON.parse(localStorage.getItem('RememberMe'))){
      this.Form.patchValue({
        user_name:localStorage.getItem('user_name'),
        password:localStorage.getItem('password'),
        isRememberMe:localStorage.getItem('RememberMe')
      })
    }
  }
  get f() { return this.Form.controls; }
  async onSubmitLogin(from?: string) {
    this.loginButtonDisable = true
    if (this.clickTimeout) {
      this.setClickTimeout(() => { });
    } else {
      this.setClickTimeout(() => {
        if (from == 'login') {
          this.headerSingleLoginClick()
        } else {
          this.handleSingleLoginClick();
        }
      })  
    }
  }
  public handleSingleLoginClick() {
    this.loading = true;   
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if(this.tPassword == this.transactionPassword){
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.loading = false;
          this.modalRef.hide();
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
           this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.loading = false;
          this.showResponseMessage = result.msg;
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loginButtonDisable = false;
        this.loading = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loginButtonDisable = false;
        this.loading = false;
        this.showResponseMessage = 'Invalid Captcha';
        this.createRandomTransactionPassword();
    }
   
  }
  public headerSingleLoginClick() {
    this.loading = true;  
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if(this.tPassword == this.transactionPassword){
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.loading = false;
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
           this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          this.router.navigate(['dashboard'])
        } else {
          this.loading = false;
          this.showResponseMessage = result.msg;
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        }
      }, (err) => {
        console.log(err);
        this.loading = false;
        this.loginButtonDisable = false;
        this.createRandomTransactionPassword()
      });
    } else {
      this.loading = false;
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword()
    }
  }
  public setClickTimeout(callback) {
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async redirectToDashboard() {
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }
  async setCokkies(result) {
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    var acctoken = localStorage.getItem('userAccessToken');
    this.router.navigate(['dashboard'])
  }
  openModalLoginCnfm(loginCnfm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginCnfm,
      Object.assign({}, { class: 'loginCnfm-modal gamblingModal',ignoreBackdropClick: true })
    );
  }
  // openModalLoginPopup(loginPopup: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(
  //     loginPopup,
  //     Object.assign({}, { class: 'loginModal modal-dialog-centered modal-fullscreen-lg-down',ignoreBackdropClick: true })
  //   );
  // }
  openModalLoginDesktop(loginDesktop: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginDesktop,
      Object.assign({}, { class: 'loginModalDesktop loginModal modal-dialog-centered modal-fullscreen-lg-down',ignoreBackdropClick: true })
    );
  }
  createRandomTransactionPassword() {
    //initialize a variable having alpha-numeric characters
    var chars = "0123456789";

    //specify the length for the new string to be generated
    var string_length = 4;
    var randomstring = '';

    //put a loop to select a character randomly in each iteration
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    this.transactionPassword = randomstring;
  }
}
