import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { SportService } from '../services/sport.service';
@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.scss']
})
export class CasinoComponent implements OnInit {

  exchangeGameList: any;
  games: string;

  constructor(private sport: SportService, private router: Router,) { }

  ngOnInit(): void {
    this.exchangeGame();
  }
  exchangeGame() {
    let data = {
      sport_id: "-100"
    };
    this.sport.getHomeMatchesList(data).subscribe((res) => {
      if (res.status == true) {
        this.exchangeGameList = res.data
      }
    })
  }
  gotoexchangeGame(cardData) {
    let data = {
      game_id : cardData.match_id
    }
    this.sport.lotusLaunch(data).subscribe((res) => {
      if(res.status == true){
        window.open(res.launchUrl, '_blank')
      }
    })
  }

  statement() {
    this.router.navigate(['statement'])
  }
  profitLoss() {
    this.router.navigate(['profit-loss'])
  }
  info() {
    this.router.navigate(['info'])
  }
  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  rollComission() {
    this.router.navigate(['rollComission'])
  }

}
