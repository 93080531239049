import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service'
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AppValidationService } from '../app-validation/app-validation.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  modalRef: BsModalRef;
  addUserFrom: FormGroup;
  loginButtonDisable = false
  submitted = false;
  transactionPassword: string;
  showResponseMessage;
  public showPassword: boolean;
  private clickTimeout = null;
  a: any;
  logo: string;
  tPassword: any;
  referCode: string;
  isAddUserFromSubmitted: boolean;

  constructor(private router: Router, private modalService: BsModalService,
    private fb: FormBuilder,
    private loginService: LoginService, private cookie: CookieService,
    private toastr: ToastrService,private appValidationService: AppValidationService) { }

    ngOnInit(): void {
      this.logo = localStorage.getItem('logo')
      this.referCode = localStorage.getItem('referCode') != undefined ? localStorage.getItem('referCode')  : '';
      this.createFrom();
      this.createRandomTransactionPassword();
      this.clearCookieAndLocalStorage();
    }
    openModalLoginCnfm(loginCnfm: TemplateRef<any>) {
      this.modalRef = this.modalService.show(
        loginCnfm,
        Object.assign({}, { class: 'loginCnfm-modal modal-lg', ignoreBackdropClick: true })
      );
    }
    createFrom() {
      this.addUserFrom = this.fb.group({
        name: ['', Validators.required],
        user_name: ['', [Validators.required]],
        password: ['', [Validators.required]],
        mobile: ['', [Validators.required,Validators.minLength(8),Validators.maxLength(15)]],
        email:[''],
        refer_code : ['']
      })
      if (this.referCode != undefined || this.referCode != '') {
        this.addUserFrom.controls['refer_code'].setValue(this.referCode);
      }
      this.applyValidationToFormGroup(this.addUserFrom, "AddUser")
    }
  
    applyValidationToFormGroup(formGroupName, jsonArrayName) {
      this.appValidationService.applyValidationRulesToFromGroup(formGroupName, jsonArrayName).then((validators) => {
      }).catch(() => { })
    }

    isControlIsValid(controlName: string): boolean {
      if (this.addUserFrom.controls[controlName].invalid && (this.isAddUserFromSubmitted || this.addUserFrom.controls[controlName].dirty || this.addUserFrom.controls[controlName].touched)) {
        return true;
      }
      return false;
    }
    isControlHasErros(controlName: string) {
      if (this.addUserFrom.controls[controlName].errors) {
        let value = ''
        let object =this.addUserFrom.controls[controlName].errors
        for (var propName in object) {
            if (object.hasOwnProperty(propName) && propName != "required") {
                value = object[propName];
            }
        }
        return value;
      }
      return null;
    }
  
  
    private markFormGroupTouched(formGroup: FormGroup) {
      (<any>Object).values(formGroup.controls).forEach(control => {
        control.markAsTouched();
  
        if (control.controls) {
          this.markFormGroupTouched(control);
        }
      });
    }
  
  
    get f() { return this.addUserFrom.controls; }
  
    async onSubmitLogin() {
      this.loginButtonDisable = true
      this.addUserFrom.controls['name'].setValue(this.addUserFrom.value.user_name);
      this.appValidationService.markFormGroupTouched(this.addUserFrom);
      if(this.addUserFrom.controls.email.value == ''){
        delete this.addUserFrom.value['email'];
      }
      if(this.addUserFrom.controls.refer_code.value == ''){
        delete this.addUserFrom.value['refer_code'];
      }
      if (this.clickTimeout) {
        this.setClickTimeout(() => { });
      } else {
        this.setClickTimeout(() =>
          this.handleSingleLoginClick());
      }
    }
    public handleSingleLoginClick() {
      //The actual action that should be performed on click      
      this.submitted = true;
      this.a = (this.addUserFrom.value.user_name);
      let b = (this.a).toLowerCase()
      this.addUserFrom.value.user_name = b;
      if (this.addUserFrom.invalid) {
        this.loginButtonDisable = false
        return;
      }
      this.loginService.register(this.addUserFrom.value).subscribe((result) => {
        if (result.status == true) {
          const data={user_name:this.addUserFrom.controls['user_name'].value,
                      password:this.addUserFrom.controls['password'].value,
                      grant_type:'password'
                    }
      // if (this.tPassword == this.transactionPassword) {
        this.loginService.submitlogin(data).subscribe((result) => {
          if (result.status == true) {
            this.setCokkies(result);
            this.toastr.success(result.msg, '', {
              positionClass: 'toast-bottom-right',
              timeOut: 1000
            })
            this.setCokkies(result);
            localStorage.setItem('userDetails', JSON.stringify(result.data));
            localStorage.setItem('operator', JSON.stringify(result.operatorId));
            this.loginButtonDisable = false
            this.redirectToDashboard();
          } else {
            this.showResponseMessage = result.msg;
            this.loginButtonDisable = false;
            this.createRandomTransactionPassword()
          }
        }, (err) => {
          console.log(err);
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword()
        });
      }
      else{
        this.showResponseMessage = result.msg;
        this.loginButtonDisable = false
      }
    }, (err) => {
      console.log(err);
      this.loginButtonDisable = false;
    });
      // } 
      // else {
      //   this.loginButtonDisable = false;
      //   this.showResponseMessage = 'Invalid Captcha';
      //   this.createRandomTransactionPassword()
      // }
  
    }
  
    async redirectToDashboard() {
      // var acctoken = await this.cookie.get('accessToken');
      var acctoken = localStorage.getItem('userAccessToken');
      if (acctoken != null && acctoken != undefined && acctoken != '') {
        this.router.navigate(['dashboard'])
      }
    }
  
    async setCokkies(result) {
      localStorage.setItem('userAccessToken', result.token.accessToken);
      this.cookie.set('userId', result.data._id);
      localStorage.setItem('userRefreshToken', result.token.refreshToken);
      var acctoken = localStorage.getItem('userAccessToken');
      this.router.navigate(['dashboard'])
    }
  
    createRandomTransactionPassword() {
      //initialize a variable having alpha-numeric characters
      var chars = "0123456789";
  
      //specify the length for the new string to be generated
      var string_length = 4;
      var randomstring = '';
  
      //put a loop to select a character randomly in each iteration
      for (var i = 0; i < string_length; i++) {
        var rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum, rnum + 1);
      }
  
      this.transactionPassword = randomstring;
  
    }
  
    public setClickTimeout(callback) {
      // clear any existing timeout
      clearTimeout(this.clickTimeout);
      this.clickTimeout = setTimeout(() => {
        this.clickTimeout = null;
        callback();
      }, 400);
    }
  
    clearCookieAndLocalStorage() {
      this.cookie.delete('userId');
      this.cookie.delete('is_socket');
      this.loginService.clearLocalStorage()
    }
    download() {
      var a = window.location.origin
      var b = window.location.hostname
      var c = a + '/assets/apk/' + b + '.apk'
      window.open(c, "_blank")
    }
}
