<div class="app-body footer-condition">
  <app-header></app-header>
  <div class="main_wrap_container acountstatementpage">
    <div class="col-left">
      <div class="sub_path">
        <div class="path">
          <a  class="path-back">...</a>
          <ul>
            <li class="path-last"><a>My Account</a></li>
          </ul>
        </div>
        <ul class="menu-list" data-widget="tree">
          <li>
            <a (click)="info()">
              <span>My Profile</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="rollComission()">
              <span>Rolling Commision</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="statement()">
              <span>Account Statement</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/betHistory','openBets']">
              <span>Bets History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="profitLoss()">
              <span>Profit & Loss</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="historydiv()" class="select">
              <span>Password History</span><span class="pull-right-container"></span>
            </a>
          </li>
          <li>
            <a (click)="activity()">
              <span>Activity Log</span><span class="pull-right-container"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div id="centerColumn" class="col-center report">

      <h2>Password Change History</h2>
      <div class="report-responsive">
        <table class="row-border hover table01" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
        </table>
      </div>
    </div>
  </div>
  <app-footer-main></app-footer-main>
</div>