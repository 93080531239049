import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { DateTime } from '../../dateTime';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ActivatedRoute, Router } from '@angular/router';
import { SportService } from '../services/sport.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { SocketServiceService } from '../services/socket-service.service';
import { ReportService } from '../services/report.service';
import { environment } from '../../environments/environment';
import { DataTableDirective } from 'angular-datatables';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import * as moment from 'moment';
class DataTablesResponse {
  data: any[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}
@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent implements AfterViewInit, OnDestroy, OnInit {
  userDetails: any;
  pageSize: number = 50;
  moment: any = moment;
  data: any;
  selections: any;
  statementsLength: any;
  statementsDataSource: any;
  statementsTotalItems: any;
  statementsPageIndex: number;
  timeStatement: Date;
  dateStatement: Date;
  endDateStatement: Date;
  endTimeStatement: Date;
  endTime: any;
  dataSource: any;
  openBetsLength: any;
  MatchList: any;
  searchMatch: any;
  layData: any = [];
  backData: any = [];
  betList: boolean;
  public Base_Url = environment['adminServerUrl'];
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  public token = localStorage.getItem('userAccessToken');
  reqHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ` + this.token
  });
  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private sport: SportService, private reportService: ReportService,
    private cookie: CookieService, private cdRef: ChangeDetectorRef, private toastr: ToastrService, private socketService: SocketServiceService, private router: Router) { }

  statement() {
    this.router.navigate(['statement'])
  }
  profitLoss() {
    this.router.navigate(['profit-loss'])
  }
  info() {
    this.router.navigate(['info'])
  }
  history() {
    this.router.navigate(['history'])
  }
  activity() {
    this.router.navigate(['activity'])
  }
  rollComission() {
    this.router.navigate(['rollComission'])
  }
  // date time picker
  @ViewChild('popoverRef') private _popoverRef: PopoverDirective;
  @ViewChild('startpopoverRef') private _startpopoverRef: PopoverDirective;
  @ViewChild('endpopoverRef') private _endpopoverRef: PopoverDirective;
  time: Date;
  date: Date;
  isDateVisible: boolean = true;
  isMeridian: boolean = false;
  dateTime = new Date();
  statementStartDate = new Date()
  statementEndDate = new Date()
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
    this.statementStartDate.setHours(0, 0, 0, 0);
    this.statementEndDate.setHours(23, 59, 59)
    const that = this;
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      serverSide: true,
      searching: false,
      processing: true,
      destroy: true,
      ajax: (dataTablesParameters1: any, callback) => {
        let dataTablesParameters = {
          limit: dataTablesParameters1.length,
          page: (dataTablesParameters1.start / dataTablesParameters1.length) + 1,
          "statement_type": '1',
          "user_id": this.userDetails.user_id,
          "from_date": this.statementStartDate.toISOString(),
          "to_date": this.statementEndDate.toISOString()
        }
        that.http.post<DataTablesResponse>(this.Base_Url + 'account/userAccountStatement', dataTablesParameters, { headers: this.reqHeader })
          .subscribe(resp => {
            if (resp["status"]) {
              this.statementsLength = resp.data[0].data.length
              this.statementsDataSource = resp.data[0].data;
              for (let i = 0; i < this.statementsDataSource.length; i++) {
                this.statementsDataSource[i].updateDateTime = moment(this.statementsDataSource[i].date).utcOffset("+5:30").format('DD-MM-YYYY HH:mm:ss');
                if (this.statementsDataSource[i].credit_debit >= 0) {
                  this.statementsDataSource[i].credit = this.statementsDataSource[i].credit_debit
                  this.statementsDataSource[i].debit = (0)
                } else {
                  this.statementsDataSource[i].credit = (0)
                  this.statementsDataSource[i].debit = -(this.statementsDataSource[i].credit_debit)
                }
              }
              if (resp.data[0].metadata.length != 0) {
                callback({
                  recordsTotal: resp.data[0].metadata[0].total,
                  recordsFiltered: resp.data[0].metadata[0].total,
                  data: this.statementsDataSource
                });
              } else {
                this.statementsDataSource = []
                callback({
                  recordsTotal: 0,
                  recordsFiltered: 0,
                  data: this.statementsDataSource
                });
              }
            } else {
              this.toastr.error(resp["msg"]);
              this.statementsDataSource = []
              callback({
                recordsTotal: 0,
                recordsFiltered: 0,
                data: this.statementsDataSource
              });
            }


          });
      },
      columns: [
        {
          title: 'Date & Time',
          data: 'updateDateTime'
        },
        {
          title: 'Deposit',
          data: 'credit',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span >' + (row.credit == 0 ? '(0)' : (row.credit).toFixed(2)) + '</span>';
            return content;
          }
        },
        {
          title: 'Withdrawl',
          data: 'debit',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span style="color: red;">' + (row.debit == 0 ? '(0)' : (row.debit).toFixed(2)) + '</span>';
            return content;
          }
        },
        {
          title: 'Balance',
          data: 'balance',
          "render": function (data, type, row) {
            var content = ''
            content = content + '<span >' + (row.balance).toFixed(2) + '</span>';
            return content;
          }
        },
        {
          title: 'Remark',
          data: 'remark'
        },
        {
          title: 'From / To',
          data: 'description'
        }],
    };
    if (this.dateTime) {
      this.time = this.date = this.dateTime;
      return;
    }
    this.date = this.time = new Date(); if (this.statementStartDate) {
      this.time = this.date = this.statementStartDate;
      return;
    }
    if (this.statementEndDate) {
      this.time = this.date = this.statementEndDate;
      return;
    }

  }

  dateSelectionDone() {
    this.isDateVisible = false;
  }
  updateDateStatement() {
    if (this.dateStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
    if (!this.timeStatement) {
      this.timeStatement = this.dateStatement;
    }
  }
  updateEndDateStatement() {
    if (this.endDateStatement) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
    if (!this.endTimeStatement) {
      this.endTimeStatement = this.endDateStatement;
    }
  }
  updateTimeStatement() {
    if (this.timeStatement) {
      this.statementStartDate = DateTime.getDateTime(this.dateStatement, this.timeStatement);
    }
  }
  updateEndTimeStatement() {
    if (this.endTime) {
      this.statementEndDate = DateTime.getDateTime(this.endDateStatement, this.endTimeStatement);
    }
  }
  updateDate() {

    if (this.date) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
    if (!this.time) {
      this.time = this.date;
    }
  }

  updateTime() {
    if (this.time) {
      this.dateTime = DateTime.getDateTime(this.date, this.time);
    }
  }

  showDate() {
    this.isDateVisible = true;
  }

  showTime() {
    this.isDateVisible = false;
  }

  close() {
    this._popoverRef.hide();
  }

  now() {
    this.dateTime = DateTime.now(this.date);
    this.time = this.dateTime;
  }

  today() {
    this.statementStartDate = DateTime.now(this.date);
    this.statementEndDate = DateTime.now(this.date);
  }
  clear() {
    this.time = void 0;
    this.date = void 0;
    this.dateTime = void 0;
  }

  getStatement(event?: any, from?: string) {
    let page = event == undefined ? 1 : event.pageIndex + 1
    this.pageSize = event == undefined ? 50 : event.pageSize
    this.data = {
      "statement_type": '1',
      "user_id": this.userDetails.user_id,
      "limit": this.pageSize,
      "page": page,

    }
    if (this.data.statement_type == undefined || this.data.statement_type == null || this.data.statement_type == 0) {
      delete this.data.statement_type;
    }
    if (from == 'filterBtnClick') {

      if (this.statementStartDate) {
        this.data["from_date"] = this.statementStartDate.toISOString()
      }
      if (this.statementEndDate) {
        this.data["to_date"] = this.statementEndDate.toISOString()
      }
    }
    this.rerender();
  }
  pageChange(event?: any) {
    if (event) {
      this.statementsPageIndex = event
    } else {
      this.statementsPageIndex = 1
    }
    this.getStatement(undefined, 'filterBtnClick')
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
