import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {  LoginComponent } from './login/login.component';
import {  DashboardComponent } from './dashboard/dashboard.component';
import {  MatchDetailComponent } from './match-detail/match-detail.component';
import { HomeComponent } from './home/home.component';
import { InplayComponent } from './inplay/inplay.component';
import { StatementComponent } from './statement/statement.component';
import { ProfitLossComponent } from './profit-loss/profit-loss.component';
import { AuthCheckGuard } from './services/auth-check.guard';
import {  CasinoComponent } from './casino/casino.component';
import { SupernovaComponent } from './supernova/supernova.component';
import { EventplComponent } from './eventpl/eventpl.component';
import { MultiMarketComponent } from './multi-market/multi-market.component';
import { PlMatchWiseComponent } from './pl-match-wise/pl-match-wise.component';
import { PlMarketwiseComponent } from './pl-marketwise/pl-marketwise.component';
import { PlUserBetComponent } from './pl-user-bet/pl-user-bet.component';
import { InfoComponent } from './info/info.component';
import { OpenDashboardComponent } from './open-dashboard/open-dashboard.component';
import { OpenInplayComponent } from './open-inplay/open-inplay.component';
import { OpenMultiMarketComponent } from './open-multi-market/open-multi-market.component';
import { HistoryComponent } from './history/history.component';
import { ActivitylogComponent } from './activitylog/activitylog.component';
import { RollingComissionComponent } from './rolling-comission/rolling-comission.component';
import { OpenMatchDetailComponent } from './open-match-detail/open-match-detail.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { BetHistoryComponent } from './bet-history/bet-history.component';
import { PlUserBetSnComponent } from './pl-user-bet-sn/pl-user-bet-sn.component';
import { OpenHomeComponent } from './open-home/open-home.component';
import { OpenNewsComponent } from './open-news/open-news.component';
import { QtechComponent } from './qtech/qtech.component';
import { RegisterComponent } from './register/register.component';
import { DepositComponent } from './deposit/deposit.component';
import { WithdrawComponent } from './withdraw/withdraw.component';
import { WithdrawDetailComponent } from './withdraw-detail/withdraw-detail.component';
const routes: Routes = [
  {path: '', redirectTo: 'openHome', pathMatch: 'full'},
  {path: 'openDashboard', component: OpenDashboardComponent},
  {path: 'openDashboard/:sportName', component: OpenDashboardComponent},
  {path: 'openInplay', component: OpenInplayComponent },
  {path: 'openInplay/:data', component: OpenInplayComponent },
  {path: 'openMulti', component: OpenMultiMarketComponent },
  {path: 'openDetail', component: OpenMatchDetailComponent },
  {path: 'openDetail/:match_id', component: OpenMatchDetailComponent },
  {path: 'openHome', component: OpenHomeComponent },
  {path: 'login', component: LoginComponent},
  {path: 'register', component: RegisterComponent},
  {path: 'deposit', component: DepositComponent},
  {path: 'withdraw', component: WithdrawComponent},
  {path: 'withdraw-details', component: WithdrawDetailComponent},
  {path: 'dashboard', component: DashboardComponent , canActivate: [AuthCheckGuard]},
  {path: 'qtech', component: QtechComponent , canActivate: [AuthCheckGuard]},
  {path: 'betHistory/:type', component: BetHistoryComponent , canActivate: [AuthCheckGuard]},
  {path: 'dashboard/:sportName', component: DashboardComponent , canActivate: [AuthCheckGuard]},
  {path: 'match-detail', component: MatchDetailComponent , canActivate: [AuthCheckGuard]},
  {path: 'match-detail/:match_id', component: MatchDetailComponent , canActivate: [AuthCheckGuard]},
  {path: 'home', component: HomeComponent , canActivate: [AuthCheckGuard]},
  {path: 'inplay', component: InplayComponent , canActivate: [AuthCheckGuard]},
  {path: 'inplay/:data', component: InplayComponent , canActivate: [AuthCheckGuard]},
  {path: 'statement', component: StatementComponent , canActivate: [AuthCheckGuard]},
  {path: 'profit-loss', component: ProfitLossComponent , canActivate: [AuthCheckGuard]},
  {path:'multiMarket',component:MultiMarketComponent , canActivate: [AuthCheckGuard]},
  { path: 'casino', component: CasinoComponent, canActivate: [AuthCheckGuard] },
  { path: 'snova/:id/:name/:code',component:SupernovaComponent, canActivate: [AuthCheckGuard] },
  { path: 'eventpl', component: EventplComponent, canActivate: [AuthCheckGuard] },
  { path: 'info', component: InfoComponent, canActivate: [AuthCheckGuard] },
  { path: 'history', component: HistoryComponent, canActivate: [AuthCheckGuard] },
  { path: 'activity', component: ActivitylogComponent, canActivate: [AuthCheckGuard] },
  { path: 'myAccount', component: MyAccountComponent, canActivate: [AuthCheckGuard] },
  { path: 'rollComission', component: RollingComissionComponent, canActivate: [AuthCheckGuard] },
  { path: 'plMatchwise/:sportId/:sportName/:fromDate/:toDate/:typeId', component: PlMatchWiseComponent, canActivate: [AuthCheckGuard] },
  { path: 'plMarketwise/:matchId/:matchName/:typeId/:sportId', component: PlMarketwiseComponent, canActivate: [AuthCheckGuard] },
  { path: 'plUserBet/:matchId/:marketId/:type', component: PlUserBetComponent, canActivate: [AuthCheckGuard] },
  { path: 'plSnUserBet/:marketId/:sportName/:matchName/:marketName', component: PlUserBetSnComponent, canActivate: [AuthCheckGuard] },
  { path: 'openNews', component: OpenNewsComponent },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
