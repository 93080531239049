<div class="main-wrapper">
    <!-- Loading Wrap -->
  <div id="loading" class="loading-wrap d-none-mobile" *ngIf="loading == true">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>
  
  <div class="loading-overlay d-none-desktop" id="loading" *ngIf="loading == true">
    <div class="loading-wrap">
      <div class="loading">
        <div></div>
        <div></div>
      </div>
      <p>Loading...
      </p>
    </div>
  </div>
    <app-open-header></app-open-header>

    <div class="col-center gamehall">
        <div class="marquee-box"><h4 _ngcontent-eft-c39="">News</h4>
            <div class="marquee"><marquee width="100%" direction="left"><a href="#">🏆𝐈𝐂𝐂 𝐂𝐑𝐈𝐂𝐊𝐄𝐓 𝐖𝐎𝐑𝐋𝐃 𝐂𝐔𝐏 𝟐𝟎𝟐𝟑 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 🏆||𝐒𝐔𝐏𝐄𝐑 50 𝐂𝐔𝐏 𝐂𝐑𝐈𝐂𝐊𝐄𝐓 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 ||🎮 𝐕𝐈𝐑𝐓𝐔𝐀𝐋 𝐂𝐑𝐈𝐂𝐊𝐄𝐓 𝐁𝐄𝐓𝐒 𝐀𝐕𝐀𝐈𝐋𝐀𝐁𝐋𝐄 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄🎮 ||🎾 𝐓𝐄𝐍𝐍𝐈𝐒 𝐒𝐄𝐓 𝐖𝐈𝐍𝐍𝐄𝐑 𝐌𝐀𝐑𝐊𝐄𝐓 𝐀𝐕𝐀𝐈𝐋𝐀𝐁𝐋𝐄 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 🎾 ||🏀 𝐏𝐑𝐄𝐌𝐈𝐔𝐌 𝐒𝐏𝐎𝐑𝐓𝐒𝐁𝐎𝐎𝐊 𝐁𝐄𝐓𝐒 𝐒𝐓𝐀𝐑𝐓𝐄𝐃 𝐈𝐍 𝐎𝐔𝐑 𝐄𝐗𝐂𝐇𝐀𝐍𝐆𝐄 🏀||Toss Fancy Market Available for all Matches|| </a></marquee></div></div>
        <div class="page-wrap">
            <div class="promo-banner-wrap active">
                <div class="promo-banner">
                    <ul class="slides">
                        <li class="banner">
                            <a><img src="assets/sky_img/kv-skyexchang-skyfair-m.jpg" alt=""></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="gamehall-wrap-simple">
                <a (click)="dashboard()">
                    <!-- <dl class="on_live">
                        <dt>
                            <p class="live_icon"><span></span> Live</p>
                        </dt>
                        <dd>
                            <p>Cricket</p> <span id="count">3</span>
                        </dd>
                        <dd>
                            <p>Soccer</p> <span id="count">3</span>
                        </dd>
                        <dd>
                            <p>Tennis</p> <span id="count">3</span>
                        </dd>
                        <dd>
                            <p>E-Soccer</p> <span id="count">3</span>
                        </dd>
                        <dd>
                            <p>Kabaddi</p> <span id="count">3</span>
                        </dd>
                    </dl> -->
                    <dl class="entrance-title" >
                        <dt>Sports</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_sports.png" alt="">
                </a>

                <a (click)="error()">
                    <dl class="entrance-title">
                        <dt>Kabaddi</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_kabaddi.png" alt="">
                </a>
                <!-- <a class="entrance" >
                    <img src="assets/sky_img/banner_skyexchangeBlog.png" alt="">
                </a> -->
                <a (click)="error()">
                    <dl class="entrance-title">
                        <dt>Virtual Cricket</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_virtualsports.png" alt="">
                </a>
                <a (click)="login()">
                    <dl class="entrance-title">
                        <dt>Live Casino</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_casino.png" alt="">
                </a>
                <a class="entrance-half" *ngFor = "let game of gameList;" (click)="size > 767 ? openModalLoginDesktop(loginDesktop) : login()">
                    <dl class="entrance-title">
                        <dt>{{game?.gameName}}</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/Casino/{{game?.gameId}}.jpg" alt="">
                </a>
                <!-- <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Supernowa</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_supernowa-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>7mojos</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_7mojos-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>HORSEBOOK</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_horsebook-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Minesweeper</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_minesweeper-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Sky Trader</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_skytrader-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>TeenPatti 20-20</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_TeenPatti2020-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>NumberKing</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_NumberKing-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Big small</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_BigSmall-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>TeenPatti Joker</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_TeenPattiJoker-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>7up7down</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_7up7down-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Dragon & Tiger</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_DragonNTiger-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Callbreak Quick</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_CallbreakQuick-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Sic Bo</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_SicBo-Jili-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Baccarat</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_Baccarat-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Bonus Dice</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_BonusDice-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Heist</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_Heist-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>5 Card Poker</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_5CardPoker-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Color Game</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_ColorGame-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>32 Cards</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_32card-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Rummy</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_rummy-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>BetGames</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_betgames-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Andar Bahar</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_andarBahar-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Sicbo</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_sicbo-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>7 UP 7 Down</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_sevenUpDown-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Coin Toss</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_CoinToss-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Teen Patti</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_teenPatti-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Card Matka</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_cardMatka-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Number Matka</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_numberMatka-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Binary</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_binary-half.png" alt="">
                </a>
                <a class="entrance-half">
                    <dl class="entrance-title">
                        <dt>Bpoker</dt>
                        <dd>Play Now</dd>
                    </dl>
                    <img src="assets/sky_img/banner_bpoker-half.png" alt="">
                </a> -->
            </div>
        </div>
    </div>
    <app-open-footer></app-open-footer>
</div>

<ng-template #loginCnfm>
    <div>
        <div class="modal-header border-bottom-0 pb-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="modalRef.hide()"></button>
        </div>
        <div class="modal-body text-center">
            <div class="confirm-text">
                <div class="text1">Non-Gambling Territories.</div>
                <hr class="confirm-line">
                <div class="text2">Connecting to our site from non gambling countries, it will be User's
                    responsibility to ensure that their use of the service is lawful.</div>
            </div>
            <div class="confirm-text mb-3">
                <div class="text1">Underage gambling is prohibited.</div>
                <hr class="confirm-line">
                <div class="text2">Please confirm if you are 18 years old and above as of today.</div>
            </div>
            <div class="modal-footer border-top-0 justify-content-center">
                <button class="btn btn-light" data-bs-target="#loginModal" data-bs-toggle="modal"
                    (click)="modalRef.hide();openModalLoginDesktop(loginDesktop)" style="cursor:pointer">Confirm</button>
                <button type="button" class="btn btn-outline-light" data-bs-dismiss="modal" (click)="modalRef.hide();"
                    style="cursor:pointer">Exit</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #loginDesktop>
    <div class="lgoin-main dialog-wrap-w login_to_go">
        <div class="kv">
            <img src="{{logo}}" alt="{{logo}}">
        </div>
        <form action="" [formGroup]='Form' novalidate="" class="login-panel">
            <dl class="">
                <dt>Please login to continue</dt>
                <dd>
                    <input formControlName="user_name" placeholder="UserName" required="" type="text">
                </dd>
                <dd>
                    <input formControlName="password" placeholder="Password" required="" type="password">
                </dd>
                <dd class="valid-code">
                    <input type="text" id="validCode" pattern="[0-9]*" placeholder="Validation Code" autocomplete="off"
                        [(ngModel)]="tPassword" [ngModelOptions]="{standalone: true}">
                    <span class="code">{{transactionPassword}}</span>
                </dd>
                <dd style="margin-bottom: 0;"><span
                        class="err-invalid-modal error-message">{{showResponseMessage}}</span></dd>
                <dd>
                    <a class="btn-send ui-link" (click)="onSubmitLogin()">Login</a>
                </dd>
            </dl>
        </form>
        <a id="close" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide();"></a>
    </div>
</ng-template>