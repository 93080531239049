import { Component, OnChanges, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service'
import { OpenApiService } from '../services/open-api.service';
import { Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-open-sidebar',
  templateUrl: './open-sidebar.component.html',
  styleUrls: ['./open-sidebar.component.scss']
})
export class OpenSidebarComponent implements OnInit {
  Form: FormGroup;
  loginButtonDisable = false
  submitted = false;
  transactionPassword: string;
  showResponseMessage;
  public showPassword: boolean;
  private clickTimeout = null;
  a: any;
  modalRef: BsModalRef;
  logo: string;
  allSportsList: any;
  allSeriesList: any;
  sportList: boolean = true;
  matchList: any[] = [];
  seriesId: any;
  isShowMenu: boolean[] = [];
  tPassword: any;
  constructor(public toastr: ToastrService, private router: Router, private open: OpenApiService, private fb: FormBuilder,
    private modalService: BsModalService, private loginService: LoginService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.logo = localStorage.getItem('logo');
    this.createFrom();
    let a = window.screen.width;
    if (a > 767) {
      this.getAllSportsListData()
    }
    this.createRandomTransactionPassword();
  }

  getAllSportsListData() {
    let userData = {};
    this.open.getAllSportsList(userData).subscribe(res => {
      if (res.status == true) {
        this.sportList = true;
        this.allSportsList = res.data
        this.allSportsList = (this.allSportsList).sort(function (a, b) { return b.sport_id - a.sport_id });
      } else {
        this.toastr.error(res.msg);
      }

    })
  }

  status: boolean = false;
  headerNav() {
    this.status = !this.status;
  }
  dashboard() {
    this.router.navigate(['dashboard'])
  }
  inplay() {
    this.router.navigate(['inplay'])
  }

  getSeriesList(id) {
    let data = {
      sport_id: id
    };
    this.open.getSeriesList(data).subscribe(res => {
      this.sportList = false;
      this.allSeriesList = res.data
      this.isShowMenu = Array(this.allSeriesList.length).fill(false);
      this.matchList = Array(this.allSeriesList.length).fill([]);
      this.cdRef.detectChanges();
    })
  }
  getMatchList(param, index) {
    let data = {
      series_id: param.series_id
    };
    this.isShowMenu[index] = !this.isShowMenu[index]
    this.open.getMatchList(data).subscribe(res => {
      this.seriesId = param.series_id
      this.matchList[index] = res.data;
      this.cdRef.detectChanges;
    })
  }
  goToDashboard(series) {
    if (series.sport_id == -100) {
      localStorage.setItem('selectedMatch', JSON.stringify(series));
      this.router.navigate(['exchDetail']);
    } else {
      this.router.navigate(['home/' + series.sport_id + '/' + series.series_id])
    }
  }
  createFrom() {
    this.Form = this.fb.group({
      user_name: ['', Validators.required],
      password: ['', [Validators.required]],
      grant_type: ['password'],
      isRememberMe: [false]
    })
    if (JSON.parse(localStorage.getItem('RememberMe'))) {
      this.Form.patchValue({
        user_name: localStorage.getItem('user_name'),
        password: localStorage.getItem('password'),
        isRememberMe: localStorage.getItem('RememberMe')
      })
    }
  }
  get f() { return this.Form.controls; }
  async onSubmitLogin() {
    this.loginButtonDisable = true
    if (this.clickTimeout) {
      this.setClickTimeout(() => { });
    } else {
      // if timeout doesn't exist, we know it's first click 
      // treat as single click until further notice
      this.setClickTimeout(() =>
        this.handleSingleLoginClick());
    }
  }
  public handleSingleLoginClick() {
    //The actual action that should be performed on click      
    this.submitted = true;
    this.a = (this.Form.value.user_name);
    let b = (this.a).toLowerCase()
    this.Form.value.user_name = b;
    if (this.Form.invalid) {
      this.loginButtonDisable = false
      return;
    }
    if (this.tPassword == this.transactionPassword) {
      this.loginService.submitlogin(this.Form.value).subscribe((result) => {
        if (result.status == true) {
          this.modalRef.hide();
          this.toastr.success(result.msg, '', {
            positionClass: 'toast-bottom-right',
            timeOut: 1000
          })
          this.setCokkies(result);
          localStorage.setItem('userDetails', JSON.stringify(result.data));
          localStorage.setItem('operator', JSON.stringify(result.operatorId));
          this.loginButtonDisable = false
          // this.redirectToDashboard();
          this.router.navigate(['dashboard'])
        } else {
          // this.modalRef.hide();
          // this.toastr.error(result.msg,'',{
          //   timeOut: 10000,
          // });
          this.showResponseMessage = result.msg;
          this.loginButtonDisable = false;
          this.createRandomTransactionPassword();
        }
      }, (err) => {
        console.log(err);
        this.loginButtonDisable = false;
      });
    } else {
      this.loginButtonDisable = false;
      this.showResponseMessage = 'Invalid Captcha';
      this.createRandomTransactionPassword();
    }
  }
  public setClickTimeout(callback) {
    // clear any existing timeout
    clearTimeout(this.clickTimeout);
    this.clickTimeout = setTimeout(() => {
      this.clickTimeout = null;
      callback();
    }, 400);
  }
  async redirectToDashboard() {
    // var acctoken = await this.cookie.get('accessToken');
    var acctoken = localStorage.getItem('userAccessToken');
    if (acctoken != null && acctoken != undefined && acctoken != '') {
      this.router.navigate(['dashboard'])
    }
  }
  async setCokkies(result) {
    localStorage.setItem('userAccessToken', result.token.accessToken);
    localStorage.setItem('userId', result.data._id);
    localStorage.setItem('userRefreshToken', result.token.refreshToken);
    var acctoken = localStorage.getItem('userAccessToken');
    this.router.navigate(['dashboard'])

  }
  openModalLoginCnfm(loginCnfm: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginCnfm,
      Object.assign({}, { class: 'loginCnfm-modal gamblingModal', ignoreBackdropClick: true })
    );
  }
  openModalLoginPopup(loginPopup: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      loginPopup,
      Object.assign({}, { class: 'loginModal loginModalDesktop modal-dialog-centered modal-fullscreen-lg-down', ignoreBackdropClick: true })
    );
  }
  matchDetail(matchData) {
    localStorage.setItem('matchData', JSON.stringify(matchData));
    this.router.navigate(['openDetail/' + matchData.match_id]);
  }

  createRandomTransactionPassword() {
    //initialize a variable having alpha-numeric characters
    var chars = "0123456789";

    //specify the length for the new string to be generated
    var string_length = 4;
    var randomstring = '';

    //put a loop to select a character randomly in each iteration
    for (var i = 0; i < string_length; i++) {
      var rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    this.transactionPassword = randomstring;
  }
}
