<div class="app-body">
  <app-header></app-header>
    <div class="main_wrap_container activitylogpage">
      <div class="col-left">
        <div class="sub_path">
          <div class="path">
            <a  class="path-back">...</a>
            <ul>
              <li class="path-last"><a >My Account</a></li>
            </ul>
          </div>
          <ul class="menu-list" data-widget="tree">
              <li>
                <a (click)="info()">
                  <span>My Profile</span><span class="pull-right-container"></span>
                </a>
              </li>
              <li>
                <a (click)="rollComission()">
                  <span>Rolling Commision</span><span class="pull-right-container"></span>
                </a>
              </li>
              <li>
                <a (click)="statement()">
                  <span>Account Statement</span><span class="pull-right-container"></span>
                </a>
              </li>
              <li>
                <a [routerLink]="['/betHistory','openBets']">
                  <span>Bets History</span><span class="pull-right-container"></span>
                </a>
              </li>
              <li>
                <a (click)="profitLoss()">
                  <span>Profit & Loss</span><span class="pull-right-container"></span>
                </a>
              </li>
              <li>
                <a (click)="history()">
                  <span>Password History</span><span class="pull-right-container"></span>
                </a>
              </li>
              <li>
                <a (click)="activity()" class="select">
                  <span>Activity Log</span><span class="pull-right-container"></span>
                </a>
              </li>
          </ul>
        </div>
      </div>

      <div id="centerColumn" class="col-center report">
        <h2>Activity Log</h2>
        <div class="report-responsive">
          <table class="table01">
            <tbody><tr>
              <th width="15%" class="align-L">Login Date & Time</th>
              <th width="15%" class="align-L">Login Status</th>
              <th width="12%">IP Address</th>
              <th width="28%">ISP</th>
              <th width="">City/State/Country</th>
              <!-- <th width="">User Agent Type</th> -->
            </tr>
            </tbody>
            <tbody>
              <tr *ngFor="let onlineuser of onlineUserDetails;let i = index;">
                <td class="align-L">{{(onlineuser?.login_time | date :'dd-MM-yyyy HH:mm:ss')}}</td>
                <td class="align-L green"><span [ngClass]="{'text-danger':onlineuser?.login_status == 'login_failed','text-success':onlineuser?.login_status == 'login_success'}">{{onlineuser?.login_status}}</span></td>
                <td>{{onlineuser?.ip_address}}</td>
                <td>{{onlineuser?.geolocation?.isp}}</td>
                <td>{{onlineuser?.geolocation?.city}}/{{onlineuser?.geolocation?.state}}/{{onlineuser?.geolocation?.country}}</td>
                <!-- <td>Browser</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  <app-footer-main></app-footer-main>
</div>

