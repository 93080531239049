<!-- Loading Wrap -->
<div id="loading" class="loading-wrap d-none-mobile" *ngIf="loading == true">
    <ul class="loading">
      <li><img src="assets/sky_img/loading40.gif"></li>
      <li>Loading...
      </li>
      <br>
      <li><span id="progress"></span></li>
    </ul>
  </div>
  
  <div class="loading-overlay d-none-desktop" id="loading" *ngIf="loading == true">
    <div class="loading-wrap">
      <div class="loading">
        <div></div>
        <div></div>
      </div>
      <p>Loading...
      </p>
    </div>
  </div>
<div class="top">
    <div class="header full-wrap">
        <h1 class="top-logo">
            <img src="assets/sky_img/logo-skyexch.webp" alt="">
        </h1>
        <div ngbDropdown id="searchWrap" class="search-wrap dropdown d-none d-lg-block">
            <div class="input-group">
                <form class="d-flex position-relative" (click)="homematches()">
                    <input ngbDropdownToggle class="search-input dropdown-toggle" id="dropdownMenuLink"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="text" autocomplete="off"
                        [(ngModel)]="searchSport" [ngModelOptions]="{standalone: true}" placeholder="Search Events">
                    <span class="float-icon clearall-icon" (click)="searchSport = ''"><i class="bi bi-x-lg"></i></span>
                    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a ngbDropdownItem class="dropdown-item"
                            *ngFor="let sport of homeData |search: searchSport :'match_name'">
                            <div class="event-info" (click)="matchDetail(sport)">
                                <div class="event-team">
                                    <div class="team-name">{{sport?.match_name}}</div>
                                </div>
                            </div>
                        </a>
                    </div>
                </form>
            </div>
        </div>
        <ul class="login-wrap">
            <form class="d-none d-lg-flex" role="search" novalidate="" [formGroup]='Form'>
                <li class="user error">
                    <i class="fa fa-user"></i>
                    <input class="me-2" type="text" placeholder="Username" formControlName="user_name"
                        placeholder="Username">
                </li>
                <li>
                    <input class="me-2" type="password" formControlName="password" placeholder="Password"
                        type="password">
                </li>
                <li class="valid-code">
                    <input id="validCode" type="text" placeholder="Validation" maxlength="4" [(ngModel)]="tPassword"
                        [ngModelOptions]="{standalone: true}">
                    <span class="code">{{transactionPassword}}</span>
                </li>
                <!-- Desktop -->
                <li>
                    <a class="btn-login" type="submit" (click)="onSubmitLogin('login')">Login
                        <span class="icon-login"></span>
                    </a>
                </li>
                <li>
                    <a class="btn-signup" type="submit"  [routerLink]="['/register']">Sign up</a>
                </li>
            </form>
            <span class="error" *ngIf="showResponseMessage">{{showResponseMessage}}</span>
            <a class="btn-signup d-block d-lg-none" type="submit"  [routerLink]="['/register']">Sign up</a>
            <a class="btn-login d-block d-lg-none" type="submit" [routerLink]="['/login']">Login</a>
        </ul>
    </div>
    <div class="menu-wrap d-none d-lg-block">
        <div class="full-wrap">
            <ul class="menu">
                <li class="ver-menu"><a [routerLink]="['/openHome/']" [class.select]='path == "openHome" '>Home</a></li>
                <li class="ver-menu"><a [routerLink]="['/openInplay/']"
                        [class.select]='path == "openInplay" '>In-Play</a></li>
                <li class="ver-menu"><a [routerLink]="['/openMulti/']" [class.select]='path == "openMulti" '>Multi
                        Markets</a></li>
                <li class="ver-menu" *ngFor="let sports of allSportsList;let i= index"
                    (click)="sports.is_live_sport == 1 ? openModalLoginDesktop(loginDesktop) :goToDashboard(sports.name,sports.sport_id,sports)">
                    <a [ngClass]="{'newlaunch':sports.sport_id <= -100 }"
                        [class.select]='(sports.sport_id <= -100)?  (path == "casino") :(sportsName == sports.name) '>{{sports?.name}}<span
                            class="tag-live" *ngIf="sports.sport_id > -100"><strong></strong>
                            {{sports?.live}}</span><img class="icon-tennis" src="assets/sky_img/transparent.gif"></a>
                </li>
            </ul>

            <ul class="setting-wrap">
                <li class="time_zone">
                    <span>Time Zone :</span>
                    GMT+5:30
                </li>
                <li>&nbsp;&nbsp;&nbsp;</li>
                <li>
                    <a class="one_click" (click)="openModalLoginDesktop(loginDesktop);">
                        One Click Bet
                    </a>
                </li>
                <li>
                    <a class="setting" (click)="openModalLoginDesktop(loginDesktop);">
                        Setting
                        <i class="fa fa-cog"></i>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>


<!-- Non Gambling Modal -->
<ng-template #loginCnfm>
    <div>
        <div class="modal-header border-bottom-0 pb-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="modalRef.hide()"></button>
        </div>
        <div class="modal-body text-center">
            <div class="confirm-text">
                <div class="text1">Non-Gambling Territories.</div>
                <hr class="confirm-line">
                <div class="text2">Connecting to our site from non gambling countries, it will be User's
                    responsibility to ensure that their use of the service is lawful.</div>
            </div>
            <div class="confirm-text mb-3">
                <div class="text1">Underage gambling is prohibited.</div>
                <hr class="confirm-line">
                <div class="text2">Please confirm if you are 18 years old and above as of today.</div>
            </div>
            <div class="modal-footer border-top-0 justify-content-center">
                <button class="btn btn-light" data-bs-target="#loginModal" data-bs-toggle="modal"
                    (click)="modalRef.hide();openModalLoginPopup(loginPopup)" style="cursor:pointer">Confirm</button>
                <button type="button" class="btn btn-outline-light" data-bs-dismiss="modal" (click)="modalRef.hide();"
                    style="cursor:pointer">Exit</button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #loginCnfmHead>
    <div>
        <div class="modal-header border-bottom-0 pb-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                (click)="modalRef.hide()"></button>
        </div>
        <div class="modal-body text-center">
            <div class="confirm-text">
                <div class="text1">Non-Gambling Territories.</div>
                <hr class="confirm-line">
                <div class="text2">Connecting to our site from non gambling countries, it will be User's
                    responsibility to ensure that their use of the service is lawful.</div>
            </div>
            <div class="confirm-text mb-3">
                <div class="text1">Underage gambling is prohibited.</div>
                <hr class="confirm-line">
                <div class="text2">Please confirm if you are 18 years old and above as of today.</div>
            </div>
            <div class="modal-footer border-top-0 justify-content-center">
                <button class="btn btn-light" data-bs-target="#loginModal" data-bs-toggle="modal"
                    (click)="modalRef.hide();onSubmitLogin('login')" style="cursor:pointer">Confirm</button>
                <button type="button" class="btn btn-outline-light" data-bs-dismiss="modal" (click)="modalRef.hide();"
                    style="cursor:pointer">Exit</button>
            </div>
        </div>
    </div>
</ng-template>

<!-- login modal -->
<ng-template #loginPopup>
    <div class="lgoin-main">
        <div class="login-head">
            <a class="close ui-link" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide();"></a>
            <h1><img src="{{logo}}" alt="{{logo}}"></h1>
            <div class="betfair-wrap">
                <p>Powered by</p>
                <span>
                    <!-- <img src="assets/images/logo-betfair.png" alt=""> -->
                </span>
            </div>
        </div>
        <form action="" [formGroup]='Form' novalidate="" class="form-login">
            <dl class="">
                <dd>
                    <input formControlName="user_name" placeholder="UserName" required="" type="text">
                </dd>
                <dd>
                    <input formControlName="password" placeholder="Password" required="" type="password">
                </dd>
                <dd>
                    <input type="text" id="validCode" pattern="[0-9]*" placeholder="Validation Code" autocomplete="off"
                        [(ngModel)]="tPassword" [ngModelOptions]="{standalone: true}">
                    <span class="code">{{transactionPassword}} </span>
                </dd>
                <dd style="margin-bottom: 0;"><span
                        class="err-invalid-modal error-message">{{showResponseMessage}}</span></dd>
                <dd>
                    <a class="btn-send ui-link" (click)="onSubmitLogin()">Login</a>
                </dd>
            </dl>
        </form>

        <ul class="policy-link">
            <li><a >Privacy Policy</a></li>
            <li><a >Terms and Conditions</a></li>
            <li><a >Rules and Regulations</a></li>
            <li><a >KYC</a></li>
            <li><a >Responsible Gaming</a></li>
            <li><a >About Us</a></li>
            <li><a >Self-exclusion Policy</a></li>
            <li><a >Underage Policy</a></li>
        </ul>
        <!-- <div class="support-wrap extend-support">
            <div class="extend-btn">
                <img src="assets/sky_img/support_black.svg" title="customer" class="support-customer">
                <a  >Customer support1</a>
                <a   class="split-line">support2</a>
            </div>
            <div class="extend-btn">
                <img src="assets/sky_img/whatsapp_black.svg" title="WhatsApp" class="support-whatsapp">
                <a  >+0000000000</a>
                <a   class="split-line">+0000000000</a>
            </div>
            <div class="extend-btn">
                <img src="assets/sky_img/telegram_black.svg" title="Telegram" class="support-telegram">
                <a  >**************</a>
                <a   class="split-line">***************</a>
            </div>
            <div class="support-social">
                <div class="social-btn">
                    <img src="assets/sky_img/skype_black.svg" title="Skype" class="support-skype">
                    <a  >Skype</a>
                </div>
                <div class="social-btn">
                    <img src="assets/sky_img/email_black.svg" title="Email" class="support-mail">
                    <a >Email</a>
                </div>
                <div class="social-btn">
                    <img src="assets/sky_img/instagram_black.svg" title="Instagram" class="support-ig">
                    <a  >Instagram</a>
                </div>
            </div>
        </div> -->
    </div>
</ng-template>

<!-- desktop login modal -->
<ng-template #loginDesktop>
    <div class="lgoin-main dialog-wrap-w login_to_go">
        <div class="kv">
            <img src="{{logo}}" alt="{{logo}}">
        </div>
        <form action="" [formGroup]='Form' novalidate="" class="login-panel">
            <dl class="">
                <dt>Please login to continue</dt>
                <dd>
                    <input formControlName="user_name" placeholder="UserName" required="" type="text">
                </dd>
                <dd>
                    <input formControlName="password" placeholder="Password" required="" type="password">
                </dd>
                <dd class="valid-code">
                    <input type="text" id="validCode" pattern="[0-9]*" placeholder="Validation Code" autocomplete="off"
                        [(ngModel)]="tPassword" [ngModelOptions]="{standalone: true}">
                    <span class="code">{{transactionPassword}}</span>
                </dd>
                <dd style="margin-bottom: 0;"><span
                        class="err-invalid-modal error-message">{{showResponseMessage}}</span></dd>
                <dd>
                    <a class="btn-send ui-link" (click)="onSubmitLogin()">Login</a>
                </dd>
            </dl>
        </form>
        <a id="close" class="close" data-bs-dismiss="modal" aria-label="Close" (click)="modalRef.hide();"></a>
    </div>
</ng-template>