// import { Injectable } from '@angular/core';
// import { io, Socket } from 'socket.io-client';
// import { CookieService } from 'ngx-cookie-service';
// @Injectable({
//   providedIn: 'root'
// })
// export class SocketServiceService {
//   socket: any;
//   public token = localStorage.getItem('userAccessToken');

//   constructor(private cookie: CookieService) {
//     this.socket =io('http://20.204.1.103:4050?access_token='+this.token,{transports: ['websocket'],upgrade:false});
//    }

//   setUpSocketConnection() {
//     this.socket =io('http://20.204.1.103:4050?access_token='+this.token,{transports: ['websocket'],upgrade:false});
//   }

// }
import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SocketServiceService {
  socket: any;
  onSuccess: Subject<any> = new Subject<any>();
  onFancySuccess: Subject<any> = new Subject<any>();
  public token = localStorage.getItem('userAccessToken');
  public Base_Url = environment['adminServerUrl'];
  constructor(private cookie: CookieService) {
    let a = this.Base_Url.split('/api/v1/')
    this.socket = io(a[0] + '?access_token=' + this.token, {
      transports: ['websocket'], upgrade: false,
      'reconnection': true,
      autoConnect: true,
      'reconnectionDelay': 1000,
      'reconnectionAttempts': 1000
    });
  }

  setUpSocketConnection() {
    let a = this.Base_Url.split('/api/v1/')
    this.socket = io(a[0] + '?access_token=' + this.token, {
      transports: ['websocket'], upgrade: false,
      'reconnection': true,
      autoConnect: true,
      'reconnectionDelay': 1000,
      'reconnectionAttempts': 1000
    });
  }
}